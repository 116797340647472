import React from "react";
import { Controller } from "react-hook-form";
import { Autocomplete } from "@material-ui/lab";
import TextField from "./Textfield";
import { getValueFromObject } from "../../utils/basic";

const ControlledAutocomplete = ({
  formProps,
  domain,
  idField = "id",
  labelField,
  getOptionLabel = (option) =>
    option[labelField] ??
    domain.find((d) => d[idField] === option?.[idField])?.[labelField] ??
    "",
  name,
  ignoreError = false,
  autocompleteProps = {},
  noOptionsText = "",
  disabled,
  ...otherProps
}) => {
  const {
    control,
    formState: { errors },
    rules,
    initialValues,
  } = formProps;

  const isError = getValueFromObject(errors, name) !== undefined;
  return (
    <Controller
      name={name}
      control={control}
      rules={rules[name]}
      defaultValue={getValueFromObject(initialValues, name)}
      render={({ field: { onChange, onBlur, value } }) => {
        return (
          <Autocomplete
            options={domain}
            classes={{
              popupIndicator: "MuiSelect-iconFilled",
              clearIndicator: "MuiSelect-iconFilled",
              option: "submenu-item",
              tag: "submenu-item chip",
            }}
            getOptionLabel={getOptionLabel}
            getOptionSelected={(option, value) =>
              option?.[idField] === value?.[idField]
            }
            value={value}
            {...autocompleteProps}
            onChange={(e, v) => {
              onChange(v);
              if (!!autocompleteProps.onChange) {
                autocompleteProps.onChange(v);
              }
            }}
            onBlur={() => {
              onBlur();
              if (!!autocompleteProps.onBlur) {
                autocompleteProps.onBlur();
              }
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                {...otherProps}
                value={value}
                error={isError}
                helperText={!ignoreError ? errors[name]?.message : undefined}
              />
            )}
            noOptionsText={noOptionsText}
            disabled={disabled}
          />
        );
      }}
    />
  );
};

export default ControlledAutocomplete;
