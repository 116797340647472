import {
  ControlledSelect,
  ControlledTextfield,
  DateTextField,
} from "../../../../../components/inputs";
import { MenuItem } from "../../../../../components";
import React, { useState } from "react";

const CardRegistroCasamento = ({ props, disabled, uf, dominioCartorios }) => {
  const [ufSelecionada, setUfSelecionada] = useState("");

  return (
    <div className="card spacer">
      <div className="card-body">
        <span className="card-title color-brand">Registro</span>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-3">
            <ControlledTextfield
              label={"Número do Registro"}
              name={"numeroRegistro"}
              formProps={props}
              disabled={disabled}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <ControlledTextfield
              label={"Data do Registro"}
              name={"dataRegistro"}
              formProps={props}
              disabled={disabled}
              InputProps={{
                inputComponent: DateTextField,
              }}
            />
          </div>

          <div className="col-12 col-md-6 col-lg-3">
            <ControlledTextfield
              formProps={props}
              label={"Nº Livro"}
              name={"numeroLivroRegistro"}
              disabled={disabled}
              margin={"none"}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <ControlledTextfield
              formProps={props}
              label={"Nº Folha"}
              name={"numeroFolhaRegistro"}
              disabled={disabled}
              margin={"none"}
            />
          </div>

          <div className="col-12 col-md-6 col-lg-9">
            <ControlledTextfield
              formProps={props}
              label={"Comarca"}
              name={"comarcaRegistro"}
              disabled={disabled}
              margin={"none"}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <ControlledSelect
              name={"ufRegistro"}
              formProps={props}
              disabled={disabled}
              label={"UF"}
              onChange={(e) => setUfSelecionada(e.target.value)}
              margin={"none"}
            >
              {uf.map((item) => (
                <MenuItem
                  className={"select-submenu-item"}
                  key={item.codigo}
                  value={item.codigo}
                >
                  {item.nome}
                </MenuItem>
              ))}
            </ControlledSelect>
          </div>
          <div className="col-12 col-md-6 col-lg-12">
            <ControlledTextfield
              formProps={props}
              label={"Cartório Registro"}
              name={"nomeCartorioRegistro"}
              disabled={disabled}
              margin={"none"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardRegistroCasamento;
