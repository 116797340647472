import { useCallback, useState } from "react";
import HistoricoPage from "./HistoricoPage";
import { useSelector, useDispatch } from "react-redux";
import { getUsuario } from "../../../../selectors/usuario.selectors";
import useCompleteForm from "../../../../hooks/useCompleteForm";
import validators, { validEmail } from "../../../../utils/validators";

import {
  getDetalheProposta,
  getHistorico,
  getExportHistorico,
} from "../../../../selectors/propostas.selectors";
import { actions } from "../../../../reducers/propostas.actions";
import { actions as emailActions } from "../../../../reducers/emails/emails.actions";
import { htmlToDraft, draftToHtml } from "../../../../utils/draft";
import formatters from "../../../../utils/formatters";
import { getEnvioEmails } from "../../../../selectors/emails.selectors";

const HistoricoPageConnected = () => {
  const dispatch = useDispatch();
  const { perfil } = useSelector(getUsuario);
  const detalheProposta = useSelector(getDetalheProposta);
  const historico = useSelector(getHistorico);
  const exportHistorico = useSelector(getExportHistorico);
  const { sending } = useSelector(getEnvioEmails);
  const [abrirDetalhes, setAbrirDetalhes] = useState(false);
  const [idConsultaEmailReferencia, setIdConsultaEmailReferencia] = useState(0);
  const [idConsulta, setIdConsulta] = useState(0);
  const [canRespond, setCanRespond] = useState(false);
  const [detalhesData, setDetalhesData] = useState({});

  const exportarHistoricoAtividades = useCallback(
    (values) => {
      dispatch(actions.exportarHistoricoConsultas.request(values));
    },
    [dispatch]
  );

  const exportarHistoricoDocumentos = useCallback(
    (values) => {
      dispatch(actions.exportHistoricoDocumentos.request(values));
    },
    [dispatch]
  );

  const exportarHistoricoEmails = useCallback(
    (values) => {
      dispatch(actions.exportHistoricoEmails.request(values));
    },
    [dispatch]
  );

  const cleanFile = useCallback(() => {
    dispatch(actions.cleanExportHistoricoAtividadesFile);
  }, [dispatch]);
  const [habilitarResponder, setHabilitarResponder] = useState(true);

  const menuItens = [
    {
      key: "atividades",
      label: "Atividades",
    },
    {
      key: "documentos",
      label: "Documentos",
    },
    {
      key: "emails",
      label: "E-mails",
    },
  ];

  const selecionarOpcaoMenu = useCallback((e, value) => {
    dispatch(actions.selecionarOpcaoMenuHistorico(value));
  });
  const handleEmailGoBack = useCallback(() => {
    setIdConsultaEmailReferencia(null);
    setHabilitarResponder(true);
    setAbrirDetalhes(false);
  }, [setAbrirDetalhes]);

  const postSubmit = useCallback(() => {
    handleEmailGoBack();
    dispatch(actions.historicoEmails.request(idConsulta));
  }, [dispatch, actions, idConsulta]);

  const [formProps, handleSubmit] = useCompleteForm({
    rules: useCallback(
      () => ({
        template: validators.string({ required: true }),
        assunto: validators.string({ required: true }),
        destinatarios: validators.array({
          required: true,
          custom: {
            isEmail: (array) => {
              for (const { value } of array) {
                const validation = validEmail(value);
                if (typeof validation === "string") {
                  return `Email inválido (${value})`;
                }
              }
              return true;
            },
          },
        }),
        conteudo: validators.object({
          custom: {
            exists: (value) => {
              const html = draftToHtml(value);
              console.log({ html });
              if (
                html !== undefined &&
                html !== null &&
                html !== "" &&
                html !== "<p></p>\n"
              ) {
                return true;
              }
              return "Campo obrigatório";
            },
          },
        }),
      }),
      []
    ),
    initialValues: useCallback(
      () => ({
        template: "",
        assunto: "",
        destinatarios: [],
        conteudo: htmlToDraft(""),
      }),
      []
    ),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          emailActions.enviarEmail.request({
            ...values,
            idConsultaEmailReferencia,
            conteudo: draftToHtml(values.conteudo),
            callback: postSubmit,
          })
        );
      },
      [dispatch, idConsultaEmailReferencia, handleEmailGoBack, postSubmit]
    ),
  });

  const formCallBack = useCallback(
    (value) => {
      setDetalhesData(value);
      formProps.setValue("assunto", value.assunto);
      formProps.setValue(
        "destinatarios",
        value?.destinatarios?.indexOf(";") === -1
          ? [{ value: value?.destinatarios, label: value?.destinatarios }]
          : value?.destinatarios?.split(";").map((item) => {
              return { value: item.trim(), label: item.trim() };
            })
      );
      formProps.setValue("conteudo", htmlToDraft(value.conteudo));
    },
    [setAbrirDetalhes, formProps, htmlToDraft, setDetalhesData]
  );
  const detalhesEmail = {
    handleEmailGoBack,
    formProps,
  };
  const handleAbrirDetalhesEmail = useCallback(
    (value) => {
      dispatch(emailActions.emailParams(value));
      setIdConsultaEmailReferencia(value.id);
      setIdConsulta(value.proposta);
      setCanRespond(value.tipo === "R");
      dispatch(
        actions.historicoEmailsDetalhes.request({
          id: value.id,
          callback: formCallBack,
        })
      );
      setAbrirDetalhes(!abrirDetalhes);
    },
    [dispatch, abrirDetalhes, formCallBack, setCanRespond]
  );

  const responseEmailHeader = (assunto, conteudo) => {
    let underline = "";
    for (let i = 0; i < 100; i++) {
      underline += "_";
    }

    let header = `<br><br><span>${underline}</span><br>`;
    header = `${header}<span style='font-size:16px'><strong>De: </strong>${detalhesData.emailResposta}</span><br>`;
    header = `${header}<span style='font-size:16px'><strong>Enviada em: </strong>${formatters.dates.long(
      new Date(detalhesData.dataHoraEnvio)
    )}</span><br>`;
    header = `${header}<span style='font-size:16px'><strong>Para: </strong>${detalhesData.destinatarios}</span><br>`;
    header = `${header}<span style='font-size:16px'><strong>Assunto: </strong>${assunto}</span><br>`;
    return `${header}${conteudo}`;
  };

  const handleResponder = useCallback(() => {
    formProps.setValue(
      "conteudo",
      htmlToDraft(
        responseEmailHeader(
          formProps.getValues("assunto"),
          draftToHtml(formProps.getValues("conteudo"))
        )
      )
    );
    formProps.setValue("assunto", `RES: ${formProps.getValues("assunto")} `);
    setHabilitarResponder(false);
  }, [setHabilitarResponder, formProps, draftToHtml, htmlToDraft]);
  return (
    <HistoricoPage
      loading={historico.loading}
      proposta={detalheProposta?.id}
      codigoPropostaproposta={detalheProposta?.codigo}
      historico={historico.list}
      perfil={perfil}
      exportarHistoricoAtividades={exportarHistoricoAtividades}
      loadingExportHistorico={exportHistorico.loading}
      exportHistorico={exportHistorico}
      cleanFile={cleanFile}
      menuItens={menuItens}
      selecionarOpcaoMenu={selecionarOpcaoMenu}
      menuSelecionado={historico["menu-selecionado"] ?? "atividades"}
      historicoDocumentos={historico?.documentos?.data}
      historicoDocumentosLoading={historico?.documentos?.loading}
      exportarHistoricoDocumentos={exportarHistoricoDocumentos}
      historicoEmails={historico?.emails?.data}
      historicoEmailsLoading={historico?.emails?.loading}
      exportarHistoricoEmails={exportarHistoricoEmails}
      abrirDetalhes={abrirDetalhes}
      handleAbrirDetalhesEmail={handleAbrirDetalhesEmail}
      detalhesEmail={detalhesEmail}
      historicoEmailDetalhesLoading={historico?.detalhes_email?.loading}
      handleSubmit={formProps.handleSubmit(handleSubmit)}
      habilitarResponder={habilitarResponder}
      handleResponder={handleResponder}
      canRespond={canRespond}
      sending={sending}
    />
  );
};

export default HistoricoPageConnected;
