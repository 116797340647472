import React from "react";
import { Dialog, DialogContent, IconButton } from "@material-ui/core";
import { CloseSharp } from "@material-ui/icons";
import formatters from "../../../../utils/formatters";
import { condicoesMatrizChecklist, uf } from "../../../../utils/parametros";

const DetalhesDocumentoDialog = ({ handleClose, detalhes, open }) => {
  return (
    <Dialog
      onClose={handleClose}
      classes={{ paper: "w100" }}
      maxWidth="md"
      open={open}
    >
      <DialogContent>
        <IconButton
          onClick={handleClose}
          classes={{ root: "position-absolute clickable close-icon" }}
        >
          <CloseSharp />
        </IconButton>
        <div className={"page-title"}>Detalhes do Documento</div>
        <div className={"mb-3"}>
          <div className="row">
            <div className="col-md-6 col-xs-12">
              <span className="label">Data/hora da criação</span>
              <div>
                <span className="data-label">
                  {formatters.dates.withHours(
                    new Date(detalhes.dataHoraCriacao)
                  )}
                </span>
              </div>
            </div>
            <div className="col-md-6 col-xs-12">
              <span className="label">Motivo da criação</span>
              <div>
                <span className="data-label">
                  {detalhes.tipoCriacao === "A"
                    ? "AUTOMÁTICA"
                    : detalhes.tipoCriacao === "M"
                    ? "MANUAL"
                    : "SUBSTITUIÇÃO"}
                </span>
              </div>
            </div>
          </div>
          {!!detalhes.dataHoraIndexacao && (
            <div className="row">
              <div className="col-md-6 col-xs-12">
                <span className="label">Data/hora da indexação</span>
                <div>
                  <span className="data-label">
                    {formatters.dates.withHours(
                      new Date(detalhes.dataHoraIndexacao)
                    )}
                  </span>
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <span className="label">Indexado por</span>
                <div>
                  <span className="data-label">
                    {detalhes.usuarioIndexacao}
                  </span>
                </div>
              </div>
            </div>
          )}
          {!!detalhes.dataHoraAnalise && (
            <div className="row">
              <div className="col-md-6 col-xs-12">
                <span className="label">Data/hora da análise</span>
                <div>
                  <span className="data-label">
                    {formatters.dates.withHours(
                      new Date(detalhes.dataHoraAnalise)
                    )}
                  </span>
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <span className="label">Analisado por</span>
                <div>
                  <span className="data-label">{detalhes.usuarioAnalise}</span>
                </div>
              </div>
              <div className="col-md-6 col-xs-12">
                <span className="label">Resultado da análise</span>
                <div>
                  <span className="data-label">
                    {detalhes.situacao_analise === "A"
                      ? "APROVADO"
                      : detalhes.situacao_analise === "S"
                      ? "RECUSADO"
                      : detalhes.situacao_analise === "D"
                      ? "DISPENSADO"
                      : ""}
                  </span>
                </div>
              </div>
            </div>
          )}
          <div className="row">
            <div className="col-md-6 col-xs-12">
              <span className="label">UF</span>
              <div>
                <span className="data-label">
                  {detalhes.uf
                    ? uf.filter((m) => {
                        return m.codigo === detalhes.uf;
                      })[0].nome
                    : ""}
                </span>
              </div>
            </div>
            <div className="col-md-6 col-xs-12">
              <span className="label">Localidade</span>
              <div>
                <span className="data-label">{detalhes.localidade}</span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-6 col-xs-12">
              <span className="label">Cartório</span>
              <div>
                <span className="data-label">{detalhes.cartorio}</span>
              </div>
            </div>
            <div className="col-md-6 col-xs-12">
              <span className="label">Condição</span>
              <div>
                <span className="data-label">
                  {
                    condicoesMatrizChecklist.filter((m) => {
                      return m.codigo === detalhes.condicao;
                    })[0]?.descricao
                  }
                </span>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DetalhesDocumentoDialog;
