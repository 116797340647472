import React from "react";
import formatters from "../../../../utils/formatters";

const formatAddress = (imovel) => {
  if (!imovel.logradouro) {
    return "Sem endereço informado";
  }

  let endereco = `${imovel.logradouro} ${imovel.numero || ""}`;
  if (!!imovel.complemento) {
    endereco += `, ${imovel.complemento}`;
  }
  endereco += ` - ${imovel.municipio}/${imovel.uf}`;

  return endereco;
};

const CardParticipantes = ({ proposta, imovel, handleOnClick }) => {
  return (
    <div
      className="card restrict clickable"
      onClick={() => handleOnClick(proposta, imovel.id)}
    >
      <div
        className={
          "d-flex flex-row card-proposta-title card-proposta-title-IntervenienteQuitante"
        }
      >
        <div
          className={
            "participantes-label-title participantes-label-title-IntervenienteQuitante"
          }
        >
          IMÓVEL
        </div>
      </div>
      <div className="card-body d-flex flex-column">
        <div className="row">
          <div>
            <div className="col-12">
              <div className="participantes-label participantes-label-name">
                {formatAddress(imovel)}
              </div>
            </div>
            {imovel?.valorAvaliacao > 0 && (
              <div className="col-12">
                <div className="participantes-label">
                  Valor de avaliação R$&nbsp;
                  {formatters.numbers.currency(imovel.valorAvaliacao)}
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardParticipantes;
