import React, { useState, useCallback } from "react";
import Loader from "../../../../components/loaders";
import Filtros from "./Filtros";
import { Add } from "@material-ui/icons";
import AdicionarEtapaDialog from "./AdicionarEtapaDialog.connected";
import DetalhesAtividadeDialog from "./DetalhesAtividadeDialog.connected";
import Atividade from "./Atividade";

const ListaAtividades = ({
  loading,
  filtro,
  proposta,
  atividades,
  handleFilterClick,
  perfil,
  etapas,
  formProps,
  dominioEtapa,
  atualizarSituacao,
  loadingAtividade,
  handleOpenEnvioEmail,
}) => {
  const [dadosAdicionarEtapa, setDadosAdicionarEtapa] = useState({
    open: false,
  });

  const [dadosDetalheAtividade, setDadosDetalheAtividade] = useState({
    open: false,
  });

  const handleCloseAdicionarEtapa = useCallback(() => {
    setDadosAdicionarEtapa({
      open: false,
    });
  }, []);
  const handleOpenAdicionarEtapa = useCallback(
    (etapa) => {
      setDadosAdicionarEtapa({
        open: true,
        etapa,
        proposta,
      });
    },
    [proposta]
  );

  const handleCloseDetalhesAtividade = useCallback(() => {
    setDadosDetalheAtividade({
      open: false,
    });
  }, []);
  const handleOpenDetalhesAtividade = useCallback((values) => {
    setDadosDetalheAtividade({
      open: true,
      ...values,
    });
  }, []);

  return (
    <>
      {!!loading && (
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <Loader type={"dots"} color={"brand"} size={50} />
          </div>
        </div>
      )}
      {!loading && (
        <>
          <Filtros
            filtro={filtro}
            handleFilterClick={handleFilterClick}
            formProps={formProps}
            dominioEtapa={dominioEtapa}
          />
          {atividades.length === 0 && (
            <div className="flex d-flex flex-column justify-content-center align-items-center">
              <span className="d-block colored-card-text mt-4 color-secondary text-center">
                Nenhuma atividade encontrada com o filtro selecionado.
                <br />
                Selecione outro filtro.
              </span>
            </div>
          )}
          {etapas.map(
            (p, i) =>
              p.atividades > 0 && (
                <React.Fragment key={i}>
                  <div className="row">
                    <div className="col-12">
                      <div className="card" style={{ minHeight: 100 }}>
                        <div className="card-body d-flex flex-column">
                          <div className={"row d-flex align-items-center"}>
                            <div className={"col mt-0"}>
                              <div className="card-title-checklist">
                                {p.etapa}
                              </div>
                            </div>
                            {perfil === "BPO" && (
                              <div className="d-none d-md-block">
                                <div
                                  className="botao-adicionar mr-3"
                                  onClick={() => handleOpenAdicionarEtapa(p.id)}
                                >
                                  <Add className="botao-adicionar-icon mr-1" />
                                  <span className="botao-adicionar-text">
                                    ADICIONAR ATIVIDADE
                                  </span>
                                </div>
                              </div>
                            )}
                          </div>
                          <div className={"row"}>
                            <div className="col-12">
                              <div
                                className={
                                  "row mt-0 d-flex align-items-center medium-row texto-dispensar tr-odd"
                                }
                              >
                                <div className="col-sm-12 col-lg-5 mt-sm-2 mt-lg-0">
                                  <span>Atividade</span>
                                </div>
                                <div className="col-sm-12 col-lg-1 mt-sm-2 mt-lg-0 ">
                                  <span>Resp.</span>
                                </div>
                                <div className="col-sm-12 col-lg-2 mt-sm-2 mt-lg-0 ">
                                  <span>Previsão Término</span>
                                </div>
                                <div className="col-sm-12 col-lg-3 mt-sm-2 mt-lg-0 text-center">
                                  <span>Situação</span>
                                </div>
                              </div>
                              {atividades
                                .filter((a) => {
                                  return a.Atividade.etapa === p.id;
                                })
                                .map((d, index) => (
                                  <Atividade
                                    key={d.id}
                                    atividade={d}
                                    index={index}
                                    loading={d.id === loadingAtividade}
                                    perfil={perfil}
                                    handleOpenDetalhesDocumento={
                                      handleOpenDetalhesAtividade
                                    }
                                    handleOpenEnvioEmail={handleOpenEnvioEmail}
                                    atualizarSituacao={atualizarSituacao}
                                  />
                                ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              )
          )}
          <DetalhesAtividadeDialog
            dados={dadosDetalheAtividade}
            handleClose={handleCloseDetalhesAtividade}
          />
        </>
      )}
      <AdicionarEtapaDialog
        dados={dadosAdicionarEtapa}
        handleClose={handleCloseAdicionarEtapa}
        loadingAbrirAtividade={loading}
      />
    </>
  );
};

export default ListaAtividades;
