import DocumentosPage from "./DocumentosPage";
import { useDispatch, useSelector } from "react-redux";
import { getDocumentos } from "../../../selectors/administracao.selectors";
import { useCallback, useState } from "react";
import { actions } from "../../../reducers/administracao/documentos.actions";
import validators from "../../../utils/validators";
import { useForm } from "react-hook-form";

const DocumentosPageConnected = () => {
  const dispatch = useDispatch();
  const [pesquisa, setPesquisa] = useState();

  const documentos = useSelector(getDocumentos);
  const loading = documentos?.loading;
  const total = documentos?.total;
  const pagina = documentos?.page;

  const handlePagination = useCallback(
    (e, page) => {
      if (!!pesquisa) {
        dispatch(
          actions.buscarDocumento.request({ search: pesquisa, pagina: page })
        );
      } else {
        dispatch(actions.listarDocumentos.request({ pagina: page }));
      }
    },
    [dispatch, pesquisa]
  );

  const handleEdit = useCallback(
    (values) => {
      dispatch(actions.editarDocumento({ documento: values }));
    },
    [dispatch]
  );

  const handleNew = useCallback(() => {
    dispatch(actions.novoDocumento());
  }, [dispatch]);

  const handleDelete = useCallback(
    (i) => {
      dispatch(actions.excluirDocumento.request({ idDocumento: i }));
    },
    [dispatch]
  );

  const initialValues = {
    search: "",
  };

  const rules = {
    search: validators.string({ required: false }),
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const handleSearch = useCallback(
    (values) => {
      const request = {
        by: "codigo",
        value: values.search,
      };
      setPesquisa(request);
      dispatch(actions.buscarDocumento.request({ search: request, pagina: 1 }));
    },
    [dispatch, setPesquisa]
  );

  return (
    <DocumentosPage
      documentos={documentos.list}
      documento={documentos.documento}
      loading={loading}
      total={total}
      handlePagination={handlePagination}
      pagina={pagina}
      handleNew={handleNew}
      handleEdit={handleEdit}
      formProps={formProps}
      handleSearch={handleSearch}
      handleDelete={handleDelete}
    />
  );
};

export default DocumentosPageConnected;
