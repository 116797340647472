import Loader from "../../../components/loaders";
import React from "react";
import Documento from "./DocumentoErroIntegracaoPage";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import BasePage from "../../BasePage";
import Filtros from "./Filtros";

const DocumentosErroIntegracaoPage = ({
  documentos,
  handlePagination,
  formProps,
  handleFilterClick,
}) => {
  return (
    <BasePage isLoggedIn={true}>
      <h2>Painel Integração CRIM</h2>
      {!documentos.loading && (
        <div className={"row spacer"}>
          <div className={"col-12"}>
            <Filtros
              documentos={documentos.listaFiltros.map((a) => {
                return a.Documento.nome;
              })}
              consultas={documentos.listaFiltros.map((a) => {
                return a["consulta-proposta"]?.codigo;
              })}
              formProps={formProps}
              handleFilterClick={handleFilterClick}
            />
          </div>
        </div>
      )}
      <div className="row spacer">
        <div className="col-12">
          <div className="card" style={{ minHeight: 100 }}>
            <div className="card-body d-flex flex-column">
              {!!documentos.loading && (
                <div className="row">
                  <div className="col-12 d-flex justify-content-center">
                    <Loader type={"dots"} color={"brand"} size={80} />
                  </div>
                </div>
              )}
              {!documentos.loading && (
                <>
                  <div className={"row d-flex align-items-center"}>
                    <div className={"col mt-0"}>
                      <div className="card-title-dashboard color-brand">
                        Documentos com Erro na Integração
                      </div>
                    </div>
                  </div>
                  <div className={"row spacer"}>
                    <div className="col-2 title-table-dashboard">
                      <span>CONSULTA</span>
                    </div>
                    <div className="col-2 title-table-dashboard">
                      <span>TIPO</span>
                    </div>
                    <div className="col-4 title-table-dashboard">
                      <span>NOME</span>
                    </div>
                    <div className="col-4 title-table-dashboard">
                      <span>DOCUMENTO</span>
                    </div>
                    <div className="col-2 title-table-dashboard">
                      <span>DATA ERRO</span>
                    </div>
                    <div className="col-10 title-table-dashboard">
                      <span>ERRO</span>
                    </div>
                  </div>
                  <div className={"row"}>
                    <div className="col-12">
                      {documentos.list.map((d, index) => (
                        <Documento key={d.id} d={d} index={index} />
                      ))}
                    </div>
                  </div>
                  {documentos.total > 10 && (
                    <>
                      <div className="row spacer">
                        <div className="col-12">
                          <Pagination
                            count={Math.ceil(documentos.total / 10)}
                            page={documentos.page}
                            variant="outlined"
                            shape="rounded"
                            onChange={handlePagination}
                          />
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </BasePage>
  );
};

export default DocumentosErroIntegracaoPage;
