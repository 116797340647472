import React from "react";
import formatters from "../../../../utils/formatters";
import Loader from "../../../../components/loaders";
import { DeleteForeverSharp, CloseSharp } from "@material-ui/icons";
import { CardWithDeleteButton } from "../../../../components";
import { converterData } from "../../../../utils/basic";

const CardParticipantes = ({
  proposta,
  pessoa,
  participacao,
  loading,
  error,
  abrirDetalhePessoa,
  excluirParticipante,
  disabled,
}) => {
  return (
    <CardWithDeleteButton
      style={{ minHeight: 190 }}
      canDelete={!disabled}
      renderDefault={() => (
        <>
          <div
            className={
              "d-flex flex-row card-proposta-title card-proposta-title-" +
              participacao.replace(" ", "")
            }
          >
            <div
              className={
                "participantes-label-title participantes-label-title-" +
                participacao.replace(" ", "")
              }
            >
              {participacao}
            </div>
          </div>
          <div className="card-body d-flex flex-column">
            {!!loading && (
              <div className="flex d-flex justify-content-center align-items-center">
                <Loader type={"dots"} size={80} />
              </div>
            )}
            {!!error && (
              <div className="flex d-flex flex-column justify-content-center align-items-center">
                <span className="d-block colored-card-text mt-4 color-red">
                  Não foi possível obter as informações
                </span>
              </div>
            )}
            {!loading && !error && (
              <div className="row">
                <div>
                  <div className="col-12 mt-0">
                    <div
                      className="participantes-label participantes-label-name clickable"
                      onClick={() =>
                        abrirDetalhePessoa(
                          proposta,
                          pessoa["id_consulta_pessoa"]
                        )
                      }
                    >
                      {pessoa.nome || pessoa.nomeInterveniente}
                    </div>
                  </div>
                  {participacao !== "Interveniente Quitante" && (
                    <div className="col-12 mt-1">
                      <div className="participantes-label participantes-label-medium">
                        {pessoa["nr_cpf_cnpj"].length <= 11
                          ? formatters.strings.cpf(pessoa["nr_cpf_cnpj"])
                          : formatters.strings.cnpj(pessoa["nr_cpf_cnpj"])}
                      </div>
                    </div>
                  )}
                  {pessoa?.PessoaDadoPessoal?.["dt_nascimento"] && (
                    <div className="col-12 mt-1">
                      <div className="participantes-label">
                        {pessoa.nr_cpf_cnpj.length <= 11
                          ? "Nascido em "
                          : "Aberta em "}{" "}
                        {converterData(
                          pessoa.PessoaDadoPessoal?.["dt_nascimento"]
                        )}
                      </div>
                    </div>
                  )}
                  {pessoa?.PessoaDadoPessoal?.["nm_conjuge"] && (
                    <div className="col-12 mt-1">
                      <div className="participantes-label">
                        Casado com{" "}
                        <span className="participantes-label-name">
                          {pessoa?.PessoaDadoPessoal?.["nm_conjuge"]}
                        </span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        </>
      )}
      renderSecondary={({ handleClick }) => (
        <div className="card-body bg-red visible-1s pb-0">
          <CloseSharp
            className="position-absolute clickable close-icon"
            onClick={handleClick}
          />
          <div className="row align-items-center justify-content-center">
            <DeleteForeverSharp className="large-delete-icon" />
            <span className="color-white text-center">
              Confirma a exclusão do(a) {participacao}{" "}
              <span className="delete-buyer-name">
                {pessoa.nome || pessoa.nomeInterveniente}
              </span>
              ?
            </span>
            <div
              className="clickable confirm-delete-button spacer"
              onClick={() =>
                excluirParticipante(proposta, pessoa["id_consulta_pessoa"])
              }
            >
              SIM, PODE EXCLUIR
            </div>
          </div>
        </div>
      )}
    />
  );
};

export default CardParticipantes;
