import {
  createAsyncAction,
  createSyncAction,
} from "../../utils/actionCreators";

export const types = {
  LISTAR_CLAUSULAS: "@@administracao/LISTAR_CLAUSULAS",
  ATUALIZAR_CLAUSULA: "@@administracao/ATUALIZAR_CLAUSULA",
  NOVA_CLAUSULA: "@@administracao/NOVA_CLAUSULA",
  EDITAR_CLAUSULA: "@@administracao/EDITAR_CLAUSULA",
  VOLTAR_LISTA: "@@/administracao/VOLTAR_LISTA",
  INCLUIR_CLAUSULA: "@@administracao/INCLUIR_CLAUSULA",
  SEARCH_CLAUSULA: "@@administracao/SEARCH_CLAUSULA",
  EXCLUIR_CLAUSULA: "@@administracao/EXCLUIR_CLAUSULA",
};

export const actions = {
  listarClausulas: createAsyncAction(types.LISTAR_CLAUSULAS),
  atualizarClausula: createAsyncAction(types.ATUALIZAR_CLAUSULA),
  novaClausula: () => createSyncAction(types.NOVA_CLAUSULA),
  editarClausula: (values) => createSyncAction(types.EDITAR_CLAUSULA, values),
  voltarListaClausula: () => createSyncAction(types.VOLTAR_LISTA),
  incluirClausula: createAsyncAction(types.INCLUIR_CLAUSULA),
  buscarClausula: createAsyncAction(types.SEARCH_CLAUSULA),
  excluirClausula: createAsyncAction(types.EXCLUIR_CLAUSULA),
};
