import BasePage from "../BasePage";
import LoginForm from "./LoginForm";
import { ReactComponent as Logo } from "assets/logo-preto.svg";

const LoginPage = (props) => {
  return (
    <BasePage>
      <div className="row justify-content-center">
        <div className="col-12 d-flex justify-content-center">
          <Logo className={"logo-login col-auto"} />
        </div>
        <div className="col-12 mw-480">
          <div className="card">
            <div className="card-body">
              <div className="card-title text-uppercase text-center">
                Bem-vindo!
                <br />
                Faça seu Login
              </div>
              <div className="mini-separator mb-2" />
              <LoginForm {...props} />
            </div>
          </div>
        </div>
      </div>
    </BasePage>
  );
};

export default LoginPage;
