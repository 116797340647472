import React, { useCallback } from "react";
import { Button, MenuItem } from "../../../../components";
import { ControlledSelect } from "../../../../components/inputs";
import Loader from "../../../../components/loaders";
import {
  estadoCivil,
  regimeComunhao,
  qualificacao,
} from "../../../../utils/parametros";

const Filtros = ({ filtro, handleFilterClick, formProps, resetFilter }) => {
  const handleClickNext = useCallback(
    (values) => {
      handleFilterClick(values);
    },
    [handleFilterClick]
  );

  const handleResetForm = useCallback(() => {
    formProps.setValue("regimeCasamento", "");
    formProps.setValue("tipoQualificacao", "");
    formProps.setValue("estadoCivil", "");
    resetFilter();
  }, [resetFilter]);

  return (
    <form onSubmit={formProps.handleSubmit(handleClickNext)}>
      <div className="card">
        <div className={"card-body"}>
          <div className={"row"}>
            <div className="col-12 card-subtitle-checklist mt-0">
              <span className="">Filtros:</span>
            </div>
          </div>
          {!!filtro?.loading && (
            <div className="row">
              <div className="col-12 d-flex justify-content-center">
                <Loader type={"dots"} color={"brand"} size={50} />
              </div>
            </div>
          )}
          {!filtro?.loading && (
            <>
              <div className="row">
                <div className="col-4 mt-0">
                  <ControlledSelect
                    name={"tipoQualificacao"}
                    formProps={formProps}
                    label={"Qualificação"}
                    margin={"none"}
                  >
                    {qualificacao
                      .filter((c) => {
                        return c.id === "CO" || c.id === "VD" || c.id === "IM";
                      })
                      .map((item) => (
                        <MenuItem
                          className={"select-submenu-item"}
                          key={item.id}
                          value={item.id}
                        >
                          {item.nome.toUpperCase()}
                        </MenuItem>
                      ))}
                  </ControlledSelect>
                </div>
                <div className="col-4 mt-0">
                  <ControlledSelect
                    name={"estadoCivil"}
                    formProps={formProps}
                    label={"Estado Civil"}
                    margin={"none"}
                  >
                    {estadoCivil.map((item) => (
                      <MenuItem
                        className={"select-submenu-item"}
                        key={item.id}
                        value={item.id}
                      >
                        {item.descricao.toUpperCase()}
                      </MenuItem>
                    ))}
                  </ControlledSelect>
                </div>
                <div className="col-4 mt-0">
                  <ControlledSelect
                    name={"regimeCasamento"}
                    formProps={formProps}
                    label={"Regime de Casamento"}
                    margin={"none"}
                  >
                    {regimeComunhao.map((item) => (
                      <MenuItem
                        className={"select-submenu-item"}
                        key={item.id}
                        value={item.id}
                      >
                        {item.descricao.toUpperCase()}
                      </MenuItem>
                    ))}
                  </ControlledSelect>
                </div>
              </div>
              <div className="row spacer">
                <div className="offset-md-6 col-md-3 col-12">
                  <Button label="LIMPAR" onClick={handleResetForm} />
                </div>
                <div className="col-md-3 col-12">
                  <Button label="FILTRAR" type={"submit"} />
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </form>
  );
};

export default Filtros;
