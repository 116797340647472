import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { EditOutlined, DeleteOutlined } from "@material-ui/icons";
import { condicoesMatrizChecklist, uf } from "../../../utils/parametros";

const converterQualificacao = (q) => {
  switch (q) {
    case "CO":
      return "COMPRADOR";
    case "CC":
      return "CÔNJUGE COMPRADOR";
    case "VD":
      return "VENDEDOR";
    case "CV":
      return "CÔNJUGE VENDEDOR";
    case "IM":
      return "IMÓVEL";
    default:
      return "N/A";
  }
};

const converterTipoPessoa = (q) => {
  switch (q) {
    case "F":
      return "FÍSICA";
    case "J":
      return "JURÍDICA";
    case "N":
      return "AMBAS";
    default:
      return "N/A";
  }
};

const CondicaoChecklist = ({
  condicao,
  index,
  handleEdit,
  handleOpenExclusaoDocumento,
}) => {
  return (
    <div
      className={
        index % 2 === 0
          ? "row mt-0 d-flex align-items-center medium-row tr-even-adm"
          : "row mt-0 d-flex align-items-center medium-row tr-odd"
      }
    >
      <div className="col-4 col-md-4 mt-1 user-table ">
        <span>{condicao.Documento.nome}</span>
      </div>
      <div className="col-2 col-md-2 mt-1 user-table">
        {converterQualificacao(condicao.qualificacao)}
      </div>
      <div className="col-1 col-md-1  mt-1 user-table">
        {condicao.qualificacao === "IM"
          ? ""
          : converterTipoPessoa(condicao.participante)}
      </div>
      <div className="col-2 col-md-2 mt-1 user-table">
        <span>{condicao.Localidade?.nome}</span>
      </div>
      <div className="col-3 col-md-3 mt-1 user-table">
        <span>{condicao.Produto?.nome}</span>
      </div>
      <div className="col-4 col-md-4 mt-1 user-table">
        <span>
          {
            condicoesMatrizChecklist.filter((m) => {
              return m.codigo === condicao.condicao;
            })[0]?.descricao
          }
        </span>
      </div>
      <div className="col-2 col-md-2 mt-1 user-table">
        <span>
          {condicao.uf
            ? uf.filter((m) => {
                return m.codigo === condicao.uf;
              })[0].nome
            : ""}
        </span>
      </div>
      <div className="col-5 col-md-5 mt-1 user-table">
        <span>{condicao.Organizacao?.nome}</span>
      </div>
      <Tooltip title="Editar">
        <IconButton className="p-2" onClick={() => handleEdit(condicao)}>
          <EditOutlined className={"color-black"} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Excluir">
        <IconButton
          className="p-2"
          onClick={() => handleOpenExclusaoDocumento(condicao)}
        >
          <DeleteOutlined className={"color-black"} />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default CondicaoChecklist;
