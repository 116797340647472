import React from "react";
import { Dialog, DialogContent, IconButton } from "@material-ui/core";
import { Button } from "../../../../components";
import { ControlledTextfield } from "../../../../components/inputs";
import { CloseSharp } from "@material-ui/icons";

const ConfirmarAnaliseDialog = ({
  handleSubmit,
  submitting,
  dados,
  handleClose,
  formProps,
  open,
}) => {
  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      classes={{ paper: "w100" }}
      maxWidth="sm"
      open={open}
    >
      <DialogContent>
        {!submitting && (
          <IconButton
            onClick={handleClose}
            classes={{ root: "position-absolute clickable close-icon" }}
          >
            <CloseSharp />
          </IconButton>
        )}
        <div className={"page-title"}>Análise de Documentos</div>
        <form onSubmit={formProps.handleSubmit(handleSubmit)}>
          <div className="row">
            <div className="col-12">
              {dados?.situacaoAnalise === "A" && (
                <div>Confirma a aprovação deste documento?</div>
              )}
              {(dados?.situacaoAnalise === "S" ||
                dados?.situacaoAnalise === "D") && (
                <ControlledTextfield
                  formProps={formProps}
                  multiline={true}
                  uppercase={false}
                  rowsMax={8}
                  rows={4}
                  name={"motivo"}
                  margin={"none"}
                  label={
                    dados?.situacaoAnalise === "S"
                      ? "Motivo da Recusa"
                      : "Motivo da Dispensa"
                  }
                  inputProps={{ maxLength: 500 }}
                />
              )}
              {dados?.situacaoAnalise === "S" && (
                <div className={"mt-2"}>Confirma a recusa deste documento?</div>
              )}
              {dados?.situacaoAnalise === "D" && (
                <div className={"mt-2"}>
                  Confirma a dispensa deste documento?
                </div>
              )}
            </div>
          </div>
          <div className={"row justify-content-center mb-3 mt-2"}>
            <div className="col-6 mt-3">
              <Button
                loading={submitting}
                label={"CONFIRMAR"}
                type={"submit"}
              />
            </div>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default ConfirmarAnaliseDialog;
