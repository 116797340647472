import React from "react";
import { Controller } from "react-hook-form";
import { getValueFromObject } from "../../utils/basic";
import Reaptcha from "reaptcha";
import { FormHelperText } from "@material-ui/core";

const ControlledReaptcha = ({
  formProps,
  name,
  margin,
  helperText,
  captchaRef,
  ...otherProps
}) => {
  const {
    control,
    formState: { errors },
    rules,
    initialValues,
  } = formProps;
  const error = getValueFromObject(errors, name);
  const isError = error !== undefined;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules[name]}
      defaultValue={getValueFromObject(initialValues, name)}
      margin={margin}
      render={({ field: { onChange } }) => {
        return (
          <div className={"d-flex flex-column"}>
            <Reaptcha
              ref={(ref) => !!captchaRef && (captchaRef.current = ref)}
              onVerify={(t) => {
                onChange(t);
                if (t) {
                  formProps.clearErrors(name);
                }
              }}
              onExpire={() => onChange("")}
              {...otherProps}
            />
            {Boolean(helperText ?? error?.message) && (
              <FormHelperText
                error={Boolean(isError)}
                classes={{
                  error: "label-error",
                }}
              >
                {helperText ?? error?.message}
              </FormHelperText>
            )}
          </div>
        );
      }}
    />
  );
};

export default ControlledReaptcha;
