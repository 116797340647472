import React, { useCallback } from "react";
import { ControlledSelect } from "../../../../components/inputs";
import { Button, MenuItem } from "../../../../components";
import {
  estadoCivil,
  regimeComunhao,
  qualificacao,
} from "../../../../utils/parametros";
import ControlledSwitch from "../../../../components/inputs/ControlledSwitch";

const FormMatrizClausula = ({
  props,
  handleSubmit,
  clausulas,
  item,
  voltarParaLista,
}) => {
  const handleClickNext = useCallback(
    (values) => {
      handleSubmit(values, item.id);
    },
    [handleSubmit, item]
  );

  return (
    <div className="card spacer">
      <div className="card-body">
        <span className="card-title color-brand">Cláusula</span>
        <form onSubmit={props.handleSubmit(handleClickNext)}>
          <div className="row">
            <div className="col-12">
              <ControlledSelect
                name={"clausula"}
                formProps={props}
                label={"Cláusula"}
                margin={"none"}
              >
                {clausulas.map((item) => (
                  <MenuItem
                    className={"select-submenu-item"}
                    key={item.id}
                    value={item.id}
                  >
                    {item.nome.toUpperCase()}
                  </MenuItem>
                ))}
              </ControlledSelect>
            </div>
            <div className="col-6">
              <ControlledSelect
                name={"tipoQualificacao"}
                formProps={props}
                label={"Qualificação"}
                margin={"none"}
              >
                {qualificacao
                  .filter((c) => {
                    return c.id === "CO" || c.id === "VD" || c.id === "IM";
                  })
                  .map((item) => (
                    <MenuItem
                      className={"select-submenu-item"}
                      key={item.id}
                      value={item.id}
                    >
                      {item.nome.toUpperCase()}
                    </MenuItem>
                  ))}
              </ControlledSelect>
            </div>
            <div className="col-6">
              <ControlledSelect
                name={"tipoParticipante"}
                formProps={props}
                label={"Tipo de Pessoa"}
                margin={"none"}
              >
                <MenuItem
                  className={"select-submenu-item"}
                  key={"sim"}
                  value={"F"}
                >
                  FÍSICA
                </MenuItem>
                <MenuItem
                  className={"select-submenu-item"}
                  key={"nao"}
                  value={"J"}
                >
                  JURÍDICA
                </MenuItem>
                <MenuItem
                  className={"select-submenu-item"}
                  key={"sim"}
                  value={"N"}
                >
                  NÃO SE APLICA
                </MenuItem>
              </ControlledSelect>
            </div>
            <div className="col-6">
              <ControlledSelect
                name={"estadoCivil"}
                formProps={props}
                label={"Estado Civil"}
                margin={"none"}
              >
                {estadoCivil.map((item) => (
                  <MenuItem
                    className={"select-submenu-item"}
                    key={item.id}
                    value={item.id}
                  >
                    {item.descricao.toUpperCase()}
                  </MenuItem>
                ))}
              </ControlledSelect>
            </div>
            <div className="col-6">
              <ControlledSelect
                name={"regimeCasamento"}
                formProps={props}
                label={"Regime de Casamento"}
                margin={"none"}
              >
                {regimeComunhao.map((item) => (
                  <MenuItem
                    className={"select-submenu-item"}
                    key={item.id}
                    value={item.id}
                  >
                    {item.descricao.toUpperCase()}
                  </MenuItem>
                ))}
              </ControlledSelect>
            </div>
            <div className="col-6">
              <ControlledSelect
                name={"tipoDataCasamento"}
                formProps={props}
                label={"Data do Casamento"}
                margin={"none"}
              >
                <MenuItem
                  className={"select-submenu-item"}
                  key={"sim"}
                  value={"A"}
                >
                  ANTES DE 26 DE DEZEMBRO DE 1977
                </MenuItem>
                <MenuItem
                  className={"select-submenu-item"}
                  key={"nao"}
                  value={"P"}
                >
                  DEPOIS DE 26 DE DEZEMBRO DE 1977
                </MenuItem>
              </ControlledSelect>
            </div>
            <div className="col-3">
              <ControlledSwitch
                name={"conjugeParticipante"}
                formProps={props}
                label={"Cônjuge Participa?"}
                margin={"none"}
              />
            </div>
            <div className="col-3">
              <ControlledSwitch
                name={"uniaoEstavel"}
                formProps={props}
                label={"União Estável?"}
                margin={"none"}
              />
            </div>
          </div>
          <div className="row spacer">
            <div className="offset-md-2 col-md-4 col-12">
              <Button
                label="Voltar"
                onClick={voltarParaLista}
                loading={item?.loading}
              />
            </div>
            <div className="col-md-4 col-12">
              <Button
                label="CONFIRMAR"
                type={"submit"}
                loading={item?.loading}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormMatrizClausula;
