import React from "react";
import { Button } from "../../../../components";

const BotaoBaixarFormulario = ({ fullWidth, className, ...otherProps }) => {
  return (
    <>
      <Button
        {...otherProps}
        variant={"outlined"}
        fullWidth={fullWidth}
        size="small"
        label={"Baixar Formulário"}
        loaderColor={"#005298"}
        classes={{
          root: "transparent-button " + className,
          label: "texto-upload color-secondary ",
        }}
      />
    </>
  );
};
export default BotaoBaixarFormulario;
