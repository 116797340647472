import React, { useState } from "react";
import {
  CpfCnpjTextField,
  DateTextField,
  ControlledTextfield,
  ControlledSwitch,
  ControlledSelect,
} from "../../../../../components/inputs";
import { MenuItem } from "../../../../../components";

const CardDadosConjuge = ({
  props,
  disabled,
  estadoCivil,
  uf,
  hasUniaoEstavel,
}) => {
  const [isComprador, setIsComprador] = useState(
    props.initialValues?.isConjugeComprador ?? false
  );

  const handleChangeIsComprador = (e) => {
    setIsComprador(e.target.checked);
  };

  return (
    <div className="card spacer">
      <div className="card-body">
        <span className="card-title color-brand">Cônjuge</span>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-3">
            <ControlledTextfield
              formProps={props}
              label={"CPF do Cônjuge"}
              type={"cpf"}
              name={"cpfConjuge"}
              disabled={disabled}
              InputProps={{
                inputComponent: CpfCnpjTextField,
              }}
              margin={"none"}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-9">
            <ControlledTextfield
              name={"nomeConjuge"}
              formProps={props}
              label="Nome do Cônjuge"
              margin={"none"}
              disabled={disabled}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <ControlledTextfield
              name={"dataNascimentoConjuge"}
              formProps={props}
              label={"Data de Nascimento"}
              InputProps={{
                inputComponent: DateTextField,
              }}
              margin={"none"}
              disabled={disabled}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-5">
            <ControlledTextfield
              formProps={props}
              label={"Profissão"}
              name={"profissaoConjuge"}
              margin={"none"}
              disabled={disabled}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <ControlledTextfield
              formProps={props}
              label={"Nacionalidade"}
              name={"nacionalidadeConjuge"}
              margin={"none"}
              disabled={disabled}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-6">
            <ControlledTextfield
              formProps={props}
              label={"Nome do Pai"}
              name={"nomePaiConjuge"}
              margin={"none"}
              disabled={disabled}
            />
          </div>

          <div className="col-12 col-md-6 col-lg-6">
            <ControlledTextfield
              formProps={props}
              label={"Nome da Mãe"}
              name={"nomeMaeConjuge"}
              margin={"none"}
              disabled={disabled}
            />
          </div>

          {hasUniaoEstavel && (
            <div className="col-12 col-md-6 col-lg-3">
              <ControlledSelect
                name={"estadoCivilConjuge"}
                formProps={props}
                label={"Estado Civil"}
                disabled={disabled}
                margin={"none"}
                //  onChange={handleChangeEstadoCivil}
              >
                {estadoCivil.map((item) => (
                  <MenuItem
                    className={"select-submenu-item"}
                    key={item.id}
                    value={item.id}
                  >
                    {item.descricao}
                  </MenuItem>
                ))}
              </ControlledSelect>
            </div>
          )}
          <div
            className={`col-12 col-md-6 col-lg-${hasUniaoEstavel ? "9" : "12"}`}
          >
            <ControlledTextfield
              name={"emailConjuge"}
              formProps={props}
              label={"E-Mail"}
              margin={"none"}
              disabled={disabled}
              uppercase={false}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <ControlledTextfield
              name={"numeroDocumentoConjuge"}
              formProps={props}
              label="Numero do Documento"
              margin={"none"}
              disabled={disabled}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <ControlledTextfield
              name={"orgaoEmissorConjuge"}
              formProps={props}
              label="Orgão Emissor"
              margin={"none"}
              disabled={disabled}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <ControlledSelect
              name={"ufOrgaoEmissorConjuge"}
              formProps={props}
              disabled={disabled}
              label={"UF do Orgão Emissor"}
              //onChange={handleChange}
              margin={"none"}
            >
              {uf.map((item) => (
                <MenuItem
                  className={"select-submenu-item"}
                  key={item.codigo}
                  value={item.codigo}
                >
                  {item.nome}
                </MenuItem>
              ))}
            </ControlledSelect>
          </div>
          {props.initialValues.tipoParticipante === "C" && (
            <>
              <div className="col-12 col-md-6 col-lg-4">
                <ControlledSwitch
                  name="isConjugeComprador"
                  formProps={props}
                  label="Entrará na composição da renda?"
                  onChange={handleChangeIsComprador}
                  margin={"none"}
                  disabled={disabled}
                />
              </div>
              {isComprador && (
                <div className="col-12 col-md-6 col-lg-3">
                  <ControlledSwitch
                    name={"fgtsConjuge"}
                    formProps={props}
                    label={"Utilizará FGTS?"}
                    margin={"none"}
                    disabled={disabled}
                  />
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CardDadosConjuge;
