import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { EditOutlined, DeleteOutlined } from "@material-ui/icons";

const Clausula = ({ clausula, index, handleEdit, handleOpenExclusao }) => {
  return (
    <div
      className={
        index % 2 === 0
          ? "row mt-0 d-flex align-items-center medium-row tr-even-adm"
          : "row mt-0 d-flex align-items-center medium-row tr-odd"
      }
    >
      <div className="col-2 mt-lg-0 user-table ">
        <span>{clausula.codigo}</span>
      </div>
      <div className="col-2 mt-lg-0 user-table ">
        <span>
          {clausula.tipo === "P"
            ? "EM TEMPO - BANCO"
            : clausula.tipo === "G"
            ? "EM TEMPO - COMARCA"
            : "QUALIFICAÇÃO"}
        </span>
      </div>
      <div className="col-7 mt-lg-0 user-table ">
        <span>{clausula.nome}</span>
      </div>
      <Tooltip title="Editar">
        <IconButton className="p-2" onClick={() => handleEdit(clausula)}>
          <EditOutlined className={"color-black"} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Excluir">
        <IconButton
          className="p-2"
          onClick={() => handleOpenExclusao(clausula)}
        >
          <DeleteOutlined className={"color-black"} />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default Clausula;
