import PropostaPage from "./PropostaPage";
import { useSelector, useDispatch } from "react-redux";
import { useCallback, useState } from "react";
import {
  getDetalheProposta,
  getMenuSelecionado,
  getPropostaLoading,
  getPropostaError,
  getInterveniente,
  getWorkflow,
} from "../../../selectors/propostas.selectors";
import { actions } from "../../../reducers/propostas.actions";
import {
  actions as routeActions,
  types as routes,
} from "../../../reducers/rotas.actions";
import {
  Assignment,
  People,
  Description,
  Home,
  DashboardOutlined,
} from "@material-ui/icons";
import { getPerfil } from "../../../selectors/usuario.selectors";
import { useForm } from "react-hook-form";

const PropostaPageConnected = () => {
  const dispatch = useDispatch();
  const detalheProposta = useSelector(getDetalheProposta);
  const menuSelecionado = useSelector(getMenuSelecionado);
  const error = useSelector(getPropostaError);
  const loading = useSelector(getPropostaLoading);
  const perfil = useSelector(getPerfil);
  const interveniente = useSelector(getInterveniente);
  const submitting = detalheProposta?.submittingObservacao;
  const workflow = useSelector(getWorkflow);

  const [alterarSituacao, setAlterarSituacao] = useState({
    open: false,
  });

  const handleCloseAlterarSituacao = useCallback(() => {
    setAlterarSituacao({
      open: false,
    });
  }, []);

  const [showMotivoCancelamento, setShowMotivoCancelamento] = useState(
    alterarSituacao.situacao === "C"
  );
  const [showDataContrato, setShowDataContrato] = useState(
    alterarSituacao.situacao === "E"
  );

  const handleOpenAlterarSituacao = useCallback(
    (value) => {
      if (value.situacao) {
        setShowMotivoCancelamento(value.situacao === "C");
        setShowDataContrato(value.situacao === "E");
      }
      setAlterarSituacao({
        situacao: value.situacao,
        consultaID: detalheProposta?.id,
        motivoCancelamento: detalheProposta?.motivoCancelamento,
        dataContrato: detalheProposta?.dataContrato,
        open: true,
      });
    },
    [detalheProposta]
  );

  const [alterarPrioridade, setAlterarPrioridade] = useState({
    open: false,
  });

  const handleCloseAlterarPrioridade = useCallback(() => {
    setAlterarPrioridade({
      open: false,
    });
  }, []);
  const handleOpenAlterarPrioridade = useCallback(
    (value) => {
      setAlterarPrioridade({
        prioridade: value.prioridade,
        consultaID: detalheProposta?.id,
        open: true,
      });
    },
    [detalheProposta]
  );

  const handleSubmit = useCallback(
    (values) => {
      dispatch(
        actions.atualizarObservacao.request({
          idProposta: detalheProposta?.id,
          ...values,
        })
      );
    },
    [dispatch, detalheProposta]
  );

  const rules = {};
  const initialValues = {
    observacao: detalheProposta?.observacao,
    observacaoInterna: detalheProposta?.observacaoInterna,
  };
  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const disabled = perfil !== "BPO";

  const itens = [
    {
      key: 0,
      label: "PROPOSTA",
      first: true,
      icon: <Assignment className={"d-md-none"} />,
    },
    {
      key: 1,
      label: "PARTICIPANTES",
      icon: <People className={"d-md-none"} />,
    },
    {
      key: 2,
      label: "IMÓVEL",
      icon: <Home className={"d-md-none"} />,
    },
    {
      key: 3,
      label: "DOCUMENTOS",
      icon: <Description className={"d-md-none"} />,
    },
    {
      key: 4,
      label: "ATIVIDADES",
      icon: <DashboardOutlined className={"d-md-none"} />,
      perfis: [
        "BPO",
        "ADMINISTRADOR",
        "BANCO GESTAO",
        "AGENCIA",
        "PARCEIRO",
        "BANCO",
      ],
    },
    {
      key: 5,
      label: "HISTÓRICO",
      icon: <DashboardOutlined className={"d-md-none"} />,
      perfis: ["BPO", "ADMINISTRADOR", "BANCO GESTAO"],
    },
    {
      key: 6,
      label: "MINUTA",
      icon: <DashboardOutlined className={"d-md-none"} />,
      perfis: ["BPO", "ADMINISTRADOR", "BANCO GESTAO"],
    },
  ];

  const selecionarOpcaoMenu = useCallback(
    (e, value, proposta) => {
      dispatch(actions.selecionarOpcaoMenu(value));
      if (value === 3) {
        dispatch(actions.consultarChecklist.request({ consultaId: proposta }));
      } else if (value === 5) {
        dispatch(actions.selecionarOpcaoMenuHistorico("atividades"));
        dispatch(actions.historicoProposta.request(proposta));
        dispatch(actions.historicoDocumentos.request(proposta));
        dispatch(actions.historicoEmails.request(proposta));
      } else if (value === 6) {
        dispatch(actions.listarClausulasProposta.request(proposta));
        dispatch(actions.listarClausulasParametro.request());
      }
    },
    [dispatch]
  );

  const abrirDetalhePessoa = useCallback(
    (id, idParticipante) => {
      if (!!idParticipante) {
        dispatch(
          routeActions.redirectTo(routes.PARTICIPANTE, { id, idParticipante })
        );
      } else {
        dispatch(
          routeActions.redirectTo(routes.INTERVENIENTE_QUITANTE, { id })
        );
      }
    },
    [dispatch]
  );

  const excluirParticipante = useCallback(
    (idProposta, idPessoa) => {
      if (!!idPessoa) {
        dispatch(
          actions.excluirParticipante.request({
            idProposta: idProposta,
            idPessoa: idPessoa,
          })
        );
      } else {
        dispatch(
          actions.atualizarIq.request({
            idProposta: idProposta,
            iq: null,
          })
        );
      }
    },
    [dispatch]
  );

  const novoCadastroPf = useCallback(
    (id, participacao, tipoPessoa) => {
      if (participacao !== "I") {
        dispatch(
          actions.abrirNovoParticipante({
            id: id,
            participacao: participacao,
            tipoPessoa: tipoPessoa,
          })
        );
        dispatch(routeActions.redirectTo(routes.NOVO_PARTICIPANTE, { id }));
      } else {
        dispatch(
          routeActions.redirectTo(routes.INTERVENIENTE_QUITANTE, { id })
        );
      }
    },
    [dispatch]
  );
  const handleOpcaoSelecionada = useCallback(
    (value) => {
      setShowMotivoCancelamento(value === "C");
      setShowDataContrato(value === "E");
    },
    [setShowMotivoCancelamento, setShowDataContrato]
  );

  return (
    <PropostaPage
      detalheProposta={detalheProposta}
      itens={itens}
      error={error}
      menuSelecionado={menuSelecionado}
      loading={loading}
      selecionarOpcaoMenu={selecionarOpcaoMenu}
      abrirDetalhePessoa={abrirDetalhePessoa}
      novoCadastroPf={novoCadastroPf}
      excluirParticipante={excluirParticipante}
      disabled={disabled}
      outros={interveniente}
      formProps={formProps}
      handleSubmit={handleSubmit}
      submitting={submitting}
      workflow={workflow}
      perfil={perfil}
      alterarSituacao={alterarSituacao}
      handleCloseAlterarSituacao={handleCloseAlterarSituacao}
      handleOpenAlterarSituacao={handleOpenAlterarSituacao}
      alterarPrioridade={alterarPrioridade}
      handleCloseAlterarPrioridade={handleCloseAlterarPrioridade}
      handleOpenAlterarPrioridade={handleOpenAlterarPrioridade}
      showMotivoCancelamento={showMotivoCancelamento}
      showDataContrato={showDataContrato}
      handleOpcaoSelecionada={handleOpcaoSelecionada}
    />
  );
};

export default PropostaPageConnected;
