import DetalheProposta from "./DetalheProposta";
import { useSelector } from "react-redux";
import {
  getDetalheProposta,
  getPropostaLoading,
} from "../../selectors/propostas.selectors";
import { useCallback } from "react";
import {
  actions as routeActions,
  types as routes,
} from "../../reducers/rotas.actions";
import { useDispatch } from "react-redux";

const DetalhePropostaConnected = () => {
  const dispatch = useDispatch();
  const proposta = useSelector(getDetalheProposta);
  const loading = useSelector(getPropostaLoading);

  const abrirCockpit = useCallback(
    (id) => {
      dispatch(routeActions.redirectTo(routes.PROPOSTA, { id }));
    },
    [dispatch]
  );

  return (
    <DetalheProposta
      data={proposta}
      loading={loading}
      abrirCockpit={abrirCockpit}
    />
  );
};

export default DetalhePropostaConnected;
