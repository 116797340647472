import React from "react";
import {
  ControlledTextfield,
  ControlledSelect,
  CpfCnpjTextField,
  DateTextField,
} from "../../../../../components/inputs";
import { MenuItem } from "../../../../../components";

const CardDadosPessoaJuridica = ({ props, disabled }) => {
  return (
    <>
      <div className="card spacer">
        <div className="card-body">
          <span className="card-title color-brand">Dados Pessoais</span>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4">
              <ControlledTextfield
                formProps={props}
                label={"CNPJ"}
                type={"cnpj"}
                name={"cpf"}
                margin={"none"}
                disabled={disabled}
                InputProps={{
                  inputComponent: CpfCnpjTextField,
                }}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-8">
              <ControlledTextfield
                formProps={props}
                label={"Razao Social"}
                name={"nome"}
                margin={"none"}
                disabled={disabled}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <ControlledSelect
                formProps={props}
                label={"Tipo de Empresa"}
                name={"tipoEmpresa"}
                margin={"none"}
                disabled={disabled}
              >
                <MenuItem className={"select-submenu-item"} value={"L"}>
                  LTDA
                </MenuItem>
                <MenuItem className={"select-submenu-item"} value={"S"}>
                  SA
                </MenuItem>
              </ControlledSelect>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <ControlledTextfield
                formProps={props}
                label={"Data de Abertura"}
                name={"dataNascimento"}
                margin={"none"}
                disabled={disabled}
                InputProps={{
                  inputComponent: DateTextField,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardDadosPessoaJuridica;
