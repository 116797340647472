import { actions, types } from "./usuarios.actions";

const initialState = {
  loading: false,
  error: null,
  list: [],
  page: 1,
  usuario: null,
  organizacao: "",
  search: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.listarUsuarios.REQUEST:
    case actions.listarUsuarios.SUCCESS:
    case actions.listarUsuarios.FAILURE:
      return {
        ...state,
        loading: action.type === actions.listarUsuarios.REQUEST,
        error:
          action.type === actions.listarUsuarios.FAILURE
            ? action.payload.error
            : null,
        list:
          action.type === actions.listarUsuarios.SUCCESS
            ? action.payload.response.data.usuarios
            : [],
        total:
          action.type === actions.listarUsuarios.SUCCESS
            ? action.payload.response.data.total
            : null,
        page:
          action.type === actions.listarUsuarios.REQUEST
            ? action.payload.pagina
            : action.payload.response.data.paginaAtual,
        search: "",
      };
    case actions.listarPerfis.REQUEST:
    case actions.listarPerfis.SUCCESS:
    case actions.listarPerfis.FAILURE:
      return {
        ...state,
        loadingPerfis: action.type === actions.listarPerfis.REQUEST,
        perfis:
          action.type === actions.listarPerfis.SUCCESS
            ? action.payload.response.data
            : [],
      };
    case actions.getOrganizacao.REQUEST:
    case actions.getOrganizacao.SUCCESS:
    case actions.getOrganizacao.FAILURE:
      return {
        ...state,
        usuario: {
          ...state.usuario,
          loading: action.type === actions.getOrganizacao.REQUEST,
          Organizacao:
            action.type === actions.getOrganizacao.SUCCESS
              ? action.payload.response.data[0]
              : state.usuario.Organizacao,
        },
      };
    case actions.atualizarUsuario.REQUEST:
    case actions.atualizarUsuario.SUCCESS:
    case actions.atualizarUsuario.FAILURE:
      return {
        ...state,
        loading: action.type === actions.atualizarUsuario.REQUEST,
        usuario:
          action.type === actions.atualizarUsuario.FAILURE
            ? action.payload.original.usuario
            : null,
        list:
          action.type === actions.atualizarUsuario.SUCCESS
            ? state.list.map((u) => {
                if (u.id === action.payload.original.usuario.id) {
                  return {
                    ...u,
                    codigo: action.payload.original.usuario.codigo ?? u.codigo,
                    email: action.payload.original.usuario.email ?? u.email,
                    nome: action.payload.original.usuario.nome ?? u.nome,
                    situacao:
                      action.payload.original.usuario.situacao ?? u.situacao,
                    idPerfil:
                      action.payload.original.usuario["id-perfil"] ??
                      u.idPerfil,
                    perfil: { ...action.payload.original.usuario.perfil },
                    Organizacao: {
                      ...action.payload.original.usuario.Organizacao,
                    },
                    organizacao:
                      action.payload.original.usuario.Organizacao?.id ??
                      u.organizacao,
                  };
                }
                return u;
              })
            : state.list,
      };
    case types.NOVO_USUARIO:
      return {
        ...state,
        usuario: {
          codigo: "",
          email: "",
          nome: "",
          situacao: "A",
          idPerfil: "",
          perfil: "",
          organizacao: "",
        },
      };
    case types.EDITAR_USUARIO:
      return {
        ...state,
        usuario: {
          ...action.payload.usuario,
          Organizacao:
            action.payload.usuario.idPerfil !== 3 &&
            action.payload.usuario.idPerfil !== 5
              ? undefined
              : state.usuario?.Organizacao
              ? state.usuario.Organizacao
              : action.payload.usuario.Organizacao,
        },
      };
    case types.VOLTAR_LISTA:
      return {
        ...state,
        usuario: null,
      };
    case actions.incluirUsuario.REQUEST:
    case actions.incluirUsuario.SUCCESS:
    case actions.incluirUsuario.FAILURE:
      return {
        ...state,
        loading: action.type === actions.incluirUsuario.REQUEST,
        usuario:
          action.type === actions.incluirUsuario.FAILURE
            ? action.payload.original.usuario
            : null,
        list:
          action.type === actions.incluirUsuario.SUCCESS
            ? action.payload.response.data.usuarios
            : state.list,
        total:
          action.type === actions.incluirUsuario.SUCCESS
            ? action.payload.response.data.total
            : state.total,
        page:
          action.type === actions.incluirUsuario.REQUEST
            ? action.payload.pagina
            : state.pagina,
      };
    case actions.buscarUsuario.REQUEST:
    case actions.buscarUsuario.SUCCESS:
    case actions.buscarUsuario.FAILURE:
      return {
        ...state,
        loading: action.type === actions.buscarUsuario.REQUEST,
        error:
          action.type === actions.buscarUsuario.FAILURE
            ? action.payload.error
            : null,
        list:
          action.type === actions.buscarUsuario.SUCCESS
            ? action.payload.response.data.usuarios
            : [],
        total:
          action.type === actions.buscarUsuario.SUCCESS
            ? action.payload.response.data.total
            : null,
        page:
          action.type === actions.buscarUsuario.REQUEST
            ? action.payload.pagina
            : action.payload.response.data.paginaAtual,
        search:
          action.type === actions.buscarUsuario.SUCCESS
            ? action.payload.original.search
            : state.search,
      };
    case actions.reenviarEmail.REQUEST:
    case actions.reenviarEmail.SUCCESS:
    case actions.reenviarEmail.FAILURE:
      return {
        ...state,
        loading: action.type === actions.reenviarEmail.REQUEST,
        usuario:
          action.type === actions.reenviarEmail.FAILURE
            ? action.payload.original.usuario
            : null,
        list:
          action.type === actions.reenviarEmail.SUCCESS
            ? state.list.map((u) => {
                if (u.id === action.payload.original.usuario.id) {
                  return {
                    ...u,
                    codigo: action.payload.original.usuario.codigo ?? u.codigo,
                    email: action.payload.original.usuario.email ?? u.email,
                    nome: action.payload.original.usuario.nome ?? u.nome,
                    situacao:
                      action.payload.original.usuario.situacao ?? u.situacao,
                    idPerfil:
                      action.payload.original.usuario["id-perfil"] ??
                      u.idPerfil,
                    perfil: { ...action.payload.original.usuario.perfil },
                    Organizacao: {
                      ...action.payload.original.usuario.Organizacao,
                    },
                    organizacao:
                      action.payload.original.usuario.Organizacao?.id ??
                      u.organizacao,
                  };
                }
                return u;
              })
            : state.list,
      };
    default:
      return state;
  }
};

export default reducer;
