import { Header } from "./pages";
import React from "react";
import Zendesk from "react-zendesk";
import { getZendeskToken } from "../utils/localStorage";

const BasePage = ({ children, isLoggedIn }) => {
  if (isLoggedIn) {
    window.zESettings = {
      webWidget: {
        authenticate: {
          chat: {
            jwtFn: function (callback) {
              callback(getZendeskToken());
            },
          },
        },
      },
    };
  }

  return (
    <>
      {isLoggedIn && <Header />}
      <div className="container-xl body">{children}</div>
      {isLoggedIn && (
        <>
          <Zendesk defer zendeskKey={process.env.REACT_APP_ZENDESK_KEY} />
          <iframe
            width="100"
            height="50"
            style={{ border: 0, position: "fixed", right: 20, bottom: 80 }}
            src="https://cdn.smooch.io/message-us/index.html?channel=whatsapp&color=green&size=standard&radius=25px&label=Whats&number=5511943129278"
          ></iframe>
        </>
      )}
    </>
  );
};
export default BasePage;
