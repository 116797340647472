import ListaDocumentos from "./ListaDocumentos";
import { useDispatch, useSelector } from "react-redux";
import {
  getChecklistList,
  getFilter,
  getLoading,
  getUploading,
  getErroFiltroUpload,
} from "../../../../selectors/documentos.selectors";
import { getUsuario } from "../../../../selectors/usuario.selectors";
import {
  getDetalheProposta,
  getUploadDocumentosBanco,
} from "../../../../selectors/propostas.selectors";
import { useCallback } from "react";
import { actions } from "reducers/propostas.actions";
import {
  actions as routeActions,
  types as routes,
} from "reducers/rotas.actions";

import { actions as artefatoActions } from "../../../../reducers/artefatos.actions";

const ListaDocumentosConnected = () => {
  const dispatch = useDispatch();
  const { nome, perfil } = useSelector(getUsuario);

  const handleFilterClick = useCallback(
    (valor) => {
      dispatch(actions.alterarFiltroChecklist(valor));
    },
    [dispatch]
  );

  const handleClickErroFiltroUpload = useCallback(
    (valor) => {
      dispatch(actions.filtroErroUpload(valor));
    },
    [dispatch]
  );

  const handleUpload = useCallback(
    (metadata, values) => {
      dispatch(
        actions.uploadArtefato.request({
          id: metadata.name,
          files: values,
          usuario: nome,
        })
      );
    },
    [dispatch, nome]
  );

  const handleOpenImagens = useCallback(
    (id) => {
      dispatch(
        routeActions.openNewWindowWith(routes.ARTEFATOS_DOCUMENTO, { id })
      );
    },
    [dispatch]
  );

  const handleUploadDocumentosBanco = useCallback(
    (id) => {
      dispatch(actions.uploadDocumentosBanco.request(id));
    },
    [dispatch]
  );

  const handleDownload = useCallback(
    (id, nome) => {
      dispatch(
        artefatoActions.download.request({
          artefato: id,
          nomeImagem: nome,
          tipo: "application/pdf",
        })
      );
    },
    [dispatch]
  );

  const checklist = useSelector(getChecklistList);
  const loading = useSelector(getLoading);
  const filter = useSelector(getFilter);
  const filtroErroUpload = useSelector(getErroFiltroUpload);
  const uploading = useSelector(getUploading);
  const detalheProposta = useSelector(getDetalheProposta);

  let filteredChecklist = getFilteredChecklist(checklist, filter);

  filteredChecklist = getFilteredListErrors(
    filteredChecklist,
    filtroErroUpload
  );

  const uploadDocumentosBanco = useSelector(getUploadDocumentosBanco);

  return (
    <ListaDocumentos
      loading={loading}
      proposta={detalheProposta?.id}
      filtro={filter}
      checklist={filteredChecklist}
      handleFilterClick={handleFilterClick}
      handleOpenImagens={handleOpenImagens}
      handleUpload={handleUpload}
      uploading={uploading}
      perfil={perfil}
      handleUploadDocumentosBanco={handleUploadDocumentosBanco}
      loadingUploadDocumentosBanco={uploadDocumentosBanco.loading}
      handleClickErroFiltroUpload={handleClickErroFiltroUpload}
      filtroErroUpload={filtroErroUpload}
      handleDownload={handleDownload}
    />
  );
};

const getFilteredListErrors = (list, filter) => {
  if (filter && filter.length > 0) {
    return list.map((participante) => {
      return {
        ...participante,
        documentos: participante.documentos.filter(
          (documento) => documento.situacaoIntegracao === filter
        ),
        documentos_conjuge: participante.documentos_conjuge?.filter(
          (documento) => documento.situacaoIntegracao === filter
        ),
      };
    });
  } else {
    return list;
  }
};

const getFilteredChecklist = (list, filter) => {
  switch (filter) {
    case "aprovados":
      return list.map((owner) => {
        return {
          ...owner,
          documentos: owner.documentos.filter(
            (documento) =>
              documento.situacao_documento === "A" &&
              documento.situacao_analise === "A"
          ),
          documentos_conjuge: owner.documentos_conjuge?.filter(
            (documento) =>
              documento.situacao_documento === "A" &&
              documento.situacao_analise === "A"
          ),
        };
      });

    case "recusados":
      return list.map((owner) => {
        return {
          ...owner,
          documentos: owner.documentos.filter(
            (documento) =>
              documento.situacao_documento === "A" &&
              documento.situacao_analise === "S"
          ),
          documentos_conjuge: owner.documentos_conjuge?.filter(
            (documento) =>
              documento.situacao_documento === "A" &&
              documento.situacao_analise === "S"
          ),
        };
      });

    case "pendentes":
      return list.map((owner) => {
        return {
          ...owner,
          documentos: owner.documentos.filter(
            (documento) => documento.situacao_documento === "I"
          ),
          documentos_conjuge: owner.documentos_conjuge?.filter(
            (documento) => documento.situacao_documento === "I"
          ),
        };
      });

    case "falta":
      return list.map((owner) => {
        return {
          ...owner,
          documentos: owner.documentos.filter(
            (documento) => documento.situacao_documento === "F"
          ),
          documentos_conjuge: owner.documentos_conjuge?.filter(
            (documento) => documento.situacao_documento === "F"
          ),
        };
      });

    case "iniciais":
      return list.map((owner) => {
        return {
          ...owner,
          documentos: owner.documentos.filter(
            (documento) => documento.condicao === "PAD1"
          ),
          documentos_conjuge: owner.documentos_conjuge?.filter(
            (documento) => documento.condicao === "PAD1"
          ),
        };
      });

    case "todos":
    default:
      return list;
  }
};

export default ListaDocumentosConnected;
