import React from "react";
import {
  FormControl,
  FormControlLabel,
  Switch as MSwitch,
  FormHelperText,
} from "@material-ui/core";

const Switch = ({
  value,
  margin = "normal",
  className = "switch-placement",
  label,
  disabled,
  helperText,
  error,
  labelPlacement = "top",
  ...otherProps
}) => {
  return (
    <FormControl fullWidth margin={margin} classes={{ root: "mb-0" }}>
      <FormControlLabel
        classes={{
          root: "label-switch " + (labelPlacement === "end" ? "mt-1" : ""),
          label: "label-switch-text",
          labelPlacementTop: "label-switch-top",
        }}
        control={
          <MSwitch
            {...otherProps}
            classes={{
              root: className,
            }}
            value={true}
            checked={value}
            disabled={disabled}
          />
        }
        labelPlacement={labelPlacement}
        label={label}
      />
      {Boolean(error) && Boolean(helperText) && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
    </FormControl>
  );
};

export default Switch;
