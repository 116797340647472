import React, { useCallback } from "react";
import { Dialog, DialogContent, IconButton } from "@material-ui/core";
import { CloseSharp } from "@material-ui/icons";
import { Button } from "../../../components";

const ConfirmarExclusaoDialog = ({
  handleClose,
  open,
  excluirEtapa,
  etapa,
}) => {
  const handleDelete = useCallback(() => {
    excluirEtapa(etapa.id);
    handleClose();
  }, [etapa, handleClose]);

  return (
    <Dialog
      onClose={handleClose}
      classes={{ paper: "w100" }}
      maxWidth="md"
      open={open}
      etapa={etapa}
    >
      {!!open && (
        <DialogContent>
          <IconButton
            onClick={handleClose}
            classes={{ root: "position-absolute clickable close-icon" }}
          >
            <CloseSharp />
          </IconButton>
          <div className={"page-title"}>Confirmação de Exclusão</div>
          <div className={"mb-3"}>
            <div className="row">
              <div className="col-md-12 col-xs-12">
                <span className="data-label">
                  Tem certeza que gostaria de excluir a etapa {etapa.nome}?
                </span>
              </div>
            </div>
            <div className="row spacer justify-content-center">
              <div className="col-md-4 col-12">
                <Button label="CANCELAR" onClick={handleClose} />
              </div>
              <div className="col-md-4 col-12">
                <Button label="CONFIRMAR" onClick={handleDelete} />
              </div>
            </div>
          </div>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default ConfirmarExclusaoDialog;
