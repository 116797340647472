import { actions, types } from "./clausulas.actions";

const initialState = {
  loading: false,
  error: null,
  list: [],
  page: 1,
  clausula: null,
  search: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.listarClausulas.REQUEST:
    case actions.listarClausulas.SUCCESS:
    case actions.listarClausulas.FAILURE:
      return {
        ...state,
        loading: action.type === actions.listarClausulas.REQUEST,
        error:
          action.type === actions.listarClausulas.FAILURE
            ? action.payload.error
            : null,
        list:
          action.type === actions.listarClausulas.SUCCESS
            ? action.payload.response.data.clausulas
            : [],
        total:
          action.type === actions.listarClausulas.SUCCESS
            ? action.payload.response.data.total
            : null,
        page:
          action.type === actions.listarClausulas.REQUEST
            ? action.payload.pagina
            : action.payload.response.data.paginaAtual,
        search: "",
      };
    case actions.atualizarClausula.REQUEST:
    case actions.atualizarClausula.SUCCESS:
    case actions.atualizarClausula.FAILURE:
      return {
        ...state,
        loading: action.type === actions.atualizarClausula.REQUEST,
        clausula:
          action.type === actions.atualizarClausula.FAILURE
            ? action.payload.original.clausula
            : null,
        list:
          action.type === actions.atualizarClausula.SUCCESS
            ? state.list.map((u) => {
                if (u.id === action.payload.original.clausula.id) {
                  return {
                    ...u,
                    nome: action.payload.original.clausula.nome ?? u.nome,
                    codigo: action.payload.original.clausula.codigo ?? u.codigo,
                    tipo: action.payload.original.clausula.tipo ?? u.tipo,
                    texto: action.payload.original.clausula.texto ?? u.texto,
                    utilizacao:
                      action.payload.original.clausula.utilizacao ??
                      u.utilizacao,
                  };
                }
                return u;
              })
            : state.list,
      };
    case types.NOVA_CLAUSULA:
      return {
        ...state,
        clausula: {
          nome: "",
          codigo: "",
        },
      };
    case types.EDITAR_CLAUSULA:
      return {
        ...state,
        clausula: {
          ...action.payload.clausula,
        },
      };
    case types.VOLTAR_LISTA:
      return {
        ...state,
        clausula: null,
      };
    case actions.incluirClausula.REQUEST:
    case actions.incluirClausula.SUCCESS:
    case actions.incluirClausula.FAILURE:
      return {
        ...state,
        loading: action.type === actions.incluirClausula.REQUEST,
        clausula:
          action.type === actions.incluirClausula.FAILURE
            ? action.payload.original.clausula
            : null,
        list:
          action.type === actions.incluirClausula.SUCCESS
            ? action.payload.response.data.clausulas
            : [],
        total:
          action.type === actions.incluirClausula.SUCCESS
            ? action.payload.response.data.total
            : state.total,
        page:
          action.type === actions.incluirClausula.REQUEST
            ? action.payload.pagina
            : state.page,
      };
    case actions.buscarClausula.REQUEST:
    case actions.buscarClausula.SUCCESS:
    case actions.buscarClausula.FAILURE:
      return {
        ...state,
        loading: action.type === actions.buscarClausula.REQUEST,
        error:
          action.type === actions.buscarClausula.FAILURE
            ? action.payload.error
            : null,
        list:
          action.type === actions.buscarClausula.SUCCESS
            ? action.payload.response.data.clausula
            : [],
        total:
          action.type === actions.buscarClausula.SUCCESS
            ? action.payload.response.data.total
            : null,
        page:
          action.type === actions.buscarClausula.REQUEST
            ? action.payload.pagina
            : action.payload.response.data.paginaAtual,
        search:
          action.type === actions.buscarClausula.SUCCESS
            ? action.payload.original.search
            : state.search,
      };
    case actions.excluirClausula.REQUEST:
    case actions.excluirClausula.SUCCESS:
    case actions.excluirClausula.FAILURE:
      return {
        ...state,
        loading: action.type === actions.excluirClausula.REQUEST,
        clausula: null,
        list:
          action.type === actions.excluirClausula.SUCCESS
            ? state.list.filter((u) => u.id !== action.payload.original.id)
            : state.list,
      };
    default:
      return state;
  }
};

export default reducer;
