import { ControlledTextfield } from "../../components/inputs";
import { Button } from "../../components";
import { InputAdornment } from "@material-ui/core";
import { Lock, NotInterested, Check } from "@material-ui/icons";
import React, { useState } from "react";

const ValidationItem = ({ state, item, children }) => (
  <div className="instrucoes">
    {!!state.dirty && state.list.indexOf(item) === -1 ? (
      <Check className={"icon-small color-success mr-2"} />
    ) : (
      <NotInterested className={"icon-small color-red mr-2"} />
    )}
    <span>{children}</span>
  </div>
);

const TrocarSenhaForm = ({
  loading,
  formProps,
  handleSubmit,
  handlePasswordChange,
}) => {
  const [state, setState] = useState({ dirty: false, list: [] });

  return (
    <form
      className="d-flex flex-column"
      onSubmit={formProps.handleSubmit(handleSubmit)}
    >
      <ControlledTextfield
        label="Nova Senha"
        name={"password"}
        formProps={formProps}
        type={"password"}
        margin={"none"}
        onChange={(v) => handlePasswordChange(v, setState)}
        uppercase={false}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" className={"ml-2 mr-3"}>
              <Lock className="color-brand-2" />
            </InputAdornment>
          ),
        }}
      />
      <ControlledTextfield
        label="Repetir Senha"
        name={"password2"}
        formProps={formProps}
        type={"password"}
        margin={"none"}
        uppercase={false}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" className={"ml-2 mr-3"}>
              <Lock className="color-brand-2" />
            </InputAdornment>
          ),
        }}
      />
      <div className="d-flex flex-column align-items-center mt-3">
        <div>Sua nova senha deve respeitar as seguintes regras:</div>
        <div className="mt-3">
          <ValidationItem state={state} item={"min"}>
            Ter no mínimo 8 caracteres
          </ValidationItem>
          <ValidationItem state={state} item={"lowercase"}>
            Ter ao menos uma letra minúscula
          </ValidationItem>
          <ValidationItem state={state} item={"uppercase"}>
            Ter ao menos uma letra maiúscula
          </ValidationItem>
          <ValidationItem state={state} item={"digits"}>
            Ter ao menos um número
          </ValidationItem>
          <ValidationItem state={state} item={"symbols"}>
            Ter ao menos um caracter especial
          </ValidationItem>
        </div>
      </div>
      <div className="mini-separator mb-3" />
      <div className={"row justify-content-center"}>
        <div className="col-xs-12 col-md-6">
          <Button loading={loading} label={"CONFIRMAR"} type={"submit"} />
        </div>
      </div>
    </form>
  );
};

export default TrocarSenhaForm;
