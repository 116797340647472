import React, { useState, useCallback } from "react";
import Loader from "../../../../components/loaders";
import { Button } from "../../../../components/";
import Documento from "./Documento";
import Filtros from "./Filtros";
import { Add } from "@material-ui/icons";
import AdicionarDocumentoDialog from "./AdicionarDocumentoDialog.connected";
import ConfirmarAnaliseDialog from "./ConfirmarAnaliseDialog.connected";
import VisualizarMotivoDialog from "./VisualizarMotivoDialog.connected";
import DetalhesDocumentoDialog from "./DetalhesDocumentoDialog.connected";
import DetalhesIntegracaoDocumentoDialog from "./DetalhesIntegracaoDocumentoDialog.connected";
import VisibleWithProfile from "../../../../components/security/VisibleWithProfile";
import ConfirmarUploadBancoDialog from "./ConfirmarUploadBancoDialog";
import Switch from "../../../../components/inputs/Switch";

const ListaDocumentos = ({
  loading,
  filtro,
  proposta,
  checklist,
  handleFilterClick,
  handleOpenImagens,
  uploading,
  handleUpload,
  perfil,
  handleUploadDocumentosBanco,
  loadingUploadDocumentosBanco,
  handleClickErroFiltroUpload,
  filtroErroUpload,
  handleDownload,
}) => {
  const [open, setOpen] = useState(false);
  const handleCloseDialog = () => {
    setOpen(false);
  };
  const [dadosAdicionarDocumento, setDadosAdicionarDocumento] = useState({
    open: false,
  });
  const [dadosConfirmarAnalise, setDadosConfirmarAnalise] = useState({
    open: false,
  });
  const [dadosVisualizarComentarios, setDadosVisualizarComentarios] = useState({
    open: false,
  });
  const [dadosDetalheDocumento, setDadosDetalheDocumento] = useState({
    open: false,
  });
  const [dadosDetalheIntegracao, setDadosDetalheIntegracao] = useState({
    open: false,
  });

  const handleCloseConfirmarAnalise = useCallback(() => {
    setDadosConfirmarAnalise({
      open: false,
    });
  }, []);
  const handleOpenConfirmarAnalise = useCallback(
    (values) => {
      setDadosConfirmarAnalise({
        open: true,
        proposta,
        ...values,
      });
    },
    [proposta]
  );

  const handleCloseVisualizarComentarios = useCallback(() => {
    setDadosVisualizarComentarios({
      open: false,
    });
  }, []);
  const handleOpenVisualizarComentarios = useCallback(
    (values, isSubstituto) => {
      setDadosVisualizarComentarios({
        open: true,
        ...values,
        isSubstituto,
      });
    },
    []
  );

  const handleCloseAdicionarDocumento = useCallback(() => {
    setDadosAdicionarDocumento({
      open: false,
    });
  }, []);
  const handleOpenAdicionarDocumento = useCallback(
    (id, tipo) => {
      setDadosAdicionarDocumento({
        open: true,
        id,
        tipo,
        proposta,
      });
    },
    [proposta]
  );

  const handleCloseDetalhesDocumento = useCallback(() => {
    setDadosDetalheDocumento({
      open: false,
    });
  }, []);

  const handleOpenDetalhesDocumento = useCallback((values) => {
    setDadosDetalheDocumento({
      open: true,
      ...values,
    });
  }, []);

  const handleCloseDetalhesIntegracao = useCallback(() => {
    setDadosDetalheIntegracao({
      open: false,
    });
  }, []);

  const handleOpenDetalhesIntegracao = useCallback((values) => {
    setDadosDetalheIntegracao({
      open: true,
      ...values,
    });
  }, []);

  if (!!loading) {
    return (
      <div className="row">
        <div className="col-12 d-flex justify-content-center">
          <Loader type={"dots"} color={"brand"} size={50} />
        </div>
      </div>
    );
  }
  return (
    <>
      <Filtros filtro={filtro} handleFilterClick={handleFilterClick} />
      {checklist.length === 0 && (
        <div className="flex d-flex flex-column justify-content-center align-items-center">
          <span className="d-block colored-card-text mt-4 color-secondary text-center">
            Nenhum documento encontrado com o filtro selecionado.
            <br />
            Selecione outro filtro.
          </span>
        </div>
      )}
      {checklist.map((p) => (
        <React.Fragment key={p.id + p.tipo_participante}>
          {p.documentos.length > 0 && (
            <div className="row">
              <div className="col-12">
                <div className="card" style={{ minHeight: 100 }}>
                  <div className="card-body d-flex flex-column">
                    <div className={"row d-flex align-items-center"}>
                      <div className={"col mt-0"}>
                        <div className="card-title-checklist">
                          {!!p.nome ? p.nome : "IMÓVEL"}
                        </div>
                        <div className="card-subtitle-checklist">
                          {p["tipo_participante"] === "C"
                            ? "Comprador"
                            : p["tipo_participante"] === "V"
                            ? "Vendedor"
                            : "Imóvel"}
                        </div>
                      </div>
                      {
                        <div className="d-none d-md-block">
                          <div
                            className="botao-adicionar mr-3"
                            onClick={() =>
                              handleOpenAdicionarDocumento(
                                p.id,
                                p.tipo_participante
                              )
                            }
                          >
                            <Add className="botao-adicionar-icon mr-1" />
                            <span className="botao-adicionar-text">
                              ADICIONAR DOCUMENTO
                            </span>
                          </div>
                        </div>
                      }
                    </div>
                    <div className={"row"}>
                      <div className="col-12">
                        {p.documentos.map((d, index) => (
                          <Documento
                            key={d.id}
                            doc={d}
                            index={index}
                            handleOpenImagens={handleOpenImagens}
                            uploading={uploading.indexOf(d.id) > -1}
                            onChange={handleUpload}
                            perfil={perfil}
                            handleOpenDetalhesDocumento={
                              handleOpenDetalhesDocumento
                            }
                            handleVisualizarMotivo={
                              handleOpenVisualizarComentarios
                            }
                            handleAtuarAnaliseDocumento={
                              handleOpenConfirmarAnalise
                            }
                            handleOpenDetalhesIntegracao={
                              handleOpenDetalhesIntegracao
                            }
                            handleDownload={handleDownload}
                          />
                        ))}
                        {
                          <div className={"responsividade-botao-documento"}>
                            <div
                              className="botao-adicionar mr-3"
                              onClick={() =>
                                handleOpenAdicionarDocumento(
                                  p.id,
                                  p.tipo_participante
                                )
                              }
                            >
                              <Add className="botao-adicionar-icon mr-1" />
                              <span className="botao-adicionar-text">
                                ADICIONAR DOCUMENTO
                              </span>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {p.documentos_conjuge?.length > 0 && (
            <div className="row">
              <div className="col-12">
                <div className="card" style={{ minHeight: 100 }}>
                  <div className="card-body d-flex flex-column">
                    <div className={"row d-flex align-items-center"}>
                      <div className={"col mt-0"}>
                        <div className="card-title-checklist">
                          {p.nomeConjuge}
                        </div>
                        <div className="card-subtitle-checklist">
                          {"Cônjuge"}
                        </div>
                      </div>
                      {
                        <div className="d-none d-md-block">
                          <div
                            className="botao-adicionar mr-3"
                            onClick={() =>
                              handleOpenAdicionarDocumento(
                                p.id,
                                p.tipo_participante === "C" ? "CC" : "CV"
                              )
                            }
                          >
                            <Add className="botao-adicionar-icon mr-1" />
                            <span className="botao-adicionar-text">
                              ADICIONAR DOCUMENTO
                            </span>
                          </div>
                        </div>
                      }
                    </div>
                    <div className={"row"}>
                      <div className="col-12">
                        {p.documentos_conjuge.map((d, index) => (
                          <Documento
                            key={d.id}
                            doc={d}
                            index={index}
                            handleOpenImagens={handleOpenImagens}
                            uploading={uploading.indexOf(d.id) > -1}
                            onChange={handleUpload}
                            perfil={perfil}
                            handleOpenDetalhesDocumento={
                              handleOpenDetalhesDocumento
                            }
                            handleVisualizarMotivo={
                              handleOpenVisualizarComentarios
                            }
                            handleAtuarAnaliseDocumento={
                              handleOpenConfirmarAnalise
                            }
                            handleOpenDetalhesIntegracao={
                              handleOpenDetalhesIntegracao
                            }
                            handleDownload={handleDownload}
                          />
                        ))}
                        {
                          <div className={"responsividade-botao-documento"}>
                            <div
                              className="botao-adicionar mr-3"
                              onClick={() =>
                                handleOpenAdicionarDocumento(
                                  p.id,
                                  p.tipo_participante
                                )
                              }
                            >
                              <Add className="botao-adicionar-icon mr-1" />
                              <span className="botao-adicionar-text">
                                ADICIONAR DOCUMENTO
                              </span>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </React.Fragment>
      ))}
      {checklist.length >= 0 && (
        <>
          <VisibleWithProfile profiles={["BPO"]}>
            <div className="row">
              <div className="col-12">
                <div className="card" style={{ minHeight: 100 }}>
                  <div className="card-body d-flex flex-column">
                    <div className="col-12 card-subtitle-checklist mt-0">
                      <span className="">Integração:</span>
                    </div>
                    <div className="row">
                      <div className="col-6 col-md-1 mt-0 p-0">
                        <Switch
                          label="Pendentes"
                          value={filtroErroUpload === "P"}
                          margin="root"
                          onChange={(e) => {
                            console.log(e.target.checked);
                            handleClickErroFiltroUpload(
                              e.target.checked ? "P" : ""
                            );
                          }}
                        />
                      </div>
                      <div className="col-6 col-md-1 mt-0 p-0">
                        <Switch
                          label="Inconsistências"
                          margin="root"
                          value={filtroErroUpload === "E"}
                          onChange={(e) => {
                            console.log(e.target.checked);
                            handleClickErroFiltroUpload(
                              e.target.checked ? "E" : ""
                            );
                          }}
                        />
                      </div>
                      <div className="col-12 col-md-4 offset-md-6 d-flex justify-content-center align-items-center">
                        <Button
                          className="mt-2"
                          label="Subir Documentos Banco"
                          loading={loadingUploadDocumentosBanco}
                          onClick={() => setOpen(true)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </VisibleWithProfile>
          <ConfirmarUploadBancoDialog
            open={open}
            handleClose={handleCloseDialog}
            handleSubmit={() => handleUploadDocumentosBanco(proposta)}
          />
        </>
      )}
      <AdicionarDocumentoDialog
        dados={dadosAdicionarDocumento}
        handleClose={handleCloseAdicionarDocumento}
      />
      <ConfirmarAnaliseDialog
        dados={dadosConfirmarAnalise}
        handleClose={handleCloseConfirmarAnalise}
      />
      <VisualizarMotivoDialog
        dados={dadosVisualizarComentarios}
        perfil={perfil}
        handleClose={handleCloseVisualizarComentarios}
      />
      <DetalhesDocumentoDialog
        dados={dadosDetalheDocumento}
        handleClose={handleCloseDetalhesDocumento}
      />
      <DetalhesIntegracaoDocumentoDialog
        dados={dadosDetalheIntegracao}
        handleClose={handleCloseDetalhesIntegracao}
      />
    </>
  );
};

export default ListaDocumentos;
