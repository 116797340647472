import formatters from "../../utils/formatters";
import {
  ArrowDownwardOutlined,
  ArrowUpwardOutlined,
  AttachMoneyOutlined,
  HomeWorkOutlined,
} from "@material-ui/icons";
import React from "react";
import Loader from "../../components/loaders";

const CardProjecaoMes = ({ projecao }) => {
  return (
    <div className="card" style={{ minHeight: 100 }}>
      <div className="card-body d-flex flex-column">
        {!!projecao.loading && (
          <div className="row">
            <div className="col-12 d-flex justify-content-center">
              <Loader type={"dots"} color={"brand"} size={80} />
            </div>
          </div>
        )}
        {!projecao.loading && (
          <>
            <div
              className={"row d-flex align-items-center justify-content-center"}
            >
              <div
                className="card-title-dashboard color-brand"
                style={{ minHeight: "34px" }}
              >
                Projeção para o final do Mês
              </div>
            </div>
            <div className="row">
              <div className="col-6 mt-3">
                <div
                  className={"d-flex align-items-center justify-content-center"}
                >
                  <AttachMoneyOutlined
                    style={{ fontSize: 56, color: "grey" }}
                  />
                </div>
                <div
                  className={
                    "d-flex align-items-center justify-content-center mt-2"
                  }
                >
                  <div className="colored-card-data">
                    {projecao.data.valor > 0
                      ? projecao.data.valor / 1000000 > 1
                        ? formatters.numbers.nFixed(
                            projecao.data.valor / 1000000,
                            2
                          ) + "M"
                        : formatters.numbers.simple(
                            projecao.data.valor / 1000
                          ) + "K"
                      : 0}
                  </div>
                </div>
                <div
                  className={"d-flex align-items-center justify-content-center"}
                >
                  <span className="colored-card-text">Reais</span>
                </div>
                <div className="mt-3 justify-content-center">
                  <div
                    className={
                      "d-flex align-items-center justify-content-center"
                    }
                  >
                    {(projecao.data.percDiffValor === 0 ||
                      !projecao.data.percDiffValor) && (
                      <div style={{ minHeight: "27px" }}>
                        <span className="colored-card-data">-</span>
                      </div>
                    )}
                    {!(projecao.data.percDiffValor === 0) &&
                      !!projecao.data.percDiffValor && (
                        <>
                          {projecao.data.percDiffValor > 0 && (
                            <ArrowUpwardOutlined className="producao-alta" />
                          )}
                          {projecao.data.percDiffValor < 0 && (
                            <ArrowDownwardOutlined className="producao-baixa" />
                          )}
                          <span
                            className={
                              projecao.data.percDiffValor < 0
                                ? "producao-baixa colored-card-text ml-2 d-inline-block"
                                : "colored-card-text ml-2 d-inline-block producao-alta"
                            }
                          >
                            {formatters.numbers.currency(
                              projecao.data.percDiffValor
                            )}{" "}
                            %
                          </span>
                        </>
                      )}
                  </div>
                </div>
              </div>
              <div className="col-6 mt-3">
                <div
                  className={"d-flex align-items-center justify-content-center"}
                >
                  <HomeWorkOutlined style={{ fontSize: 56, color: "grey" }} />
                </div>
                <div
                  className={
                    "d-flex align-items-center justify-content-center mt-2"
                  }
                >
                  <div className="colored-card-data">
                    {projecao.data.quantidade}
                  </div>
                </div>
                <div
                  className={"d-flex align-items-center justify-content-center"}
                >
                  <span className="colored-card-text">
                    {projecao.data.quantidade === 1 ? "Contrato" : "Contratos"}
                  </span>
                </div>
                <div className="mt-3">
                  <div
                    className={
                      "d-flex align-items-center justify-content-center"
                    }
                  >
                    {(projecao.data.percDiffQuantidade === 0 ||
                      !projecao.data.percDiffQuantidade) && (
                      <div style={{ minHeight: "27px" }}>
                        <span className="colored-card-data">-</span>
                      </div>
                    )}
                    {projecao.data.percDiffQuantidade !== 0 &&
                      !!projecao.data.percDiffQuantidade && (
                        <>
                          {projecao.data.percDiffQuantidade > 0 && (
                            <ArrowUpwardOutlined className="producao-alta" />
                          )}
                          {projecao.data.percDiffQuantidade < 0 && (
                            <ArrowDownwardOutlined className="producao-baixa" />
                          )}
                          <span
                            className={
                              projecao.data.percDiffQuantidade < 0
                                ? "producao-baixa colored-card-text ml-2 d-inline-block"
                                : "colored-card-text ml-2 d-inline-block producao-alta"
                            }
                          >
                            {formatters.numbers.currency(
                              projecao.data.percDiffQuantidade
                            )}{" "}
                            %
                          </span>
                        </>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default CardProjecaoMes;
