import React, { useCallback, useState } from "react";
import TrocarSituacaoConsulta from "./TrocarSituacaoConsulta";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import validators from "../../../utils/validators";
import { actions } from "../../../reducers/propostas.actions";
import { getProposta } from "../../../selectors/propostas.selectors";
import { getMotivosCancelamento } from "../../../selectors/dominios.selectors";

const TrocarSituacaoConsultaConnected = ({
  alterarSituacao,
  handleCloseAlterarSituacao,
  handleOpenAlterarSituacao,
  handleOpcaoSelecionada,
  showMotivoCancelamento,
  showDataContrato,
}) => {
  const dispatch = useDispatch();
  const situacaoProposta = useSelector(getProposta)?.situacaoProposta;
  const motivosCancelamento = useSelector(getMotivosCancelamento);
  const dominioData = [
    { key: "A", desc: "Em andamento" },
    { key: "C", desc: "Cancelada" },
    { key: "E", desc: "Contrato Emitido" },
    { key: "P", desc: "Paralisada" },
  ];
  const rules = {
    situacao: validators.string({ required: false }),
    consultaID: validators.number({ required: true }),
    motivoCancelamento: validators.number({ required: true }),
    dataContrato: validators.date({
      required: true,
      // custom: {
      //   beforeToday: (value) =>
      //     new Date(
      //       `${value.split("/")[2]}-${value.split("/")[1]}-${
      //         value.split("/")[0]
      //       }`
      //     ) <= new Date() || "A data não pode ser futura",
      // },
    }),
  };
  const initialValues = {
    situacao: alterarSituacao.situacao,
    consultaID: alterarSituacao.consultaID,
    motivoCancelamento: alterarSituacao.motivoCancelamento,
    dataContrato: alterarSituacao.dataContrato,
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const handleSubmit = useCallback(
    ({ situacao, motivoCancelamento, dataContrato }) => {
      dispatch(
        actions.atualizarSituacaoConsulta.request({
          consultaID: alterarSituacao.consultaID,
          situacao,
          motivoCancelamento: motivoCancelamento ? motivoCancelamento : null,
          dataContrato: dataContrato,
          handleCloseAlterarSituacao,
        })
      );
    },
    [dispatch, alterarSituacao, handleCloseAlterarSituacao]
  );

  return (
    <TrocarSituacaoConsulta
      handleSubmit={handleSubmit}
      handleOpen={handleOpenAlterarSituacao}
      open={alterarSituacao.open}
      formProps={formProps}
      dominioData={dominioData}
      handleClose={handleCloseAlterarSituacao}
      motivoCancelameto={motivosCancelamento}
      submitting={situacaoProposta?.loading}
      showMotivoCancelamento={showMotivoCancelamento}
      handleOpcaoSelecionada={handleOpcaoSelecionada}
      showDataContrato={showDataContrato}
    />
  );
};

export default TrocarSituacaoConsultaConnected;
