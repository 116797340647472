import {
  ControlledOtpTextField,
  ControlledReaptcha,
  IntegerTextField,
} from "../../components/inputs";

import { Button } from "../../components";
import React from "react";
const TokenForm = ({
  formProps,
  loading,
  handleSubmit,
  voltar,
  reenviarTokenSenha,
  captchaRef,
}) => {
  return (
    <form
      className="d-flex flex-column"
      onSubmit={formProps.handleSubmit(handleSubmit)}
    >
      <div className="row">
        <div className="d-flex flex-grow-1 align-items-center justify-content-center">
          <div className={""}>
            <ControlledOtpTextField
              name={"token"}
              formProps={formProps}
              margin={"none"}
              InputProps={{
                inputComponent: IntegerTextField,
              }}
              numInputs={6}
              separator={""}
              isInputNum={true}
            />
          </div>
        </div>
      </div>
      <div className="mini-separator mb-3 mt-3" />
      <div className={"row justify-content-center"}>
        <ControlledReaptcha
          formProps={formProps}
          captchaRef={captchaRef}
          name={"tokenCaptcha"}
          margin={"none"}
          sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
        />
      </div>

      <div className={"row justify-content-center"}>
        <div className="col-xs-12 col-md-6 mt-3">
          <Button
            className="button-alternative"
            loading={loading}
            label={"VOLTAR"}
            onClick={voltar}
          />
        </div>
        <div className="col-xs-12 col-md-6 mt-3">
          <Button loading={loading} label={"CONFIRMAR"} type={"submit"} />
        </div>
      </div>
      <div className={"row justify-content-center"}>
        <div className="col-12">
          <Button
            className={"button-more"}
            loading={loading}
            label={"REENVIAR TOKEN"}
            onClick={reenviarTokenSenha}
          />
        </div>
      </div>
    </form>
  );
  // }
};
export default TokenForm;
