import React, { useState } from "react";
import { Dialog, DialogContent, IconButton, Popover } from "@material-ui/core";
import { ControlledSwitch } from "../../../../components/inputs";
import { Button } from "../../../../components";
import { CloseSharp, InfoOutlined } from "@material-ui/icons";

const AdicionarClausulaDialog = ({
  handleSubmit,
  formProps,
  dominioClausulas,
  submitting,
  handleClose,
  handleOpen,
  open,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [utilizacao, setUtilizacao] = useState("");

  const handleClick = (event, u) => {
    setAnchorEl(event.currentTarget);
    setUtilizacao(u);
  };

  const handleClosePopup = () => {
    setAnchorEl(null);
  };

  const openPopup = Boolean(anchorEl);

  return (
    <Dialog
      onClose={handleClose}
      onEnter={handleOpen}
      classes={{ paper: "w100" }}
      maxWidth="lg"
      open={open}
    >
      <DialogContent>
        <IconButton
          onClick={handleClose}
          classes={{ root: "position-absolute clickable close-icon" }}
        >
          <CloseSharp />
        </IconButton>
        <div className={"page-title"}>Adicionar Cláusulas</div>
        <form
          className="d-flex flex-column"
          onSubmit={formProps.handleSubmit(handleSubmit)}
        >
          {!!open &&
            dominioClausulas
              .filter((c) => {
                return c.tipo !== "Q";
              })
              .map((item) => (
                <div className="row">
                  <div className="col-1">
                    <ControlledSwitch
                      name={"clausula" + item.id}
                      margin={"none"}
                      formProps={formProps}
                    />
                  </div>
                  <div className="col-1 label-switch-clausula">
                    {item.codigo}
                  </div>
                  <div className="col-8 label-switch-clausula">
                    {item.nome}
                    <InfoOutlined
                      className="ml-2 info-documento clickable"
                      onClick={(event) => handleClick(event, item.utilizacao)}
                    />
                  </div>
                  <div className="col-1 label-switch-clausula">
                    {item.tipo === "P" ? "BANCO" : "COMARCA"}
                  </div>
                </div>
              ))}
          <div className={"row justify-content-center mb-3 mt-2"}>
            <div className="col-6 mt-3">
              <Button
                loading={submitting}
                label={"CONFIRMAR"}
                type={"submit"}
              />
            </div>
          </div>
        </form>
      </DialogContent>
      <Popover
        open={openPopup}
        anchorEl={anchorEl}
        onClose={handleClosePopup}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <div className="p-2 bg-dark color-white orientacao">{utilizacao}</div>
      </Popover>
    </Dialog>
  );
};

export default AdicionarClausulaDialog;
