import React from "react";

const Filtro = ({ filtroSelecionado, label, code, handleFilterClick }) => {
  const buttonStyle = "h-100 base-filter";
  const buttonStyleSelected = buttonStyle + " filter-selected";

  const textStyle = "color-dark base-filter-text";
  const textStyleSelected = textStyle + " filter-text-selected";

  return (
    <div className="col-sm-6 col-md-4 col-lg-2">
      <div
        onClick={() => handleFilterClick(code)}
        className={
          filtroSelecionado === code ? buttonStyleSelected : buttonStyle
        }
      >
        <span
          className={filtroSelecionado === code ? textStyleSelected : textStyle}
        >
          {label}
        </span>
      </div>
    </div>
  );
};

const Filtros = ({ filtro, handleFilterClick }) => {
  return (
    <div className="card">
      <div className={"card-body d-flex flex-column"}>
        <div className={"row"}>
          <div className="col-12 card-subtitle-checklist mt-0">
            <span className="">Filtrar:</span>
          </div>
          <div className="col-12 mt-0">
            <div className="row d-flex flex-row">
              <Filtro
                filtroSelecionado={filtro}
                handleFilterClick={handleFilterClick}
                label={"TODOS"}
                code={"todos"}
              />
              <Filtro
                filtroSelecionado={filtro}
                handleFilterClick={handleFilterClick}
                label={"DOCUMENTOS INICIAIS"}
                code={"iniciais"}
              />
              <Filtro
                filtroSelecionado={filtro}
                handleFilterClick={handleFilterClick}
                label={"APROVADOS"}
                code={"aprovados"}
              />
              <Filtro
                filtroSelecionado={filtro}
                handleFilterClick={handleFilterClick}
                label={"RECUSADOS"}
                code={"recusados"}
              />
              <Filtro
                filtroSelecionado={filtro}
                handleFilterClick={handleFilterClick}
                label={"PENDENTES DE ANÁLISE"}
                code={"pendentes"}
              />
              <Filtro
                filtroSelecionado={filtro}
                handleFilterClick={handleFilterClick}
                label={"EM FALTA"}
                code={"falta"}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Filtros;
