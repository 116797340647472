import { createSelector } from "reselect";
import { getProposta } from "./propostas.selectors";

export const getChecklist = createSelector(
  getProposta,
  (state) => state?.checklist
);
export const getChecklistList = createSelector(
  getChecklist,
  (state) => state?.list
);
export const getLoading = createSelector(
  getChecklist,
  (state) => state?.loading
);
export const getUploading = createSelector(
  getChecklist,
  (state) => state?.uploading
);
export const getFilter = createSelector(getChecklist, (state) => state?.filter);
export const getErroFiltroUpload = createSelector(
  getChecklist,
  (state) => state?.filtro_erro_upload
);
