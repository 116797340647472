import { useCallback } from "react";
import Header from "./Header";
import { getUsuario } from "../../selectors/usuario.selectors";
import { useSelector, useDispatch } from "react-redux";
import { actions as authActions } from "../../reducers/autenticacao.actions";
import {
  types as routes,
  actions as routeActions,
} from "../../reducers/rotas.actions";
import { actions as propostaActions } from "../../reducers/propostas.actions";

const HeaderConnected = () => {
  const dispatch = useDispatch();
  const { nome, perfil, permissoes } = useSelector(getUsuario);

  const handleClickMenu = useCallback(
    (route) => {
      dispatch(routeActions.redirectTo(route));
    },
    [dispatch]
  );

  const selecionarFiltro = useCallback(() => {
    dispatch(propostaActions.limparFiltroValores());
  }, [dispatch]);

  const selecionarFiltroEMudarPagina = (route) => {
    //selecionarFiltro();
    setTimeout(function () {
      handleClickMenu(route);
    }, 50);
  };

  const menus = [
    {
      id: "minhas-consultas",
      label: "Minhas Consultas",
      route: routes.PROPOSTAS,
      action: selecionarFiltroEMudarPagina,
    },
    {
      id: "dashboard",
      label: "Dashboard",
      route: routes.DASHBOARD,
      perfil: ["ADMINISTRADOR", "BPO", "BANCO GESTAO", "BANCO"],
      action: handleClickMenu,
    },
    {
      id: "administracao",
      label: "Administração",
      perfil: ["ADMINISTRADOR", "BPO", "BANCO GESTAO"],
      menus: [
        {
          id: "cadastros",
          label: "Cadastros",
          perfil: ["ADMINISTRADOR", "BPO"],
          menus: [
            {
              id: "atividades",
              label: "Atividades",
              perfil: ["ADMINISTRADOR"],
              action: handleClickMenu,
              route: routes.ADMINISTRACAO_ATIVIDADES,
            },
            {
              id: "documentos",
              label: "Documentos",
              perfil: ["ADMINISTRADOR"],
              action: handleClickMenu,
              route: routes.ADMINISTRACAO_DOCUMENTOS,
            },
            {
              id: "etapas",
              label: "Etapas",
              perfil: ["ADMINISTRADOR"],
              action: handleClickMenu,
              route: routes.ADMINISTRACAO_ETAPAS,
            },
            {
              id: "localidade",
              label: "Localidades",
              perfil: ["ADMINISTRADOR"],
              action: handleClickMenu,
              route: routes.ADMINISTRACAO_LOCALIDADES,
            },
            {
              id: "matrizChecklist",
              label: "Matriz Checklist",
              perfil: ["ADMINISTRADOR"],
              action: handleClickMenu,
              route: routes.ADMINISTRACAO_MCHECKLIST,
            },
            {
              id: "minutas",
              label: "Minutas",
              perfil: ["ADMINISTRADOR"],
              menus: [
                {
                  id: "clausulas",
                  label: "Cláusulas",
                  perfil: ["ADMINISTRADOR"],
                  action: handleClickMenu,
                  route: routes.ADMINISTRACAO_CLAUSULAS,
                },
                {
                  id: "matrizClausulas",
                  label: "Matriz Cláusulas",
                  perfil: ["ADMINISTRADOR"],
                  action: handleClickMenu,
                  route: routes.ADMINISTRACAO_MATRIZ_CLAUSULAS,
                },
              ],
            },
            {
              id: "motivoCancelamento",
              label: "Motivo de Cancelamento",
              perfil: ["ADMINISTRADOR"],
              action: handleClickMenu,
              route: routes.ADMINISTRACAO_MOTIVO_CANCELAMENTO,
            },
            {
              id: "organizacao",
              label: "Organização",
              perfil: ["ADMINISTRADOR"],
              action: handleClickMenu,
              route: routes.ADMINISTRACAO_ORGANIZACAO,
            },
            // {
            //   id: "perfis",
            //   label: "Perfis",
            //   perfil: ["ADMINISTRADOR"],
            //   action: handleClickMenu,
            //   route: routes.ADMINISTRACAO_PERFIS,
            // },
            {
              id: "produtos",
              label: "Produtos",
              perfil: ["ADMINISTRADOR"],
              action: handleClickMenu,
              route: routes.ADMINISTRACAO_PRODUTOS,
            },
            {
              id: "templates",
              label: "Templates de E-mail",
              perfil: ["ADMINISTRADOR"],
              action: handleClickMenu,
              route: routes.ADMINISTRACAO_TEMPLATES,
            },
            {
              id: "usuarios",
              label: "Usuários",
              perfil: ["ADMINISTRADOR", "BPO"],
              action: handleClickMenu,
              route: routes.ADMINISTRACAO_USUARIOS,
            },
          ],
        },
        {
          id: "carga-consultas",
          label: "Carga de Consultas",
          perfil: ["ADMINISTRADOR"],
          action: handleClickMenu,
          route: routes.CARGA_CONSULTAS,
        },
        {
          id: "export-consultas",
          label: "Exportação de Consultas",
          perfil: ["ADMINISTRADOR", "BANCO GESTAO"],
          action: handleClickMenu,
          route: routes.EXPORT_CONSULTAS,
        },
        // {
        //   id: "export-sla",
        //   label: "Exportação de SLA",
        //   perfil: ["ADMINISTRADOR", "BPO", "BANCO GESTAO"],
        //   action: handleClickMenu,
        //   route: routes.EXPORT_SLA,
        // },
        {
          id: "painel-integracao",
          label: "Painel de Integração (Erros)",
          perfil: ["ADMINISTRADOR", "BPO"],
          action: handleClickMenu,
          route: routes.ADMINISTRACAO_INTEGRA_DOC,
        },
        {
          id: "relatorios",
          label: "Relatórios",
          perfil: ["ADMINISTRADOR"],
          menus: [
            {
              id: "auditoria",
              label: "Auditoria Documentos",
              perfil: ["ADMINISTRADOR"],
              action: handleClickMenu,
              route: routes.ADMINISTRACAO_RELATORIO_AUDITORIA,
            },
          ],
        },
      ],
    },
  ];

  const handleClickLogout = useCallback(() => {
    dispatch(authActions.logout());
  }, [dispatch]);

  const handleClickOpenSearch = useCallback((e) => {
    dispatch(propostaActions.openSearch(e.currentTarget));
  });

  const restrictedMenus = restrictMenus(menus, [], perfil, permissoes);
  const showSearch = perfil !== "CLIENTE";
  return (
    <Header
      menus={restrictedMenus}
      username={nome}
      handleClickLogout={handleClickLogout}
      handleClickOpenSearch={handleClickOpenSearch}
      showSearch={showSearch}
    />
  );
};

const restrictMenus = (menus, current, perfil, permissoes) => {
  menus.forEach((menu) => {
    if (!menu.perfil) {
      if (!menu.menus) {
        current.push(menu);
      } else {
        const restricted = {
          ...menu,
          menus: restrictMenus(menu.menus, [], perfil, permissoes),
        };
        current.push(restricted);
      }
    } else {
      const canAccess = menu.perfil.indexOf(perfil) > -1;
      if (canAccess) {
        if (!menu.menus) {
          current.push(menu);
        } else {
          const restricted = {
            ...menu,
            menus: restrictMenus(menu.menus, [], perfil, permissoes),
          };
          current.push(restricted);
        }
      }
    }
  });
  return current;
};

export default HeaderConnected;
