import React from "react";
import { qualificacao } from "../../../utils/parametros";
import formatters from "../../../utils/formatters";

const DocumentoErroIntegracaoPage = ({ d, index }) => {
  return (
    <div
      className={
        index % 2 === 0
          ? "row mt-0 d-flex align-items-center medium-row tr-even-adm"
          : "row mt-0 d-flex align-items-center medium-row tr-odd"
      }
    >
      <div className="col-2 user-table clickable">
        <span>{d["consulta-proposta"].codigo}</span>
      </div>
      <div className="col-2 user-table">
        <span>
          {
            qualificacao.filter((q) => {
              return d.tipoParticipante === q.id;
            })[0].nome
          }
        </span>
      </div>
      <div className="col-4 user-table resumir-texto">
        <span>{d.Participantes?.nome || d.Imovel?.logradouro}</span>
      </div>
      <div className="col-4 user-table resumir-texto">
        <span>{d.Documento.nome}</span>
      </div>
      <div className="col-2 user-table">
        <span>
          {formatters.dates.withHours(new Date(d.dataHoraIntegracao))}
        </span>
      </div>
      <div className="col-10 mb-2 user-table">
        <span>{d.erroIntegracao}</span>
      </div>
    </div>
  );
};

export default DocumentoErroIntegracaoPage;
