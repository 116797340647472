import React, { useState } from "react";
import { MenuItem } from "../../../../../components";
import CardDadosConjuge from "./CardDadosConjuge";
import {
  ControlledSelect,
  ControlledSwitch,
  ControlledTextfield,
  CpfCnpjTextField,
  DateTextField,
} from "../../../../../components/inputs";
import CardEscrituraPublica from "./CardEscrituraPublica";
import CardRegistroCasamento from "./CardRegistroCasamento";
import CardDadosContato from "./CardDadosContato";

const CardDadosPessoaFisica = ({
  props,
  dominioEstadoCivil,
  dominioRegimeComunhao,
  disabled,
  validarCpfCnpjRepetido,
  isAtualizacao,
  uf,
  dominioCartorios,
  isPf,
}) => {
  const DATA_CASAMENTO = new Date("1977-12-27");

  const [hasCompanheira, setHasCompanheira] = useState(
    dominioEstadoCivil.filter(
      (e) => e.id === props.initialValues?.estadoCivil
    )[0]?.conjuge
  );

  const [estadoCivil, setEstadoCivil] = useState(
    props.initialValues?.estadoCivil
  );
  const [dataCasamento, setDataCasamento] = useState(
    props.initialValues?.dataCasamento
  );
  const [regimeComunhao, setRegimeComunhao] = useState(
    props.initialValues?.regimeCasamento
  );
  const [compoeRenda, setCompoeRenda] = useState();
  const [conjugeVendedor, setConjugeVendedor] = useState();

  const [showUniaoEstavel, setShowUniaoEstavel] = useState(
    dominioEstadoCivil.filter(
      (e) => e.id === props.initialValues?.estadoCivil
    )[0]?.uniaoEstavel
  );
  const [hasUniaoEstavel, setHasUniaoEstavel] = useState(
    props.initialValues?.uniaoEstavel ?? false
  );

  const [hasRegime, setHasRegime] = useState(
    props.initialValues?.estadoCivil === "CASADO" ||
      props.initialValues?.estadoCivil === "CASADO NO EXTERIOR"
  );

  const handleChangeEstadoCivil = (e) => {
    setEstadoCivil(e.target.value);
    setHasCompanheira(
      e.target.value &&
        dominioEstadoCivil.some((i) => i.id === e.target.value && i["conjuge"])
    );
    setShowUniaoEstavel(
      e.target.value &&
        dominioEstadoCivil.some(
          (i) =>
            i.id === e.target.value &&
            e.target.value !== "CASADO" &&
            i["uniaoEstavel"]
        )
    );
    setHasRegime(
      e.target.value === "CASADO" || e.target.value === "CASADO NO EXTERIOR"
    );

    if (
      e.target.value === "CASADO" ||
      e.target.value === "CASADO NO EXTERIOR"
    ) {
      setHasUniaoEstavel(false);
    } else {
      setHasUniaoEstavel(props.initialValues?.uniaoEstavel);
    }
  };

  const handleUniaoEstavel = (value) => {
    setHasUniaoEstavel(value);
  };

  const showBlocosRegistroEscrituraPublica = () => {
    const show = {
      escritura: false,
      registro: false,
    };

    const data =
      dataCasamento !== null
        ? new Date(
            `${dataCasamento.split("/")[2]}-${dataCasamento.split("/")[1]}-${
              dataCasamento.split("/")[0]
            }`
          )
        : null;

    console.log(
      "estadoCivil: ",
      estadoCivil,
      " regimeComunhao: ",
      regimeComunhao,
      " dataCasamento: ",
      data
    );

    if (props.initialValues.tipoParticipante === "C") {
      if (
        (estadoCivil === "CASADO" &&
          data >= DATA_CASAMENTO &&
          regimeComunhao === "COMUNHÃO UNIVERSAL DE BENS") ||
        (estadoCivil === "CASADO" &&
          data < DATA_CASAMENTO &&
          regimeComunhao === "COMUNHÃO PARCIAL DE BENS") ||
        (estadoCivil === "CASADO" && regimeComunhao === "SEPARAÇÃO DE BENS")
      ) {
        show.escritura = true;
        show.registro = true;
      } else if (
        (estadoCivil === "CASADO" && !hasUniaoEstavel) ||
        (estadoCivil === "CASADO" &&
          data >= DATA_CASAMENTO &&
          regimeComunhao === "SEPARAÇÃO DE BENS OBRIGATÓRIA POR LEI") ||
        (estadoCivil === "CASADO" &&
          data >= DATA_CASAMENTO &&
          regimeComunhao === "COMUNHÃO PARCIAL DE BENS") ||
        (estadoCivil === "CASADO" &&
          dataCasamento < DATA_CASAMENTO &&
          regimeComunhao === "SEPARAÇÃO OBRIGATÓRIA DE BENS POR LEI") ||
        (estadoCivil === "CASADO" &&
          dataCasamento < DATA_CASAMENTO &&
          regimeComunhao === "COMUNHÃO UNIVERSAL DE BENS")
      ) {
        show.escritura = false;
        show.registro = false;
      } else if (estadoCivil !== "CASADO" && hasUniaoEstavel) {
        show.escritura = true;
        show.registro = false;
      }
    } else {
      if (
        (estadoCivil === "CASADO" &&
          data >= DATA_CASAMENTO &&
          regimeComunhao === "COMUNHÃO UNIVERSAL DE BENS") ||
        (estadoCivil === "CASADO" &&
          data < DATA_CASAMENTO &&
          regimeComunhao === "COMUNHÃO PARCIAL DE BENS") ||
        (estadoCivil === "CASADO" && regimeComunhao === "SEPARAÇÃO DE BENS")
      ) {
        {
          show.escritura = true;
          show.registro = true;
        }
      } else if (
        (estadoCivil === "CASADO" &&
          data >= DATA_CASAMENTO &&
          regimeComunhao === "SEPARAÇÃO DE BENS OBRIGATÓRIA POR LEI") ||
        (estadoCivil === "CASADO" &&
          data >= DATA_CASAMENTO &&
          regimeComunhao === "COMUNHÃO PARCIAL DE BENS") ||
        (estadoCivil === "CASADO" &&
          dataCasamento < DATA_CASAMENTO &&
          regimeComunhao === "SEPARAÇÃO OBRIGATÓRIA DE BENS POR LEI") ||
        (estadoCivil === "CASADO" &&
          dataCasamento < DATA_CASAMENTO &&
          regimeComunhao === "COMUNHÃO UNIVERSAL DE BENS")
      ) {
        show.escritura = false;
        show.registro = false;
      } else if (estadoCivil !== "CASADO" && hasUniaoEstavel) {
        show.escritura = true;
        show.registro = false;
      }
    }

    return show;
  };

  return (
    <>
      <div className="card spacer">
        <div className="card-body">
          <span className="card-title color-brand">Dados Pessoais</span>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-3">
              <ControlledTextfield
                formProps={props}
                label={"CPF do Participante"}
                type={"cpf"}
                name={"cpf"}
                margin={"none"}
                disabled={disabled || isAtualizacao}
                onBlur={(value) => validarCpfCnpjRepetido(value)}
                InputProps={{
                  inputComponent: CpfCnpjTextField,
                }}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-9">
              <ControlledTextfield
                formProps={props}
                label={"Nome do Participante"}
                name={"nome"}
                margin={"none"}
                disabled={disabled}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <ControlledTextfield
                formProps={props}
                label={"Data de Nascimento"}
                name={"dataNascimento"}
                margin={"none"}
                disabled={disabled}
                InputProps={{
                  inputComponent: DateTextField,
                }}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-5">
              <ControlledTextfield
                formProps={props}
                label={"Profissão"}
                name={"profissao"}
                margin={"none"}
                disabled={disabled}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <ControlledTextfield
                formProps={props}
                label={"Nacionalidade"}
                name={"nacionalidade"}
                margin={"none"}
                disabled={disabled}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-6">
              <ControlledTextfield
                formProps={props}
                label={"Nome do Pai"}
                name={"nomePai"}
                margin={"none"}
                disabled={disabled}
              />
            </div>

            <div className="col-12 col-md-6 col-lg-6">
              <ControlledTextfield
                formProps={props}
                label={"Nome da Mãe"}
                name={"nomeMae"}
                margin={"none"}
                disabled={disabled}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <ControlledSelect
                name={"estadoCivil"}
                formProps={props}
                label={"Estado Civil"}
                disabled={disabled}
                margin={"none"}
                onChange={handleChangeEstadoCivil}
              >
                {dominioEstadoCivil.map((item) => (
                  <MenuItem
                    className={"select-submenu-item"}
                    key={item.id}
                    value={item.id}
                  >
                    {item.descricao}
                  </MenuItem>
                ))}
              </ControlledSelect>
            </div>
            {hasRegime && (
              <>
                <div className="col-12 col-md-6 col-lg-6">
                  <ControlledSelect
                    name={"regimeCasamento"}
                    formProps={props}
                    label={"Regime de Casamento"}
                    margin={"none"}
                    disabled={disabled}
                    onChange={(e) => setRegimeComunhao(e.target.value)}
                  >
                    {dominioRegimeComunhao?.map((item) => (
                      <MenuItem
                        className={"select-submenu-item"}
                        key={item.id}
                        value={item.id}
                      >
                        {item.descricao}
                      </MenuItem>
                    ))}
                  </ControlledSelect>
                </div>
                <div className="col-12 col-md-6 col-lg-3">
                  <ControlledTextfield
                    formProps={props}
                    label={"Data do Casamento"}
                    name={"dataCasamento"}
                    margin={"none"}
                    disabled={disabled}
                    onChange={(value) => {
                      if (value.length === 10) {
                        setDataCasamento(value);
                      } else {
                        setDataCasamento(null);
                      }
                    }}
                    InputProps={{
                      inputComponent: DateTextField,
                    }}
                  />
                </div>
              </>
            )}
            {showUniaoEstavel && (
              <div className="col-12 col-md-3">
                <ControlledSwitch
                  name={"uniaoEstavel"}
                  formProps={props}
                  onChange={(e) => handleUniaoEstavel(e.target.checked)}
                  label={"União estável?"}
                  margin={"none"}
                  disabled={disabled}
                />
              </div>
            )}
            {props.initialValues.tipoParticipante === "V" && (
              <div className="col-12 col-md-3">
                <ControlledSwitch
                  name={"conjugeVendedor"}
                  formProps={props}
                  label={"Cônjuge é Vendedor?"}
                  margin={"none"}
                  disabled={disabled}
                />
              </div>
            )}
          </div>
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4">
              <ControlledTextfield
                formProps={props}
                name={"numeroDocumento"}
                label={"Número do Documento"}
                margin={"none"}
                disabled={disabled}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <ControlledTextfield
                formProps={props}
                name={"orgaoEmissor"}
                label={"Orgão Emissor"}
                margin={"none"}
                disabled={disabled}
              />
            </div>
            <div className="col-12 col-md-6 col-lg-4">
              <ControlledSelect
                name={"ufOrgaoEmissor"}
                formProps={props}
                disabled={disabled}
                label={"UF do Orgão Emissor"}
                margin={"none"}
              >
                {uf.map((item) => (
                  <MenuItem
                    className={"select-submenu-item"}
                    key={item.codigo}
                    value={item.codigo}
                  >
                    {item.nome}
                  </MenuItem>
                ))}
              </ControlledSelect>
            </div>
          </div>
          {props.initialValues.tipoParticipante === "C" && (
            <div className="row">
              <div className="col-6 col-md-3">
                <ControlledSwitch
                  name={"colaborador"}
                  formProps={props}
                  label={"É Colaborador?"}
                  margin={"none"}
                  disabled={disabled}
                />
              </div>
              <div className="col-6 col-md-3">
                <ControlledSwitch
                  name={"fgts"}
                  formProps={props}
                  label={"Utilizará FGTS?"}
                  margin={"none"}
                  disabled={disabled}
                />
              </div>
            </div>
          )}
        </div>
      </div>
      <CardDadosContato props={props} disabled={disabled} isPf={isPf} />
      {(hasCompanheira || hasUniaoEstavel) && (
        <CardDadosConjuge
          props={props}
          disabled={disabled}
          estadoCivil={dominioEstadoCivil}
          uf={uf}
          hasUniaoEstavel={hasUniaoEstavel}
        />
      )}
      {showBlocosRegistroEscrituraPublica().escritura && (
        <CardEscrituraPublica
          props={props}
          disabled={disabled}
          uf={uf}
          dominioCartorios={dominioCartorios}
        />
      )}
      {showBlocosRegistroEscrituraPublica().registro && (
        <CardRegistroCasamento
          props={props}
          disabled={disabled}
          uf={uf}
          dominioCartorios={dominioCartorios}
        />
      )}
    </>
  );
};

export default CardDadosPessoaFisica;
