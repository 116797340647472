import basicFlow from "sagas/asyncHandler";
import { actions } from "../../reducers/administracao/cargaConsultas.actions";
import { authenticatedRequest } from "utils/api";
import store from "../../store";

const processarApi = (form) => {
  return authenticatedRequest({
    url: "/administracao/carga-consultas",
    method: "post",
    body: form,
    headers: { "Content-Type": "multipart/form-data" },
    onDownloadProgress: (progressEvent) => {
      const label = progressEvent.srcElement.responseText.slice(
        progressEvent.srcElement.responseText.lastIndexOf("@") + 1
      );
      store.dispatch(actions.updateLabel(label));
    },
  });
};
const processar = basicFlow({
  actionGenerator: actions.processar,
  transform: (payload) => {
    const form = new FormData();

    payload.files.forEach((file) => {
      form.append("file", file, file.name);
    });

    return form;
  },
  api: processarApi,
});

export const sagas = [processar.watcher()];
