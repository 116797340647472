import React from "react";
import Button from "@material-ui/core/Button";
import Loader from "../loaders";

const PrimaryButton = ({
  classes = {},
  loading = false,
  label,
  loaderType = "dots",
  loaderColor = "white",
  loaderSize = 24,
  variant = "contained",
  fullWidth = true,
  ...otherProps
}) => {
  classes.root = "button button-more" + (classes.root ?? "");

  return (
    <Button
      {...otherProps}
      classes={classes}
      fullWidth={fullWidth}
      variant={variant}
      disabled={loading}
    >
      {!!loading ? (
        <Loader type={loaderType} color={loaderColor} size={loaderSize} />
      ) : (
        label
      )}
    </Button>
  );
};

export default PrimaryButton;
