import ClausulaQualificacaoPage from "./ClausulaQualificacaoPage";
import { useDispatch, useSelector } from "react-redux";
import { getClausulas } from "../../../selectors/administracao.selectors";
import { useCallback } from "react";
import { actions } from "../../../reducers/administracao/clausulas.actions";
import validators from "../../../utils/validators";
import { useForm } from "react-hook-form";

const ClausulaQualificacaoPageConnected = () => {
  const dispatch = useDispatch();
  const clausulas = useSelector(getClausulas);
  const loading = clausulas?.loading;
  const total = clausulas?.total;
  const pagina = clausulas?.page;
  const pesquisa = clausulas?.search;

  const handlePagination = useCallback(
    (e, page) => {
      if (!!pesquisa) {
        dispatch(
          actions.buscarClausula.request({ search: pesquisa, pagina: page })
        );
      } else {
        dispatch(actions.listarClausulas.request({ pagina: page }));
      }
    },
    [dispatch, pesquisa]
  );

  const handleEdit = useCallback(
    (values) => {
      dispatch(actions.editarClausula({ clausula: values }));
    },
    [dispatch]
  );

  const handleNew = useCallback(() => {
    dispatch(actions.novaClausula());
  }, [dispatch]);

  const handleDelete = useCallback(
    (i) => {
      dispatch(actions.excluirClausula.request({ id: i }));
    },
    [dispatch]
  );

  const initialValues = {
    search: "",
  };

  const rules = {
    search: validators.string({ required: false }),
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const handleSearch = useCallback(
    (values) => {
      const request = {
        by: "nome",
        value: values.search,
      };
      dispatch(actions.buscarClausula.request({ search: request, pagina: 1 }));
    },
    [dispatch]
  );

  return (
    <ClausulaQualificacaoPage
      clausulas={clausulas?.list}
      clausula={clausulas?.clausula}
      loading={loading}
      total={total}
      handlePagination={handlePagination}
      pagina={pagina}
      handleNew={handleNew}
      handleEdit={handleEdit}
      formProps={formProps}
      handleSearch={handleSearch}
      handleDelete={handleDelete}
    />
  );
};

export default ClausulaQualificacaoPageConnected;
