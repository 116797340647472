import DocumentosErroIntegracaoPage from "./DocumentosErroIntegracaoPage";
import { getListDocumentosErro } from "../../../selectors/painelIntegracao.selectors";
import { useSelector, useDispatch } from "react-redux";
import { useCallback } from "react";
import { actions } from "../../../reducers/administracao/painelIntegracao.actions";
import { useForm } from "react-hook-form";
import validators from "../../../utils/validators";

const DocumentosErroIntegracaoPageConnected = () => {
  const dispatch = useDispatch();
  const documentosErro = useSelector(getListDocumentosErro);

  const handlePagination = useCallback(
    (e, page) => {
      dispatch(
        actions.listarDocumentosErro.request({
          page: page,
          filter: documentosErro?.filter,
        })
      );
    },
    [dispatch, documentosErro]
  );

  const handleFilter = useCallback(
    (values) => {
      dispatch(
        actions.listarDocumentosErro.request({
          page: 1,
          filter: {
            consultas: values?.consultas?.id,
            documentos: values?.documentos,
          },
        })
      );
    },
    [dispatch]
  );

  const rules = {
    consultas: validators.string({ required: false }),
    documentos: validators.string({ required: false }),
  };
  const initialValues = {
    consultas: documentosErro?.filter?.consultas ?? "",
    documentos: documentosErro?.filter?.documentos ?? "",
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  return (
    <DocumentosErroIntegracaoPage
      handlePagination={handlePagination}
      documentos={documentosErro}
      formProps={formProps}
      handleFilterClick={handleFilter}
    />
  );
};

export default DocumentosErroIntegracaoPageConnected;
