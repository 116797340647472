import React, { useState } from "react";
import { IconButton, Popover, Tooltip } from "@material-ui/core";
import { VisibleWithProfile } from "../../../../components";
import {
  InfoOutlined,
  InsertPhoto,
  ListAltOutlined,
  Publish,
} from "@material-ui/icons";
import {
  default as BotaoUploadDocumento,
  getAcceptedTypes,
} from "./BotaoUploadDocumento";
import BotaoBaixarFormulario from "./BotaoBaixarFormulario";

const Documento = ({
  doc,
  index,
  onChange,
  uploading,
  handleOpenImagens,
  perfil,
  handleOpenDetalhesDocumento,
  handleAtuarAnaliseDocumento,
  handleVisualizarMotivo,
  handleOpenDetalhesIntegracao,
  handleDownload,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div
      className={
        index % 2 === 0
          ? "row mt-0 d-flex align-items-center medium-row tr-even"
          : "row mt-0 d-flex align-items-center medium-row tr-odd"
      }
    >
      <div className="col-sm-12 col-lg-7 mt-sm-2 mt-lg-0 ">
        <span>{doc["nome_documento"]}</span>
        {!!doc.orientacao && (
          <>
            <InfoOutlined
              className="ml-2 info-documento"
              onClick={handleClick}
            />
            <Popover
              id={doc.id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <div className="p-2 bg-dark color-white orientacao">
                {doc.orientacao}
              </div>
            </Popover>
          </>
        )}
      </div>
      <div className="col-sm-12 col-lg-5 my-2 my-lg-0">
        <div className="d-flex">
          {doc["situacao_documento"] === "F" && (
            <>
              <div
                className={
                  "flex d-flex align-items-center justify-content-center"
                }
              >
                {doc["codigoArtefatoFormularioBanco"] !== null && (
                  <BotaoBaixarFormulario
                    fullWidth={false}
                    classes={{
                      formControl: "justify-content-center",
                    }}
                    onClick={() =>
                      handleDownload(
                        doc["codigoArtefatoFormularioBanco"],
                        doc["arquivoFormularioBanco"]
                      )
                    }
                  />
                )}
                <BotaoUploadDocumento
                  name={doc.id}
                  uploading={uploading}
                  classes={{
                    formControl: "justify-content-center",
                  }}
                  accept={getAcceptedTypes(doc.tipo)}
                  fullWidth={false}
                  onChange={onChange}
                />

                {!!doc.motivoRecusaSubstituido && (
                  <Tooltip title={"Motivo da Recusa"}>
                    <InfoOutlined
                      className={`texto-substituir-icon`}
                      onClick={() => handleVisualizarMotivo(doc, true)}
                    />
                  </Tooltip>
                )}
              </div>
              {perfil === "BPO" && doc.permiteDispensa === "S" && (
                <BotaoDocumento
                  doc={doc}
                  acao={"D"}
                  handleAtuarAnaliseDocumento={handleAtuarAnaliseDocumento}
                />
              )}
            </>
          )}
          {perfil !== "BPO" && doc["situacao_documento"] !== "F" && (
            <BotaoDocumento
              doc={doc}
              handleVisualizarMotivo={handleVisualizarMotivo}
            />
          )}
          {perfil === "BPO" && doc["situacao_documento"] === "I" && (
            <>
              <BotaoDocumento
                handleAtuarAnaliseDocumento={handleAtuarAnaliseDocumento}
                doc={doc}
                acao={"A"}
              />
              <BotaoDocumento
                handleAtuarAnaliseDocumento={handleAtuarAnaliseDocumento}
                doc={doc}
                acao={"S"}
              />
              {doc.permiteDispensa === "S" && (
                <BotaoDocumento
                  handleAtuarAnaliseDocumento={handleAtuarAnaliseDocumento}
                  doc={doc}
                  acao={"D"}
                />
              )}
            </>
          )}
          {perfil === "BPO" && doc["situacao_documento"] === "A" && (
            <BotaoDocumento
              doc={doc}
              handleVisualizarMotivo={handleVisualizarMotivo}
            />
          )}
          {doc["situacao_documento"] !== "F" && doc.imagens > 0 && (
            <Tooltip title="Arquivos">
              <IconButton
                className="p-2"
                onClick={() => handleOpenImagens(doc.id)}
              >
                <InsertPhoto className={"color-secondary icon-indexado"} />
              </IconButton>
            </Tooltip>
          )}
          <Tooltip title="Detalhes do documento">
            <IconButton
              className="p-2"
              onClick={() => handleOpenDetalhesDocumento(doc)}
            >
              <ListAltOutlined className={"color-secondary icon-indexado"} />
            </IconButton>
          </Tooltip>
          <VisibleWithProfile profiles={["BPO", "ADMINISTRADOR"]}>
            {doc["situacaoIntegracao"] === "E" && (
              <Tooltip title="Situação da integração: Erro na integração">
                <IconButton
                  className={"p-2 color-error info-documento"}
                  onClick={() => handleOpenDetalhesIntegracao(doc)}
                >
                  <Publish
                    className={"change-analyst-icon icon_integra_doc_error"}
                  />
                </IconButton>
              </Tooltip>
            )}
            {doc["situacaoIntegracao"] === "S" && (
              <Tooltip title="Situação da integração: Integrado com sucesso">
                <IconButton
                  className={"p-2 color-error info-documento"}
                  onClick={() => handleOpenDetalhesIntegracao(doc)}
                >
                  <Publish className="icon_integra_doc_success" />
                </IconButton>
              </Tooltip>
            )}
            {doc["situacaoIntegracao"] === "P" && (
              <Tooltip title="Situação da integração: Pendente ">
                <IconButton className={"p-2 color-error info-documento"}>
                  <Publish className={"change-analyst-icon icon_integra_doc"} />
                </IconButton>
              </Tooltip>
            )}
            {doc["situacaoIntegracao"] === "N" && (
              <Tooltip title="Situação da integração: Não Integrar">
                <IconButton className={"p-2 color-error info-documento"}>
                  <Publish
                    className={"change-analyst-icon"}
                    style={{ color: "gray" }}
                  />
                </IconButton>
              </Tooltip>
            )}
            {doc["situacaoIntegracao"] === "X" && (
              <Tooltip title="Situação da integração: Não Integrar existe documento aprovado mais recente">
                <IconButton className={"p-2 color-error info-documento"}>
                  <Publish
                    className={"change-analyst-icon icon_integra_doc_rec"}
                  />
                </IconButton>
              </Tooltip>
            )}
          </VisibleWithProfile>
        </div>
      </div>
    </div>
  );
};

const BotaoDocumento = ({
  doc,
  acao,
  handleAtuarAnaliseDocumento,
  handleVisualizarMotivo,
}) => {
  const isAnalisado = doc.situacao_analise !== "N";
  const podeAnalisar =
    doc.situacao_documento === "I" ||
    (doc.situacao_documento === "F" && acao === "D");
  // prettier-ignore
  const handleClick =
    (podeAnalisar && !isAnalisado && !!handleAtuarAnaliseDocumento) ? handleAtuarAnaliseDocumento : () => {};

  let estiloLabel;
  let label;

  if (acao === "A" || doc.situacao_analise === "A") {
    estiloLabel = "texto-aprovar";
    label = acao === "A" ? "APROVAR" : "APROVADO";
  } else if (acao === "S" || doc.situacao_analise === "S") {
    estiloLabel = "texto-substituir";
    label = acao === "S" ? "RECUSAR" : "RECUSADO";
  } else if (acao === "D" || doc.situacao_analise === "D") {
    estiloLabel = "texto-dispensar";
    label = acao === "D" ? "DISPENSAR" : "DISPENSADO";
  } else {
    estiloLabel = "texto-indexado";
    label = "PENDENTE DE ANÁLISE";
  }

  return (
    <div
      className={"documento-acoes"}
      onClick={() =>
        handleClick({
          checklist: doc.id,
          situacaoAnalise: acao,
        })
      }
    >
      <span className={estiloLabel}>{label}</span>
      {(doc?.situacao_analise === "S" || doc?.situacao_analise === "D") &&
        !!doc?.motivoRecusa && (
          <Tooltip
            title={
              doc?.situacao_analise === "S"
                ? "Motivo da Recusa"
                : "Motivo da Dispensa"
            }
          >
            <InfoOutlined
              className={`ml-2 ${estiloLabel}-icon`}
              onClick={() => handleVisualizarMotivo(doc)}
            />
          </Tooltip>
        )}
    </div>
  );
};

export default Documento;
