import React from "react";
import { Dialog, DialogContent, IconButton } from "@material-ui/core";
import { CloseSharp } from "@material-ui/icons";
import formatters from "../../../../utils/formatters";

const DetalhesDocumentoDialog = ({ handleClose, detalhes, open }) => {
  return (
    <Dialog
      onClose={handleClose}
      classes={{ paper: "w100" }}
      maxWidth="md"
      open={open}
    >
      <DialogContent>
        <IconButton
          onClick={handleClose}
          classes={{ root: "position-absolute clickable close-icon" }}
        >
          <CloseSharp />
        </IconButton>
        <div className={"page-title"}>Detalhes da Integracão do Documento</div>
        <div className={"mb-3"}>
          <div className="row">
            <div className="col-md-6 col-xs-12">
              <span className="label">Data/hora Integração</span>
              <div>
                <span className="data-label">
                  {formatters.dates.withHours(
                    new Date(detalhes.dataHoraIntegracao)
                  )}
                </span>
              </div>
            </div>
            <div className="col-md-6 col-xs-12">
              <span className="label">Status da Integração</span>
              <div>
                <span className="data-label">
                  {detalhes.situacaoIntegracao === "S"
                    ? "INTEGRADO COM SUCESSO"
                    : detalhes.situacaoIntegracao === "E"
                    ? "ERRO NA INTEGRAÇÃO"
                    : ""}
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12 col-xs-12">
              <span className="label">Integrado por</span>
              <div>
                <span className="data-label">{detalhes.usuarioIntegracao}</span>
              </div>
            </div>
          </div>
          {!!detalhes.erroIntegracao && (
            <div className="row">
              <div className="col-md-12 col-xs-12">
                <span className="label">Descrição do Erro</span>
                <div>
                  <span className="data-label">{detalhes.erroIntegracao}</span>
                </div>
              </div>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default DetalhesDocumentoDialog;
