import React, { useCallback, useState } from "react";
import formatters from "../../../utils/formatters";
import Loader from "../../../components/loaders";
import Divider from "@material-ui/core/Divider";
import { Autorenew } from "@material-ui/icons";
import { VisibleWithoutProfile, VisibleWithProfile } from "../../../components";
import TrocarAnalistaDialog from "../analista/TrocarAnalistaDialog.connected";
import TrocarPrioridadeConsulta from "../prioridadeConsulta/TrocarPrioridadeConsulta.connected";

const CardDadosProposta = ({
  loading,
  error,
  data,
  handleCloseAlterarPrioridade,
  handleOpenAlterarPrioridade,
}) => {
  const [dadosTrocarAnalista, setDadosTrocarAnalista] = useState({
    open: false,
  });
  const handleCloseTrocarAnalista = useCallback(() => {
    setDadosTrocarAnalista({
      open: false,
    });
  }, []);
  const handleOpenTrocarAnalista = useCallback(() => {
    setDadosTrocarAnalista({
      open: true,
      analista: data.usuarioBackoffice?.id,
      consulta: data.id,
    });
  }, [data]);

  return (
    <div className="card spacer" style={{ minHeight: 300 }}>
      <div className="card-body d-flex flex-column">
        {!!loading && (
          <div className="flex d-flex justify-content-center align-items-center">
            <Loader type={"dots"} size={80} />
          </div>
        )}
        {!!error && (
          <div className="flex d-flex flex-column justify-content-center align-items-center">
            <span className="d-block colored-card-text mt-4 color-red">
              Não foi possível obter as informações
            </span>
          </div>
        )}
        {!loading && !error && (
          <>
            {!!loading && (
              <div className="col-12">
                <Loader type={"dots"} color={"brand"} size={50} />
              </div>
            )}
            {!loading && (
              <div className="col-12 mt-0">
                {data?.parceiro?.nome && (
                  <div className="row">
                    <div className="col-md-6 col-xs-12">
                      <span className="label">Parceiro</span>
                      <div className="d-flex flex-row align-items-center">
                        <span className="data-label">{data.parceiro.nome}</span>
                      </div>
                    </div>
                  </div>
                )}
                <div className="row">
                  <div className="col-md-3 col-xs-12">
                    <span className="label">Tipo de Operação</span>
                    <div>
                      <span className="data-label">{data?.Produto?.nome}</span>
                    </div>
                  </div>
                  <div className="col-md-6 col-xs-12">
                    <span className="label">Canal de Originação</span>
                    <div>
                      <span className="data-label">{data?.canalCaptacao}</span>
                    </div>
                  </div>
                  {data?.dataContrato && (
                    <div className="col-md-3 col-xs-12">
                      <span className="label">Data do Contrato</span>
                      <div>
                        <span className="data-label">{data?.dataContrato}</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-12">
                    <Divider />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6 col-xs-12">
                    <span className="label">Agência</span>
                    <div className="d-flex flex-row align-items-center">
                      <span className="data-label">
                        {data?.organizacao?.codigo} - {data?.organizacao?.nome}
                      </span>
                    </div>
                  </div>
                  <VisibleWithoutProfile profiles={["CLIENTE"]}>
                    <div className="col-md-6 col-xs-12">
                      <span className="label">Analista Backoffice</span>
                      <div className="d-flex flex-row align-items-center">
                        <span className="data-label text-transform-unset">
                          {data.usuarioBackoffice?.nome ?? "(Nenhum)"}
                        </span>
                        <VisibleWithProfile profiles={["ADMINISTRADOR"]}>
                          <Autorenew
                            className={"change-analyst-icon"}
                            onClick={handleOpenTrocarAnalista}
                          />
                        </VisibleWithProfile>
                      </div>
                    </div>
                  </VisibleWithoutProfile>
                </div>
                <div className="row">
                  <div className="col-md-6 col-xs-12">
                    {data.cliente.length > 0 && (
                      <span className="label">Comprador</span>
                    )}
                    {data.cliente.map((comprador, index) => (
                      <div key={index}>
                        <span className="data-label">{comprador.nome}</span>
                      </div>
                    ))}
                  </div>
                  <div className="col-md-6 col-xs-12">
                    {data.vendedor.length > 0 && (
                      <span className="label">Vendedor</span>
                    )}
                    {data.vendedor.map((vendedor, index) => (
                      <div key={index}>
                        <span className="data-label">{vendedor.nome}</span>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="row">
                  {data?.interveniente?.nomeInterveniente?.length > 0 && (
                    <div className="col-md-6 col-xs-12">
                      <span className="label">Interveniente Quitante</span>
                      <div>
                        <span className="data-label">
                          {data?.interveniente?.nomeInterveniente}
                        </span>
                      </div>
                    </div>
                  )}
                  <div className="col-md-6 col-xs-12">
                    <span className="label">Usa FGTS?</span>
                    <div>
                      <span className="data-label">
                        {data?.cliente?.some(
                          (cliente) =>
                            cliente?.PessoaDadoPessoal?.fgts === "S" ||
                            cliente?.PessoaDadoPessoal?.fgtsConjuge === "S"
                        )
                          ? "SIM"
                          : "NÃO"}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Divider />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 col-6">
                    <span className="label">Avaliação</span>
                    <div>
                      <span className="data-label">
                        R${" "}
                        {!!data.imovel[0].valorAvaliacao
                          ? formatters.numbers.currency(
                              data.imovel[0].valorAvaliacao
                            )
                          : ""}
                        &nbsp;
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3 col-6">
                    <span className="label">Imóvel</span>
                    <div>
                      <span className="data-label">
                        R${" "}
                        {!!data.simulacao.valorImovel
                          ? formatters.numbers.currency(
                              data.simulacao.valorImovel
                            )
                          : ""}
                        &nbsp;
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3 col-6">
                    <span className="label">Financiamento</span>
                    <div>
                      <span className="data-label">
                        R${" "}
                        {formatters.numbers.currency(
                          data.simulacao.valorFinanciamento
                        )}
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3 col-6">
                    <span className="label">Compr. Renda</span>
                    <div>
                      <span className="data-label">
                        {!!data.simulacao.comprometimentoRenda
                          ? formatters.numbers.currency(
                              data.simulacao.comprometimentoRenda
                            ) + " %"
                          : ""}
                        &nbsp;
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-6 col-md-3">
                    <span className="label">Taxa Efetiva</span>
                    <div>
                      <span className="data-label">
                        {formatters.numbers.currency(
                          data.simulacao.taxaJurosEfetivaAnual
                        )}{" "}
                        % a.a
                      </span>
                    </div>
                  </div>
                  <div className="col-6 col-md-3">
                    <span className="label">CET</span>
                    <div>
                      <span className="data-label">
                        {formatters.numbers.currency(data.simulacao.cetAnual)} %
                      </span>
                    </div>
                  </div>
                  <div className="col-6 col-md-3">
                    <span className="label">Sist. Amortização</span>
                    <div>
                      <span className="data-label">
                        {data.simulacao.tipoAmortizacao}
                      </span>
                    </div>
                  </div>
                  <div className="col-6 col-md-3">
                    <span className="label">Enquadramento</span>
                    <div>
                      <span className="data-label">
                        {data.simulacao.enquadramento}
                      </span>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-3 col-6">
                    <span className="label">Taxas Financiadas</span>
                    <div>
                      <span className="data-label">
                        R${" "}
                        {!!data.simulacao.taxasFinanciadas
                          ? formatters.numbers.currency(
                              data.simulacao.taxasFinanciadas
                            )
                          : ""}
                        &nbsp;
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3 col-6">
                    <span className="label">FGTS Solicitado</span>
                    <div>
                      <span className="data-label">
                        R${" "}
                        {!!data.simulacao.fgtsSolicitado
                          ? formatters.numbers.currency(
                              data.simulacao.fgtsSolicitado
                            )
                          : ""}
                        &nbsp;
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3 col-6">
                    <span className="label">FGTS Autorizado</span>
                    <div>
                      <span className="data-label">
                        R${" "}
                        {!!data.simulacao.fgtsAutorizado
                          ? formatters.numbers.currency(
                              data.simulacao.fgtsAutorizado
                            )
                          : ""}
                        &nbsp;
                      </span>
                    </div>
                  </div>
                  <div className="col-md-3 col-6">
                    {false && (
                      <>
                        <span className="label">Prestação</span>
                        <div>
                          <span className="data-label">
                            R${" "}
                            {formatters.numbers.currency(
                              data.simulacao.valorPrestacao
                            )}
                          </span>
                        </div>
                      </>
                    )}
                  </div>
                </div>
                <div className="row">
                  <div className="col-12">
                    <Divider />
                  </div>
                </div>
                <div className="row">
                  <VisibleWithoutProfile profiles={["CLIENTE"]}>
                    <div className="col-md-6 col-xs-12">
                      <span className="label">Prioridade</span>
                      <div className="d-flex flex-row align-items-center">
                        <span className="data-label text-transform-unset">
                          {data.prioridade
                            ? prioridadeDecode(data.prioridade)
                            : "(Nenhum)"}
                        </span>
                        <VisibleWithProfile profiles={["BPO"]}>
                          <Autorenew
                            className={"change-analyst-icon"}
                            onClick={() =>
                              handleOpenAlterarPrioridade({
                                prioridade: data.prioridade,
                                consultaID: data.id,
                              })
                            }
                          />
                        </VisibleWithProfile>
                      </div>
                    </div>
                  </VisibleWithoutProfile>
                </div>
              </div>
            )}
          </>
        )}
      </div>
      <TrocarAnalistaDialog
        dados={dadosTrocarAnalista}
        handleClose={handleCloseTrocarAnalista}
      />
    </div>
  );
};

const prioridadeDecode = (prioridade) => {
  switch (prioridade) {
    case "M":
      return "Manifestação";
    case "R":
      return "Alta Renda";
    case "B":
      return "Solicitação Banco";
    default:
      return "";
  }
};

export default CardDadosProposta;
