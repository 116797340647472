import React, { useCallback } from "react";
import { Dialog, DialogContent, IconButton } from "@material-ui/core";
import { CloseSharp } from "@material-ui/icons";
import { Button } from "../../../../components";

const ConfirmarUploadBancoDialog = ({ handleClose, open, handleSubmit }) => {
  return (
    <Dialog
      onClose={handleClose}
      classes={{ paper: "w100" }}
      maxWidth="md"
      open={open}
    >
      {!!open && (
        <DialogContent>
          <div className={"page-title"}>Deseja prosseguir?</div>
          <div className={"mb-3"}>
            <div className="row">
              <div className="col-md-12 col-xs-12">
                <span className="data-label">
                  Esta ação vai enviar os Documentos Aprovados para o Sistema do
                  Banco.
                </span>
              </div>
            </div>
            <div className="row spacer justify-content-center">
              <div className="col-md-4 col-12">
                <form>
                  <Button
                    label="SIM"
                    onClick={() => {
                      handleSubmit();
                      handleClose();
                    }}
                  />
                </form>
              </div>
              <div className="col-md-4 col-12">
                <Button label="NÃO" onClick={handleClose} />
              </div>
            </div>
          </div>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default ConfirmarUploadBancoDialog;
