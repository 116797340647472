import React, { useCallback } from "react";
import AdicionarEtapaDialog from "./AdicionarEtapaDialog";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { getAtividades } from "../../../../selectors/dominios.selectors";
import { getWorkflow } from "../../../../selectors/propostas.selectors";
import validators from "../../../../utils/validators";
import { actions as domainActions } from "../../../../reducers/dominios.actions";
import { actions } from "../../../../reducers/propostas.actions";

const AdicionarEtapaDialogConnected = ({
  handleClose,
  dados,
  loadingAbrirAtividade,
}) => {
  const dispatch = useDispatch();
  const { loading, data: dominioAtividades } = useSelector(getAtividades);

  const rules = {
    atividade: validators.string({ required: true }),
  };
  const initialValues = {
    atividade: "",
  };
  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const handleOpen = useCallback(() => {
    dispatch(domainActions.atividades.request({ etapa: dados.etapa }));
  }, [dispatch, dados]);

  const handleSubmit = useCallback(
    ({ atividade }) => {
      dispatch(
        actions.abrirAtividade.request({
          consulta: dados.proposta,
          atividade: atividade,
          condicaoCriacao: "M",
          handleClose,
        })
      );
    },
    [dispatch, dados, handleClose]
  );

  return (
    <AdicionarEtapaDialog
      handleSubmit={handleSubmit}
      handleOpen={handleOpen}
      open={dados.open}
      loading={loading}
      formProps={formProps}
      dominioAtividades={dominioAtividades}
      handleClose={handleClose}
      submitting={loadingAbrirAtividade}
    />
  );
};

export default AdicionarEtapaDialogConnected;
