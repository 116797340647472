import { useDispatch, useSelector } from "react-redux";
import {
  getPerfis,
  getPerfil,
} from "../../../selectors/administracao.selectors";
import { useCallback } from "react";
import { actions } from "../../../reducers/administracao/perfis.actions";
import validators from "../../../utils/validators";
import { useForm } from "react-hook-form";
import FormEtapa from "./FormPerfil";

const PerfisPageConnected = () => {
  const dispatch = useDispatch();
  const perfis = useSelector(getPerfis);
  const perfil = useSelector(getPerfil);

  const initialValues = {
    ...perfil,
  };

  const rules = {
    codigo: validators.string({ required: true }),
    nome: validators.string({ required: true }),
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const handleSubmit = useCallback(
    (values, i) => {
      const request = {
        id: i,
        tipo: values.tipo.toUpperCase(),
      };
      if (!!i) {
        dispatch(actions.atualizarPerfil.request({ perfil: request }));
      } else {
        dispatch(actions.incluirPerfil.request({ perfil: request }));
      }
    },
    [dispatch, perfis]
  );

  const voltarParaLista = useCallback(() => {
    dispatch(actions.voltarListaPerfil());
  }, [dispatch]);

  return (
    <FormEtapa
      perfil={perfil}
      props={formProps}
      handleSubmit={handleSubmit}
      voltarParaLista={voltarParaLista}
    />
  );
};

export default PerfisPageConnected;
