import TokenPage from "./TokenPage";
import { useForm } from "react-hook-form";
import validators from "utils/validators";
import { useSelector, useDispatch } from "react-redux";
import { getTokenLoading } from "../../selectors/autenticacao.selectors";
import { useCallback, useRef } from "react";
import { actions } from "reducers/autenticacao.actions";

import {
  actions as routeActions,
  types as routes,
} from "reducers/rotas.actions";
import { toast } from "react-toastify";
import { useEffect } from "react";

const TokenPageConnected = () => {
  const dispatch = useDispatch();
  const loading = useSelector(getTokenLoading);
  const captchaRef = useRef();

  const rules = {
    token: validators.number({ required: false }),
    tokenCaptcha: validators.string({ required: true }),
  };
  const initialValues = {
    token: "",
    tokenCaptcha: "",
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const handleSubmit = useCallback(
    (values) => {
      if (!!values && values?.token.length === 6) {
        dispatch(
          actions.tokenLogin.request({
            token: values.token,
            tokenRecaptcha: values.tokenCaptcha,
            ref: captchaRef.current,
          })
        );
        formProps.setValue("tokenCaptcha", "");
      } else {
        toast.error("Favor informar o Token");
      }
    },
    [dispatch, toast]
  );

  const voltar = useCallback(() => {
    dispatch(routeActions.redirectTo(routes.LOGIN));
  }, [dispatch, routeActions, routes]);

  const reenviarTokenSenha = useCallback(() => {
    const tokenCaptcha = formProps.getValues("tokenCaptcha");
    if (!!tokenCaptcha) {
      dispatch(
        actions.reenviarTokenSenha.request({
          ref: captchaRef.current,
          tokenRecaptcha: tokenCaptcha,
        })
      );
      formProps.setValue("tokenCaptcha", "");
    } else {
      formProps.setError("tokenCaptcha", { message: "Campo obrigatório" });
    }
  }, [dispatch]);

  return (
    <TokenPage
      formProps={formProps}
      loading={loading}
      handleSubmit={handleSubmit}
      voltar={voltar}
      reenviarTokenSenha={reenviarTokenSenha}
      captchaRef={captchaRef}
    />
  );
};
export default TokenPageConnected;
