import React, { useCallback } from "react";
import TrocarPrioridadeConsulta from "./TrocarPrioridadeConsulta";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import validators from "../../../utils/validators";
import { actions } from "../../../reducers/propostas.actions";
import { getProposta } from "../../../selectors/propostas.selectors";

const TrocarPrioridadeConsultaConnected = ({
  alterarPrioridade,
  handleCloseAlterarPrioridade,
  handleOpenAlterarPrioridade,
}) => {
  const dispatch = useDispatch();
  const prioridadeProposta = useSelector(getProposta)?.prioridadeProposta;
  const dominioData = [
    { key: "M", desc: "Manifestação" },
    { key: "R", desc: "Alta Renda" },
    { key: "B", desc: "Solicitação Banco" },
  ];
  const rules = {
    prioridade: validators.string({ required: false }),
    consultaID: validators.number({ required: true }),
  };
  const initialValues = {
    prioridade: alterarPrioridade.prioridade,
    consultaID: alterarPrioridade.consultaID,
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };
  const handleSubmit = useCallback(
    ({ prioridade }) => {
      dispatch(
        actions.atualizarPrioridadeConsulta.request({
          consultaID: alterarPrioridade.consultaID,
          prioridade,
          handleCloseAlterarPrioridade,
        })
      );
    },
    [dispatch, alterarPrioridade, handleCloseAlterarPrioridade]
  );
  return (
    <TrocarPrioridadeConsulta
      handleSubmit={handleSubmit}
      handleOpen={handleOpenAlterarPrioridade}
      open={alterarPrioridade.open}
      formProps={formProps}
      dominioData={dominioData}
      handleClose={handleCloseAlterarPrioridade}
      submitting={prioridadeProposta?.loading}
    />
  );
};

export default TrocarPrioridadeConsultaConnected;
