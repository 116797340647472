import React from "react";
import Loader from "../../../components/loaders";
import { ControlledTextfield } from "../../../components/inputs";
import { Button, VisibleWithProfile } from "../../../components";

const CardObservacao = ({
  loading,
  formProps,
  handleSubmit,
  disabled,
  submitting,
  perfil,
}) => (
  <div className="card" style={{ minHeight: 300 }}>
    <div className="card-body d-flex flex-column">
      {!!loading && (
        <div className="flex d-flex justify-content-center align-items-center">
          <Loader type={"dots"} size={80} />
        </div>
      )}
      {!loading && (
        <div className="col-12 mt-0">
          <form onSubmit={formProps.handleSubmit(handleSubmit)}>
            <div className="row mb-3">
              <div className="col-12">
                <ControlledTextfield
                  formProps={formProps}
                  multiline={true}
                  disabled={disabled}
                  rowsMax={8}
                  rows={6}
                  uppercase={false}
                  name={"observacao"}
                  margin={"none"}
                  label={"Observação"}
                  inputProps={{ maxLength: 2000 }}
                />
              </div>
            </div>
            <VisibleWithProfile
              profiles={["BPO", "BANCO GESTAO", "BANCO", "ADMINISTRADOR"]}
            >
              <div className="row mb-3">
                <div className="col-12">
                  <ControlledTextfield
                    formProps={formProps}
                    multiline={true}
                    disabled={perfil !== "BPO"}
                    rowsMax={8}
                    rows={6}
                    uppercase={false}
                    name={"observacaoInterna"}
                    margin={"none"}
                    label={"Observação Interna"}
                    inputProps={{ maxLength: 2000 }}
                  />
                </div>
              </div>
            </VisibleWithProfile>
            {!disabled && (
              <div className={"row justify-content-center mb-3"}>
                <div className="col-6 mt-3">
                  <Button
                    loading={submitting}
                    label={"ATUALIZAR"}
                    type={"submit"}
                  />
                </div>
              </div>
            )}
          </form>
        </div>
      )}
    </div>
  </div>
);

export default CardObservacao;
