import { actions, types } from "./matrizClausulas.actions";

const initialState = {
  loading: false,
  error: null,
  list: [],
  page: 1,
  item: null,
  search: "",
  clausulas: {
    list: [],
    loading: true,
  },
  filter: {
    tipoQualificacao: "",
    estadoCivil: "",
    regimeCasamento: "",
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.listarMatriz.REQUEST:
    case actions.listarMatriz.SUCCESS:
    case actions.listarMatriz.FAILURE:
      return {
        ...state,
        loading: action.type === actions.listarMatriz.REQUEST,
        error:
          action.type === actions.listarMatriz.FAILURE
            ? action.payload.error
            : null,
        list:
          action.type === actions.listarMatriz.SUCCESS
            ? action.payload.response.data.matriz
            : [],
        total:
          action.type === actions.listarMatriz.SUCCESS
            ? action.payload.response.data.total
            : null,
        page:
          action.type === actions.listarMatriz.REQUEST
            ? action.payload.pagina
            : action.payload.response.data.paginaAtual,
        search: "",
        filter:
          action.type === actions.listarMatriz.REQUEST
            ? action.payload.filter
            : state.filter,
      };
    case actions.atualizarMatriz.REQUEST:
    case actions.atualizarMatriz.SUCCESS:
    case actions.atualizarMatriz.FAILURE:
      return {
        ...state,
        loading: action.type === actions.atualizarMatriz.REQUEST,
        item:
          action.type === actions.atualizarMatriz.FAILURE
            ? action.payload.original.clausula
            : null,
        list:
          action.type === actions.atualizarMatriz.SUCCESS
            ? state.list.map((u) => {
                if (u.id === action.payload.original.item.id) {
                  return {
                    ...u,
                    clausula:
                      action.payload.original.item.clausula ?? u.clausula,
                    conjugeParticipante:
                      action.payload.original.item.conjugeParticipante ??
                      u.conjugeParticipante,
                    estadoCivil:
                      action.payload.original.item.estadoCivil ?? u.estadoCivil,
                    regimeCasamento:
                      action.payload.original.item.regimeCasamento ??
                      u.regimeCasamento,
                    tipoDataCasamento:
                      action.payload.original.item.tipoDataCasamento ??
                      u.tipoDataCasamento,
                    tipoParticipante:
                      action.payload.original.item.tipoParticipante ??
                      u.tipoParticipante,
                    tipoQualificacao:
                      action.payload.original.item.tipoQualificacao ??
                      u.tipoQualificacao,
                    uniaoEstavel:
                      action.payload.original.item.uniaoEstavel ??
                      u.uniaoEstavel,
                  };
                }
                return u;
              })
            : state.list,
      };
    case types.NOVA_MATRIZ:
      return {
        ...state,
        item: {
          nome: "",
          codigo: "",
        },
      };
    case types.EDITAR_MATRIZ:
      return {
        ...state,
        item: {
          ...action.payload.item,
        },
      };
    case types.VOLTAR_LISTA:
      return {
        ...state,
        item: null,
      };
    case actions.incluirMatriz.REQUEST:
    case actions.incluirMatriz.SUCCESS:
    case actions.incluirMatriz.FAILURE:
      return {
        ...state,
        loading: action.type === actions.incluirMatriz.REQUEST,
        item:
          action.type === actions.incluirMatriz.FAILURE
            ? action.payload.original.clausula
            : null,
        list:
          action.type === actions.incluirMatriz.SUCCESS
            ? action.payload.response.data.matriz
            : [],
        total:
          action.type === actions.incluirMatriz.SUCCESS
            ? action.payload.response.data.total
            : state.total,
        page:
          action.type === actions.incluirMatriz.REQUEST
            ? action.payload.pagina
            : state.page,
      };
    case actions.buscarMatriz.REQUEST:
    case actions.buscarMatriz.SUCCESS:
    case actions.buscarMatriz.FAILURE:
      return {
        ...state,
        loading: action.type === actions.buscarMatriz.REQUEST,
        error:
          action.type === actions.buscarMatriz.FAILURE
            ? action.payload.error
            : null,
        list:
          action.type === actions.buscarMatriz.SUCCESS
            ? action.payload.response.data.clausula
            : [],
        total:
          action.type === actions.buscarMatriz.SUCCESS
            ? action.payload.response.data.total
            : null,
        page:
          action.type === actions.buscarMatriz.REQUEST
            ? action.payload.pagina
            : action.payload.response.data.paginaAtual,
        search:
          action.type === actions.buscarMatriz.SUCCESS
            ? action.payload.original.search
            : state.search,
      };
    case actions.excluirMatriz.REQUEST:
    case actions.excluirMatriz.SUCCESS:
    case actions.excluirMatriz.FAILURE:
      return {
        ...state,
        loading: action.type === actions.excluirMatriz.REQUEST,
        item: null,
        list:
          action.type === actions.excluirMatriz.SUCCESS
            ? state.list.filter((u) => u.id !== action.payload.original.id)
            : state.list,
      };
    case actions.listarClausulas.REQUEST:
    case actions.listarClausulas.SUCCESS:
    case actions.listarClausulas.FAILURE:
      return {
        ...state,
        clausulas: {
          list:
            action.type === actions.listarClausulas.SUCCESS
              ? action.payload.response.data.clausulas
              : state.clausulas.list,
          loading: action.type === actions.listarClausulas.REQUEST,
        },
      };
    case types.ALTERAR_FILTRO:
      return {
        ...state,
        filter: action.payload.valores,
      };
    default:
      return state;
  }
};

export default reducer;
