import React from "react";
import { Description, Delete } from "@material-ui/icons";
import formatters from "utils/formatters";
import { ControlledFileInput } from "components/inputs";
import { Button } from "components";

const fileSize = (size) => {
  if (size > 1024 * 1024) {
    // Mb
    return (
      formatters.numbers.nFixed(Math.round(size / (1024 * 1024), 1), 1) + " Mb"
    );
  }
  if (size > 1024) {
    // Mb
    return formatters.numbers.nFixed(size / 1024, 1) + " Kb";
  }
  return size + " bytes";
};

const CargaConsultasForm = ({
  formProps,
  handleSubmit,
  submitting,
  updateLabel,
}) => {
  return (
    <form
      className="d-flex flex-column"
      onSubmit={formProps.handleSubmit(handleSubmit)}
    >
      <ControlledFileInput
        name={"files"}
        formProps={formProps}
        maxFiles={1}
        onChange={(prop) => console.log(prop)}
        accept={".xlsx"}
        multiple={false}
        renderFile={(file, index, handleClickDeleteIcon) => {
          return (
            <div
              key={file.id}
              className="col-12 mt-3 file-thumb d-flex flex-row align-items-center justify-content-between"
            >
              <div className="ml-3 d-flex flex-row align-items-center">
                <Description />
                <div className="ml-3">
                  <span className="d-block file-thumb-name">{file.name}</span>
                  <span className="d-block file-thumb-size">
                    {fileSize(file.size)}
                  </span>
                </div>
              </div>
              {!submitting && (
                <Delete
                  onClick={() => handleClickDeleteIcon(file)}
                  className="mr-3 file-thumb-delete clickable"
                />
              )}
            </div>
          );
        }}
        classes={{
          root:
            "dropzone d-flex flex-column justify-content-center align-items-center",
          disabled: "dropzone-disabled",
        }}
      >
        <span className="dropzone-title mt-4">
          Arraste o arquivo para cá ou clique para selecioná-lo
        </span>
        <span className="dropzone-subtitle mb-4">
          (somente arquivos com extensão .xlsx)
        </span>
      </ControlledFileInput>
      <div className="row">
        <div className="col-xs-12 col-md-12 mt-3 text-center">
          <label>{updateLabel}</label>
        </div>
      </div>
      <div className={"row justify-content-center"}>
        <div className="col-xs-12 col-md-6">
          <Button loading={submitting} label={"PROCESSAR"} type={"submit"} />
        </div>
      </div>
    </form>
  );
};

export default CargaConsultasForm;
