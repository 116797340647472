import {
  createAsyncAction,
  createSyncAction,
} from "../../utils/actionCreators";

export const types = {
  LISTAR_MATRIZ: "@@administracao/LISTAR_MATRIZ_CLAUSULA",
  ATUALIZAR_MATRIZ: "@@administracao/ATUALIZAR_MATRIZ_CLAUSULA",
  NOVA_MATRIZ: "@@administracao/NOVA_MATRIZ",
  EDITAR_MATRIZ: "@@administracao/EDITAR_MATRIZ",
  VOLTAR_LISTA: "@@/administracao/VOLTAR_LISTA",
  INCLUIR_MATRIZ: "@@administracao/INCLUIR_MATRIZ_CLAUSULA",
  SEARCH_MATRIZ: "@@administracao/SEARCH_MATRIZ",
  EXCLUIR_MATRIZ: "@@administracao/EXCLUIR_MATRIZ_CLAUSULA",
  LISTAR_CLAUSULAS: "@@administracao/MLISTAR_CLAUSULAS",
  ALTERAR_FILTRO: "@@administracao/MALTERAR_FILTRO",
};

export const actions = {
  listarMatriz: createAsyncAction(types.LISTAR_MATRIZ),
  atualizarMatriz: createAsyncAction(types.ATUALIZAR_MATRIZ),
  novaMatriz: () => createSyncAction(types.NOVA_MATRIZ),
  editarMatriz: (values) => createSyncAction(types.EDITAR_MATRIZ, values),
  voltarListaMatriz: () => createSyncAction(types.VOLTAR_LISTA),
  incluirMatriz: createAsyncAction(types.INCLUIR_MATRIZ),
  buscarMatriz: createAsyncAction(types.SEARCH_MATRIZ),
  excluirMatriz: createAsyncAction(types.EXCLUIR_MATRIZ),
  listarClausulas: createAsyncAction(types.LISTAR_CLAUSULAS),
  alterarFiltro: (valores) =>
    createSyncAction(types.ALTERAR_FILTRO, { valores }),
};
