import React from "react";
import formatters from "../../utils/formatters";

const ConsultaAcimaValor = ({ consulta, index, goToCockpit }) => {
  return (
    <div
      className={
        index % 2 === 0
          ? "row mt-0 d-flex align-items-center medium-row tr-even-adm"
          : "row mt-0 d-flex align-items-center medium-row tr-odd"
      }
    >
      <div className="col-2 col-md-2 mt-sm-2 mt-lg-0 user-table clickable">
        <span onClick={() => goToCockpit(consulta.id)}>{consulta.codigo}</span>
      </div>
      <div className="col-5 col-md-5 my-2 my-lg-0 user-table">
        <span>{consulta.cliente[0].nome}</span>
      </div>
      <div className="col-3 col-md-3 my-2 my-lg-0 user-table">
        <span>
          R${" "}
          {formatters.numbers.currency(
            Number(consulta.simulacao["vl_financiamento"]) +
              Number(consulta.simulacao["vl_taxas_financiadas"])
          )}
        </span>
      </div>
      <div className="col-2 col-md-2 my-2 my-lg-0 user-table">
        <span>
          {Math.round(
            (new Date().getTime() - new Date(consulta.dataConsulta).getTime()) /
              (1000 * 60 * 60 * 24)
          )}
        </span>
      </div>
    </div>
  );
};

export default ConsultaAcimaValor;
