import { IconButton, Popover, Tooltip } from "@material-ui/core";
import {
  ListAltOutlined,
  Email,
  EmailOutlined,
  SubdirectoryArrowRight,
  Drafts,
} from "@material-ui/icons";
const View = ({ email, index, handleAbrirDetalhesEmail }) => {
  return (
    <div
      className={
        index % 2 === 0
          ? "row mt-0 d-flex align-items-center medium-row tr-even"
          : "row mt-0 d-flex align-items-center medium-row tr-odd"
      }
    >
      <div className="col-sm-12 col-lg-3 mt-sm-2 mt-lg-0 resumir-texto">
        {email.idConsultaEmailReferencia !== null && (
          <IconButton className="p-2">
            <SubdirectoryArrowRight />
          </IconButton>
        )}
        <Tooltip title={email.atividade}>
          <span>{email.atividade}</span>
        </Tooltip>
      </div>
      <div className="col-sm-12 col-lg-4 mt-sm-2 mt-lg-0 resumir-texto">
        <Tooltip title={email.titulo_email}>
          <span>{email.titulo_email}</span>
        </Tooltip>
      </div>
      <div className="col-sm-12 col-lg-2 mt-sm-1 mt-lg-0 resumir-texto">
        <Tooltip title={email.usuario ?? ""}>
          <span>{email.usuario}</span>
        </Tooltip>
      </div>
      <div className={"col-sm-12 col-lg-2 mt-sm-2 mt-lg-0"}>
        <span>
          {email.data_hora_envio &&
            new Date(email.data_hora_envio).toLocaleDateString("pt-BR", {
              day: "2-digit",
              month: "2-digit",
              year: "numeric",
              hour: "2-digit",
              minute: "2-digit",
            })}
        </span>
      </div>
      <div
        className={
          "col-sm-12 col-lg-1 mt-sm-2 mt-lg-0 d-flex justify-content-center"
        }
      >
        {email.idConsultaEmailReferencia !== null &&
          email.tipo === "R" &&
          email.lido === "S" && (
            <Tooltip title="Email lido">
              <IconButton className="p-2">
                <Drafts className="color-secondary" />
              </IconButton>
            </Tooltip>
          )}
        {email.idConsultaEmailReferencia !== null &&
          email.tipo === "R" &&
          email.lido !== "S" && (
            <Tooltip title="Email não lido">
              <IconButton className="p-2">
                <Email className="color-primary" />
              </IconButton>
            </Tooltip>
          )}
        <Tooltip title="Detalhes do Email">
          <IconButton
            className="p-2"
            onClick={() =>
              handleAbrirDetalhesEmail({
                id: email.id,
                proposta: email.idConsulta,
                atividade: email.idAtividade,
                tipo: email.tipo,
              })
            }
          >
            <ListAltOutlined className="color-secondary" />
          </IconButton>
        </Tooltip>
      </div>
    </div>
  );
};

export default View;
