import React, { useCallback } from "react";
import {
  ControlledSelect,
  ControlledTextfield,
} from "../../../components/inputs";
import { Button, MenuItem } from "../../../components";
import { tipoClausula } from "../../../utils/parametros";

const FormLocalidade = ({
  props,
  disabled,
  handleSubmit,
  clausula,
  voltarParaLista,
}) => {
  const handleClickNext = useCallback(
    (values) => {
      handleSubmit(values, clausula.id);
    },
    [handleSubmit, clausula]
  );

  return (
    <div className="card spacer">
      <div className="card-body">
        <span className="card-title color-brand">Cláusula</span>
        <form onSubmit={props.handleSubmit(handleClickNext)}>
          <div className="row">
            <div className="col-6">
              <ControlledTextfield
                name={"codigo"}
                margin={"none"}
                disabled={disabled}
                formProps={props}
                uppercase={true}
                label={"Código"}
                inputProps={{ maxLength: 10 }}
              />
            </div>
            <div className="col-6">
              <ControlledSelect
                name={"tipo"}
                formProps={props}
                label={"Tipo Cláusula"}
                margin={"none"}
              >
                {tipoClausula.map((item) => (
                  <MenuItem
                    className={"select-submenu-item"}
                    key={item.id}
                    value={item.id}
                  >
                    {item.nome.toUpperCase()}
                  </MenuItem>
                ))}
              </ControlledSelect>
            </div>
            <div className="col-12">
              <ControlledTextfield
                name={"nome"}
                margin={"none"}
                disabled={disabled}
                formProps={props}
                uppercase={true}
                label={"Nome"}
                inputProps={{ maxLength: 50 }}
              />
            </div>
            <div className="col-12">
              <ControlledTextfield
                formProps={props}
                multiline={true}
                disabled={disabled}
                rowsMax={8}
                rows={6}
                uppercase={false}
                name={"texto"}
                margin={"none"}
                label={"Texto"}
                inputProps={{ maxLength: 4000 }}
              />
            </div>
            <div className="col-12">
              <ControlledTextfield
                formProps={props}
                multiline={true}
                disabled={disabled}
                rowsMax={4}
                rows={4}
                uppercase={false}
                name={"utilizacao"}
                margin={"none"}
                label={"Utilização"}
                inputProps={{ maxLength: 2000 }}
              />
            </div>
          </div>
          <div className="row spacer">
            <div className="offset-md-2 col-md-4 col-12">
              <Button
                label="Voltar"
                onClick={voltarParaLista}
                loading={clausula.loading}
              />
            </div>
            <div className="col-md-4 col-12">
              <Button
                label="CONFIRMAR"
                type={"submit"}
                loading={clausula.loading}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormLocalidade;
