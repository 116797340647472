import React, { useCallback } from "react";
import AdicionarClausulaDialog from "./AdicionarClausulaDialog";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import validators from "../../../../utils/validators";
import { actions } from "../../../../reducers/propostas.actions";
import { getMinuta } from "../../../../selectors/propostas.selectors";

const AdicionarClausulaConnected = ({ handleClose, dados }) => {
  const dispatch = useDispatch();

  const minuta = useSelector(getMinuta);
  let clausulasSelecionadas = minuta["clausulas-selecionadas"];

  const rules = {
    clausula: validators.string({ required: true }),
  };

  const initialValues = {};

  for (const key of clausulasSelecionadas.list.map(
    (i) => "clausula" + i.clausula
  )) {
    initialValues[key] = true;
  }

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const handleSubmit = useCallback(
    (clausula) => {
      let request = [];
      const keys = Object.keys(clausula);
      keys.forEach((key, index) => {
        if (clausula[key] === true)
          request.push(key.toString().replace("clausula", ""));
      });
      dispatch(
        actions.gravarClausulasSelecionadas.request({
          consulta: dados.proposta,
          clausulas: request,
          handleClose,
        })
      );
    },
    [dispatch, dados, handleClose]
  );

  return (
    <AdicionarClausulaDialog
      handleSubmit={handleSubmit}
      open={dados.open}
      loading={minuta.clausulas?.loading}
      formProps={formProps}
      dominioClausulas={minuta.clausulas?.list}
      handleClose={handleClose}
      submitting={clausulasSelecionadas?.loading}
      clausulasSelecionadas={clausulasSelecionadas?.list}
    />
  );
};

export default AdicionarClausulaConnected;
