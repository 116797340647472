import {
  ControlledReaptcha,
  ControlledTextfield,
} from "../../components/inputs";
import { Button, Link } from "../../components";
import { InputAdornment } from "@material-ui/core";
import { Lock, Person } from "@material-ui/icons";
import React from "react";

const LoginForm = ({
  loading,
  formProps,
  captchaRef,
  handleSubmit,
  handleClickForgotPassword,
}) => {
  return (
    <form
      className="d-flex flex-column"
      onSubmit={formProps.handleSubmit(handleSubmit)}
    >
      <ControlledTextfield
        label="Usuário"
        name={"user"}
        formProps={formProps}
        margin={"none"}
        uppercase={false}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" className={"ml-2 mr-3"}>
              <Person className="color-brand-2" />
            </InputAdornment>
          ),
        }}
      />
      <ControlledTextfield
        label="Senha"
        name={"password"}
        formProps={formProps}
        type={"password"}
        margin={"none"}
        uppercase={false}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" className={"ml-2 mr-3"}>
              <Lock className="color-brand-2" />
            </InputAdornment>
          ),
        }}
      />
      <Link
        className="text-right mt-2 mr-1 esqueci-senha clickable"
        onClick={handleClickForgotPassword}
      >
        Esqueci minha senha
      </Link>
      <div className="mini-separator mb-3 mt-3" />
      <div className={"row justify-content-center"}>
        <ControlledReaptcha
          formProps={formProps}
          captchaRef={captchaRef}
          name={"token"}
          margin={"none"}
          sitekey={process.env.REACT_APP_RECAPTCHA_KEY}
        />
        <div className="col-xs-12 col-md-6 mt-3">
          <Button loading={loading} label={"ACESSAR"} type={"submit"} />
        </div>
      </div>
    </form>
  );
};

export default LoginForm;
