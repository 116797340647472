import React, { useCallback, useState } from "react";
import CardEndereco from "../../CardEndereco";
import CardOutrasInformacoes from "./CardOutrasInformacoes";
import { Button } from "../../../../../components";
import ConfirmarDialog from "../../ConfirmarDialog";
import CardApartamento from "./CardApartamento";
import CardRegistroMatricula from "./CardRegistroMatricula";

const ImovelForm = ({
  formProps,
  dominioUfs,
  handleZipCodeBlur,
  handleSubmit,
  submitting,
  disabled,
  filtrarCartorios,
  dominioCartorios,
  handleGoBack,
}) => {
  const [open, setOpen] = useState(false);
  const handleCloseDialog = () => {
    setOpen(false);
  };

  const [tipoImovel, setTipoImovel] = useState(
    formProps.initialValues?.tipoImovel ?? ""
  );

  const handleClickNext = useCallback(
    (values) => {
      handleCloseDialog();
      handleSubmit(values, formProps.initialValues);
    },
    [handleSubmit, handleCloseDialog]
  );

  if (Object.keys(formProps.formState.errors).length > 0 && open) {
    setOpen(false);
  }

  return (
    <form
      className="d-flex flex-column"
      onSubmit={formProps.handleSubmit(handleClickNext)}
    >
      <CardEndereco
        title="Endereço do Imóvel"
        props={formProps}
        disabled={disabled}
        uf={dominioUfs}
        consultarCep={handleZipCodeBlur}
        filtrarCartorios={filtrarCartorios}
        setTipoImovel={setTipoImovel}
        showTipoImovel={true}
      />
      <CardOutrasInformacoes
        props={formProps}
        disabled={disabled}
        dominioCartorios={dominioCartorios}
      />
      <CardRegistroMatricula
        props={formProps}
        disabled={disabled}
        dominioCartorios={dominioCartorios}
      />
      <div className="row spacer justify-content-center">
        <div className="col-md-4 col-5">
          <Button
            label="VOLTAR"
            loading={submitting}
            onClick={() => handleGoBack()}
          />
        </div>
        {!disabled && (
          <div className="col-md-4 col-5">
            <Button
              label="ATUALIZAR"
              loading={submitting}
              onClick={() => {
                setOpen(true);
                formProps.clearErrors();
              }}
            />
          </div>
        )}
      </div>
      <ConfirmarDialog open={open} handleClose={handleCloseDialog} />
    </form>
  );
};

export default ImovelForm;
