import OtpInput from "react-otp-input";

const OtpTextfield = ({
  InputProps,
  variant = "filled",
  uppercase = true,
  margin,
  error,
  InputLabelProps,
  FormHelperTextProps,
  search = false,
  numInputs,
  separator,
  value,
  placeholder,
  isInputNum,
  ...other
}) => (
  <OtpInput
    {...other}
    numInputs={numInputs}
    separator={separator}
    inputStyle={"textfield-regular label-input text-uppercase"}
    hasErrored={error}
    errorStyle={"textfield-undeline-error"}
    focusStyle={"textfield-undeline"}
    placeholder={placeholder}
    containerStyle={"otpTextfieldContainer"}
    isInputNum={isInputNum}
    value={value}
  />
);

export default OtpTextfield;
