import React, { useState } from "react";
import HistoricoAtividadesPage from "./HistoricoAtividadesPage";
import HistoricoDocumentosPage from "./HistoricoDocumentosPage";
import HistoricoEmailsPage from "./HistoricoEmailsPage";

import MenuHistorico from "./MenuHistorico";

const HistoricoPage = ({
  loading,
  historico,
  exportarHistoricoAtividades,
  proposta,
  exportHistorico,
  loadingExportHistorico,
  cleanFile,
  codigoPropostaproposta,
  menuItens,
  selecionarOpcaoMenu,
  menuSelecionado,
  historicoDocumentos,
  exportarHistoricoDocumentos,
  historicoDocumentosLoading,
  historicoEmails,
  historicoEmailsLoading,
  exportarHistoricoEmails,
  detalhesEmail,
  handleAbrirDetalhesEmail,
  abrirDetalhes,
  historicoEmailDetalhesLoading,
  handleSubmit,
  habilitarResponder,
  handleResponder,
  canRespond,
  sending,
}) => {
  // const [dadosDetalheAtividade, setDadosDetalheAtividade] = useState({
  //   open: false,
  // });
  //
  // const handleCloseDetalhesAtividade = useCallback(() => {
  //   setDadosDetalheAtividade({
  //     open: false,
  //   });
  // }, []);
  // const handleOpenDetalhesAtividade = useCallback((values) => {
  //   setDadosDetalheAtividade({
  //     open: true,
  //     ...values,
  //   });
  // }, []);

  const [exportName, setExportName] = useState("atividades");

  const handleDownload = () => {
    setExportName("atividades");
    exportarHistoricoAtividades(proposta);
  };
  const handleDownloadDocumentos = () => {
    setExportName("documentos");
    exportarHistoricoDocumentos(proposta);
  };

  const handleDownloadEmails = () => {
    setExportName("emails");
    exportarHistoricoEmails(proposta);
  };

  const download = (fileBase64, momento_geracao, codigoPropostaproposta) => {
    if (fileBase64 != null) {
      const outFile = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${fileBase64}`;
      const element = document.createElement("a");
      element.href = outFile;
      element.download = `exportacao_${exportName}_${codigoPropostaproposta}_${momento_geracao}.xlsx`;
      document.body.appendChild(element);
      element.click();
    }
  };

  if (exportHistorico?.file) {
    download(
      exportHistorico.file,
      exportHistorico.momento_geracao,
      codigoPropostaproposta
    );
    cleanFile();
  }

  return (
    <div className="row">
      <div className="col-12 mt-0">
        <MenuHistorico
          menuSelecionado={menuSelecionado}
          itens={menuItens}
          selecionarOpcaoMenu={selecionarOpcaoMenu}
        />
        <div className="row">
          <div className="col-12">
            <div className="card">
              {menuSelecionado === "atividades" && (
                <HistoricoAtividadesPage
                  historico={historico}
                  loading={loading}
                  handleDownload={handleDownload}
                  loadingExportHistorico={loadingExportHistorico}
                />
              )}
              {menuSelecionado === "documentos" && (
                <HistoricoDocumentosPage
                  historico={historicoDocumentos}
                  loading={historicoDocumentosLoading}
                  handleDownload={handleDownloadDocumentos}
                  loadingExportHistorico={loadingExportHistorico}
                />
              )}
              {menuSelecionado === "emails" && (
                <HistoricoEmailsPage
                  historico={historicoEmails}
                  loading={historicoEmailsLoading}
                  handleDownload={handleDownloadEmails}
                  loadingExportHistorico={loadingExportHistorico}
                  detalhesEmail={detalhesEmail}
                  handleAbrirDetalhesEmail={handleAbrirDetalhesEmail}
                  abrirDetalhes={abrirDetalhes}
                  historicoEmailDetalhesLoading={historicoEmailDetalhesLoading}
                  handleSubmit={handleSubmit}
                  habilitarResponder={habilitarResponder}
                  handleResponder={handleResponder}
                  canRespond={canRespond}
                  sending={sending}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HistoricoPage;
