import Minuta from "./Minuta";
import { useDispatch, useSelector } from "react-redux";
import { getMinuta } from "../../../../selectors/propostas.selectors";
import { getDetalheProposta } from "../../../../selectors/propostas.selectors";
import { useCallback } from "react";
import { actions } from "reducers/propostas.actions";

const MinutaConnected = () => {
  const dispatch = useDispatch();
  const minuta = useSelector(getMinuta);
  const loading = minuta?.loading;
  const menuSelecionado = minuta["menu-selecionado"];

  let clausulasSelecionadas = minuta["clausulas-selecionadas"];

  const clausulas = minuta.clausulas?.list;

  const itens = [
    {
      key: 0,
      label: "QUALIFICAÇÃO",
      first: true,
    },
    {
      key: 1,
      label: "EM TEMPO",
    },
  ];

  const selecionarOpcaoMenu = useCallback(
    (e, value) => {
      dispatch(actions.selecionarOpcaoMenuMinuta(value));
    },
    [dispatch]
  );

  const detalheProposta = useSelector(getDetalheProposta);

  const gerarMinuta = useCallback(() => {
    dispatch(actions.gerarMinuta.request(detalheProposta.id));
  }, [dispatch, detalheProposta]);

  return (
    <Minuta
      loading={loading}
      proposta={detalheProposta?.id}
      minuta={minuta}
      gerarVersaoMinuta={gerarMinuta}
      itens={itens}
      selecionarOpcaoMenu={selecionarOpcaoMenu}
      menuSelecionado={menuSelecionado}
      clausulasSelecionadas={clausulasSelecionadas}
      clausulas={clausulas}
    />
  );
};

export default MinutaConnected;
