import { useCallback } from "react";
import SearchPopup from "./SearchPopup";
import { useSelector, useDispatch } from "react-redux";
import { actions } from "../../../reducers/propostas.actions";
import { getSearch } from "../../../selectors/propostas.selectors";
import {
  types as routes,
  actions as routeActions,
} from "../../../reducers/rotas.actions";

const SearchPopupConnected = () => {
  const dispatch = useDispatch();
  const { open, anchor, arrow } = useSelector(getSearch);
  const handleClickAway = useCallback(() => {
    dispatch(actions.closeSearch());
  }, [dispatch]);

  const handleArrowRef = useCallback(
    (node) => {
      dispatch(actions.arrowReference(node));
    },
    [dispatch]
  );
  const handleSearchSubmit = useCallback(
    (values) => {
      dispatch(
        routeActions.redirectTo(routes.PROPOSTAS, { pagina: 1, values })
      );
    },
    [dispatch]
  );

  const selecionarFiltro = useCallback(() => {
    dispatch(actions.limparFiltroValores());
  }, [dispatch]);

  const selecionarFiltroEMudarPagina = (route) => {
    selecionarFiltro();
    setTimeout(function () {
      handleSearchSubmit(route);
    }, 50);
  };

  return (
    <SearchPopup
      open={!!open}
      anchor={anchor}
      arrow={arrow}
      handleClickAway={handleClickAway}
      handleArrowRef={handleArrowRef}
      handleSearchSubmit={selecionarFiltroEMudarPagina}
    />
  );
};

export default SearchPopupConnected;
