import React from "react";
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select as MSelect,
} from "@material-ui/core";

const Select = ({
  id,
  name,
  variant = "filled",
  value,
  uppercase = true,
  margin = "normal",
  disabled,
  label,
  multiple,
  helperText,
  MenuProps,
  children,
  ...otherValues
}) => (
  <FormControl margin={margin} variant={variant} fullWidth disabled={disabled}>
    <InputLabel
      htmlFor={name}
      focused={!!value}
      shrink={true}
      classes={{
        root: "label-input",
      }}
    >
      {label}
    </InputLabel>
    <MSelect
      {...otherValues}
      margin={margin}
      variant={variant}
      multiple={multiple}
      className={"select-input select-underline"}
      classes={{
        select: "select-fixer",
      }}
      MenuProps={MenuProps}
      inputProps={{
        name: name,
        id: id,
        value: value,
        disabled: disabled,
      }}
    >
      {children}
    </MSelect>
    {Boolean(helperText) && (
      <FormHelperText error={Boolean(otherValues.error)}>
        {helperText}
      </FormHelperText>
    )}
  </FormControl>
);

export default Select;
