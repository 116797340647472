import React, { useState } from "react";
import CardDadosProposta from "./CardDadosProposta";
import MenuProposta from "./MenuProposta";
import BasePage from "../../BasePage";
import ListaDocumentos from "./documentos/ListaDocumentos.connected";
import ParticipantesPage from "./participantes/ParticipantesPage";
import ImoveisBlock from "./imoveis/ImoveisBlock.connected";
import CardObservacao from "./CardObervacao";
import ChevronList from "./ChevronList";
import ListaAtividades from "./atividades/ListaAtividades.connected";
import HistoricoPage from "./historico/HistoricoPage.connected";
import MinutaPage from "./minuta/Minuta.connected";
import { Autorenew, InfoOutlined } from "@material-ui/icons";
import { Dialog, DialogContent, IconButton } from "@material-ui/core";
import { VisibleWithProfile } from "../../../components";
import TrocarSituacaoConsulta from "../situacaoConsulta/TrocarSituacaoConsulta.connected";
import TrocarPrioridadeConsulta from "../prioridadeConsulta/TrocarPrioridadeConsulta.connected";
import InformacaoDaProposta from "./InformacaoDaProposta";

const PropostaPage = ({
  detalheProposta,
  itens,
  menuSelecionado,
  loading,
  error,
  selecionarOpcaoMenu,
  abrirDetalhePessoa,
  novoCadastroPf,
  excluirParticipante,
  disabled,
  outros,
  formProps,
  handleSubmit,
  submitting,
  workflow,
  perfil,
  alterarSituacao,
  handleCloseAlterarSituacao,
  handleOpenAlterarSituacao,
  alterarPrioridade,
  handleCloseAlterarPrioridade,
  handleOpenAlterarPrioridade,
  handleOpcaoSelecionada,
  showMotivoCancelamento,
  showDataContrato,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <>
      <BasePage isLoggedIn={true}>
        {!loading && !error && (
          <div
            style={{
              display: "flex",
              flexGrow: 1,
              justifyContent: "space-between",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            <div>
              <span className="page-title">#{detalheProposta.codigo}</span>
            </div>
            <div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <span className="page-title">
                  {situacaoDecode(detalheProposta.situacao)}
                </span>
                <VisibleWithProfile
                  profiles={[
                    "BPO",
                    "AGENCIA",
                    "BANCO GESTAO",
                    "PARCEIRO",
                    "ADMINISTRADOR",
                  ]}
                >
                  <IconButton className={"p-1"} onClick={handleClick}>
                    <InfoOutlined className={"change-analyst-icon"} />
                  </IconButton>
                  <Dialog
                    onClose={handleClose}
                    classes={{ paper: "w100" }}
                    maxWidth="md"
                    open={open}
                  >
                    <InformacaoDaProposta
                      handleClose={handleClose}
                      detalheProposta={detalheProposta}
                    />
                  </Dialog>
                </VisibleWithProfile>
                <VisibleWithProfile profiles={["BPO"]}>
                  <IconButton
                    className={"p-1"}
                    onClick={() =>
                      handleOpenAlterarSituacao({
                        situacao: detalheProposta.situacao,
                        consultaID: detalheProposta.id,
                      })
                    }
                  >
                    <Autorenew className={"change-analyst-icon"} />
                  </IconButton>
                </VisibleWithProfile>
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                }}
              >
                <span
                  className="sub-title"
                  style={{
                    flex: 1,
                    fontSize: "18px",
                    color: "#000",
                    textAlign: "right",
                  }}
                >
                  {detalheProposta.diasNaEsteira}
                </span>
              </div>
            </div>
          </div>
        )}
        {!loading && !error && (
          <div className="row spacer">
            <div className="col-12">
              <MenuProposta
                itens={itens}
                menuSelecionado={menuSelecionado}
                selecionarOpcaoMenu={selecionarOpcaoMenu}
                proposta={!!detalheProposta ? detalheProposta.id : null}
                perfil={perfil}
              />
            </div>
          </div>
        )}
        {menuSelecionado === 0 && (
          <>
            <div className="row spacer">
              <div className="col-12">
                <CardDadosProposta
                  data={detalheProposta}
                  error={error}
                  loading={loading}
                  handleCloseAlterarPrioridade={handleCloseAlterarPrioridade}
                  handleOpenAlterarPrioridade={handleOpenAlterarPrioridade}
                />
              </div>
            </div>
            <div className="row spacer">
              <div className="col-12">
                {!workflow.loading && !!workflow.timeline && (
                  <ChevronList timeline={workflow.timeline} />
                )}
              </div>
            </div>
            <div className="row spacer">
              <div className="col-12">
                <CardObservacao
                  formProps={formProps}
                  error={error}
                  loading={loading}
                  handleSubmit={handleSubmit}
                  disabled={disabled}
                  submitting={submitting}
                  perfil={perfil}
                />
              </div>
            </div>
          </>
        )}
        {menuSelecionado === 1 && (
          <div className="row spacer">
            <div className="col-12">
              <ParticipantesPage
                proposta={detalheProposta?.id}
                buyers={detalheProposta?.cliente}
                vendedores={detalheProposta?.vendedor}
                outros={outros}
                error={false}
                loading={loading}
                abrirDetalhePessoa={abrirDetalhePessoa}
                novoCadastroPf={novoCadastroPf}
                excluirParticipante={excluirParticipante}
                disabled={disabled}
              />
            </div>
          </div>
        )}
        {menuSelecionado === 2 && (
          <div className="row">
            <div className="col-12">
              <ImoveisBlock />
            </div>
          </div>
        )}
        {menuSelecionado === 3 && (
          <div className="row spacer">
            <div className="col-12">
              <ListaDocumentos />
            </div>
          </div>
        )}
        {menuSelecionado === 4 && (
          <div className="row spacer">
            <div className="col-12">
              <ListaAtividades />
            </div>
          </div>
        )}
        {menuSelecionado === 5 && (
          <div className="row spacer">
            <div className="col-12">
              <HistoricoPage />
            </div>
          </div>
        )}
        {menuSelecionado === 6 && (
          <div className="row spacer">
            <div className="col-12">
              <MinutaPage />
            </div>
          </div>
        )}
        <TrocarSituacaoConsulta
          alterarSituacao={alterarSituacao}
          handleCloseAlterarSituacao={handleCloseAlterarSituacao}
          handleOpenAlterarSituacao={handleOpenAlterarSituacao}
          showMotivoCancelamento={showMotivoCancelamento}
          handleOpcaoSelecionada={handleOpcaoSelecionada}
          showDataContrato={showDataContrato}
        />
        <TrocarPrioridadeConsulta
          alterarPrioridade={alterarPrioridade}
          handleCloseAlterarPrioridade={handleCloseAlterarPrioridade}
          handleOpenAlterarPrioridade={handleOpenAlterarPrioridade}
        />
      </BasePage>
    </>
  );
};

const situacaoDecode = (situacao) => {
  switch (situacao) {
    case "A":
      return "Em andamento";
    case "C":
      return "Cancelada";
    case "E":
      return "Contrato Emitido";
    case "P":
      return "Paralisada";
    default:
      return "";
  }
};

export default PropostaPage;
