import { styled } from "@material-ui/core/styles";
import CookieConsent from "react-cookie-consent";
import { Button } from "@material-ui/core";

const Link = styled("span")({
  color: "#FF914D",
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline",
  },
});
const AcceptButton = styled(Button)({
  fontFamily: "Montserrat, sans-serif",
  backgroundColor: "#cc092f",
  "&:hover": {
    backgroundColor: "#cc092f",
  },
  "& .MuiButton-label": {
    color: "white",
    fontWeight: 800,
  },
});

const CookieBanner = ({ handlePolicyClick }) => {
  return (
    <CookieConsent
      location="bottom"
      cookieName="gpnetimob-consent"
      style={{
        backgroundColor: "#3a3f53",
        alignItems: "center",
      }}
      buttonWrapperClasses={"p-3 flex-fill flex-md-grow-0 d-flex flex-column"}
      ButtonComponent={({ className, style, ...props }) => (
        <AcceptButton {...props}>PROSSEGUIR</AcceptButton>
      )}
    >
      Nós usamos cookies e outras tecnologias semelhantes para melhorar a sua
      experiência em nossos serviços, personalizar publicidade e recomendar
      conteúdo de seu interesse. Ao utilizar nossos serviços, você concorda com
      tal monitoramento. Essas e outras informações podem ser acessadas em nossa{" "}
      <Link onClick={handlePolicyClick}>Política de Privacidade</Link>.
    </CookieConsent>
  );
};

export default CookieBanner;
