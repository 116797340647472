import React from "react";
import { Tooltip } from "@material-ui/core";

const View = ({ documento, index }) => {
  return (
    <div
      className={
        index % 2 === 0
          ? "row mt-0 d-flex align-items-center medium-row tr-even"
          : "row mt-0 d-flex align-items-center medium-row tr-odd"
      }
    >
      <div className="col-sm-12 col-lg-3 mt-sm-2 mt-lg-0 resumir-texto">
        <Tooltip title={`${documento.tipoParticipante} ${documento.nome}`}>
          <span>{`${documento.tipoParticipante} ${documento.nome}`}</span>
        </Tooltip>
      </div>
      <div className="col-sm-12 col-lg-3 mt-sm-2 mt-lg-0 resumir-texto">
        <Tooltip title={documento.documento}>
          <span>{documento.documento}</span>
        </Tooltip>
      </div>
      <div className="col-sm-12 col-lg-2 mt-sm-1 mt-lg-0 resumir-texto">
        <Tooltip title={documento.situacao}>
          <span>{documento.situacao}</span>
        </Tooltip>
      </div>
      <div className={"col-sm-12 col-lg-1 mt-sm-2 mt-lg-0"}>
        <span>
          {documento.dataHoraCriacao &&
            new Date(documento.dataHoraCriacao).toLocaleDateString("pt-BR", {
              day: "2-digit",
              month: "2-digit",
              year: "2-digit",
            })}
        </span>
      </div>
      <div className={"col-sm-12 col-lg-2 mt-sm-2 mt-lg-0 text-center"}>
        <span>
          {documento.dataHoraIndexacao &&
            new Date(documento.dataHoraIndexacao).toLocaleDateString("pt-BR", {
              day: "2-digit",
              month: "2-digit",
              year: "2-digit",
            })}
        </span>
      </div>
      <div className={"col-sm-12 col-lg-1 mt-sm-2 mt-lg-0"}>
        <span>
          {documento.dataHoraAnalise &&
            new Date(documento.dataHoraAnalise).toLocaleDateString("pt-BR", {
              day: "2-digit",
              month: "2-digit",
              year: "2-digit",
            })}
        </span>
      </div>
    </div>
  );
};

export default View;
