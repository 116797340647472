import React from "react";
import { Button } from "../../../../components";
import Loader from "../../../../components/loaders";
import CardParticipante from "./CardParticipante";

const ParticipantesPage = ({
  proposta,
  buyers,
  vendedores,
  outros,
  submitting,
  abrirDetalhePessoa,
  novoCadastroPf,
  excluirParticipante,
  disabled,
  loading,
}) => (
  <>
    {!!loading && (
      <div className="row">
        <div className="col-12 d-flex justify-content-center">
          <Loader type={"dots"} color={"brand"} size={50} />
        </div>
      </div>
    )}
    {!loading && (
      <div className="row">
        {!!buyers &&
          buyers.map((buyer) => (
            <div
              key={buyer.nome}
              className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3"
            >
              <CardParticipante
                proposta={proposta}
                pessoa={buyer}
                participacao={"comprador"}
                abrirDetalhePessoa={abrirDetalhePessoa}
                excluirParticipante={excluirParticipante}
                disabled={disabled}
              />
            </div>
          ))}
        {!!vendedores &&
          vendedores.map((vendedor) => (
            <div
              key={vendedor.nome}
              className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3"
            >
              <CardParticipante
                proposta={proposta}
                pessoa={vendedor}
                participacao={"vendedor"}
                abrirDetalhePessoa={abrirDetalhePessoa}
                excluirParticipante={excluirParticipante}
                disabled={disabled}
              />
            </div>
          ))}
        {!!outros?.nomeInterveniente && (
          <div
            key={outros?.nomeInterveniente}
            className="col-12 col-sm-6 col-md-4 col-lg-3 mt-3"
          >
            <CardParticipante
              proposta={proposta}
              pessoa={outros}
              participacao={"Interveniente Quitante"}
              abrirDetalhePessoa={abrirDetalhePessoa}
              disabled={disabled}
              excluirParticipante={excluirParticipante}
            />
          </div>
        )}
      </div>
    )}
    {!loading && !disabled && (
      <div className="row spacer">
        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
          <Button
            disabled={submitting}
            type={"submit"}
            className={"botao-participante"}
            onClick={() => novoCadastroPf(proposta, "C", "F")}
            label={
              submitting ? (
                <Loader type={"dots"} color={"white"} size={30} />
              ) : (
                "ADICIONAR COMPRADOR PF"
              )
            }
          />
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
          <Button
            disabled={submitting}
            type={"submit"}
            className={"botao-participante"}
            onClick={() => novoCadastroPf(proposta, "V", "F")}
            label={
              submitting ? (
                <Loader type={"dots"} color={"white"} size={30} />
              ) : (
                "ADICIONAR VENDEDOR PF"
              )
            }
          />
        </div>
        {!outros?.nomeInterveniente && (
          <div className="col-12 col-sm-6 col-md-4 col-lg-3">
            <Button
              disabled={submitting}
              type={"submit"}
              className={"botao-participante"}
              onClick={() => novoCadastroPf(proposta, "I", "J")}
              label={
                submitting ? (
                  <Loader type={"dots"} color={"white"} size={30} />
                ) : (
                  "ADICIONAR IQ"
                )
              }
            />
          </div>
        )}
      </div>
    )}
    {!loading && !disabled && (
      <div className="row">
        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
          <Button
            disabled={submitting}
            type={"submit"}
            className={"botao-participante"}
            onClick={() => novoCadastroPf(proposta, "C", "J")}
            label={
              submitting ? (
                <Loader type={"dots"} color={"white"} size={30} />
              ) : (
                "ADICIONAR COMPRADOR PJ"
              )
            }
          />
        </div>
        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
          <Button
            disabled={submitting}
            type={"submit"}
            className={"botao-participante"}
            onClick={() => novoCadastroPf(proposta, "V", "J")}
            label={
              submitting ? (
                <Loader type={"dots"} color={"white"} size={30} />
              ) : (
                "ADICIONAR VENDEDOR PJ"
              )
            }
          />
        </div>
      </div>
    )}
  </>
);

export default ParticipantesPage;
