import React, { Fragment, useCallback, useState } from "react";
import {
  ControlledTextfield,
  ControlledWysiwygTextField,
} from "../../../components/inputs";
import { Button } from "../../../components";
import { Chip } from "@material-ui/core";
import { Add } from "@material-ui/icons";
import { TIPOS_DESTINATARIOS } from "../../../constants/administracao/templateEmails.constants";
import DestinatarioDialog from "./DestinatarioDialog.connected";
import { toast } from "react-toastify";
import translations from "../../../i18n/pt_br";

const getDestinatario = (destinatario) => {
  if (destinatario.tipo === "OU") {
    return destinatario.outrosDestinatarios;
  }
  return TIPOS_DESTINATARIOS[destinatario.tipo];
};

const TemplateEmailForm = ({
  formProps,
  handleSubmit,
  handleBack,
  arrayDestinatarios,
}) => {
  const [dadosDestinatario, setDadosDestinatario] = useState({
    open: false,
  });
  const handleCloseDestinatario = useCallback(() => {
    setDadosDestinatario({
      open: false,
    });
  }, []);
  const handleOpenDestinatario = useCallback(
    (data) => {
      setDadosDestinatario({
        open: true,
        ...data,
        handleAdd: (values) => {
          if (
            !!arrayDestinatarios.fields.find(
              (c) =>
                (values.tipo === "OU" &&
                  values.outrosDestinatarios === c.outrosDestinatarios) ||
                (values.tipo !== "OU" && values.tipo === c.tipo)
            )
          ) {
            toast.error("Destinatário já existe na lista atual");
            return false;
          }

          arrayDestinatarios.append({ ...values });
          return true;
        },
        handleEdit: (values, index) => {
          const listaAtual = arrayDestinatarios.fields.filter(
            (v, i) => i !== index
          );

          if (
            !!listaAtual.find(
              (c) =>
                (values.tipo === "OU" &&
                  values.outrosDestinatarios === c.outrosDestinatarios) ||
                (values.tipo !== "OU" && values.tipo === c.tipo)
            )
          ) {
            toast.error("Destinatário já existe na lista atual");
            return false;
          }

          arrayDestinatarios.update(index, { ...values });
          return true;
        },
      });
    },
    [arrayDestinatarios]
  );

  return (
    <div className="card spacer">
      <div className="card-body">
        <span className="card-title color-brand">Template de E-mail</span>
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-12 col-md-6">
              <ControlledTextfield
                name={"nome"}
                margin={"none"}
                formProps={formProps}
                label={"Nome"}
              />
            </div>
            <div className="col-12 col-md-6">
              <ControlledTextfield
                name={"assunto"}
                formProps={formProps}
                uppercase={false}
                label={"Assunto"}
                margin={"none"}
              />
            </div>
          </div>
          <span className="card-subtitle color-brand mt-3 d-block">
            Destinatários
          </span>
          <div className="d-flex flex-row flex-wrap gap-2 mt-2 mb-4">
            {arrayDestinatarios.fields.map((field, index) => {
              return (
                <Fragment key={field.id}>
                  <ControlledTextfield
                    name={`destinatarios.${index}.tipo`}
                    formProps={formProps}
                    fake={true}
                  />
                  <ControlledTextfield
                    name={`destinatarios.${index}.outrosDestinatarios`}
                    formProps={formProps}
                    fake={true}
                  />
                  <Chip
                    clickable
                    classes={{
                      root: "chip-text color-light-gray bg-almost-black",
                      deleteIcon: "color-light-gray",
                    }}
                    onClick={() => handleOpenDestinatario({ ...field, index })}
                    label={getDestinatario(field)}
                    onDelete={() => {
                      arrayDestinatarios.remove(index);
                    }}
                  />
                </Fragment>
              );
            })}
            <Chip
              clickable
              onClick={() => handleOpenDestinatario({})}
              icon={<Add />}
              classes={{
                root: "chip-text color-almost-black",
                icon: "color-almost-black",
              }}
              label={"Adicionar"}
            />
          </div>
          <div className="row">
            <div className="col-12">
              <ControlledWysiwygTextField
                formProps={formProps}
                margin={"none"}
                name={"conteudo"}
                label={""}
              />
            </div>
          </div>
          <div className="row spacer">
            <div className="offset-md-2 col-md-4 col-12">
              <Button label="VOLTAR" onClick={handleBack} />
            </div>
            <div className="col-md-4 col-12">
              <Button label="CONFIRMAR" type={"submit"} />
            </div>
          </div>
        </form>
      </div>
      <DestinatarioDialog
        data={dadosDestinatario}
        handleClose={handleCloseDestinatario}
      />
    </div>
  );
};

export default TemplateEmailForm;
