import { all } from "redux-saga/effects";
import { sagas as routeSagas } from "./rotas.saga";
import { sagas as authSagas } from "./autenticacao.saga";
import { sagas as propostasSagas } from "./propostas.saga";
import { sagas as cargaConsultasSagas } from "./administracao/cargaConsultas.saga";
import { sagas as documentosSaga } from "./documentos.saga";
import { sagas as imovelSaga } from "./imovel.saga";
import { sagas as dominiosSagas } from "./dominios.saga";
import { sagas as usuariosSagas } from "./administracao/usuarios.saga";
import { sagas as exportConsultasSagas } from "./administracao/exportConsultas.saga";
import { sagas as matrizChecklistSagas } from "./administracao/matrizChecklist.saga";
import { sagas as documentosSagas } from "./administracao/documentos.saga";
import { sagas as atividadesSagas } from "./administracao/atividades.saga";
import { sagas as etapasSagas } from "./administracao/etapas.saga";
import { sagas as produtosSagas } from "./administracao/produtos.saga";
import { sagas as perfisSagas } from "./administracao/perfis.saga";
import { sagas as organizacaoSagas } from "./administracao/organizacao.saga";
import { sagas as dashboardSagas } from "./dashboard.saga";
import { sagas as exportSla } from "./administracao/exportSla.saga";
import { sagas as localidadesSagas } from "./administracao/localidades.saga";
import { sagas as motivoCancelamentoSagas } from "./administracao/motivoCancelamento.saga";
import { sagas as clausulasSagas } from "./administracao/clausulas.saga";
import { sagas as matrizClausulasSagas } from "./administracao/matrizClausulas.saga";
import { sagas as painelIntegracaoSagas } from "./administracao/painelIntegracao.saga";
import { sagas as templatesEmailSagas } from "./administracao/templatesEmail.saga";
import { sagas as emailsSagas } from "./emails.saga";
import { sagas as relatoriosSagas } from "./administracao/relatorios.saga";

function* sagas() {
  yield all([
    ...authSagas,
    ...propostasSagas,
    ...cargaConsultasSagas,
    ...documentosSaga,
    ...routeSagas,
    ...imovelSaga,
    ...dominiosSagas,
    ...usuariosSagas,
    ...exportConsultasSagas,
    ...documentosSagas,
    ...atividadesSagas,
    ...etapasSagas,
    ...matrizChecklistSagas,
    ...produtosSagas,
    ...perfisSagas,
    ...organizacaoSagas,
    ...dashboardSagas,
    ...exportSla,
    ...localidadesSagas,
    ...motivoCancelamentoSagas,
    ...clausulasSagas,
    ...matrizClausulasSagas,
    ...painelIntegracaoSagas,
    ...templatesEmailSagas,
    ...emailsSagas,
    ...relatoriosSagas,
  ]);
}

export default sagas;
