import { types as authTypes } from "./autenticacao.actions";
import { actions } from "./dominios.actions";

const initialState = {
  documentos: {
    loading: false,
    data: [],
  },
  analistas: {
    loading: false,
    data: [],
  },
  etapas: {
    loading: false,
    data: [],
  },
  atividades: {
    loading: false,
    data: [],
  },
  produtos: {
    loading: false,
    data: [],
  },
  localidades: {
    loading: false,
    data: [],
  },
  cartorios: {
    loading: false,
    data: [],
  },
  motivosCancelamento: {
    loading: false,
    data: [],
  },
  templates: {
    loading: false,
    data: [],
  },
};

const partial = (state, path, action, actionGenerator) => {
  return {
    ...state,
    [path]: {
      ...state[path],
      loading: action.type === actionGenerator.REQUEST,
      data:
        action.type === actionGenerator.SUCCESS
          ? action.payload.response.data
          : [],
    },
  };
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case authTypes.LOGOUT:
      return {
        ...initialState,
      };
    case actions.documentos.REQUEST:
    case actions.documentos.SUCCESS:
    case actions.documentos.FAILURE:
      return partial(state, "documentos", action, actions.documentos);
    case actions.analistas.REQUEST:
    case actions.analistas.SUCCESS:
    case actions.analistas.FAILURE:
      return partial(state, "analistas", action, actions.analistas);
    case actions.etapas.REQUEST:
    case actions.etapas.SUCCESS:
    case actions.etapas.FAILURE:
      return partial(state, "etapas", action, actions.etapas);
    case actions.atividades.REQUEST:
    case actions.atividades.SUCCESS:
    case actions.atividades.FAILURE:
      return partial(state, "atividades", action, actions.atividades);
    case actions.produtos.REQUEST:
    case actions.produtos.SUCCESS:
    case actions.produtos.FAILURE:
      return partial(state, "produtos", action, actions.produtos);
    case actions.localidades.REQUEST:
    case actions.localidades.SUCCESS:
    case actions.localidades.FAILURE:
      return partial(state, "localidades", action, actions.localidades);
    case actions.cartorio.REQUEST:
    case actions.cartorio.SUCCESS:
    case actions.cartorio.FAILURE:
      return partial(state, "cartorios", action, actions.cartorio);
    case actions.motivos_cancelamento.REQUEST:
    case actions.motivos_cancelamento.SUCCESS:
    case actions.motivos_cancelamento.FAILURE:
      return partial(
        state,
        "motivosCancelamento",
        action,
        actions.motivos_cancelamento
      );
    case actions.templates.REQUEST:
    case actions.templates.SUCCESS:
    case actions.templates.FAILURE:
      return partial(state, "templates", action, actions.templates);
    default:
      return state;
  }
};

export default reducer;
