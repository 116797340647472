import React, { useState } from "react";
import {
  ControlledSelect,
  ControlledTextfield,
  ZipCodeTextField,
} from "../../../components/inputs";
import { MenuItem } from "../../../components";
import { tiposImovel } from "../../../utils/parametros";

const CardEndereco = ({
  props,
  title,
  uf,
  consultarCep,
  disabled,
  filtrarCartorios,
  setTipoImovel,
  showTipoImovel,
}) => {
  const handleChange = () => {
    filtrarCartorios(props.getValues());
  };
  return (
    <div className="card spacer">
      <div className="card-body">
        <span className="card-title color-brand">{title || "Endereço"}</span>
        <div className="row">
          {showTipoImovel && (
            <div className="col-12">
              <ControlledSelect
                name={"tipoImovel"}
                formProps={props}
                disabled={disabled}
                label={"Tipo do Imóvel"}
                onChange={(e) => setTipoImovel(e.target.value)}
                margin={"none"}
              >
                {tiposImovel.map((item, index) => (
                  <MenuItem
                    className={"select-submenu-item"}
                    key={index}
                    value={item}
                  >
                    {item}
                  </MenuItem>
                ))}
              </ControlledSelect>
            </div>
          )}
          <div className="col-12 col-md-4">
            <ControlledTextfield
              name={"cep"}
              margin={"none"}
              disabled={disabled}
              formProps={props}
              label={"CEP"}
              onBlur={consultarCep}
              InputProps={{
                inputComponent: ZipCodeTextField,
              }}
            />
          </div>
          <div className="col-12 col-md-6">
            <ControlledTextfield
              formProps={props}
              margin={"none"}
              disabled={disabled}
              name={"logradouro"}
              label={"Logradouro"}
              inputProps={{ maxLength: 40 }}
            />
          </div>
          <div className="col-12 col-md-2">
            <ControlledTextfield
              name={"numero"}
              formProps={props}
              disabled={disabled}
              label={"Número"}
              margin={"none"}
              inputProps={{ maxLength: 7 }}
            />
          </div>
          <div className="col-12 col-md-4">
            <ControlledTextfield
              name={"complemento"}
              formProps={props}
              disabled={disabled}
              label={"Complemento"}
              margin={"none"}
              inputProps={{ maxLength: 30 }}
            />
          </div>
          <div className="col-12 col-md-3">
            <ControlledTextfield
              name={"bairro"}
              formProps={props}
              disabled={disabled}
              label={"Bairro"}
              margin={"none"}
              inputProps={{ maxLength: 40 }}
            />
          </div>
          <div className="col-12 col-md-3">
            <ControlledTextfield
              name={"municipio"}
              formProps={props}
              disabled={disabled}
              label={"Cidade"}
              margin={"none"}
              onChange={handleChange}
              inputProps={{ maxLength: 60 }}
            />
          </div>
          <div className="col-12 col-md-2">
            <ControlledSelect
              name={"uf"}
              formProps={props}
              disabled={disabled}
              label={"UF"}
              onChange={handleChange}
              margin={"none"}
            >
              {uf.map((item) => (
                <MenuItem
                  className={"select-submenu-item"}
                  key={item.codigo}
                  value={item.codigo}
                >
                  {item.nome}
                </MenuItem>
              ))}
            </ControlledSelect>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardEndereco;
