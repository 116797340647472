import { actions, types } from "./propostas.actions";
import { types as authTypes } from "./autenticacao.actions";
import { types as routes } from "./rotas.actions";
import formatters from "../utils/formatters";

const initialState = {
  loading: false,
  changing: false,
  error: null,
  list: [],
  proposta: {
    error: false,
    loading: true,
    detalhe: null,
    "menu-selecionado": 0,
    workflow: {
      filter: {
        etapa: [],
        responsavel: [],
        situacao: [],
      },
    },
    situacaoProposta: {
      loading: false,
    },
    historico: {
      loading: false,
      list: [],
    },
    export_historico: {
      loading: false,
      momento_geracao: null,
      file: null,
    },
    minuta: {
      "menu-selecionado": 0,
    },
    uploadDocumentosBanco: {
      loading: false,
    },
  },
  search: {},
  page: 1,
  expanded: null,
  pesquisa: null,
  filtroSimplesProposta: [],
  filtroExcludenteProposta: null,
  filtroValores: {
    analistaBack: [],
    resp: [],
    priorizadas: [],
    fgts: false,
    iq: false,
    headerText: "",
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case authTypes.LOGOUT:
      return {
        ...initialState,
      };
    case routes.PROPOSTAS:
      return {
        ...state,
        expanded: null,
      };
    case actions.listarPropostas.REQUEST:
    case actions.listarPropostas.SUCCESS:
    case actions.listarPropostas.FAILURE:
      return {
        ...state,
        loading: action.type === actions.listarPropostas.REQUEST,
        proposta: {
          ...initialState.proposta,
        },
        error:
          action.type === actions.listarPropostas.FAILURE
            ? action.payload.error
            : null,
        list:
          action.type === actions.listarPropostas.SUCCESS
            ? action.payload.response.data.consultas
            : [],
        total:
          action.type === actions.listarPropostas.SUCCESS
            ? action.payload.response.data.total
            : null,
        page:
          action.type === actions.listarPropostas.REQUEST
            ? action.payload.pagina
            : action.payload.original.pagina,
        pesquisa:
          action.type === actions.listarPropostas.SUCCESS
            ? action.payload.response.data.pesquisa
            : null,
        filtroValores:
          action.type === actions.listarPropostas.REQUEST
            ? action.payload.filtro
            : action.payload.original.filtro,
      };
    case actions.consultarProposta.REQUEST:
    case actions.consultarProposta.SUCCESS:
    case actions.consultarProposta.FAILURE:
      return {
        ...state,
        expanded:
          action.type === actions.consultarProposta.REQUEST
            ? action.payload
            : action.payload.original,
        proposta: {
          ...state.proposta,
          loading: action.type === actions.consultarProposta.REQUEST,
          error:
            action.type === actions.consultarProposta.FAILURE
              ? action.payload.error
              : null,
          detalhe:
            action.type === actions.consultarProposta.SUCCESS
              ? {
                  ...action.payload.response.data[0],
                  dataContrato: action.payload.response.data[0]
                    ?.dataContratoBanco
                    ? action.payload.response.data[0]?.dataContratoBanco.split(
                        "-"
                      )[2] +
                      "/" +
                      action.payload.response.data[0]?.dataContratoBanco.split(
                        "-"
                      )[1] +
                      "/" +
                      action.payload.response?.data[0]?.dataContratoBanco.split(
                        "-"
                      )[0]
                    : null,
                }
              : null,
        },
      };
    case types.FECHAR_DETALHE:
      return {
        ...state,
        expanded: null,
      };
    case types.ABRIR_COCKPIT:
      return {
        ...state,
      };
    case types.SELECIONAR_OPCAO_MENU:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          "menu-selecionado": !!action.payload ? action.payload : 0,
          checklist: {
            ...state.proposta.checklist,
            filter: "todos",
          },
          workflow: {
            ...state.proposta.workflow,
            filter: {
              etapa: [],
              responsavel: [],
              situacao: [],
            },
          },
        },
      };
    case actions.consultarChecklist.REQUEST:
    case actions.consultarChecklist.SUCCESS:
    case actions.consultarChecklist.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          checklist: {
            ...state.proposta.checklist,
            loading: action.type === actions.consultarChecklist.REQUEST,
            uploading:
              action.type === actions.consultarChecklist.REQUEST
                ? []
                : state.proposta.checklist?.uploading,
            error:
              action.type === actions.consultarChecklist.FAILURE
                ? action.payload.error
                : null,
            list:
              action.type === actions.consultarChecklist.SUCCESS
                ? action.payload.response.data
                : [],
          },
        },
      };
    case types.FILTRO_CHECKLIST:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          checklist: {
            ...state.proposta.checklist,
            filter: action.payload.filtro,
          },
        },
      };
    case types.FILTRO_ATIVIDADE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          workflow: {
            ...state.proposta.workflow,
            filter: {
              ...state.proposta.workflow.filter,
              etapa:
                action.payload.filtro === "etapa"
                  ? action.payload.valores
                  : state.proposta.workflow.filter?.etapa,
              responsavel:
                action.payload.filtro === "responsavel"
                  ? action.payload.valores
                  : state.proposta.workflow.filter?.responsavel,
              situacao:
                action.payload.filtro === "situacao"
                  ? action.payload.valores
                  : state.proposta.workflow.filter?.situacao,
            },
          },
        },
      };
    case actions.uploadArtefato.REQUEST:
    case actions.uploadArtefato.SUCCESS:
    case actions.uploadArtefato.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          checklist: {
            ...state.proposta.checklist,
            list:
              action.type === actions.uploadArtefato.SUCCESS
                ? state.proposta.checklist.list.map((item) => {
                    return {
                      ...item,
                      documentos: item.documentos.map((doc) => {
                        if (doc.id === action.payload.original.id) {
                          return {
                            ...doc,
                            situacao_documento: "I",
                            imagens:
                              doc.imagens +
                              action.payload.original.files.length,
                            dataHoraIndexacao: new Date(),
                            usuarioIndexacao: action.payload.original.usuario,
                          };
                        } else {
                          return doc;
                        }
                      }),
                      documentos_conjuge: item.documentos_conjuge?.map(
                        (doc) => {
                          if (doc.id === action.payload.original.id) {
                            return {
                              ...doc,
                              situacao_documento: "I",
                              imagens:
                                doc.imagens +
                                action.payload.original.files.length,
                              dataHoraIndexacao: new Date(),
                              usuarioIndexacao: action.payload.original.usuario,
                            };
                          } else {
                            return doc;
                          }
                        }
                      ),
                    };
                  })
                : state.proposta.checklist.list,
            uploading:
              action.type === actions.uploadArtefato.REQUEST
                ? [...state.proposta.checklist.uploading, action.payload.id]
                : [
                    ...state.proposta.checklist.uploading.filter(
                      (i) => i !== action.payload.original.id
                    ),
                  ],
          },
        },
      };

    case actions.atuarAnalisarDocumento.REQUEST:
    case actions.atuarAnalisarDocumento.SUCCESS:
    case actions.atuarAnalisarDocumento.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          checklist: {
            ...state.proposta.checklist,
            analysing: action.type === actions.atuarAnalisarDocumento.REQUEST,
            list: state.proposta.checklist.list.map((checklist) => {
              return {
                ...checklist,
                documentos:
                  action.type === actions.atuarAnalisarDocumento.SUCCESS
                    ? checklist.documentos.map((documento) => {
                        if (
                          documento.id === action.payload.original.checklist
                        ) {
                          return {
                            ...documento,
                            situacao_analise:
                              action.payload.original.situacaoAnalise,
                            situacao_documento:
                              action.payload.original.situacaoDocumento,
                          };
                        }
                        return documento;
                      })
                    : checklist.documentos,
              };
            }),
          },
        },
      };
    case actions.atualizarMotivoAnalise.REQUEST:
    case actions.atualizarMotivoAnalise.SUCCESS:
    case actions.atualizarMotivoAnalise.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          checklist: {
            ...state.proposta.checklist,
            analysing: action.type === actions.atualizarMotivoAnalise.REQUEST,
            list: state.proposta.checklist.list.map((checklist) => {
              return {
                ...checklist,
                documentos:
                  action.type === actions.atualizarMotivoAnalise.SUCCESS
                    ? checklist.documentos.map((documento) => {
                        if (documento.id === action.payload.original.id) {
                          return {
                            ...documento,
                            motivoRecusa: action.payload.original.motivo,
                          };
                        }
                        return documento;
                      })
                    : checklist.documentos,
                documentos_conjuge:
                  action.type === actions.atualizarMotivoAnalise.SUCCESS
                    ? checklist.documentos_conjuge?.map((documento) => {
                        if (documento.id === action.payload.original.id) {
                          return {
                            ...documento,
                            motivoRecusa: action.payload.original.motivo,
                          };
                        }
                        return documento;
                      })
                    : checklist.documentos_conjuge,
              };
            }),
          },
        },
      };
    case actions.consultarParticipante.REQUEST:
    case actions.consultarParticipante.SUCCESS:
    case actions.consultarParticipante.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          detalhe: {
            ...state.proposta.detalhe,
            participante: {
              loading: action.type === actions.consultarParticipante.REQUEST,
              dados:
                action.type === actions.consultarParticipante.SUCCESS
                  ? {
                      cpf: formatters.strings.cpfCnpj(
                        action.payload.response.data["cpf_cnpj"] ?? "",
                        action.payload.response.data.tipoPessoa
                      ),
                      dataNascimento: action.payload.response.data
                        .PessoaDadoPessoal?.dataNascimento
                        ? action.payload.response.data.PessoaDadoPessoal[
                            "dataNascimento"
                          ].split("-")[2] +
                          "/" +
                          action.payload.response.data.PessoaDadoPessoal[
                            "dataNascimento"
                          ].split("-")[1] +
                          "/" +
                          action.payload.response.data.PessoaDadoPessoal[
                            "dataNascimento"
                          ].split("-")[0]
                        : "",
                      nome: action.payload.response.data.nome ?? "",
                      tipoPessoa: action.payload.response.data.tipoPessoa ?? "",
                      tipoParticipante:
                        action.payload.response.data.tipoParticipante ?? "",
                      genero: action.payload.response.data?.genero ?? "",
                      fgts:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.fgts === "S",
                      colaborador:
                        action.payload.response.data?.colaborador === "S",
                      estadoCivil:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.estadoCivil,
                      uniaoEstavel:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.uniaoEstavel === "S",
                      cep:
                        action.payload.response.data?.PessoaEndereco?.cep
                          ?.length === 7
                          ? "0" +
                            action.payload.response.data.PessoaEndereco.cep
                          : action.payload.response.data?.PessoaEndereco?.cep ??
                            "",
                      logradouro:
                        action.payload.response.data?.PessoaEndereco
                          ?.logradouro ?? "",
                      complemento:
                        action.payload.response.data?.PessoaEndereco
                          ?.complemento ?? "",
                      numero:
                        action.payload.response.data?.PessoaEndereco?.numero ??
                        "",
                      bairro:
                        action.payload.response.data?.PessoaEndereco?.bairro ??
                        "",
                      municipio:
                        action.payload.response.data?.PessoaEndereco
                          ?.municipio ?? "",
                      uf:
                        action.payload.response.data?.PessoaEndereco?.uf ?? "",
                      email: action.payload.response.data?.contato?.email ?? "",
                      telefone: action.payload.response.data?.contato
                        ?.areaCelular
                        ? "(" +
                          action.payload.response.data?.contato?.areaCelular.toString() +
                          ") " +
                          action.payload.response.data?.contato?.telefoneCelular
                        : "",
                      telefoneResidencial: action.payload.response.data?.contato
                        ?.areaResidencial
                        ? "(" +
                          action.payload.response.data?.contato?.areaResidencial.toString() +
                          ") " +
                          action.payload.response.data?.contato
                            ?.telefoneResidencial
                        : "",
                      nomeConjuge:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.nomeConjuge ?? "",
                      cpfConjuge:
                        action.payload.response.data?.PessoaDadoPessoal?.[
                          "cpf_conjuge"
                        ] ?? "",
                      dataCasamento: action.payload.response.data
                        ?.PessoaDadoPessoal?.dataCasamento
                        ? action.payload.response.data.PessoaDadoPessoal.dataCasamento.split(
                            "-"
                          )[2] +
                          "/" +
                          action.payload.response.data.PessoaDadoPessoal.dataCasamento.split(
                            "-"
                          )[1] +
                          "/" +
                          action.payload.response.data.PessoaDadoPessoal.dataCasamento.split(
                            "-"
                          )[0]
                        : "",
                      regimeCasamento:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.regimeCasamento ?? "",
                      dataNascimentoConjuge: action.payload.response.data
                        .PessoaDadoPessoal?.dataNascimentoConjuge
                        ? action.payload.response.data.PessoaDadoPessoal.dataNascimentoConjuge.split(
                            "-"
                          )[2] +
                          "/" +
                          action.payload.response.data.PessoaDadoPessoal.dataNascimentoConjuge.split(
                            "-"
                          )[1] +
                          "/" +
                          action.payload.response.data.PessoaDadoPessoal.dataNascimentoConjuge.split(
                            "-"
                          )[0]
                        : "",
                      emailConjuge:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.emailConjuge ?? "",
                      idPessoa: action.payload.response.data?.id ?? "",
                      idConsulta: action.payload.response.data?.consulta ?? "",
                      codigoPessoa: action.payload.response.data?.codigo ?? "",
                      isConjugeComprador:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.isConjugeComprador === "S",
                      nomeMae:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.nomeMae,
                      fgtsConjuge:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.fgtsConjuge === "S",
                      localidade:
                        action.payload.response.data?.PessoaEndereco
                          ?.localidade ?? "",
                      tipoEmpresa:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.tipoEmpresa,
                      profissao:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.profissao,
                      nacionalidade:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.nacionalidade,
                      nomePai:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.nomePai,
                      numeroDocumento:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.numeroDocumento,
                      orgaoEmissor:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.orgaoEmissor,
                      ufOrgaoEmissor:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.ufOrgaoEmissor,
                      estadoCivilConjuge:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.estadoCivilConjuge,
                      nomeMaeConjuge:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.nomeMaeConjuge,
                      nomePaiConjuge:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.nomePaiConjuge,
                      profissaoConjuge:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.profissaoConjuge,
                      nacionalidadeConjuge:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.nacionalidadeConjuge,
                      numeroDocumentoConjuge:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.numeroDocumentoConjuge,
                      orgaoEmissorConjuge:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.orgaoEmissorConjuge,
                      ufOrgaoEmissorConjuge:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.ufOrgaoEmissorConjuge,
                      dataEscritura: action.payload.response.data
                        ?.PessoaDadoPessoal?.dataEscritura
                        ? `${
                            action.payload.response.data?.PessoaDadoPessoal?.dataEscritura.split(
                              "-"
                            )[2]
                          }/${
                            action.payload.response.data?.PessoaDadoPessoal?.dataEscritura.split(
                              "-"
                            )[1]
                          }/${
                            action.payload.response.data?.PessoaDadoPessoal?.dataEscritura.split(
                              "-"
                            )[0]
                          }`
                        : null,
                      numeroLivroEscritura:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.numeroLivroEscritura,
                      numeroFolhaEscritura:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.numeroFolhaEscritura,
                      nomeCartorioEscritura: action.payload.response.data
                        ?.PessoaDadoPessoal?.nomeCartorioEscritura
                        ? action.payload.response.data?.PessoaDadoPessoal
                            ?.nomeCartorioEscritura
                        : "",
                      comarcaEscritura:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.comarcaEscritura,
                      ufEscritura:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.ufEscritura,
                      numeroRegistro:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.numeroRegistro,
                      dataRegistro: action.payload.response.data
                        ?.PessoaDadoPessoal?.dataRegistro
                        ? `${
                            action.payload.response.data?.PessoaDadoPessoal?.dataRegistro.split(
                              "-"
                            )[2]
                          }/${
                            action.payload.response.data?.PessoaDadoPessoal?.dataRegistro.split(
                              "-"
                            )[1]
                          }/${
                            action.payload.response.data?.PessoaDadoPessoal?.dataRegistro.split(
                              "-"
                            )[0]
                          }`
                        : null,
                      numeroLivroRegistro:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.numeroLivroRegistro,
                      numeroFolhaRegistro:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.numeroFolhaRegistro,
                      nomeCartorioRegistro: action.payload.response.data
                        ?.PessoaDadoPessoal?.nomeCartorioRegistro
                        ? action.payload.response.data?.PessoaDadoPessoal
                            ?.nomeCartorioRegistro
                        : "",
                      comarcaRegistro:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.comarcaRegistro,
                      ufRegistro:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.ufRegistro,
                      conjugeVendedor:
                        action.payload.response.data?.PessoaDadoPessoal
                          ?.conjugeVendedor === "S",
                    }
                  : null,
            },
          },
        },
      };
    case actions.consultarCepCorreios.REQUEST:
    case actions.consultarCepCorreios.SUCCESS:
    case actions.consultarCepCorreios.FAILURE:
      return {
        ...state,
        cep: {
          ...state.cep,
          loading: action.type === actions.consultarCepCorreios.REQUEST,
          error: action.type === actions.consultarCepCorreios.FAILURE,
        },
      };
    case actions.atualizarParticipante.REQUEST:
    case actions.atualizarParticipante.SUCCESS:
    case actions.atualizarParticipante.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          detalhe: {
            ...state.proposta.detalhe,
            participante: {
              ...state.proposta.detalhe.participante,
              confirmacao: {
                loading: action.type === actions.atualizarParticipante.REQUEST,
                error: action.type === actions.atualizarParticipante.FAILURE,
              },
            },
          },
        },
      };
    case types.ABRIR_NOVO_PARTICIPANTE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          detalhe: {
            ...state.proposta.detalhe,
            participante: {
              dados: {
                cpf: "",
                dataNascimento: "",
                nome: "",
                tipoPessoa: action.payload.tipoPessoa,
                tipoParticipante: action.payload.participacao,
                fgts: false,
                estadoCivil: "",
                uniaoEstavel: false,
                cep: "",
                logradouro: "",
                complemento: "",
                numero: "",
                bairro: "",
                municipio: "",
                uf: "",
                email: "",
                telefone: "",
                nomeConjuge: "",
                cpfConjuge: "",
                dataCasamento: "",
                dataNascimentoConjuge: "",
                generoConjuge: "",
                idPessoa: "",
                idConsulta: action.payload.id ?? "",
                codigoPessoa: "",
                isConjugeCompradora: false,
                nomeMae: "",
                fgtsConjuge: false,
                regimeCasamento: "",
                localidade: "",
              },
            },
          },
        },
      };
    case actions.excluirParticipante.REQUEST:
    case actions.excluirParticipante.SUCCESS:
    case actions.excluirParticipante.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          loading: true,
          detalhe: {
            ...state.proposta.detalhe,
            cliente: {
              ...state.proposta.detalhe.cliente,
              loadingExclusao:
                action.type === actions.excluirParticipante.REQUEST,
            },
          },
        },
      };
    case actions.adicionarDocumento.REQUEST:
    case actions.adicionarDocumento.SUCCESS:
    case actions.adicionarDocumento.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          checklist: {
            ...state.proposta.checklist,
            adding: action.type === actions.adicionarDocumento.REQUEST,
          },
        },
      };
    case actions.consultarIq.REQUEST:
    case actions.consultarIq.SUCCESS:
    case actions.consultarIq.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          detalhe: {
            ...state.proposta.detalhe,
            id:
              action.type === actions.consultarIq.REQUEST
                ? action.payload
                : action.payload.original,
            interveniente: {
              idConsulta:
                action.type === actions.consultarIq.REQUEST
                  ? action.payload
                  : action.payload.original,
              nomeInterveniente:
                action.type === actions.consultarIq.SUCCESS
                  ? action.payload.response.data[0].interveniente
                  : "",
              loading: action.type === actions.consultarIq.REQUEST,
              loadingAtualizacao: false,
            },
          },
        },
      };
    case actions.atualizarIq.REQUEST:
    case actions.atualizarIq.SUCCESS:
    case actions.atualizarIq.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          loading:
            action.type === actions.atualizarIq.REQUEST ||
            !state.proposta.detalhe.codigo,
          detalhe: {
            ...state.proposta.detalhe,
            interveniente: {
              ...state.proposta.detalhe.interveniente,
              loadingAtualizacao: action.type === actions.atualizarIq.REQUEST,
              nomeInterveniente:
                action.type === actions.atualizarIq.REQUEST
                  ? action.payload.iq
                  : action.payload.original.iq,
            },
          },
        },
      };
    case actions.atualizarObservacao.REQUEST:
    case actions.atualizarObservacao.SUCCESS:
    case actions.atualizarObservacao.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          detalhe: {
            ...state.proposta.detalhe,
            observacao:
              action.type === actions.atualizarObservacao.REQUEST
                ? action.payload.observacao
                : action.payload.original.observacao,
            observacaoInterna:
              action.type === actions.atualizarObservacao.REQUEST
                ? action.payload.observacaoInterna
                : action.payload.original.observacaoInterna,
            submittingObservacao:
              action.type === actions.atualizarObservacao.REQUEST,
          },
        },
      };
    case actions.getWorkflow.REQUEST:
    case actions.getWorkflow.SUCCESS:
    case actions.getWorkflow.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          workflow: {
            ...state.proposta.workflow,
            loading: action.type === actions.getWorkflow.REQUEST,
            timeline:
              action.type === actions.getWorkflow.SUCCESS
                ? action.payload.response.data.timeline
                : "",
            atividades:
              action.type === actions.getWorkflow.SUCCESS
                ? action.payload.response.data.atividades
                : "",
          },
        },
      };
    case actions.abrirAtividade.REQUEST:
    case actions.abrirAtividade.SUCCESS:
    case actions.abrirAtividade.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          workflow: {
            ...state.proposta.workflow,
            loading: action.type === actions.abrirAtividade.REQUEST,
            timeline:
              action.type === actions.abrirAtividade.SUCCESS
                ? action.payload.response.data.timeline
                : state.proposta.workflow.timeline,
            atividades:
              action.type === actions.abrirAtividade.SUCCESS
                ? action.payload.response.data.atividades
                : state.proposta.workflow.atividades,
            filter: {
              etapa: [],
              responsavel: [],
              situacao: [],
              loading: action.type === actions.abrirAtividade.REQUEST,
            },
          },
          situacaoProposta: {
            loading: action.type === actions.abrirAtividade.REQUEST,
          },
          detalhe: {
            ...state.proposta.detalhe,
            situacao:
              actions.abrirAtividade.SUCCESS === action.type
                ? !!action.payload.response?.data?.situacaoConsulta
                  ? action.payload.response?.data?.situacaoConsulta
                  : state.proposta.detalhe.situacao
                : state.proposta.detalhe.situacao,
          },
        },
      };
    case actions.atualizarSituacaoAtividade.REQUEST:
    case actions.atualizarSituacaoAtividade.SUCCESS:
    case actions.atualizarSituacaoAtividade.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          workflow: {
            ...state.proposta.workflow,
            loadingAtividade:
              action.type === actions.atualizarSituacaoAtividade.REQUEST
                ? action.payload.atividade
                : "",
            timeline:
              action.type === actions.atualizarSituacaoAtividade.SUCCESS
                ? action.payload.response.data.timeline
                : state.proposta.workflow.timeline,
            atividades:
              action.type === actions.atualizarSituacaoAtividade.SUCCESS
                ? action.payload.response.data.atividades
                : state.proposta.workflow.atividades,
            filter: {
              etapa: [],
              responsavel: [],
              situacao: [],
              loading:
                action.type === actions.atualizarSituacaoAtividade.REQUEST,
            },
          },
          situacaoProposta: {
            loading: action.type === actions.atualizarSituacaoAtividade.REQUEST,
          },
          detalhe: {
            ...state.proposta.detalhe,
            dhUsuarioContratoEmitido:
              actions.atualizarSituacaoAtividade.SUCCESS === action.type
                ? action.payload.response?.data?.dhUsuarioContratoEmitido
                : state.proposta.detalhe.dhUsuarioContratoEmitido,
            usuarioContratoEmitido:
              actions.atualizarSituacaoAtividade.SUCCESS === action.type
                ? action.payload.response?.data.usuarioContratoEmitido
                : state.proposta.detalhe.usuarioContratoEmitido,
            situacao:
              actions.atualizarSituacaoAtividade.SUCCESS === action.type
                ? !!action.payload.response?.data?.situacaoConsulta
                  ? action.payload.response?.data?.situacaoConsulta
                  : state.proposta.detalhe.situacao
                : state.proposta.detalhe.situacao,
            dataContrato:
              actions.atualizarSituacaoAtividade.SUCCESS === action.type
                ? !!action.payload.response?.data?.dataContrato
                  ? action.payload.response?.data?.dataContrato.split("-")[2] +
                    "/" +
                    action.payload.response?.data?.dataContrato.split("-")[1] +
                    "/" +
                    action.payload.response?.data?.dataContrato.split("-")[0]
                  : state.proposta.detalhe.dataContrato
                : state.proposta.detalhe.dataContrato,
            diasNaEsteira:
              actions.atualizarSituacaoAtividade.SUCCESS === action.type &&
              action.payload.response?.data?.diasNaEsteira
                ? action.payload.response?.data?.diasNaEsteira
                : state.proposta.detalhe.diasNaEsteira,
          },
        },
      };
    case types.OPEN_SEARCH:
      return {
        ...state,
        search: {
          ...state.search,
          open: true,
          anchor: action.payload.anchor,
        },
      };
    case types.ARROW_REFERENCE:
      return {
        ...state,
        search: {
          ...state.search,
          arrow: action.payload.arrow,
        },
      };
    case types.CLOSE_SEARCH:
      return {
        ...state,
        search: {
          ...state.search,
          open: false,
          anchor: undefined,
        },
      };
    case types.SET_SEARCH:
      return {
        ...state,
        page: action.payload.pagina,
        pesquisa: action.payload.pesquisa,
      };
    case actions.atualizarAnalista.REQUEST:
    case actions.atualizarAnalista.SUCCESS:
    case actions.atualizarAnalista.FAILURE:
      return {
        ...state,
        changing: action.type === actions.atualizarAnalista.REQUEST,
        proposta: {
          ...state.proposta,
          detalhe: {
            ...state.proposta.detalhe,
            usuarioBackoffice:
              action.type === actions.atualizarAnalista.SUCCESS
                ? action.payload.response.data
                : state.proposta.detalhe.usuarioBackoffice,
          },
        },
      };
    case actions.atualizarSituacaoConsulta.REQUEST:
    case actions.atualizarSituacaoConsulta.SUCCESS:
    case actions.atualizarSituacaoConsulta.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          situacaoProposta: {
            loading: actions.atualizarSituacaoConsulta.REQUEST === action.type,
          },
          detalhe: {
            ...state.proposta.detalhe,
            situacao:
              actions.atualizarSituacaoConsulta.SUCCESS === action.type
                ? action.payload.response?.data?.situacao
                : state.proposta.detalhe.situacao,
            dhUsuarioCancelamento:
              actions.atualizarSituacaoConsulta.SUCCESS === action.type
                ? action.payload.response?.data?.dhUsuarioCancelamento
                : state.proposta.detalhe.dhUsuarioCancelamento,
            dhUsuarioContratoEmitido:
              actions.atualizarSituacaoConsulta.SUCCESS === action.type
                ? action.payload.response?.data?.dhUsuarioContratoEmitido
                : state.proposta.detalhe.dhUsuarioContratoEmitido,
            dhUsuarioEmAndamento:
              actions.atualizarSituacaoConsulta.SUCCESS === action.type
                ? action.payload.response?.data.dhUsuarioEmAndamento
                : state.proposta.detalhe.dhUsuarioEmAndamento,
            dhUsuarioParalizada:
              actions.atualizarSituacaoConsulta.SUCCESS === action.type
                ? action.payload.response?.data?.dhUsuarioParalizada
                : state.proposta.detalhe.dhUsuarioParalizada,
            usuarioContratoEmitido:
              actions.atualizarSituacaoConsulta.SUCCESS === action.type
                ? action.payload.response?.data.usuarioContratoEmitido
                : state.proposta.detalhe.usuarioContratoEmitido,
            usuarioParalizada:
              actions.atualizarSituacaoConsulta.SUCCESS === action.type
                ? action.payload.response?.data?.usuarioParalizada
                : state.proposta.detalhe.usuarioParalizada,
            usuarioCancelado:
              actions.atualizarSituacaoConsulta.SUCCESS === action.type
                ? action.payload.response?.data?.usuarioCancelado
                : state.proposta.detalhe.usuarioCancelado,
            usuarioEmAndamento:
              actions.atualizarSituacaoConsulta.SUCCESS === action.type
                ? action.payload.response?.data.usuarioEmAndamento
                : state.proposta.detalhe.usuarioEmAndamento,
            dataContrato:
              actions.atualizarSituacaoConsulta.SUCCESS === action.type
                ? action.payload.response?.data?.dataContrato
                  ? action.payload.response?.data?.dataContrato.split("-")[2] +
                    "/" +
                    action.payload.response?.data?.dataContrato.split("-")[1] +
                    "/" +
                    action.payload.response?.data?.dataContrato.split("-")[0]
                  : null
                : state.proposta.detalhe.dataContrato,
            diasNaEsteira:
              actions.atualizarSituacaoConsulta.SUCCESS === action.type
                ? action.payload.response?.data?.diasNaEsteira
                : state.proposta.detalhe.diasNaEsteira,
            motivoCancelamento:
              actions.atualizarSituacaoConsulta.SUCCESS === action.type
                ? action.payload.response?.data?.motivoCancelamento
                : state.proposta.detalhe.motivoCancelamento,
            MotivoCancelamento: {
              ...state.proposta.detalhe.MotivoCancelamento,
              descricao:
                actions.atualizarSituacaoConsulta.SUCCESS === action.type
                  ? action.payload.response?.data?.descricaoMotivoCancelamento
                  : state.proposta.detalhe?.MotivoCancelamento?.descricao,
            },
          },
        },
      };
    case actions.atualizarPrioridadeConsulta.REQUEST:
    case actions.atualizarPrioridadeConsulta.SUCCESS:
    case actions.atualizarPrioridadeConsulta.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          prioridadeProposta: {
            loading:
              actions.atualizarPrioridadeConsulta.REQUEST === action.type,
          },
          detalhe: {
            ...state.proposta.detalhe,
            prioridade:
              actions.atualizarPrioridadeConsulta.SUCCESS === action.type
                ? action.payload.response?.data?.prioridade
                : state.proposta.detalhe.prioridade,
          },
        },
      };
    case types.LIMPAR_PROPOSTA.REQUEST:
    case types.LIMPAR_PROPOSTA.SUCCESS:
    case types.LIMPAR_PROPOSTA.FAILURE:
      return {
        ...state,
        proposta: null,
      };
    case types.SELECIONAR_FILTRO_SIMPLES:
      return {
        ...state,
        filtroSimplesProposta: action.payload,
      };
    case types.SELECIONAR_FILTRO_EXCLUDENTE:
      return {
        ...state,
        filtroExcludenteProposta: action.payload,
      };
    case types.LIMPAR_FILTRO_VALORES:
      return {
        ...state,
        filtroSimplesProposta: [],
        filtroExcludenteProposta: null,
        filtroValores: {
          analistaBack: [],
          resp: [],
          priorizadas: [],
          fgts: false,
          iq: false,
        },
      };
    case actions.historicoProposta.REQUEST:
    case actions.historicoProposta.SUCCESS:
    case actions.historicoProposta.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          historico: {
            ...state.proposta.historico,
            loading: actions.historicoProposta.REQUEST === action.type,
            list:
              actions.historicoProposta.SUCCESS === action.type
                ? action.payload.response.data
                : [],
          },
        },
      };
    case actions.exportarHistoricoConsultas.REQUEST:
    case actions.exportarHistoricoConsultas.SUCCESS:
    case actions.exportarHistoricoConsultas.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          export_historico: {
            loading: actions.exportarHistoricoConsultas.REQUEST === action.type,
            file:
              actions.exportarHistoricoConsultas.SUCCESS === action.type
                ? action.payload.response.data.file
                : null,
            momento_geracao:
              actions.exportarHistoricoConsultas.SUCCESS === action.type
                ? action.payload.response.data.momento_geracao
                : null,
          },
        },
      };
    case types.LIMPAR_HISTORICO_ATIVIDADES_FILE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          export_historico: {
            ...state.proposta.export_historico,
            file: null,
            momento_geracao: null,
          },
        },
      };
    case actions.gerarMinuta.REQUEST:
    case actions.gerarMinuta.SUCCESS:
    case actions.gerarMinuta.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          minuta: {
            ...state.proposta.minuta,
            loading: actions.gerarMinuta.REQUEST === action.type,
            minuta:
              actions.gerarMinuta.SUCCESS === action.type
                ? action.payload.response.data
                : state.proposta.minuta,
          },
        },
      };
    case actions.listarClausulasProposta.REQUEST:
    case actions.listarClausulasProposta.SUCCESS:
    case actions.listarClausulasProposta.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          minuta: {
            ...state.proposta.minuta,
            loading: actions.listarClausulasProposta.REQUEST === action.type,
            minuta:
              actions.listarClausulasProposta.SUCCESS === action.type
                ? action.payload.response.data
                : state.proposta.minuta.minuta,
            "clausulas-selecionadas": {
              ...state.proposta.minuta["clausulas-selecionadas"],
              list:
                actions.listarClausulasProposta.SUCCESS === action.type
                  ? action.payload.response.data.filter((c) => {
                      return !c.texto && !!c.clausula;
                    })
                  : state.proposta.minuta["clausulas-selecionadas"]?.list,
            },
          },
        },
      };
    case types.SELECIONAR_OPCAO_MENU_MINUTA:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          minuta: {
            ...state.proposta.minuta,
            "menu-selecionado": !!action.payload ? action.payload : 0,
          },
        },
      };
    case actions.listarClausulasParametro.REQUEST:
    case actions.listarClausulasParametro.SUCCESS:
    case actions.listarClausulasParametro.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          minuta: {
            ...state.proposta.minuta,
            clausulas: {
              loading: actions.listarClausulasParametro.REQUEST === action.type,
              list:
                actions.listarClausulasParametro.SUCCESS === action.type
                  ? action.payload.response.data.clausulas
                  : state.proposta.minuta.clausulas?.list,
            },
          },
        },
      };
    case actions.gravarClausulasSelecionadas.REQUEST:
    case actions.gravarClausulasSelecionadas.SUCCESS:
    case actions.gravarClausulasSelecionadas.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          minuta: {
            ...state.proposta.minuta,
            "clausulas-selecionadas": {
              loading:
                actions.gravarClausulasSelecionadas.REQUEST === action.type,
              list:
                actions.gravarClausulasSelecionadas.SUCCESS === action.type
                  ? action.payload.response.data.filter((c) => {
                      return !c.texto && !!c.clausula;
                    })
                  : state.proposta.minuta["clausulas-selecionadas"]?.list,
            },
          },
        },
      };
    case actions.uploadDocumentosBanco.REQUEST:
    case actions.uploadDocumentosBanco.SUCCESS:
    case actions.uploadDocumentosBanco.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          uploadDocumentosBanco: {
            loading: actions.uploadDocumentosBanco.REQUEST === action.type,
          },
        },
      };
    case types.FILTRO_ERRO_UPLOAD:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          checklist: {
            ...state.proposta.checklist,
            filtro_erro_upload: action.payload.filtro,
          },
        },
      };
    case types.SELECIONAR_OPCAO_MENU_HISTORICO:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          historico: {
            ...state.proposta.historico,
            "menu-selecionado": !!action.payload
              ? action.payload
              : "atividades",
          },
        },
      };
    case actions.historicoDocumentos.REQUEST:
    case actions.historicoDocumentos.SUCCESS:
    case actions.historicoDocumentos.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          historico: {
            ...state.proposta.historico,
            documentos: {
              loading: actions.historicoDocumentos.REQUEST === action.type,
              data:
                actions.historicoDocumentos.SUCCESS === action.type
                  ? action.payload.response.data
                  : [],
            },
          },
        },
      };
    case actions.exportHistoricoDocumentos.REQUEST:
    case actions.exportHistoricoDocumentos.SUCCESS:
    case actions.exportHistoricoDocumentos.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          export_historico: {
            loading: actions.exportHistoricoDocumentos.REQUEST === action.type,
            file:
              actions.exportHistoricoDocumentos.SUCCESS === action.type
                ? action.payload.response.data.file
                : null,
            momento_geracao:
              actions.exportHistoricoDocumentos.SUCCESS === action.type
                ? action.payload.response.data.momento_geracao
                : null,
          },
        },
      };
    case actions.historicoEmails.REQUEST:
    case actions.historicoEmails.SUCCESS:
    case actions.historicoEmails.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          historico: {
            ...state.proposta.historico,
            emails: {
              loading: actions.historicoEmails.REQUEST === action.type,
              data:
                actions.historicoEmails.SUCCESS === action.type
                  ? action.payload.response.data
                  : [],
            },
          },
        },
      };
    case actions.exportHistoricoEmails.REQUEST:
    case actions.exportHistoricoEmails.SUCCESS:
    case actions.exportHistoricoEmails.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          export_historico: {
            loading: actions.exportHistoricoEmails.REQUEST === action.type,
            file:
              actions.exportHistoricoEmails.SUCCESS === action.type
                ? action.payload.response.data.file
                : null,
            momento_geracao:
              actions.exportHistoricoEmails.SUCCESS === action.type
                ? action.payload.response.data.momento_geracao
                : null,
          },
        },
      };
    case actions.historicoEmailsDetalhes.REQUEST:
    case actions.historicoEmailsDetalhes.SUCCESS:
    case actions.historicoEmailsDetalhes.FAILURE:
      return {
        ...state,
        proposta: {
          ...state.proposta,
          historico: {
            ...state.proposta.historico,
            detalhes_email: {
              loading: actions.historicoEmailsDetalhes.REQUEST === action.type,
              data:
                actions.historicoEmailsDetalhes.SUCCESS === action.type
                  ? action.payload.response.data
                  : {},
            },
            emails: {
              ...state.proposta.historico.emails,
              data:
                actions.historicoEmailsDetalhes.SUCCESS === action.type
                  ? state.proposta.historico.emails.data.map((email) => {
                      if (email.id === action.payload.response.data.id) {
                        return {
                          ...email,
                          lido: action.payload.response.data.lido,
                        };
                      }
                      return email;
                    })
                  : state.proposta.historico.emails.data,
            },
          },
        },
      };
    default:
      return state;
  }
};

export default reducer;
