export const estadoCivil = [
  { id: "SOLTEIRO", descricao: "SOLTEIRO", uniaoEstavel: true, conjuge: false },
  { id: "CASADO", descricao: "CASADO", uniaoEstavel: false, conjuge: true },
  {
    id: "DESQUITADO",
    descricao: "DESQUITADO",
    uniaoEstavel: true,
    conjuge: false,
  },
  {
    id: "DIVORCIADO",
    descricao: "DIVORCIADO",
    uniaoEstavel: true,
    conjuge: false,
  },
  { id: "VIUVO", descricao: "VIUVO", uniaoEstavel: true, conjuge: false },
  {
    id: "SEPARADO JUDICIALMENTE",
    descricao: "SEPARADO JUDICIALMENTE",
    uniaoEstavel: true,
    conjuge: false,
  },
  {
    id: "CASADO NO EXTERIOR",
    descricao: "CASADO NO EXTERIOR",
    uniaoEstavel: false,
    conjuge: true,
  },
];

export const regimeComunhao = [
  { id: "COMUNHÃO PARCIAL DE BENS", descricao: "COMUNHÃO PARCIAL DE BENS" },
  { id: "COMUNHÃO UNIVERSAL DE BENS", descricao: "COMUNHÃO UNIVERSAL DE BENS" },
  { id: "SEPARAÇÃO DE BENS", descricao: "SEPARAÇÃO DE BENS" },
  { id: "AQUESTO", descricao: "AQUESTO" },
  {
    id: "SEPARAÇÃO DE BENS OBRIGATÓRIA POR LEI",
    descricao: "SEPARAÇÃO DE BENS OBRIGATÓRIA POR LEI",
  },
];

const unidadesFederativa = [
  {
    codigo: "RO",
    nome: "RONDÔNIA",
    regiao: { id: 1, sigla: "N", nome: "Norte" },
  },
  { codigo: "AC", nome: "ACRE", regiao: { id: 1, sigla: "N", nome: "Norte" } },
  {
    codigo: "AM",
    nome: "AMAZONAS",
    regiao: { id: 1, sigla: "N", nome: "Norte" },
  },
  {
    codigo: "RR",
    nome: "RORAIMA",
    regiao: { id: 1, sigla: "N", nome: "Norte" },
  },
  { codigo: "PA", nome: "PARÁ", regiao: { id: 1, sigla: "N", nome: "Norte" } },
  { codigo: "AP", nome: "AMAPÁ", regiao: { id: 1, sigla: "N", nome: "Norte" } },
  {
    codigo: "TO",
    nome: "TOCANTINS",
    regiao: { id: 1, sigla: "N", nome: "Norte" },
  },
  {
    codigo: "MA",
    nome: "MARANHÃO",
    regiao: { id: 2, sigla: "NE", nome: "Nordeste" },
  },
  {
    codigo: "PI",
    nome: "PIAUÍ",
    regiao: { id: 2, sigla: "NE", nome: "Nordeste" },
  },
  {
    codigo: "CE",
    nome: "CEARÁ",
    regiao: { id: 2, sigla: "NE", nome: "Nordeste" },
  },
  {
    codigo: "RN",
    nome: "RIO GRANDE DO NORTE",
    regiao: { id: 2, sigla: "NE", nome: "Nordeste" },
  },
  {
    codigo: "PB",
    nome: "PARAÍBA",
    regiao: { id: 2, sigla: "NE", nome: "Nordeste" },
  },
  {
    codigo: "PE",
    nome: "PERNAMBUCO",
    regiao: { id: 2, sigla: "NE", nome: "Nordeste" },
  },
  {
    codigo: "AL",
    nome: "ALAGOAS",
    regiao: { id: 2, sigla: "NE", nome: "Nordeste" },
  },
  {
    codigo: "SE",
    nome: "SERGIPE",
    regiao: { id: 2, sigla: "NE", nome: "Nordeste" },
  },
  {
    codigo: "BA",
    nome: "BAHIA",
    regiao: { id: 2, sigla: "NE", nome: "Nordeste" },
  },
  {
    codigo: "MG",
    nome: "MINAS GERAIS",
    regiao: { id: 3, sigla: "SE", nome: "Sudeste" },
  },
  {
    codigo: "ES",
    nome: "ESPÍRITO SANTO",
    regiao: { id: 3, sigla: "SE", nome: "Sudeste" },
  },
  {
    codigo: "RJ",
    nome: "RIO DE JANEIRO",
    regiao: { id: 3, sigla: "SE", nome: "Sudeste" },
  },
  {
    codigo: "SP",
    nome: "SÃO PAULO",
    regiao: { id: 3, sigla: "SE", nome: "Sudeste" },
  },
  { codigo: "PR", nome: "PARANÁ", regiao: { id: 4, sigla: "S", nome: "Sul" } },
  {
    codigo: "SC",
    nome: "SANTA CATARINA",
    regiao: { id: 4, sigla: "S", nome: "Sul" },
  },
  {
    codigo: "RS",
    nome: "RIO GRANDE DO SUL",
    regiao: { id: 4, sigla: "S", nome: "Sul" },
  },
  {
    codigo: "MS",
    nome: "MATO GROSSO DO SUL",
    regiao: { id: 5, sigla: "CO", nome: "Centro-Oeste" },
  },
  {
    codigo: "MT",
    nome: "MATO GROSSO",
    regiao: { id: 5, sigla: "CO", nome: "Centro-Oeste" },
  },
  {
    codigo: "GO",
    nome: "GOIÁS",
    regiao: { id: 5, sigla: "CO", nome: "Centro-Oeste" },
  },
  {
    codigo: "DF",
    nome: "DISTRITO FEDERAL",
    regiao: { id: 5, sigla: "CO", nome: "Centro-Oeste" },
  },
];

export const uf = unidadesFederativa.sort((a, b) => {
  if (a.nome > b.nome) return 1;
  else return -1;
});

export const responsavelAtividade = [
  {
    id: "A",
    nome: "Agência",
  },
  { id: "B", nome: "BPO" },
  {
    id: "D",
    nome: "DEF",
  },
  {
    id: "O",
    nome: "DOC",
  },
  { id: "P", nome: "Patrimônio" },
  { id: "S", nome: "Seguradora" },
  { id: "C", nome: "Crédito" },
  { id: "L", nome: "Cliente" },
];

export const situacaoAtividade = [
  { id: "N", nome: "Não Iniciada" },
  { id: "P", nome: "Pendente Execução" },
  {
    id: "E",
    nome: "Em Andamento",
  },
  {
    id: "A",
    nome: "Aguardando Retorno",
  },
  {
    id: "C",
    nome: "Concluída",
  },
];

export const origemDocumento = [
  { id: "P", descricao: "PARTICIPANTE" },
  { id: "I", descricao: "IMÓVEL" },
];

export const qualificacao = [
  {
    id: "CO",
    nome: "COMPRADOR",
  },
  {
    id: "CC",
    nome: "CÔNJUGE COMPRADOR",
  },
  {
    id: "VD",
    nome: "VENDEDOR",
  },
  {
    id: "CV",
    nome: "CÔNJUGE VENDEDOR",
  },
  {
    id: "IM",
    nome: "IMÓVEL",
  },
];

export const tipoPessoa = [
  {
    id: "F",
    nome: "FÍSICA",
  },
  {
    id: "J",
    nome: "JURÍDICA",
  },
  {
    id: "N",
    nome: "AMBAS",
  },
];

export const condicoesMatrizChecklist = [
  {
    codigo: "PAD1",
    descricao: "Padrão I",
  },
  {
    codigo: "PAD2",
    descricao: "Padrão II",
  },
  {
    codigo: "CA77",
    descricao: "Casado antes de 1977",
  },
  {
    codigo: "CP77",
    descricao: "Casado após 1977",
  },
  {
    codigo: "UNES",
    descricao: "União Estável",
  },
  {
    codigo: "CEXT",
    descricao: "Casamento no Exterior",
  },
  {
    codigo: "TESA",
    descricao: "Tipo de Empresa SA",
  },
  {
    codigo: "TELT",
    descricao: "Tipo de Empresa LTDA",
  },
  {
    codigo: "INQI",
    descricao: "IQ",
  },
  {
    codigo: "COND",
    descricao: "Condomínio",
  },
  {
    codigo: "FORE",
    descricao: "Foreiro",
  },
  {
    codigo: "FGTS",
    descricao: "FGTS",
  },
  {
    codigo: "CART",
    descricao: "Cartorária",
  },
  {
    codigo: "UFIM",
    descricao: "UF do Imóvel",
  },
  {
    codigo: "REND",
    descricao: "Compõe Renda",
  },
];

export const tiposImovel = [
  "APARTAMENTO",
  "CASA",
  "SALA",
  "LOJA",
  "GALPÃO",
  "ESCRITORIO",
  "VAGA AUTONOMA",
  "DEPOSITO AUTONOMO",
  "LOTE",
  "TERRENO",
  "COMERCIAL",
  "LAZER",
  "CASA EM CONDOMINIO",
  "CASA COMERCIAL",
];

export const tipoClausula = [
  {
    id: "Q",
    nome: "QUALIFICAÇÃO",
  },
  {
    id: "P",
    nome: "EM TEMPO - BANCO",
  },
  {
    id: "G",
    nome: "EM TEMPO - COMARCA",
  },
];
