import { createSelector } from "reselect";

export const getPropostas = (state) => state.propostas;
export const getProposta = (state) => state.propostas.proposta;
export const getDetalhe = (state) => state.propostas.proposta.detalhe;
export const getLocation = (state) => state.location;
export const getSearch = (state) => state.propostas.search;

export const isChangingAnalyst = createSelector(
  getPropostas,
  (state) => state.changing
);

export const getPropostasLoading = createSelector(
  getPropostas,
  (state) => state.loading
);

export const getPropostasList = createSelector(
  getPropostas,
  (state) => state.list
);

export const getTotalPropostasList = createSelector(
  getPropostas,
  (state) => state.total
);

export const getFiltroPesquisa = createSelector(
  getPropostas,
  (state) => state.pesquisa
);
export const getFiltroSimplesPropostas = createSelector(
  getPropostas,
  (state) => state.filtroSimplesProposta
);
export const getFiltroExcludentePropostas = createSelector(
  getPropostas,
  (state) => state.filtroExcludenteProposta
);
export const getFiltroValores = createSelector(
  getPropostas,
  (state) => state.filtroValores
);
export const getPaginaAtual = createSelector(
  getPropostas,
  (state) => state.page
);

export const getExpanded = createSelector(
  getPropostas,
  (state) => state.expanded
);

export const getPropostaError = createSelector(
  getProposta,
  (state) => state.error
);
export const getDetalheProposta = createSelector(
  getProposta,
  (state) => state.detalhe
);
export const getPropostaLoading = createSelector(
  getProposta,
  (state) => state.loading
);
export const getMenuSelecionado = createSelector(
  getProposta,
  (state) => state["menu-selecionado"]
);

export const getParticipante = createSelector(
  getDetalhe,
  (state) => state?.participante
);
export const getImoveis = createSelector(getDetalhe, (state) => state.imovel);

export const getLoadingInclusaoParticipante = createSelector(
  getDetalhe,
  (state) => state?.participante?.confirmacao?.loading ?? false
);

export const getChecklist = createSelector(
  getProposta,
  (state) => state.checklist.list
);

export const getLoadingChecklist = createSelector(
  getProposta,
  (state) => state.checklist.loading
);
export const getInterveniente = createSelector(
  getDetalhe,
  (state) => state?.interveniente
);

export const getLoadingAtualizacaoInterveniente = createSelector(
  getDetalhe,
  (state) => state?.interveniente.loadingAtualizacao
);

export const getLoadingInterveniente = createSelector(
  getDetalhe,
  (state) => state?.interveniente.loading
);

export const getLocationPayload = createSelector(
  getLocation,
  (state) => state.payload
);

export const getWorkflow = createSelector(
  getProposta,
  (state) => state.workflow
);

export const getHistorico = createSelector(
  getProposta,
  (state) => state.historico
);

export const getExportHistorico = createSelector(
  getProposta,
  (state) => state.export_historico
);

export const getChecklistAlteracaoCadastral = createSelector(
  getProposta,
  (state) => state.checklistAlteracaoCadastral
);

export const getMinuta = createSelector(getProposta, (state) => state.minuta);

export const getUploadDocumentosBanco = createSelector(
  getProposta,
  (state) => state.uploadDocumentosBanco
);

export const getMenuHistorico = createSelector(
  getProposta,
  (state) => state.historico
);
