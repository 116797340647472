import { Button } from "components";
import {
  ControlledSelect,
  ControlledTextfield,
  DateTextField,
} from "../../../components/inputs";
import formatters from "../../../utils/formatters";
import React from "react";
import { MenuItem } from "../../../components";

const ExportSlaForm = ({
  formProps,
  handleSubmit,
  submitting,
  resultado,
  handleCleanFile,
  atividades,
}) => {
  const responsavel = [
    { key: "A", desc: "Agência" },
    { key: "B", desc: "BPO" },
    { key: "C", desc: "Crédito" },
    { key: "D", desc: "DEF" },
    { key: "O", desc: "DOC" },
    { key: "P", desc: "Patrimônio" },
    { key: "S", desc: "Seguradora" },
    { key: "L", desc: "Cliente" },
  ];

  const download = (fileBase64, momento_geracao) => {
    if (fileBase64 != null) {
      const outFile = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${fileBase64}`;
      const element = document.createElement("a");
      element.href = outFile;
      element.download = `exportacao_sla_${momento_geracao}.xlsx`;
      document.body.appendChild(element);
      element.click();
    }
  };
  if (resultado?.file) {
    download(resultado.file, resultado.momento_geracao);
    handleCleanFile();
  }
  return (
    <form
      className="d-flex flex-column"
      onSubmit={formProps.handleSubmit(handleSubmit)}
    >
      <div className={"row justify-content-center"}>
        <div className="col-xs-12 col-md-6 mt-3">
          <ControlledTextfield
            formProps={formProps}
            label={"Data Início"}
            name={"dataInicio"}
            margin={"none"}
            InputProps={{
              inputComponent: DateTextField,
            }}
          />
        </div>
        <div className="col-xs-12 col-md-6 mt-3">
          <ControlledTextfield
            formProps={formProps}
            label={"Data Fim"}
            name={"dataFim"}
            margin={"none"}
            InputProps={{
              inputComponent: DateTextField,
            }}
          />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 mt-0">
          <ControlledSelect
            name={"responsavel"}
            formProps={formProps}
            multiple={true}
            label={"Responsável"}
            margin={"none"}
          >
            {responsavel.map((item) => (
              <MenuItem
                className={"select-submenu-item"}
                key={item.key}
                value={item.key}
              >
                {item.desc}
              </MenuItem>
            ))}
          </ControlledSelect>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-12 mt-0">
          <ControlledSelect
            name={"atividade"}
            formProps={formProps}
            multiple={true}
            label={"Atividade"}
            margin={"none"}
          >
            {atividades.data
              .sort((a, b) => (a.nome > b.nome ? 1 : b.nome > a.nome ? -1 : 0))
              .map((item) => (
                <MenuItem
                  className={"select-submenu-item"}
                  key={item.id}
                  value={item.id}
                >
                  {item.nome}
                </MenuItem>
              ))}
          </ControlledSelect>
        </div>
      </div>
      <div className={"row justify-content-center"}>
        <div className="col-xs-12 col-md-6 mt-3">
          <Button loading={submitting} label={"DOWNLOAD"} type={"submit"} />
        </div>
      </div>
    </form>
  );
};

export default ExportSlaForm;
