import BasePage from "../../../BasePage";
import Loader from "../../../../components/loaders";
import React, { useCallback, useState } from "react";
import { Add } from "@material-ui/icons";
import Clausula from "./Clausula";
import Pagination from "@material-ui/lab/Pagination/Pagination";
import FormClausula from "./FormClausula.connected";
import ConfirmarExclusaoDialog from "./ConfirmarExclusaoDialog";
import Filtros from "./Filtros";

const ClausulaQualificacaoPage = ({
  loading,
  clausulas,
  matriz,
  item,
  total,
  pagina,
  handlePagination,
  handleNew,
  handleEdit,
  handleDelete,
  filtro,
  handleFilterClick,
  formProps,
  resetFilter,
}) => {
  const [dadosExclusao, setDadosExclusao] = useState({
    open: false,
  });

  const handleCloseExclusao = useCallback(() => {
    setDadosExclusao({
      open: false,
    });
  }, []);

  const handleOpenExclusao = useCallback((values) => {
    setDadosExclusao({
      open: true,
      item: values,
    });
  }, []);

  return (
    <BasePage isLoggedIn={true}>
      {!!loading && (
        <div className="row spacer">
          <div className="col-12 d-flex justify-content-center">
            <Loader type={"dots"} color={"brand"} size={80} />
          </div>
        </div>
      )}
      {!loading && !item && (
        <Filtros
          filtro={filtro}
          handleFilterClick={handleFilterClick}
          formProps={formProps}
          loading={loading}
          resetFilter={resetFilter}
        />
      )}
      {!loading && !item && (
        <div className="row spacer">
          <div className="col-12">
            <div className="card" style={{ minHeight: 100 }}>
              <div className="card-body d-flex flex-column">
                <div className={"row d-flex align-items-center"}>
                  <div className={"col mt-0"}>
                    <div className="card-title color-brand">
                      Matriz Cláusulas Qualificação
                    </div>
                  </div>
                </div>
                <div className={"row spacer header-matriz-minuta"}>
                  <div className="col-8 mt-lg-0 color-brand">
                    <span>CLÁUSULA</span>
                  </div>
                  <div className="col-2 mt-lg-0 color-brand">
                    <span>QUALIFICAÇÃO</span>
                  </div>
                  <div className="col-2 mt-lg-0 color-brand">
                    <span>TIPO PESSOA</span>
                  </div>
                  <div className="col-3 mt-lg-0 color-brand">
                    <span>ESTADO CIVIL</span>
                  </div>
                  <div className="col-2 mt-lg-0 color-brand">
                    <span>UNIÃO ESTÁVEL</span>
                  </div>
                  <div className="col-3 mt-lg-0 color-brand">
                    <span>REGIME CASAMENTO</span>
                  </div>
                  <div className="col-2 mt-lg-0 color-brand">
                    <span>DATA CASAMENTO</span>
                  </div>
                  <div className="col-2 mt-lg-0 color-brand">
                    <span>CÔNJUGE PARTICIPA</span>
                  </div>
                </div>
                <div className={"row"}>
                  <div className="col-12">
                    {matriz.map((d, index) => (
                      <Clausula
                        key={d.id}
                        item={d}
                        index={index}
                        clausulas={clausulas}
                        handleEdit={handleEdit}
                        handleOpenExclusao={handleOpenExclusao}
                      />
                    ))}
                  </div>
                </div>
                <div className="row spacer">
                  <div className="col-12 offset-md-9 col-md-3">
                    <div className="d-none d-md-block">
                      <div className="botao-adicionar mr-3" onClick={handleNew}>
                        <Add className="botao-adicionar-icon mr-1" />
                        <span className="botao-adicionar-text">ADICIONAR</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {!loading && !item && total > 10 && (
        <>
          <div className="row spacer">
            <div className="col-12">
              <Pagination
                count={Math.ceil(total / 10)}
                page={pagina}
                variant="outlined"
                shape="rounded"
                onChange={handlePagination}
              />
            </div>
          </div>
        </>
      )}
      {!loading && !!item && (
        <div>
          <FormClausula />
        </div>
      )}
      <ConfirmarExclusaoDialog
        open={dadosExclusao.open}
        handleClose={handleCloseExclusao}
        excluir={handleDelete}
        item={dadosExclusao.item}
      />
    </BasePage>
  );
};

export default ClausulaQualificacaoPage;
