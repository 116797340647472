import { actions } from "./painelIntegracao.actions";

const initialState = {
  "documentos-erro": {
    loading: true,
    error: null,
    list: [],
    page: 1,
    filter: {
      documento: null,
      consulta: null,
    },
    listaFiltros: [],
  },
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.listarDocumentosErro.REQUEST:
    case actions.listarDocumentosErro.SUCCESS:
    case actions.listarDocumentosErro.FAILURE:
      return {
        ...state,
        "documentos-erro": {
          ...state["documentos-erro"],
          loading: action.type === actions.listarDocumentosErro.REQUEST,
          error:
            action.type === actions.listarDocumentosErro.FAILURE
              ? action.payload.error
              : null,
          list:
            action.type === actions.listarDocumentosErro.SUCCESS
              ? action.payload.response.data.list
              : [],
          total:
            action.type === actions.listarDocumentosErro.SUCCESS
              ? action.payload.response.data.total
              : null,
          page:
            action.type === actions.listarDocumentosErro.REQUEST
              ? action.payload.page
              : action.payload.response.data.page,
          filter:
            action.type === actions.listarDocumentosErro.REQUEST
              ? action.payload.filter
              : action.payload.original.filter,
          listaFiltros:
            action.type === actions.listarDocumentosErro.SUCCESS
              ? action.payload.response.data.listaFiltros
              : state["documentos-erro"].listaFiltros,
        },
      };
    default:
      return state;
  }
};

export default reducer;
