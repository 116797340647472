import React, { useCallback, useState } from "react";
import {
  ControlledSelect,
  ControlledTextfield,
  IntegerTextField,
  ControlledSwitch,
  ControlledAutocomplete,
} from "../../../components/inputs";
import { Button, MenuItem } from "../../../components";

const FormAtividade = ({
  props,
  disabled,
  handleSubmit,
  atividade,
  etapa,
  templates,
  tipoCondicaoCriacao,
  tipoResponsavel,
  voltarParaLista,
}) => {
  const [isProducao, setIsProducao] = useState(atividade.isProducao);

  const handleClickNext = useCallback(
    (values) => {
      handleSubmit(values, atividade.id);
    },
    [handleSubmit, atividade]
  );

  return (
    <div className="card spacer">
      <div className="card-body">
        <span className="card-title color-brand">Atividade</span>
        <form onSubmit={props.handleSubmit(handleClickNext)}>
          <div className="row">
            <div className="col-12 col-md-3">
              <ControlledTextfield
                name={"codigo"}
                margin={"none"}
                disabled={disabled}
                formProps={props}
                label={"Código"}
                InputProps={{
                  inputComponent: IntegerTextField,
                }}
              />
            </div>
            <div className="col-12 col-md-9">
              <ControlledTextfield
                name={"nome"}
                formProps={props}
                uppercase={false}
                disabled={disabled}
                label={"Atividade"}
                margin={"none"}
                inputProps={{ maxLength: 100 }}
              />
            </div>
            <div className="col-12 col-md-6">
              <ControlledSelect
                name={"Etapa.id"}
                formProps={props}
                label={"Etapa"}
                margin={"none"}
              >
                {etapa.map((item) => (
                  <MenuItem
                    className={"select-submenu-item"}
                    key={item.id}
                    value={item.id}
                  >
                    {item.nome.toUpperCase()}
                  </MenuItem>
                ))}
              </ControlledSelect>
            </div>
            <div className="col-12 col-md-6">
              <ControlledSelect
                name={"condicaoCriacao"}
                formProps={props}
                label={"Condição Criação"}
                margin={"none"}
              >
                {tipoCondicaoCriacao.map((item) => (
                  <MenuItem
                    className={"select-submenu-item"}
                    key={item.id}
                    value={item.id}
                  >
                    {item.descricao}
                  </MenuItem>
                ))}
              </ControlledSelect>
            </div>
            <div className="col-12 col-md-6">
              <ControlledSelect
                name={"responsavel"}
                formProps={props}
                label={"Responsável"}
                margin={"none"}
              >
                {tipoResponsavel.map((item) => (
                  <MenuItem
                    className={"select-submenu-item"}
                    key={item.id}
                    value={item.id}
                  >
                    {item.descricao}
                  </MenuItem>
                ))}
              </ControlledSelect>
            </div>
            <div className="col-12 col-md-3">
              <ControlledTextfield
                name={"ordem"}
                margin={"none"}
                disabled={disabled}
                formProps={props}
                label={"Ordem"}
                InputProps={{
                  inputComponent: IntegerTextField,
                }}
              />
            </div>
            <div className="col-12 col-md-3">
              <ControlledTextfield
                name={"sla"}
                margin={"none"}
                disabled={disabled}
                formProps={props}
                label={"SLA (dias)"}
                InputProps={{
                  inputComponent: IntegerTextField,
                }}
              />
            </div>
            <div className="col-12">
              <ControlledAutocomplete
                name={"templates"}
                formProps={props}
                label={"Templates de Email"}
                labelField={"nome"}
                margin={"none"}
                domain={templates}
                autocompleteProps={{
                  multiple: true,
                }}
              />
            </div>
            <div className="col-12 col-md-3">
              <ControlledSwitch
                name={"isProducao"}
                formProps={props}
                onChange={() => setIsProducao(!isProducao)}
                label={"É Produção?"}
                margin={"none"}
              />
            </div>
            <div className="col-12 col-md-3">
              <ControlledSwitch
                name={"isComentarioObrigatorio"}
                formProps={props}
                label={"Comentário Obrigatório?"}
                margin={"none"}
              />
            </div>

            <div className="col-12 col-md-12">
              <ControlledTextfield
                name={"orientacao"}
                formProps={props}
                uppercase={false}
                disabled={disabled}
                label={"Orientação"}
                margin={"none"}
                inputProps={{ maxLength: 4000 }}
                rowsMax={16}
                rows={12}
                multiline={true}
              />
            </div>
          </div>
          <div className="row spacer">
            <div className="offset-md-2 col-md-4 col-12">
              <Button
                label="Voltar"
                onClick={voltarParaLista}
                loading={atividade.loading}
              />
            </div>
            <div className="col-md-4 col-12">
              <Button
                label="CONFIRMAR"
                type={"submit"}
                loading={atividade.loading}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormAtividade;
