import React, { useCallback } from "react";
import { Button, MenuItem } from "../../../components";
import {
  ControlledAutocomplete,
  ControlledSelect,
} from "../../../components/inputs";

const Filtros = ({ handleFilterClick, formProps, consultas, documentos }) => {
  const handleFilter = useCallback(
    (values) => {
      handleFilterClick(values);
    },
    [handleFilterClick]
  );

  const handleResetForm = () => {
    formProps.setValue("documentos", "");
    formProps.setValue("consultas", "");
  };

  return (
    <form onSubmit={formProps.handleSubmit(handleFilter)}>
      <div className="card">
        <div className={"card-body"}>
          <div className={"row"}>
            <div className="col-12 card-subtitle-checklist mt-0">
              <span className="">Filtros:</span>
            </div>
          </div>
          <>
            <div className="row">
              <div className="col-4 mt-0">
                <ControlledAutocomplete
                  name={"consultas"}
                  formProps={formProps}
                  label={"Consulta"}
                  margin={"none"}
                  labelField={"label"}
                  domain={consultas
                    .filter(function (este, i) {
                      return consultas.indexOf(este) === i;
                    })
                    .map((item) => {
                      return {
                        id: item,
                        label: item,
                      };
                    })}
                />
              </div>
              <div className="col-8 mt-0">
                <ControlledSelect
                  name={"documentos"}
                  formProps={formProps}
                  label={"Documento"}
                  margin={"none"}
                >
                  {documentos
                    .filter(function (este, i) {
                      return documentos.indexOf(este) === i;
                    })
                    .map((item, index) => (
                      <MenuItem
                        className={"select-submenu-item"}
                        key={index}
                        value={item}
                      >
                        {item}
                      </MenuItem>
                    ))}
                </ControlledSelect>
              </div>
            </div>
            <div className="row spacer">
              <div className="offset-md-6 col-md-3 col-12">
                <Button label="LIMPAR" onClick={handleResetForm} />
              </div>
              <div className="col-md-3 col-12">
                <Button label="CONFIRMAR" type={"submit"} />
              </div>
            </div>
          </>
        </div>
      </div>
    </form>
  );
};

export default Filtros;
