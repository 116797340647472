import React from "react";
import { Dialog, DialogContent, IconButton } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { CloseSharp } from "@material-ui/icons";
import { Button } from "../../../../components";
import validators from "../../../../utils/validators";
import {
  ControlledTextfield,
  DateTextField,
} from "../../../../components/inputs";

const ConfirmarDialog = ({ handleClose, open, handleSubmit }) => {
  const rules = {
    dataContrato: validators.date({
      required: true,
      // custom: {
      //   beforeToday: (value) =>
      //     new Date(
      //       `${value.split("/")[2]}-${value.split("/")[1]}-${
      //         value.split("/")[0]
      //       }`
      //     ) <= new Date() || "A data não pode ser futura",
      // },
    }),
  };
  const initialValues = {
    dataContrato: null,
  };
  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const handleClick = (event) => {
    handleSubmit(event.dataContrato);
    handleClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      classes={{ paper: "w100" }}
      maxWidth="md"
      open={open}
    >
      {!!open && (
        <DialogContent>
          <IconButton
            onClick={handleClose}
            classes={{ root: "position-absolute clickable close-icon" }}
          >
            <CloseSharp />
          </IconButton>
          <div className={"page-title"}>Informe a data do contrato.</div>
          <div className={"mb-3"}>
            <form onSubmit={formProps.handleSubmit(handleClick)}>
              <div className="row d-flex justify-content-center">
                <div className="col-12 col-md-6 col-lg-9">
                  <ControlledTextfield
                    formProps={formProps}
                    label={"Data do Contrato"}
                    name={"dataContrato"}
                    margin={"none"}
                    InputProps={{
                      inputComponent: DateTextField,
                    }}
                  />
                </div>
              </div>
              <div className="row spacer justify-content-center">
                <div className="col-md-4 col-12">
                  <Button label="CONFIRMAR" type="submit" />
                </div>
              </div>
            </form>
          </div>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default ConfirmarDialog;
