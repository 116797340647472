import React, { useCallback, useState } from "react";
import {
  ControlledSelect,
  ControlledTextfield,
  IntegerTextField,
} from "../../../components/inputs";
import { Button, MenuItem } from "../../../components";

const FormUsuario = ({
  props,
  disabled,
  handleSubmit,
  perfil,
  usuario,
  getOrganizacao,
  listaSituacoes,
  voltarParaLista,
  perfilUsuario,
}) => {
  const disableField = perfilUsuario !== "ADMINISTRADOR" || disabled;

  const handleClickNext = useCallback(
    (values) => {
      handleSubmit(values, usuario.id);
    },
    [handleSubmit, usuario]
  );

  const [isAgencia, setIsAgencia] = useState(usuario.idPerfil === 3);
  const [isParceiro, setIsParceiro] = useState(usuario.idPerfil === 5);

  const handleGetAgencia = useCallback(
    (values) => {
      getOrganizacao(values + "A");
    },
    [getOrganizacao]
  );

  const handleGetParceiro = useCallback(
    (values) => {
      getOrganizacao(values + "P");
    },
    [getOrganizacao]
  );

  const handleIsOrganizacao = () => {
    setIsAgencia(props.getValues("perfil") === 3);
    setIsParceiro(props.getValues("perfil") === 5);

    props.setValue("nome-organizacao", "");
    props.setValue("codigo-organizacao", "");
  };

  return (
    <div className="card spacer">
      <div className="card-body">
        <span className="card-title color-brand">Usuário</span>
        <form onSubmit={props.handleSubmit(handleClickNext)}>
          <div className="row">
            <div className="col-12 col-md-6">
              <ControlledTextfield
                name={"codigo"}
                margin={"none"}
                uppercase={false}
                disabled={disableField}
                formProps={props}
                label={"Usuário"}
              />
            </div>
            <div className="col-12 col-md-6">
              <ControlledTextfield
                name={"email"}
                formProps={props}
                uppercase={false}
                disabled={disabled}
                label={"E-Mail"}
                margin={"none"}
              />
            </div>
            <div className="col-12 col-md-6">
              <ControlledTextfield
                formProps={props}
                margin={"none"}
                disabled={disableField}
                name={"nome"}
                label={"Nome"}
                inputProps={{ maxLength: 40 }}
              />
            </div>
            <div className="col-12 col-md-3">
              <ControlledSelect
                name={"perfil"}
                formProps={props}
                disabled={props.getValues("perfil") === 1 || disableField}
                label={"Perfil"}
                margin={"none"}
                onChange={handleIsOrganizacao}
              >
                {perfil.map((item) => (
                  <MenuItem
                    className={"select-submenu-item"}
                    key={item.id}
                    value={item.id}
                  >
                    {item.tipo}
                  </MenuItem>
                ))}
              </ControlledSelect>
            </div>
            <div className="col-12 col-md-3">
              <ControlledSelect
                name={"situacao"}
                formProps={props}
                disabled={props.getValues("situacao") === "A" || disableField}
                label={"Situação"}
                margin={"none"}
              >
                {listaSituacoes.map((item) => (
                  <MenuItem
                    className={"select-submenu-item"}
                    key={item.id}
                    value={item.id}
                  >
                    {item.descricao}
                  </MenuItem>
                ))}
              </ControlledSelect>
            </div>
            {(isAgencia || isParceiro) && (
              <>
                <div className="col-12 col-md-4">
                  <ControlledTextfield
                    name={"codigo-organizacao"}
                    formProps={props}
                    disabled={disableField}
                    label={isAgencia ? "Agência" : "Parceiro"}
                    margin={"none"}
                    InputProps={{
                      inputComponent: IntegerTextField,
                    }}
                    onBlur={isAgencia ? handleGetAgencia : handleGetParceiro}
                  />
                </div>
                <div className="col-12 col-md-8">
                  <ControlledTextfield
                    formProps={props}
                    margin={"none"}
                    disabled={true}
                    name={"nome-organizacao"}
                    label={"Nome"}
                    inputProps={{ maxLength: 40 }}
                  />
                </div>
              </>
            )}
            {/* {isParceiro && (
              <>
                <div className="col-12 col-md-4">
                  <ControlledTextfield
                    name={"codigo-organizacao"}
                    formProps={props}
                    disabled={disabled}
                    label={"Parceiro"}
                    margin={"none"}
                    InputProps={{
                      inputComponent: IntegerTextField,
                    }}
                    onBlur={handleGetParceiro}
                  />
                </div>
                <div className="col-12 col-md-8">
                  <ControlledTextfield
                    formProps={props}
                    margin={"none"}
                    disabled={true}
                    name={"nome-organizacao"}
                    label={"Nome"}
                    inputProps={{ maxLength: 40 }}
                  />
                </div>
              </>
            )} */}
          </div>
          <div className="row spacer">
            <div className="offset-md-2 col-md-4 col-12">
              <Button
                label="Voltar"
                onClick={voltarParaLista}
                loading={usuario.loading}
              />
            </div>

            {perfilUsuario === "ADMINISTRADOR" && (
              <div className="col-md-4 col-12">
                <Button
                  label="CONFIRMAR"
                  type={"submit"}
                  loading={usuario.loading}
                />
              </div>
            )}
            {perfilUsuario === "BPO" && (
              <div className="col-md-4 col-12">
                <Button
                  label="REENVIAR E-MAIL"
                  type={"submit"}
                  loading={usuario.loading}
                />
              </div>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormUsuario;
