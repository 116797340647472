import {
  ControlledSelect,
  ControlledTextfield,
  DecimalTextField,
  IntegerTextField,
} from "../../../../../components/inputs";
import { MenuItem } from "../../../../../components";
import React from "react";

const CardRegistroMatricula = ({ props, disabled, dominioCartorios }) => {
  return (
    <div className="card spacer">
      <div className="card-body">
        <span className="card-title color-brand">Registro (Matrícula)</span>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-9">
            <ControlledTextfield
              name={"registroAquisicao"}
              disabled={disabled}
              margin={"none"}
              formProps={props}
              inputProps={{ maxLength: 20 }}
              label={"Registro de Aquisição"}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <ControlledTextfield
              name={"matricula"}
              disabled={disabled}
              margin={"none"}
              formProps={props}
              label={"Número da Matrícula"}
              inputProps={{ maxLength: 13 }}
            />
          </div>
          <div className="col-12 col-md-9">
            <ControlledTextfield
              name={"comarca"}
              disabled={disabled}
              margin={"none"}
              formProps={props}
              inputProps={{ maxLength: 100 }}
              label={"Comarca Imóvel"}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <ControlledTextfield
              name={"inscricaoImobiliaria"}
              disabled={disabled}
              margin={"none"}
              formProps={props}
              label={"Inscrição Imobiliária"}
              InputProps={{ inputComponent: IntegerTextField }}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-12">
            <ControlledTextfield
              name={"cartorioMinuta"}
              disabled={disabled}
              formProps={props}
              label={"Cartório"}
              margin={"none"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardRegistroMatricula;
