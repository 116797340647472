import React from "react";
import { useSelector } from "react-redux";
import queryString from "query-string";
import { connectRoutes, NOT_FOUND } from "redux-first-router";
import restoreScroll from "redux-first-router-restore-scroll";
import { getCurrentRoute } from "./selectors/rotas.selectors";
import { getPerfil, isUsuarioLogado } from "./selectors/usuario.selectors";
import {
  types as routes,
  actions as routeActions,
} from "./reducers/rotas.actions";
import * as pages from "./business/pages";
import { toast } from "react-toastify";

const routePages = {
  [routes.LOGIN]: {
    component: pages.LoginPage,
  },
  [routes.ESQUECI_SENHA]: {
    component: pages.EsqueciSenhaPage,
  },
  [routes.TROCAR_SENHA]: {
    component: pages.TrocarSenhaPage,
  },
  [routes.PROPOSTAS]: {
    component: pages.PropostasPage,
    restricted: [],
  },
  [routes.CARGA_CONSULTAS]: {
    component: pages.Administracao.CargaConsultasPage,
    restricted: ["ADMINISTRADOR"],
  },
  [routes.PROPOSTA]: {
    component: pages.PropostaPage,
    restricted: [],
  },
  [routes.ENVIO_EMAIL]: {
    component: pages.EnvioEmailPage,
    restricted: ["BPO"],
  },
  [routes.ARTEFATOS_DOCUMENTO]: {
    component: pages.ArtefatosDocumentoPage,
    restricted: [],
  },
  [routes.PARTICIPANTE]: {
    component: pages.FormParticipante,
    restricted: [],
  },
  [routes.IMOVEL]: {
    component: pages.ImovelPage,
    restricted: [],
  },
  [routes.NOVO_PARTICIPANTE]: {
    component: pages.FormParticipante,
    restricted: [],
  },
  [routes.INTERVENIENTE_QUITANTE]: {
    component: pages.FormInterveniente,
    restricted: [],
  },
  [routes.ADMINISTRACAO_USUARIOS]: {
    component: pages.Administracao.UsuariosPage,
    restricted: ["ADMINISTRADOR", "BPO"],
  },
  [routes.ADMINISTRACAO_DOCUMENTOS]: {
    component: pages.Administracao.DocumentosPage,
    restricted: ["ADMINISTRADOR"],
  },
  [routes.ADMINISTRACAO_ATIVIDADES]: {
    component: pages.Administracao.AtividadesPage,
    restricted: ["ADMINISTRADOR"],
  },
  [routes.ADMINISTRACAO_MCHECKLIST]: {
    component: pages.Administracao.MatrizPage,
    restricted: ["ADMINISTRADOR"],
  },
  [routes.ADMINISTRACAO_ETAPAS]: {
    component: pages.Administracao.EtapasPage,
    restricted: ["ADMINISTRADOR"],
  },
  [routes.ADMINISTRACAO_PRODUTOS]: {
    component: pages.Administracao.ProdutosPage,
    restricted: ["ADMINISTRADOR"],
  },
  [routes.ADMINISTRACAO_PERFIS]: {
    component: pages.Administracao.PerfisPage,
    restricted: ["ADMINISTRADOR"],
  },
  [routes.ADMINISTRACAO_PERFIS]: {
    component: pages.Administracao.PerfisPage,
    restricted: ["ADMINISTRADOR"],
  },
  [routes.ADMINISTRACAO_INTEGRA_DOC]: {
    component: pages.Administracao.DocumentosErroIntegracao,
    restricted: ["ADMINISTRADOR", "BPO"],
  },
  [routes.NOT_FOUND]: {
    component: pages.NotFoundPage,
  },
  [NOT_FOUND]: {
    component: pages.NotFoundPage,
  },
  [routes.EXPORT_CONSULTAS]: {
    component: pages.Administracao.ExportConsultasPage,
    restricted: ["ADMINISTRADOR", "BANCO GESTAO"],
  },
  [routes.ADMINISTRACAO_ORGANIZACAO]: {
    component: pages.Administracao.OrganizacaoPage,
    restricted: ["ADMINISTRADOR"],
  },
  [routes.EXPORT_SLA]: {
    component: pages.Administracao.ExportSlaPage,
    restricted: ["ADMINISTRADOR", "BPO", "BANCO GESTAO"],
  },
  [routes.DASHBOARD]: {
    component: pages.DashboardPage,
    restricted: ["ADMINISTRADOR", "BPO", "BANCO GESTAO", "BANCO"],
  },
  [routes.EXPORT_SLA]: {
    component: pages.Administracao.ExportSlaPage,
    restricted: ["ADMINISTRADOR", "BPO", "BANCO GESTAO", "BANCO"],
  },
  [routes.ADMINISTRACAO_LOCALIDADES]: {
    component: pages.Administracao.LocalidadesPage,
    restricted: ["ADMINISTRADOR"],
  },
  [routes.ADMINISTRACAO_MOTIVO_CANCELAMENTO]: {
    component: pages.Administracao.MotivoCancelamentoPage,
    restricted: ["ADMINISTRADOR"],
  },
  [routes.TOKEN]: {
    component: pages.TokenPage,
  },
  [routes.ADMINISTRACAO_CLAUSULAS]: {
    component: pages.Administracao.ClausulasPage,
    restricted: ["ADMINISTRADOR"],
  },
  [routes.ADMINISTRACAO_MATRIZ_CLAUSULAS]: {
    component: pages.Administracao.MatrizClausulasPage,
    restricted: ["ADMINISTRADOR"],
  },
  [routes.ADMINISTRACAO_TEMPLATES]: {
    component: pages.Administracao.TemplateEmailPage,
    restricted: ["ADMINISTRADOR"],
  },
  [routes.ADMINISTRACAO_RELATORIO_AUDITORIA]: {
    component: pages.Administracao.RelatorioAuditoriaPage,
    restricted: ["ADMINISTRADOR"],
  },
};
export const routePaths = {
  [routes.LOGIN]: "/",
  [routes.NOT_FOUND]: "/acesso-negado",
  [routes.ESQUECI_SENHA]: "/esqueci-senha",
  [routes.TROCAR_SENHA]: "/trocar-senha/:token",
  [routes.PROPOSTAS]: "/propostas",
  [routes.ARTEFATOS_DOCUMENTO]: "/artefatos-documento/:id",
  [routes.CARGA_CONSULTAS]: "/administracao/carga-consultas",
  [routes.PROPOSTA]: "/proposta/:id",
  [routes.ENVIO_EMAIL]: "/proposta/:proposta/envio-email/:atividade",
  [routes.PARTICIPANTE]: "/proposta/:id/participante/:idParticipante",
  [routes.IMOVEL]: "/proposta/:proposta/imovel/:id",
  [routes.NOVO_PARTICIPANTE]: "/proposta/:id/novo-participante",
  [routes.INTERVENIENTE_QUITANTE]: "/proposta/:id/interveniente",
  [routes.ADMINISTRACAO_USUARIOS]: "/administracao/usuarios",
  [routes.ADMINISTRACAO_ORGANIZACAO]: "/administracao/organizacao",
  [routes.EXPORT_CONSULTAS]: "/administracao/exportar-consultas",
  [routes.ADMINISTRACAO_DOCUMENTOS]: "/administracao/documentos",
  [routes.ADMINISTRACAO_ATIVIDADES]: "/administracao/atividades",
  [routes.ADMINISTRACAO_ETAPAS]: "/administracao/etapas",
  [routes.ADMINISTRACAO_MCHECKLIST]: "/administracao/matriz-checklist",
  [routes.ADMINISTRACAO_PRODUTOS]: "/administracao/produtos",
  [routes.ADMINISTRACAO_PERFIS]: "/administracao/perfis",
  [routes.EXPORT_SLA]: "/administracao/exportar-sla",
  [routes.DASHBOARD]: "/dashboard",
  [routes.EXPORT_SLA]: "/administracao/exportar-sla",
  [routes.ADMINISTRACAO_LOCALIDADES]: "/administracao/localidades",
  [routes.ADMINISTRACAO_MOTIVO_CANCELAMENTO]:
    "/administracao/motivo-cancelamento",
  [routes.TOKEN]: "/token/",
  [routes.ADMINISTRACAO_CLAUSULAS]: "/administracao/clausulas",
  [routes.ADMINISTRACAO_MATRIZ_CLAUSULAS]: "/administracao/matriz-clausulas",
  [routes.ADMINISTRACAO_TEMPLATES]: "/administracao/templates",
  [routes.ADMINISTRACAO_INTEGRA_DOC]:
    "/administracao/integracao/erros-documentos",
  [routes.ADMINISTRACAO_RELATORIO_AUDITORIA]:
    "/administracao/relatorio/auditoria",
};

const { reducer, middleware, enhancer } = connectRoutes(routePaths, {
  querySerializer: queryString,
  restoreScroll: restoreScroll(),
  notFoundPath: "/erro",
  onBeforeChange: (dispatch, getState, { action }) => {
    const route = routePages[action.type] ?? routePages[NOT_FOUND];
    if (route.restricted !== undefined) {
      const state = getState();
      if (!isUsuarioLogado(state)) {
        dispatch(routeActions.rejectTo(routes.LOGIN));
      } else {
        const perfil = getPerfil(state);
        if (!canAccessRestrictedRoute(route, perfil)) {
          toast.error("Acesso Negado");
          dispatch(routeActions.rejectTo(routes.PROPOSTAS));
        }
      }
    }
  },
});

export { reducer, middleware, enhancer };

const Container = () => {
  const routeCode = useSelector(getCurrentRoute);
  const route = routePages[routeCode] ?? routePages[NOT_FOUND];
  const Component = route.component;

  return <Component />;
};

const canAccessRestrictedRoute = (route, perfil) => {
  if (!perfil) {
    return false;
  }
  if (route.restricted === true) {
    return true;
  }
  if (Array.isArray(route.restricted)) {
    if (
      route.restricted.length === 0 ||
      route.restricted.indexOf(perfil) > -1
    ) {
      return true;
    }
  }
  return false;
};

export default Container;
