import {
  createAsyncAction,
  createSyncAction,
} from "../../utils/actionCreators";

export const types = {
  LISTAR_ATIVIDADES: "@@administracao/LISTAR_ATIVIDADES",
  LISTAR_CONDICAO_CRIACAO: "@@administracao/LISTAR_CONDICAO_CRIACAO",
  LISTAR_RESPONSAVEL: "@@administracao/LISTAR_RESPONSAVEL",
  ATUALIZAR_ATIVIDADE: "@@administracao/ATUALIZAR_ATIVIDADE",
  GET_ETAPA: "@@administracao/GET_ETAPA",
  NOVA_ATIVIDADE: "@@administracao/NOVA_ATIVIDADE",
  EDITAR_ATIVIDADE: "@@administracao/EDITAR_ATIVIDADE",
  VOLTAR_LISTA: "@@/administracao/VOLTAR_LISTA",
  INCLUIR_ATIVIDADE: "@@administracao/INCLUIR_ATIVIDADE",
  SEARCH_ATIVIDADE: "@@administracao/SEARCH_ATIVIDADE",
  EXCLUIR_ATIVIDADE: "@@administracao/EXCLUIR_ATIVIDADE",
};

export const actions = {
  listarAtividades: createAsyncAction(types.LISTAR_ATIVIDADES),
  listarCondicaoCriacao: createAsyncAction(types.LISTAR_CONDICAO_CRIACAO),
  listarResponsavel: createAsyncAction(types.LISTAR_RESPONSAVEL),
  atualizarAtividade: createAsyncAction(types.ATUALIZAR_ATIVIDADE),
  getEtapa: createAsyncAction(types.GET_ETAPA),
  novaAtividade: () => createSyncAction(types.NOVA_ATIVIDADE),
  editarAtividade: (values) => createSyncAction(types.EDITAR_ATIVIDADE, values),
  voltarListaAtividade: () => createSyncAction(types.VOLTAR_LISTA),
  incluirAtividade: createAsyncAction(types.INCLUIR_ATIVIDADE),
  buscarAtividade: createAsyncAction(types.SEARCH_ATIVIDADE),
  excluirAtividade: createAsyncAction(types.EXCLUIR_ATIVIDADE),
};
