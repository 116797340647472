import React, { useCallback } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import {
  EditOutlined,
  DeleteOutlined,
  LockOpenOutlined,
} from "@material-ui/icons";

const Usuario = ({ usuario, index, handleEdit, inativarUsuario, perfil }) => {
  const handleDelete = useCallback(
    (usuario) => {
      inativarUsuario(usuario.id);
    },
    [usuario]
  );

  return (
    <div
      className={
        index % 2 === 0
          ? "row mt-0 d-flex align-items-center medium-row tr-even-adm"
          : "row mt-0 d-flex align-items-center medium-row tr-odd"
      }
    >
      <div className="col-3 col-md-3 mt-sm-2 mt-lg-0 user-table ">
        <span>{usuario.codigo}</span>
      </div>
      <div className="col-3 col-md-3 my-2 my-lg-0 user-table">
        <span>{usuario.nome}</span>
      </div>
      <div className="col-2 col-md-2  my-2 my-lg-0 user-table">
        <span>{usuario.perfil.tipo}</span>
      </div>
      <div className="col-3 col-md-3 my-2 my-lg-0 user-table">
        <span>
          {usuario.situacao === "A"
            ? "ATIVO"
            : usuario.situacao === "B"
            ? "BLOQUEADO"
            : usuario.situacao === "I"
            ? "INATIVO"
            : "BLOQUEADO"}
        </span>
      </div>
      <Tooltip title="Editar">
        <IconButton className="p-2" onClick={() => handleEdit(usuario)}>
          <EditOutlined className={"color-black"} />
        </IconButton>
      </Tooltip>
      {perfil === "ADMINISTRADOR" && usuario.situacao === "A" && (
        <Tooltip title="Inativar">
          <IconButton className="p-2" onClick={() => handleDelete(usuario)}>
            <DeleteOutlined className={"color-black"} />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

export default Usuario;
