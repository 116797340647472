import { useCallback, useState } from "react";
import ImovelPage from "./ImovelPage";
import { useSelector, useDispatch } from "react-redux";
import { getImovel } from "../../../../../selectors/imovel.selectors";
import { getPerfil } from "../../../../../selectors/usuario.selectors";
import { actions } from "../../../../../reducers/imovel.actions";
import { actions as propostaActions } from "../../../../../reducers/propostas.actions";
import { uf } from "../../../../../utils/parametros";
import { useForm } from "react-hook-form";
import validators from "../../../../../utils/validators";
import { getCartorios } from "../../../../../selectors/dominios.selectors";
import {
  actions as rotasActions,
  types as routes,
} from "../../../../../reducers/rotas.actions";

import { getDetalheProposta } from "../../../../../selectors/propostas.selectors";

const ImovelPageConnected = () => {
  const dispatch = useDispatch();
  const { loading, error, submitting, data } = useSelector(getImovel);
  const perfil = useSelector(getPerfil);
  const { data: dominioCartorios } = useSelector(getCartorios);
  const detalheProposta = useSelector(getDetalheProposta);

  const rules = {
    cep: validators.string({ required: true }),
    logradouro: validators.string({ required: true }),
    numero: validators.string({ required: true }),
    bairro: validators.string({ required: true }),
    municipio: validators.string({ required: true }),
    uf: validators.string({ required: true }),
  };
  const initialValues = {
    cep: data?.cep || "",
    logradouro: data?.logradouro || "",
    numero: data?.numero || "",
    complemento: data?.complemento || "",
    bairro: data?.bairro || "",
    municipio: data?.municipio || "",
    uf: data?.uf || "",
    valorAvaliacao: Number(data?.valorAvaliacao) || "",
    foreiro: data?.foreiro === "S",
    condominio: data?.condominio === "S",
    cartorio: data?.cartorio || "",
    tipoImovel: data?.tipoImovel || "",
    areaTotal: data?.areaTotal || "",
    empreendimento: data?.empreendimento || "",
    edificio: data?.edificio || "",
    andar: data?.andar || "",
    vagaDeposito: data?.vagaDeposito || "",
    areaComum: data?.areaComum || "",
    fracaoIdeal: data?.fracaoIdeal || "",
    registroAquisicao: data?.registroAquisicao || "",
    matricula: data?.matricula || "",
    comarca: data?.comarca || "",
    inscricaoImobiliaria: data?.inscricaoImobiliaria || "",
    cartorioMinuta: data?.cartorioMinuta || "",
  };
  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const [dominioCartoriosFiltrado, setDominioCartoriosFiltrado] = useState([]);
  const [didMount, setDidMount] = useState(false);

  const filtrarCartorios = useCallback(
    (values) => {
      setDominioCartoriosFiltrado(
        dominioCartorios.filter((cartorio) => {
          if (
            cartorio.uf === values.uf?.toUpperCase() &&
            cartorio.municipio === values.municipio?.toUpperCase()
          )
            return cartorio;
        })
      );
    },
    [dispatch, dominioCartorios, setDominioCartoriosFiltrado]
  );

  const successCallback = useCallback(
    ({ data }, value) => {
      formProps.setValue("logradouro", data?.logradouro || "");
      formProps.setValue("bairro", data?.bairro || "");
      formProps.setValue("uf", data?.uf || "");
      formProps.setValue("municipio", data?.localidade || "");
      filtrarCartorios({ uf: data?.uf, municipio: data?.localidade });
    },
    [formProps, filtrarCartorios]
  );
  const handleZipCodeBlur = useCallback(
    (value) => {
      if (!!value && value.length === 9) {
        dispatch(
          propostaActions.consultarCepCorreios.request({
            value,
            successCallback,
          })
        );
      }
    },
    [dispatch, successCallback]
  );

  const handleSubmit = useCallback(
    (values) => {
      dispatch(
        actions.gravarImovel.request({
          ...values,
          cartorio: values?.cartorio === "" ? null : values?.cartorio,
          registroAquisicao: values?.registroAquisicao?.toUpperCase(),
          comarca: values?.comarca?.toUpperCase(),
          cartorioMinuta:
            values?.cartorioMinuta === ""
              ? null
              : values?.cartorioMinuta.toUpperCase(),
          vagaDeposito:
            values?.tipoImovel === "APARTAMENTO"
              ? values?.vagaDeposito?.toUpperCase()
              : "",
          andar:
            values?.tipoImovel === "APARTAMENTO"
              ? values?.andar?.toUpperCase()
              : "",
          edificio:
            values?.tipoImovel === "APARTAMENTO"
              ? values?.edificio?.toUpperCase()
              : "",
          empreendimento:
            values?.tipoImovel === "APARTAMENTO"
              ? values?.empreendimento?.toUpperCase()
              : "",
          tipoImovel: values?.tipoImovel?.toUpperCase(),
          areaComum:
            values?.tipoImovel === "APARTAMENTO" ? values?.areaComum : "",
          fracaoIdeal:
            values?.tipoImovel === "APARTAMENTO" ? values?.fracaoIdeal : "",
        })
      );
    },
    [dispatch]
  );

  const disabled = perfil !== "BPO";
  if (!didMount) {
    if (dominioCartorios?.length > 0 && data !== null) {
      setTimeout(() => {
        filtrarCartorios({
          uf: data?.uf?.toUpperCase(),
          municipio: data?.municipio?.toUpperCase(),
        });
      }, 50);
      setDidMount(true);
    }
  }

  const handleGoBack = useCallback(() => {
    dispatch(
      rotasActions.redirectTo(routes.PROPOSTA, {
        id: data.id_consulta,
      })
    );
  }, [dispatch, data]);

  const handleOpenImagens = useCallback(
    (id) => {
      dispatch(
        rotasActions.openNewWindowWith(routes.ARTEFATOS_DOCUMENTO, { id })
      );
    },
    [dispatch]
  );

  return (
    <ImovelPage
      loading={loading}
      error={error}
      dominioUfs={uf}
      handleZipCodeBlur={handleZipCodeBlur}
      formProps={formProps}
      submitting={submitting}
      disabled={disabled}
      handleSubmit={handleSubmit}
      dominioCartorios={dominioCartoriosFiltrado}
      filtrarCartorios={filtrarCartorios}
      handleGoBack={handleGoBack}
      handleOpenImagens={handleOpenImagens}
      checklistAlteracaoCadastral={detalheProposta?.checklistAlteracaoCadastral}
      idImovel={data?.id}
    />
  );
};

export default ImovelPageConnected;
