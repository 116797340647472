import React, { useCallback } from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import {
  EditOutlined,
  DeleteOutlined,
  LockOpenOutlined,
} from "@material-ui/icons";

const Organizacao = ({
  organizacao,
  index,
  tipoOrganizacao,
  handleEdit,
  handleOpenExclusaoOrganizacao,
}) => {
  return (
    <div
      className={
        index % 2 === 0
          ? "row mt-0 d-flex align-items-center medium-row tr-even-adm"
          : "row mt-0 d-flex align-items-center medium-row tr-odd"
      }
    >
      <div className="col-3 col-md-3 mt-sm-2 mt-lg-0 user-table ">
        <span>{organizacao.codigo}</span>
      </div>
      <div className="col-6 col-md-6 my-2 my-lg-0 user-table">
        <span>{organizacao.nome}</span>
      </div>
      <div className="col-2 col-md-2  my-2 my-lg-0 user-table">
        <span>
          {tipoOrganizacao.find((item) => item.tipo === organizacao.tipo)?.desc}
        </span>
      </div>
      <Tooltip title="Editar">
        <IconButton className="p-2" onClick={() => handleEdit(organizacao)}>
          <EditOutlined className={"color-black"} />
        </IconButton>
      </Tooltip>
      {organizacao?.situacao !== "A" && (
        <Tooltip title="Excluir">
          <IconButton
            className="p-2"
            onClick={() => handleOpenExclusaoOrganizacao(organizacao)}
          >
            <DeleteOutlined className={"color-black"} />
          </IconButton>
        </Tooltip>
      )}
    </div>
  );
};

export default Organizacao;
