import basicFlow from "../asyncHandler";
import { actions } from "../../reducers/administracao/documentos.actions";
import { authenticatedRequest } from "../../utils/api";
import { routeWatcher } from "../rotas.saga";
import { types as routes } from "../../reducers/rotas.actions";
import { put } from "redux-saga/effects";
import { toast } from "react-toastify";

const listarDocumentosApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/documentos",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const atualizarDocumentoApi = (form) => {
  return authenticatedRequest({
    url: "/administracao/documento",
    isResourceService: true,
    method: "post",
    body: form,
  });
};

const uploadFormularioBancoApi = (form) => {
  return authenticatedRequest({
    url: "/upload-formulario",
    isResourceService: true,
    method: "post",
    body: form,
  });
};

const excluirDocumentoApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/documento/delete",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const buscarDocumentosApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/documento/search",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const listarDocumentos = basicFlow({
  actionGenerator: actions.listarDocumentos,
  api: listarDocumentosApi,
});

const atualizarDocumento = basicFlow({
  actionGenerator: actions.atualizarDocumento,
  api: atualizarDocumentoApi,
  postSuccess: function* ({ original }) {
    if (
      original?.documento?.files.length > 0 &&
      original?.documento?.files[0].size
    ) {
      yield put(
        actions.uploadFormularioBanco.request({
          files: original.documento.files,
          id: original.documento.id,
          tipo: "atualizacao",
        })
      );
    } else {
      toast.success("Atualização realizada com sucesso");
    }
  },
});

const incluirDocumento = basicFlow({
  actionGenerator: actions.incluirDocumento,
  api: atualizarDocumentoApi,
  postSuccess: function* ({ original, response }) {
    if (original?.documento?.files.length > 0) {
      yield put(
        actions.uploadFormularioBanco.request({
          files: original.documento.files,
          id: response.data.novoDocumento.id,
          tipo: "inclusao",
        })
      );
    } else {
      toast.success("Inclusão realizada com sucesso");
    }
  },
});

const uploadFormularioBanco = basicFlow({
  actionGenerator: actions.uploadFormularioBanco,
  api: uploadFormularioBancoApi,
  transform: (payload) => {
    const form = new FormData();
    Object.keys(payload).forEach((key) => {
      if (key !== "files") form.append(key, payload[key]);
    });
    payload.files.forEach((file) => {
      form.append("form", file, file.name);
    });
    return form;
  },
  postSuccess: function (original) {
    if (original.tipo === "atualizacao") {
      toast.success("Atualização realizada com sucesso");
    } else {
      toast.success("Inclusão realizada com sucesso");
    }
  },
});

const excluirDocumento = basicFlow({
  actionGenerator: actions.excluirDocumento,
  api: excluirDocumentoApi,
  postSuccess: function () {
    toast.success("Exclusão realizada com sucesso");
  },
});

const buscarDocumento = basicFlow({
  actionGenerator: actions.buscarDocumento,
  api: buscarDocumentosApi,
});

function* listaDocumentosRouteWatcher() {
  const pagina = 1;
  yield routeWatcher(routes.ADMINISTRACAO_DOCUMENTOS, function* () {
    yield put(
      actions.listarDocumentos.request({
        pagina,
      })
    );
  });
}

export const sagas = [
  listarDocumentos.watcher(),
  listaDocumentosRouteWatcher(),
  atualizarDocumento.watcher(),
  incluirDocumento.watcher(),
  buscarDocumento.watcher(),
  excluirDocumento.watcher(),
  uploadFormularioBanco.watcher(),
];
