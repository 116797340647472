import React from "react";
import { Dialog, DialogContent, IconButton } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { CloseSharp } from "@material-ui/icons";
import { Button } from "../../../../components";
import validators from "../../../../utils/validators";
import {
  ControlledTextfield,
  DateTextField,
} from "../../../../components/inputs";

const ConfirmarDialog = ({
  handleClose,
  open,
  handleSubmit,
  hasComentario,
  hasData,
}) => {
  const rules = {
    dataContrato: validators.date({
      required: hasData,
    }),
    comentario: validators.string({ required: hasComentario }),
  };
  const initialValues = {
    dataContrato: null,
    comentario: null,
  };
  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const handleClick = (event) => {
    handleSubmit(event.dataContrato, event.comentario);
    handleClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      classes={{ paper: "w100" }}
      maxWidth="md"
      open={open}
    >
      {!!open && (
        <DialogContent>
          <IconButton
            onClick={handleClose}
            classes={{ root: "position-absolute clickable close-icon" }}
          >
            <CloseSharp />
          </IconButton>
          <div className={"page-title"}>
            Informe um comentário{hasData ? " e a data do contrato." : "."}
          </div>
          <div className={"mb-3"}>
            <form onSubmit={formProps.handleSubmit(handleClick)}>
              {hasData && (
                <div className="row d-flex justify-content-center">
                  <div className="col-12 col-md-6 col-lg-9">
                    <ControlledTextfield
                      formProps={formProps}
                      label={"Data do Contrato"}
                      name={"dataContrato"}
                      margin={"none"}
                      InputProps={{
                        inputComponent: DateTextField,
                      }}
                    />
                  </div>
                </div>
              )}
              <div className="row d-flex justify-content-center">
                <div className="col-12 col-md-6 col-lg-9">
                  <ControlledTextfield
                    formProps={formProps}
                    multiline={true}
                    rowsMax={6}
                    rows={6}
                    uppercase={false}
                    name={"comentario"}
                    margin={"none"}
                    label={"Comentário"}
                    inputProps={{ maxLength: 2000 }}
                  />
                </div>
              </div>
              <div className="row spacer justify-content-center">
                <div className="col-md-4 col-12">
                  <Button label="CONFIRMAR" type="submit" />
                </div>
              </div>
            </form>
          </div>
        </DialogContent>
      )}
    </Dialog>
  );
};

export default ConfirmarDialog;
