import PropostasPage from "./PropostasPage";
import { useDispatch, useSelector } from "react-redux";
import {
  getPropostasList,
  getPropostasLoading,
  getTotalPropostasList,
  getPaginaAtual,
  getDetalheProposta,
  getExpanded,
  getFiltroPesquisa,
  getFiltroExcludentePropostas,
  getFiltroValores,
  getFiltroSimplesPropostas,
} from "../../selectors/propostas.selectors";
import { useCallback } from "react";
import {
  actions as propostaActions,
  actions,
} from "../../reducers/propostas.actions";
import { getUsuario, getPerfil } from "../../selectors/usuario.selectors";
import { useForm } from "react-hook-form";

import { getAnalistas } from "../../selectors/dominios.selectors";

const PropostasPageConnected = () => {
  const dispatch = useDispatch();
  const propostas = useSelector(getPropostasList);
  const loading = useSelector(getPropostasLoading);
  const total = useSelector(getTotalPropostasList);
  const pagina = useSelector(getPaginaAtual);
  const { nome } = useSelector(getUsuario);
  const perfil = useSelector(getPerfil);
  const detalheProposta = useSelector(getDetalheProposta);
  const expanded = useSelector(getExpanded);
  const pesquisa = useSelector(getFiltroPesquisa);
  const filtroSimples = useSelector(getFiltroSimplesPropostas);
  const filtroValores = useSelector(getFiltroValores);
  const filtroExcludente = useSelector(getFiltroExcludentePropostas);

  const responsavel = [
    { key: "A", desc: "AGÊNCIA" },
    { key: "B", desc: "BPO" },
    { key: "C", desc: "CRÉDITO" },
    { key: "D", desc: "DEF" },
    { key: "O", desc: "DOC" },
    { key: "P", desc: "PATRIMÔNIO" },
    { key: "S", desc: "SEGURADORA" },
    { key: "L", desc: "CLIENTE" },
  ];

  const prioridades = [
    { key: "M", desc: "MANIFESTAÇÃO" },
    { key: "R", desc: "ALTA RENDA" },
    { key: "B", desc: "SOLICITAÇÃO BANCO" },
  ];

  const rules = {};
  const initialValues = {
    iq: filtroValores?.filtro_basico?.find((item) => item.tipo === "iq")
      ?.valores,
    fgts: filtroValores?.filtro_basico?.find((item) => item.tipo === "fgts")
      ?.valores,
    priorizadas:
      filtroValores?.filtro_basico?.find((item) => item.tipo === "priorizadas")
        ?.valores ?? [],
    resp:
      filtroValores?.filtro_basico?.find((item) => item.tipo === "resp")
        ?.valores ?? [],
    analistaBack:
      filtroValores?.filtro_basico?.find((item) => item.tipo === "analistaBack")
        ?.valores ?? [],

    tipo: filtroSimples,
  };
  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const analistas = useSelector(getAnalistas);
  const handlePagination = useCallback(
    (e, page, pesquisa, filtroValores) => {
      dispatch(
        actions.listarPropostas.request({
          pagina: page,
          pesquisa: pesquisa,
          filtro: { ...filtroValores },
        })
      );
    },
    [dispatch]
  );

  const consultarProposta = useCallback(
    (e, expanded) => {
      if (!!expanded) {
        dispatch(
          actions.consultarProposta.request(e.currentTarget.attributes.id.value)
        );
      } else {
        dispatch(actions.fecharDetalhe());
      }
    },
    [dispatch]
  );

  const filtrarPropostas = useCallback(
    (data, headerText) => {
      dispatch(
        actions.listarPropostas.request({
          pagina: 1,
          filtro: {
            filtro_excludente: { tipo: filtroExcludente },
            filtro_basico: filtroSimples?.map((item) => {
              return { tipo: item, valores: data[item] };
            }),
            headerText: headerText,
          },
        })
      );
      dispatch(actions.fecharDetalhe());
    },
    [dispatch, filtroSimples, filtroExcludente]
  );

  const selecionarFiltroSimples = useCallback(
    (code) => {
      if (filtroSimples?.find((item) => item === code)) {
        dispatch(
          actions.selecionarFiltroSimples(
            filtroSimples.filter((item) => item !== code)
          )
        );
      } else {
        dispatch(actions.selecionarFiltroSimples([...filtroSimples, code]));
      }
    },
    [dispatch, filtroSimples]
  );

  const selecionarFiltroExcludente = useCallback(
    (code) => {
      if (filtroExcludente === code) {
        dispatch(actions.selecionarFiltroExcludente(null));
      } else {
        dispatch(actions.selecionarFiltroExcludente(code));
      }
    },
    [dispatch, filtroExcludente]
  );
  const limparFiltros = useCallback(() => {
    dispatch(actions.limparFiltroValores());
    setTimeout(function () {
      dispatch(actions.listarPropostas.request({ pagina: 1 }));
      dispatch(actions.fecharDetalhe());
    }, 50);
  }, [dispatch]);

  return (
    <PropostasPage
      propostas={propostas}
      loading={loading}
      total={total}
      handlePagination={handlePagination}
      pagina={pagina}
      usuario={nome}
      consultarProposta={consultarProposta}
      detalheProposta={detalheProposta}
      expanded={expanded}
      pesquisa={pesquisa}
      filtrarPropostas={filtrarPropostas}
      filtroSimples={filtroSimples}
      perfil={perfil}
      selecionarFiltroSimples={selecionarFiltroSimples}
      formProps={formProps}
      filtroValores={filtroValores}
      dominioAnalistas={analistas}
      selecionarFiltroExcludente={selecionarFiltroExcludente}
      filtroExcludente={filtroExcludente}
      responsavel={responsavel}
      prioridades={prioridades}
      limparFiltros={limparFiltros}
    />
  );
};

export default PropostasPageConnected;
