import React, { useCallback } from "react";
import AdicionarDocumentoDialog from "./AdicionarDocumentoDialog";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import { getDocumentos } from "../../../../selectors/dominios.selectors";
import { getChecklist } from "../../../../selectors/documentos.selectors";
import validators from "../../../../utils/validators";
import { actions as domainActions } from "../../../../reducers/dominios.actions";
import { actions } from "../../../../reducers/propostas.actions";

const AdicionarDocumentoDialogConnected = ({ handleClose, dados }) => {
  const dispatch = useDispatch();
  const { loading, data: dominioDocumentos } = useSelector(getDocumentos);
  const { adding } = useSelector(getChecklist);
  const { tipo } = dados;

  const rules = {
    documento: validators.string({ required: true }),
  };
  const initialValues = {
    documento: "",
  };
  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const handleOpen = useCallback(() => {
    dispatch(domainActions.documentos.request(tipo));
  }, [dispatch, tipo]);

  const handleSubmit = useCallback(
    ({ documento }) => {
      dispatch(
        actions.adicionarDocumento.request({
          proposta: dados.proposta,
          id: dados.id,
          tipo: dados.tipo,
          documento: documento.id,
          handleClose,
        })
      );
    },
    [dispatch, dados, handleClose]
  );

  return (
    <AdicionarDocumentoDialog
      handleSubmit={handleSubmit}
      handleOpen={handleOpen}
      open={dados.open}
      loading={loading}
      formProps={formProps}
      dominioDocumentos={dominioDocumentos}
      handleClose={handleClose}
      submitting={adding}
    />
  );
};

export default AdicionarDocumentoDialogConnected;
