import BasePage from "../BasePage";
import React, { useCallback, useState } from "react";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Loader from "../../components/loaders";
import Pagination from "@material-ui/lab/Pagination";
import DetalheProposta from "./DetalheProposta.connected";
import Filtros from "./Filtros";
import FilterListOutlinedIcon from "@material-ui/icons/FilterListOutlined";
import ClearAllOutlined from "@material-ui/icons/Clear";
import { Button, VisibleWithoutProfile } from "../../components";

// estilo responsavel pela responsividade e adquacao para mostrar o campo comprador sem quebra muito
import _PropostasPage from "../../styles/_PropostasPage.scss";

const PropostasPage = ({
  propostas,
  loading,
  total,
  handlePagination,
  pagina,
  usuario,
  consultarProposta,
  expanded,
  pesquisa,
  filtrarPropostas,
  filtroSimples,
  perfil,
  selecionarFiltroSimples,
  formProps,
  filtroValores,
  dominioAnalistas,
  selecionarFiltroExcludente,
  filtroExcludente,
  responsavel,
  prioridades,
  limparFiltros,
}) => {
  const [dadosFiltros, setDadosFiltros] = useState({
    open: false,
  });

  const handleCloseFiltros = useCallback(() => {
    setDadosFiltros({
      open: false,
    });
  }, []);

  const handleOpenFiltros = useCallback(() => {
    setDadosFiltros({
      open: true,
    });
  }, []);

  const headerFiltros = (filtroSimples, filtroExcludente, filtroValores) => {
    let texto = "";

    if (filtroExcludente === "faseI") {
      texto = `${texto} DOCUMENTAÇÃO ENVIADA FASE I`;
    } else if (filtroExcludente === "faseII") {
      texto = `${texto} DOCUMENTAÇÃO ENVIADA FASE II`;
    } else if (filtroExcludente === "docIncompleta") {
      texto = `${texto} DOCUMENTAÇÃO INCOMPLETA`;
    } else if (filtroExcludente === "pendenteAnalise") {
      texto = `${texto} DOCUMENTAÇÃO PENDENTE DE ANÁLISE`;
    }

    if (filtroSimples?.find((item) => item === "analistaBack")) {
      if (texto.length > 0) texto = `${texto} \u2022`;
      texto = `${texto} ANALISTA BACKOFFICE: ${
        filtroValores["analistaBack"]?.length > 0
          ? filtroValores["analistaBack"]?.map((item) => {
              return dominioAnalistas?.data?.find(
                (analista) => analista.id === item
              )?.nome;
            })
          : "NENHUM"
      }`;
    }

    if (filtroSimples?.find((item) => item === "resp")) {
      if (texto.length > 0) texto = `${texto} \u2022`;
      texto = `${texto} RESPONSÁVEL: ${
        filtroValores["resp"]?.length > 0
          ? filtroValores["resp"]?.map((item) => {
              return responsavel.find((responsavel) => responsavel.key === item)
                ?.desc;
            })
          : "NENHUM"
      }`;
    }

    if (filtroSimples?.find((item) => item === "priorizadas")) {
      if (texto.length > 0) texto = `${texto} \u2022`;
      texto = `${texto} PRIORIZADAS: ${
        filtroValores["priorizadas"]?.length > 0
          ? filtroValores["priorizadas"]?.map((item) => {
              return prioridades.find((prioridade) => prioridade.key === item)
                ?.desc;
            })
          : "NENHUM"
      }`;
    }
    if (filtroSimples?.find((item) => item === "fgts")) {
      if (texto.length > 0) texto = `${texto} \u2022`;
      texto = `${texto} FGTS: ${filtroValores["fgts"] ? "SIM" : "NÃO"}`;
    }
    if (filtroSimples?.find((item) => item === "iq")) {
      if (texto.length > 0) texto = `${texto} \u2022`;
      texto = `${texto} IQ: ${filtroValores["iq"] ? "SIM" : "NÃO"}`;
    }
    return texto;
  };

  return (
    <BasePage isLoggedIn={true}>
      <h2>Bem Vindo, {usuario}</h2>
      <h6 className="mb-3">
        Aqui você consulta as propostas de Crédito Imobiliário que estão em
        andamento
      </h6>
      {perfil !== "CLIENTE" && perfil !== "AGENCIA" && (
        <div
          className="card"
          style={{ marginTop: "10px", marginBottom: "10px", minHeight: "75px" }}
        >
          <div className="row" style={{ padding: "10px" }}>
            {filtroValores?.headerText?.length > 0 && (
              <div className={"col-lg-8 col-xs-8 col-12 col-md-6"}>
                <div
                  style={{
                    display: "flex",
                    height: "100%",
                    alignItems: "center",
                  }}
                >
                  <div>
                    <span> {filtroValores?.headerText}</span>
                  </div>
                </div>
              </div>
            )}

            <div
              className={
                filtroValores?.headerText?.length > 0
                  ? "col-12 col-md-3 col-lg-2 col-xs-2 justify-content-center"
                  : "offset-md-9 offset-lg-10 offset-xs-8 col-12 col-md-3 col-lg-2 col-xs-2 align-self-center"
              }
            >
              <Button
                label="Filtrar"
                onClick={() => handleOpenFiltros()}
                className={"align-middle"}
                endIcon={
                  <FilterListOutlinedIcon className="d-flex align-items-center justify-content-end" />
                }
              />
            </div>
            {filtroValores?.headerText?.length > 0 && (
              <div className="col-12 col-md-3 col-lg-2 col-xs-2 justify-content-center">
                <Button
                  label="Limpar"
                  onClick={limparFiltros}
                  className={"align-middle"}
                  endIcon={
                    <ClearAllOutlined className="d-flex align-items-center justify-content-end" />
                  }
                />
              </div>
            )}
          </div>
        </div>
      )}

      {perfil !== "CLIENTE" && perfil !== "AGENCIA" && (
        <Filtros
          open={dadosFiltros.open}
          filtroSimples={filtroSimples}
          handleFilterClick={filtrarPropostas}
          handleClose={handleCloseFiltros}
          selecionarFiltroSimples={selecionarFiltroSimples}
          formProps={formProps}
          dominioAnalistas={dominioAnalistas}
          selecionarFiltroExcludente={selecionarFiltroExcludente}
          filtroExcludente={filtroExcludente}
          responsavel={responsavel}
          prioridades={prioridades}
          headerFiltros={headerFiltros}
        />
      )}
      {!!loading && (
        <Accordion key="loader01" className="card">
          <AccordionSummary
            classes={{ content: "d-flex justify-content-center" }}
            aria-controls={"propostaLoader"}
            id={"propostaLoader"}
          >
            <Loader type={"dots"} color={"brand"} size={50} />
          </AccordionSummary>
        </Accordion>
      )}
      {!loading &&
        !!propostas &&
        propostas.map((proposta) => (
          <Accordion
            key={proposta.id}
            className="card"
            onChange={consultarProposta}
            expanded={expanded == proposta.id}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls={"proposta" + proposta.id}
              id={proposta.id}
            >
              <div className={_PropostasPage}>
                <b className={_PropostasPage}>Proposta : </b>
                {proposta.codigo}
                <span>
                  <b className="none _PropostasPage">&nbsp;-&nbsp;</b>
                  <b>Comprador :</b> {proposta.cliente[0]["nm_pessoa"]}
                </span>
              </div>
              {proposta.sla !== null && (
                <VisibleWithoutProfile profiles={["CLIENTE"]}>
                  <div className="accordion-sm d-flex justify-content-end flex-grow-1">
                    <div className={`sla-tag ${slaStyle(proposta.sla)}`}>
                      <span className="accordion-sm sla-tag-label">
                        {slaLabel(proposta.sla)}
                      </span>
                    </div>
                  </div>
                </VisibleWithoutProfile>
              )}
            </AccordionSummary>
            <AccordionDetails>
              <DetalheProposta />
            </AccordionDetails>
          </Accordion>
        ))}
      {!loading && (!propostas || propostas?.length === 0) && (
        <div className="card">
          <div
            style={{
              display: "flex",
            }}
            className={"MuiAccordionSummary-content"}
          >
            <div
              className="col-10"
              style={{
                alignSelf: "center",
                justifySelf: "center",
                padding: "10px",
                margin: 0,
              }}
            >
              Não existem propostas disponíveis para consulta
            </div>
          </div>
        </div>
      )}
      {!loading && total > 10 && (
        <>
          <div className="row">
            <div className="col-12">
              <Pagination
                count={Math.ceil(total / 10)}
                page={pagina}
                variant="outlined"
                shape="rounded"
                onChange={(event, pagina) =>
                  handlePagination(event, pagina, pesquisa, filtroValores)
                }
              />
            </div>
          </div>
        </>
      )}
    </BasePage>
  );
};

const slaLabel = (sla) => {
  if (sla > 0) {
    return "Em atraso";
  } else if (sla < 0) {
    return "Em dia";
  } else {
    return "Hoje";
  }
};

const slaStyle = (sla) => {
  if (sla > 0) {
    return "sla-red";
  } else if (sla < 0) {
    return "sla-green";
  } else {
    return "sla-orange";
  }
};

export default PropostasPage;
