import { useDispatch, useSelector } from "react-redux";
import { getMatrizClausulas } from "../../../../selectors/administracao.selectors";
import { useCallback } from "react";
import { actions } from "../../../../reducers/administracao/matrizClausulas.actions";
import validators from "../../../../utils/validators";
import { useForm } from "react-hook-form";
import FormClausula from "./FormClausula";

const FormMatrizClausulaConnected = () => {
  const dispatch = useDispatch();
  const clausulas = useSelector(getMatrizClausulas);

  const initialValues = {
    ...clausulas.item,
    conjugeParticipante: clausulas.item.conjugeParticipante === "S",
    uniaoEstavel: clausulas.item.uniaoEstavel === "S",
  };

  const rules = {
    clausula: validators.string({ required: true }),
    conjugeParticipante: validators.string({ required: true }),
    uniaoEstavel: validators.string({ required: true }),
    tipoParticipante: validators.string({ required: true }),
    tipoQualificacao: validators.string({ required: true }),
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const handleSubmit = useCallback(
    (values, i) => {
      const request = {
        id: i,
        ...values,
      };
      if (!!i) {
        dispatch(actions.atualizarMatriz.request({ item: request }));
      } else {
        dispatch(actions.incluirMatriz.request({ item: request }));
      }
    },
    [dispatch]
  );

  const voltarParaLista = useCallback(() => {
    dispatch(actions.voltarListaMatriz());
  }, [dispatch]);

  return (
    <FormClausula
      clausulas={clausulas?.clausulas.list}
      props={formProps}
      item={clausulas?.item}
      handleSubmit={handleSubmit}
      voltarParaLista={voltarParaLista}
    />
  );
};

export default FormMatrizClausulaConnected;
