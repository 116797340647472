import FormParticipante from "./FormParticipante";
import { useSelector, useDispatch } from "react-redux";
import { useCallback, useState } from "react";
import { useForm } from "react-hook-form";
import validators from "../../../../../utils/validators";
import {
  estadoCivil,
  uf,
  regimeComunhao,
} from "../../../../../utils/parametros";
import {
  getDetalheProposta,
  getLoadingInclusaoParticipante,
  getLocationPayload,
  getParticipante,
  getPropostaLoading,
} from "../../../../../selectors/propostas.selectors";
import {
  actions as propostaActions,
  actions,
} from "../../../../../reducers/propostas.actions";
import { getPerfil } from "../../../../../selectors/usuario.selectors";
import {
  actions as rotasActions,
  types as routes,
} from "../../../../../reducers/rotas.actions";
import { getCartorios } from "../../../../../selectors/dominios.selectors";

const FormParticipanteConnected = () => {
  const dispatch = useDispatch();
  const participante = useSelector(getParticipante);
  const perfil = useSelector(getPerfil);
  const loadingDetalhe = useSelector(getPropostaLoading);
  const detalheProposta = useSelector(getDetalheProposta);
  const isAtualizacao = !!useSelector(getLocationPayload).idParticipante;
  const { data: dominioCartorios } = useSelector(getCartorios);

  const initialValues = {
    ...participante?.dados,
    participantes: {
      comprador: detalheProposta?.cliente,
      vendedor: detalheProposta?.vendedor,
    },
  };

  const rules = {
    dataNascimento: validators.date({ required: true }),
    cep: validators.string({ required: true }),
    logradouro: validators.string({ required: true }),
    bairro: validators.string({ required: true }),
    municipio: validators.string({ required: true }),
    cpf: validators.cpfCnpj({
      required: true,
      participantes: initialValues.participantes,
      validarRepetido: !isAtualizacao,
    }),
    nome: validators.string({ required: true }),
    email: validators.email({ required: false }),
    dataNascimentoConjuge: validators.date({ required: false, valid: true }),
    telefone: validators.phone({ required: false }),
    telefoneResidencial: validators.phone({ required: false }),
    uf: validators.string({ required: true }),
    dataCasamento: validators.date({ required: false, valid: true }),
    emailConjuge: validators.email({ required: false }),
    dataRegistro: validators.date({ required: false, valid: true }),
    dataEscritura: validators.date({ required: false, valid: true }),
  };

  const disabled = perfil !== "BPO";

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const loadingInclusao = useSelector(getLoadingInclusaoParticipante);

  const handleSubmit = useCallback(
    (values, i) => {
      const request = {
        id_pessoa: i.idPessoa ?? "",
        id_consulta: i.idConsulta,
        DadosPessoa: {
          tipoParticipante: i.tipoParticipante,
          tipoPessoa: i.tipoPessoa,
          nome: values.nome.toUpperCase(),
          cpf_cnpj: values.cpf
            ?.replaceAll(".", "")
            .replace("-", "")
            .replace("/", ""),
          codigo: i.codigoPessoa,
          colaborador: values.colaborador ? "S" : "N",
        },
        PessoaEndereco: {
          pessoa: i.idPessoa ?? "",
          tipoEndereco: "R",
          cep: values.cep?.replace("-", ""),
          logradouro: values.logradouro.toUpperCase(),
          numero: values.numero ?? "",
          complemento: values.complemento.toUpperCase() ?? "",
          bairro: values.bairro.toUpperCase(),
          municipio: values.municipio?.toUpperCase(),
          uf: values.uf,
        },
        PessoaDadoPessoal: {
          pessoa: i.idPessoa ?? "",
          dataNascimento: values.dataNascimento,
          estadoCivil: values.estadoCivil,
          nomeMae: values.nomeMae?.toUpperCase() ?? "",
          fgts: values.fgts ? "S" : "N",
          uniaoEstavel: values.uniaoEstavel ? "S" : "N",
          conjuge: !!values.nomeConjuge ? "S" : "N",
          cpf_conjuge:
            values.cpfConjuge?.replaceAll(".", "").replace("-", "") ?? "",
          dataCasamento: values.dataCasamento ?? "",
          nomeConjuge: values.nomeConjuge?.toUpperCase() ?? "",
          dataNascimentoConjuge: values.dataNascimentoConjuge ?? "",
          isConjugeComprador: values.isConjugeComprador ? "S" : "N",
          fgtsConjuge: values.fgtsConjuge ? "S" : "N",
          tipoEmpresa: values.tipoEmpresa ?? "",
          regimeCasamento: values.regimeCasamento?.toUpperCase() ?? "",
          emailConjuge: values.emailConjuge ?? "",
          profissao: values.profissao?.toUpperCase() ?? "",
          nacionalidade: values.nacionalidade?.toUpperCase() ?? "",
          nomePai: values.nomePai?.toUpperCase() ?? "",
          numeroDocumento: values.numeroDocumento?.toUpperCase() ?? "",
          orgaoEmissor: values.orgaoEmissor?.toUpperCase() ?? "",
          ufOrgaoEmissor: values.ufOrgaoEmissor ?? "",
          estadoCivilConjuge: values.estadoCivilConjuge ?? "",
          nomePaiConjuge: values.nomePaiConjuge?.toUpperCase() ?? "",
          nomeMaeConjuge: values.nomeMaeConjuge?.toUpperCase() ?? "",
          profissaoConjuge: values.profissaoConjuge?.toUpperCase() ?? "",
          nacionalidadeConjuge:
            values.nacionalidadeConjuge?.toUpperCase() ?? "",
          numeroDocumentoConjuge:
            values.numeroDocumentoConjuge?.toUpperCase() ?? "",
          orgaoEmissorConjuge: values.orgaoEmissorConjuge?.toUpperCase() ?? "",
          ufOrgaoEmissorConjuge: values.ufOrgaoEmissorConjuge ?? "",
          dataEscritura: values?.dataEscritura?.length
            ? `${values.dataEscritura.split("/")[2]}-${
                values.dataEscritura.split("/")[1]
              }-${values.dataEscritura.split("/")[0]}`
            : null,
          numeroLivroEscritura:
            values.numeroLivroEscritura?.toUpperCase() ?? "",
          numeroFolhaEscritura:
            values.numeroFolhaEscritura?.toUpperCase() ?? "",
          nomeCartorioEscritura: values.nomeCartorioEscritura,
          comarcaEscritura: values.comarcaEscritura?.toUpperCase() ?? "",
          ufEscritura: values.ufEscritura ?? "",
          numeroRegistro: values.numeroRegistro?.toUpperCase() ?? "",
          dataRegistro:
            values?.dataRegistro?.length > 0
              ? `${values.dataRegistro.split("/")[2]}-${
                  values.dataRegistro.split("/")[1]
                }-${values.dataRegistro.split("/")[0]}`
              : null,
          numeroLivroRegistro: values.numeroLivroRegistro?.toUpperCase() ?? "",
          numeroFolhaRegistro: values.numeroFolhaRegistro?.toUpperCase() ?? "",
          nomeCartorioRegistro: values.nomeCartorioRegistro,
          comarcaRegistro: values.comarcaRegistro?.toUpperCase() ?? "",
          ufRegistro: values.ufRegistro ?? "",
          conjugeVendedor: values?.conjugeVendedor ? "S" : "N",
        },
        PessoaContato: {
          pessoa: i.idPessoa ?? "",
          email: values.email ?? "",
          areaCelular: values.telefone?.charAt(1) + values.telefone?.charAt(2),
          telefoneCelular: values.telefone?.slice(5).replace("-", ""),
          areaResidencial:
            values.telefoneResidencial?.charAt(1) +
            values.telefoneResidencial?.charAt(2),
          telefoneResidencial: values.telefoneResidencial
            ?.slice(5)
            .replace("-", ""),
        },
      };
      dispatch(actions.atualizarParticipante.request(request));
    },
    [dispatch]
  );

  const handleGoBack = useCallback(() => {
    dispatch(
      rotasActions.redirectTo(routes.PROPOSTA, {
        id: formProps.initialValues.idConsulta,
      })
    );
  }, [dispatch, formProps]);

  const successCallback = useCallback(
    ({ data }, value) => {
      formProps.setValue("logradouro", data?.logradouro || "");
      formProps.setValue("bairro", data?.bairro || "");
      formProps.setValue("uf", data?.uf || "");
      formProps.setValue("municipio", data?.localidade || "");
      formProps.setValue("localidade", data?.ibge || "");
    },
    [formProps]
  );

  const consultarCep = useCallback(
    (value) => {
      if (!!value && value.length === 9) {
        dispatch(
          propostaActions.consultarCepCorreios.request({
            value,
            successCallback,
          })
        );
      }
    },
    [dispatch, successCallback]
  );

  const validarCpfCpnjRepetido = useCallback(
    (value) => {
      initialValues.participantes.comprador.forEach((c) => {
        if (c.cpfCnpj === value) return true;
      });
      initialValues.participantes.vendedor.forEach((c) => {
        if (c.cpfCnpj === value) return true;
      });
      return false;
    },
    [dispatch]
  );
  const handleOpenImagens = useCallback(
    (id) => {
      dispatch(
        rotasActions.openNewWindowWith(routes.ARTEFATOS_DOCUMENTO, { id })
      );
    },
    [dispatch]
  );

  return (
    <FormParticipante
      isPf={participante?.dados?.tipoPessoa === "F"}
      loading={participante?.loading}
      formProps={formProps}
      estadoCivil={estadoCivil}
      uf={uf.sort()}
      participante={participante?.dados}
      consultarCep={consultarCep}
      handleSubmit={handleSubmit}
      loadingInclusao={loadingInclusao}
      regimeComunhao={regimeComunhao}
      disabled={disabled}
      loadingDetalhe={loadingDetalhe}
      validarCpfCnpjRepetido={validarCpfCpnjRepetido}
      isAtualizacao={isAtualizacao}
      handleGoBack={handleGoBack}
      dominioCartorios={dominioCartorios}
      checklistAlteracaoCadastral={detalheProposta?.checklistAlteracaoCadastral}
      handleOpenImagens={handleOpenImagens}
    />
  );
};

export default FormParticipanteConnected;
