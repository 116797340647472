import { useDispatch, useSelector } from "react-redux";
import {
  getProdutos,
  getProduto,
} from "../../../selectors/administracao.selectors";
import { useCallback } from "react";
import { actions } from "../../../reducers/administracao/produtos.actions";
import validators from "../../../utils/validators";
import { useForm } from "react-hook-form";
import FormProduto from "./FormProduto";

const ProdutosPageConnected = () => {
  const dispatch = useDispatch();
  const produtos = useSelector(getProdutos);
  const produto = useSelector(getProduto);

  const initialValues = {
    ...produto,
  };

  const rules = {
    codigo: validators.string({ required: true }),
    nome: validators.string({ required: true }),
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const handleSubmit = useCallback(
    (values, i) => {
      const request = {
        id: i,
        codigo: values.codigo.toUpperCase(),
        nome: values.nome.toUpperCase(),
      };
      if (!!i) {
        dispatch(actions.atualizarProduto.request({ produto: request }));
      } else {
        dispatch(actions.incluirProduto.request({ produto: request }));
      }
    },
    [dispatch, produtos]
  );

  const voltarParaLista = useCallback(() => {
    dispatch(actions.voltarListaProduto());
  }, [dispatch]);

  return (
    <FormProduto
      produto={produto}
      props={formProps}
      handleSubmit={handleSubmit}
      voltarParaLista={voltarParaLista}
    />
  );
};

export default ProdutosPageConnected;
