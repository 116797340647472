import React from 'react';
import {LinearProgress} from "@material-ui/core";

export default ({className, color = 'brand'}) => {
  return (
      <LinearProgress className={'input-loader ' + className}
                      classes={{
                        bar: `bg-${color}`
                      }} />
  );
}