import BasePage from "../BasePage";
import EsqueciSenhaForm from "./EsqueciSenhaForm.connected";
import { ReactComponent as Logo } from "assets/logo-preto.svg";

const EsqueciSenhaPage = (props) => {
  return (
    <BasePage>
      <div className="d-flex flex-column">
        <div className="row justify-content-center">
          <div className="col-12 d-flex justify-content-center">
            <Logo className={"logo-login col-auto"} />
          </div>
          <div className="col-12 mw-480">
            <div className="card">
              <div className="card-body">
                <div className="card-title text-uppercase text-center">
                  Esqueceu sua senha?
                </div>
                <div className="mini-separator mb-2" />
                <EsqueciSenhaForm {...props} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </BasePage>
  );
};

export default EsqueciSenhaPage;
