import React from "react";
import NumberFormat from "react-number-format";

const CurrencyTextField = ({ inputRef, onChange, ...other }) => (
  <NumberFormat
    {...other}
    getInputRef={inputRef}
    onValueChange={(values) => {
      onChange(values.floatValue);
    }}
    thousandSeparator={"."}
    decimalSeparator={","}
    decimalScale={2}
    fixedDecimalScale={true}
    prefix="R$ "
  />
);

export default CurrencyTextField;
