import basicFlow from "../asyncHandler";
import { actions } from "../../reducers/administracao/matrizClausulas.actions";
import { authenticatedRequest } from "../../utils/api";
import { routeWatcher } from "../rotas.saga";
import { types as routes } from "../../reducers/rotas.actions";
import { put } from "redux-saga/effects";
import { toast } from "react-toastify";

const listarMatrizApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/matriz-clausulas",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const atualizarMatrizApi = ({ item }) => {
  return authenticatedRequest({
    url: "/administracao/matriz-clausula",
    isResourceService: true,
    method: "post",
    body: { itemMatrizClausula: item },
  });
};

const excluirMatrizApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/excluir-matriz-clausula",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const buscarMatrizApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/matriz-clausula/search",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const listarClausulasApi = () => {
  return authenticatedRequest({
    url: "/administracao/matriz-clausula/listar-clausulas",
    isResourceService: true,
    method: "get",
  });
};

const listarMatriz = basicFlow({
  actionGenerator: actions.listarMatriz,
  api: listarMatrizApi,
  transform: (values) => {
    for (const propName in values.filter) {
      if (
        values.filter[propName] === null ||
        values.filter[propName] === undefined ||
        values.filter[propName] === ""
      ) {
        delete values.filter[propName];
      }
    }
    return values;
  },
});

const atualizarMatriz = basicFlow({
  actionGenerator: actions.atualizarMatriz,
  api: atualizarMatrizApi,
  postSuccess: function () {
    toast.success("Atualização realizada com sucesso");
  },
});

const incluirMatriz = basicFlow({
  actionGenerator: actions.incluirMatriz,
  api: atualizarMatrizApi,
  postSuccess: function () {
    toast.success("Inclusão realizada com sucesso");
  },
});

const excluirMatriz = basicFlow({
  actionGenerator: actions.excluirMatriz,
  api: excluirMatrizApi,
  postSuccess: function () {
    toast.success("Exclusão realizada com sucesso");
  },
});

const buscarMatriz = basicFlow({
  actionGenerator: actions.buscarMatriz,
  api: buscarMatrizApi,
});

const listarClausulas = basicFlow({
  actionGenerator: actions.listarClausulas,
  api: listarClausulasApi,
});

function* listaMatrizRouteWatcher() {
  const pagina = 1;
  yield routeWatcher(routes.ADMINISTRACAO_MATRIZ_CLAUSULAS, function* () {
    yield put(
      actions.listarMatriz.request({
        pagina,
      })
    );
    yield put(actions.listarClausulas.request());
  });
}

export const sagas = [
  listarMatriz.watcher(),
  listaMatrizRouteWatcher(),
  atualizarMatriz.watcher(),
  incluirMatriz.watcher(),
  buscarMatriz.watcher(),
  excluirMatriz.watcher(),
  listarClausulas.watcher(),
];
