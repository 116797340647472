import { useDispatch, useSelector } from "react-redux";
import {
  getDocumentos,
  getDocumento,
} from "../../../selectors/administracao.selectors";
import { useCallback } from "react";
import { actions } from "../../../reducers/administracao/documentos.actions";
import validators from "../../../utils/validators";
import { useForm } from "react-hook-form";
import FormDocumento from "./FormDocumento";

const DocumentosPageConnected = () => {
  const dispatch = useDispatch();
  const documentos = useSelector(getDocumentos);
  const documento = useSelector(getDocumento);

  const initialValues = {
    ...documento,
    interno: documento.interno === "S",
    permiteDispensa: documento.permiteDispensa === "S",
    alteracaoCadastral: documento.alteracaoCadastral === "S",
    files: [],
  };

  if (
    documento.arquivoFormularioBanco !== null &&
    documento.arquivoFormularioBanco !== undefined
  ) {
    initialValues.files = [
      {
        name: documento.arquivoFormularioBanco,
        id: documento.codigoArtefatoFormularioBanco,
      },
    ];
  }

  const rules = {
    codigo: validators.string({ required: true }),
    nome: validators.string({ required: true }),
    origem: validators.string({ required: true }),
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const handleSubmit = useCallback(
    (values, i) => {
      const request = {
        id: i,
        codigo: values.codigo,
        nome: values.nome.toUpperCase(),
        tipo: "P",
        origem: values.origem,
        orientacao: values.orientacao,
        interno: values.interno ? "S" : "N",
        permiteDispensa: values.permiteDispensa ? "S" : "N",
        alteracaoCadastral: values.alteracaoCadastral ? "S" : "N",
        codigoIntegracao: values.codigoIntegracao,
        files: values.files,
      };
      if (!!i) {
        dispatch(actions.atualizarDocumento.request({ documento: request }));
      } else {
        dispatch(actions.incluirDocumento.request({ documento: request }));
      }
    },
    [dispatch, documentos]
  );

  const voltarParaLista = useCallback(() => {
    dispatch(actions.voltarListaDocumento());
  }, [dispatch]);

  return (
    <FormDocumento
      documento={documento}
      props={formProps}
      handleSubmit={handleSubmit}
      voltarParaLista={voltarParaLista}
    />
  );
};

export default DocumentosPageConnected;
