import basicFlow, { genericErrorHandler } from "./asyncHandler";
import { put, select } from "redux-saga/effects";
import { actions, types } from "../reducers/autenticacao.actions";
import {
  actions as routeActions,
  types as routes,
} from "../reducers/rotas.actions";
import { unauthenticatedRequest } from "../utils/api";
import {
  storeJwt,
  storeRefreshToken,
  storeZendeskToken,
  saveState,
  cleanJwt,
  cleanRefreshToken,
  cleanZendeskToken,
  cleanState,
  storeUser,
  getUser,
  cleanUser,
  storePass,
  getPass,
  cleanPass,
} from "../utils/localStorage";
import { toast } from "react-toastify";
import { getTokenValidacao } from "../selectors/autenticacao.selectors";
import { routeWatcher } from "./rotas.saga";

const loginApi = (values) => {
  return unauthenticatedRequest({
    url: "/auth/login",
    method: "post",
    body: values,
  });
};
const login = basicFlow({
  actionGenerator: actions.login,
  transform: ({ ref, ...values }) => {
    return values;
  },
  api: loginApi,
  postSuccess: function* ({ response, original }) {
    if (!!response.data.jwt) {
      yield saveState({ usuario: response.data.usuario });
      yield storeJwt(response.data.jwt);
      yield storeRefreshToken(response.data.refreshToken);
      yield storeZendeskToken(response.data.zendeskToken);
      yield put(routeActions.redirectTo(routes.PROPOSTAS));
    }
    if (!!response.data.token) {
      toast.info("Sua senha expirou!\nPor favor escolha uma nova senha.");
      yield put(
        routeActions.redirectTo(routes.TROCAR_SENHA, {
          token: response.data.token,
        })
      );
    }
    if (!!response.data.data_expiracao && !response.data.jwt) {
      yield storeUser(original.user);
      yield storePass(original.password);

      yield put(
        routeActions.redirectTo(routes.TOKEN, {
          data_expiracao: response.data.data_expiracao,
        })
      );
      toast.info("Enviamos um e-mail para você com um Token de acesso");
    }
  },
  postFailure: function* ({ original, error }) {
    if (original.ref) {
      original.ref.reset();
    }
    yield genericErrorHandler({ error });
  },
});

const tokenApi = (values) => {
  return unauthenticatedRequest({
    url: "/auth/login-token",
    method: "post",
    body: { ...values, user: getUser(), password: getPass() },
  });
};

const token = basicFlow({
  actionGenerator: actions.tokenLogin,
  transform: ({ ref, ...values }) => {
    return values;
  },
  api: tokenApi,
  postSuccess: function* ({ response }) {
    if (!!response.data.jwt) {
      yield saveState({ usuario: response.data.usuario });
      yield cleanUser();
      yield cleanPass();
      yield storeJwt(response.data.jwt);
      yield storeRefreshToken(response.data.refreshToken);
      yield storeZendeskToken(response.data.zendeskToken);
      yield put(routeActions.redirectTo(routes.PROPOSTAS));
    }
  },
  postFailure: function* ({ original, error }) {
    if (original.ref) {
      original.ref.reset();
    }
    yield genericErrorHandler({ error });
  },
});

const esqueciSenhaApi = (values) => {
  return unauthenticatedRequest({
    url: "/auth/esqueci-senha",
    method: "post",
    body: values,
  });
};
const esqueciSenha = basicFlow({
  actionGenerator: actions.esqueciSenha,
  transform: ({ ref, ...values }) => {
    return values;
  },
  api: esqueciSenhaApi,
  postSuccess: ({ original }) => {
    if (original.ref) {
      original.ref.reset();
    }
    toast.info(
      "Enviamos um e-mail para você com as instruções para a troca de senha."
    );
  },
  postFailure: function* ({ original, error }) {
    if (original.ref) {
      original.ref.reset();
    }
    yield genericErrorHandler({ error });
  },
});

const validarTokenSenhaApi = ({ token }) => {
  return unauthenticatedRequest({
    url: `/auth/validar-token/${token}`,
    method: "get",
  });
};

const validarTokenSenha = basicFlow({
  actionGenerator: actions.validarTokenSenha,
  transform: function* () {
    const token = yield select(getTokenValidacao);
    return { token };
  },
  api: validarTokenSenhaApi,
});

const reenviarTokenSenhaApi = (values) => {
  return unauthenticatedRequest({
    url: "/auth/reenviar-token",
    method: "post",
    body: {
      user: getUser(),
      password: getPass(),
      tokenRecaptcha: values.tokenRecaptcha,
    },
  });
};

const reenviarTokenSenha = basicFlow({
  actionGenerator: actions.reenviarTokenSenha,
  transform: ({ ref, ...values }) => {
    return values;
  },
  api: reenviarTokenSenhaApi,
  postSuccess: function* () {
    yield toast.info("Enviamos um e-mail para você com um Token de acesso");
  },
  postFailure: function* ({ original, error }) {
    if (original.ref) {
      original.ref.reset();
    }
    yield genericErrorHandler({ error });
  },
});

function* trocarSenhaRouteWatcher() {
  yield routeWatcher(routes.TROCAR_SENHA, function* () {
    yield put(actions.validarTokenSenha.request());
  });
}

const trocarSenhaApi = (values) => {
  return unauthenticatedRequest({
    url: `/auth/trocar-senha`,
    method: "post",
    body: values,
  });
};
const trocarSenha = basicFlow({
  actionGenerator: actions.trocarSenha,
  transform: function* (payload) {
    const token = yield select(getTokenValidacao);
    return { token, password: payload.password };
  },
  api: trocarSenhaApi,
  postSuccess: function* ({ response, original }) {
    if (!!response.data.jwt) {
      yield saveState({ usuario: response.data.usuario });
      yield storeJwt(response.data.jwt);
      yield storeRefreshToken(response.data.refreshToken);
      yield storeZendeskToken(response.data.zendeskToken);
      yield put(routeActions.redirectTo(routes.PROPOSTAS));
    }
    if (!!response.data.data_expiracao && !response.data.jwt) {
      yield storeUser(response.data.user);
      yield storePass(original.password);

      yield put(
        routeActions.redirectTo(routes.TOKEN, {
          data_expiracao: response.data.data_expiracao,
        })
      );
      toast.info("Enviamos um e-mail para você com um Token de acesso");
    }
  },
});

function* logoutWatcher() {
  yield routeWatcher(types.LOGOUT, function* () {
    yield cleanState();
    yield cleanJwt();
    yield cleanRefreshToken();
    yield cleanZendeskToken();
    yield put(routeActions.redirectTo(routes.LOGIN));
  });
}

export const sagas = [
  login.watcher(),
  token.watcher(),
  esqueciSenha.watcher(),
  reenviarTokenSenha.watcher(),
  validarTokenSenha.watcher(),
  trocarSenha.watcher(),
  trocarSenhaRouteWatcher(),
  logoutWatcher(),
];
