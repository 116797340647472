import ClausulaQualificacaoPage from "./ClausulaQualificacaoPage";
import { useDispatch, useSelector } from "react-redux";
import { getMatrizClausulas } from "../../../../selectors/administracao.selectors";
import { useCallback } from "react";
import { actions } from "../../../../reducers/administracao/matrizClausulas.actions";
import validators from "../../../../utils/validators";
import { useForm } from "react-hook-form";
import MatrizChecklistPage from "../../matriz/MatrizChecklistPage";

const ClausulaQualificacaoPageConnected = () => {
  const dispatch = useDispatch();
  const matrizClausulas = useSelector(getMatrizClausulas);
  const loading = matrizClausulas?.loading;
  const total = matrizClausulas?.total;
  const pagina = matrizClausulas?.page;
  const filter = matrizClausulas?.filter;

  const handlePagination = useCallback(
    (e, page) => {
      dispatch(actions.listarMatriz.request({ pagina: page, filter: filter }));
    },
    [dispatch, filter]
  );

  const handleEdit = useCallback((values) => {
    dispatch(actions.editarMatriz({ item: values }));
  });

  const handleNew = useCallback(() => {
    dispatch(actions.novaMatriz());
  }, [dispatch]);

  const handleDelete = useCallback(
    (i) => {
      dispatch(actions.excluirMatriz.request({ id: i }));
    },
    [dispatch]
  );

  const rules = {
    tipoQualificacao: validators.string({ required: false }),
    estadoCivil: validators.string({ required: false }),
    regimeCasamento: validators.string({ required: false }),
  };
  const initialValues = {
    estadoCivil: filter?.estadoCivil,
    regimeCasamento: filter?.regimeCasamento,
    tipoQualificacao: filter?.tipoQualificacao,
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const handleSearch = useCallback(
    (values) => {
      const request = {
        by: "nome",
        value: values.search,
      };
      dispatch(actions.buscarClausula.request({ search: request, pagina: 1 }));
    },
    [dispatch]
  );

  const handleFilterClick = useCallback(
    (value) => {
      dispatch(actions.alterarFiltro(value));
      dispatch(
        actions.listarMatriz.request({
          pagina: 1,
          filter: value,
        })
      );
    },
    [dispatch]
  );

  const resetFilter = useCallback(() => {
    dispatch(
      actions.alterarFiltro({
        tipoQualificacao: "",
        estadoCivil: "",
        regimeCasamento: "",
      })
    );
  }, [dispatch]);

  return (
    <ClausulaQualificacaoPage
      matriz={matrizClausulas?.list}
      item={matrizClausulas?.item}
      clausulas={matrizClausulas?.clausulas}
      loading={loading}
      total={total}
      handlePagination={handlePagination}
      pagina={pagina}
      handleNew={handleNew}
      handleEdit={handleEdit}
      formProps={formProps}
      handleSearch={handleSearch}
      handleDelete={handleDelete}
      filtro={filter}
      handleFilterClick={handleFilterClick}
      resetFilter={resetFilter}
    />
  );
};

export default ClausulaQualificacaoPageConnected;
