import basicFlow from "../asyncHandler";
import { actions } from "../../reducers/administracao/atividades.actions";
import { authenticatedRequest } from "../../utils/api";
import { routeWatcher } from "../rotas.saga";
import { types as routes } from "../../reducers/rotas.actions";
import { put } from "redux-saga/effects";
import { toast } from "react-toastify";
import { actions as dominiosAction } from "../../reducers/dominios.actions";

const listarAtividadesApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/atividades",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const atualizarAtividadeApi = ({ atividade }) => {
  return authenticatedRequest({
    url: "/administracao/atividade",
    isResourceService: true,
    method: "post",
    body: { atividade: atividade },
  });
};

const excluirAtividadeApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/atividade/delete",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const buscarAtividadesApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/atividade/search",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const listarAtividades = basicFlow({
  actionGenerator: actions.listarAtividades,
  api: listarAtividadesApi,
});

const atualizarAtividade = basicFlow({
  actionGenerator: actions.atualizarAtividade,
  api: atualizarAtividadeApi,
  postSuccess: function () {
    toast.success("Atualização realizada com sucesso");
  },
});

const incluirAtividade = basicFlow({
  actionGenerator: actions.incluirAtividade,
  api: atualizarAtividadeApi,
  postSuccess: function () {
    toast.success("Inclusão realizada com sucesso");
  },
});

const excluirAtividade = basicFlow({
  actionGenerator: actions.excluirAtividade,
  api: excluirAtividadeApi,
  postSuccess: function () {
    toast.success("Exclusão realizada com sucesso");
  },
});

const buscarAtividade = basicFlow({
  actionGenerator: actions.buscarAtividade,
  api: buscarAtividadesApi,
});

function* listaAtividadesRouteWatcher() {
  const pagina = 1;
  yield routeWatcher(routes.ADMINISTRACAO_ATIVIDADES, function* () {
    yield put(
      actions.listarAtividades.request({
        pagina,
      })
    );
    yield put(dominiosAction.etapas.request());
    yield put(dominiosAction.templates.request());
  });
}

export const sagas = [
  listarAtividades.watcher(),
  listaAtividadesRouteWatcher(),
  atualizarAtividade.watcher(),
  incluirAtividade.watcher(),
  buscarAtividade.watcher(),
  excluirAtividade.watcher(),
];
