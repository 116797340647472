import {
  ControlledSelect,
  ControlledTextfield,
  DateTextField,
} from "../../../../../components/inputs";
import { MenuItem } from "../../../../../components";
import React, { useState } from "react";

const CardEscrituraPublica = ({ props, disabled, uf, dominioCartorios }) => {
  const [ufSelecionada, setUfSelecionada] = useState("");
  return (
    <div className="card spacer">
      <div className="card-body">
        <span className="card-title color-brand">Escritura Pública</span>
        <div className="row">
          <div className="col-12 col-md-6 col-lg-4">
            <ControlledTextfield
              formProps={props}
              label={"Data Escritura"}
              name={"dataEscritura"}
              disabled={disabled}
              margin={"none"}
              InputProps={{
                inputComponent: DateTextField,
              }}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <ControlledTextfield
              formProps={props}
              label={"Nº do Livro"}
              name={"numeroLivroEscritura"}
              disabled={disabled}
              margin={"none"}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <ControlledTextfield
              formProps={props}
              label={"Nº Folha"}
              name={"numeroFolhaEscritura"}
              disabled={disabled}
              margin={"none"}
            />
          </div>

          <div className="col-12 col-md-6 col-lg-9">
            <ControlledTextfield
              formProps={props}
              label={"Comarca"}
              name={"comarcaEscritura"}
              disabled={disabled}
              margin={"none"}
            />
          </div>
          <div className="col-12 col-md-6 col-lg-3">
            <ControlledSelect
              name={"ufEscritura"}
              formProps={props}
              disabled={disabled}
              label={"UF"}
              onChange={(e) => setUfSelecionada(e.target.value)}
              margin={"none"}
            >
              {uf.map((item) => (
                <MenuItem
                  className={"select-submenu-item"}
                  key={item.codigo}
                  value={item.codigo}
                >
                  {item.nome}
                </MenuItem>
              ))}
            </ControlledSelect>
          </div>
          <div className="col-12 col-md-6 col-lg-12">
            <ControlledTextfield
              formProps={props}
              label={"Cartório Escritura"}
              name={"nomeCartorioEscritura"}
              disabled={disabled}
              margin={"none"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardEscrituraPublica;
