import React, { useState } from "react";
import { Button } from "../../../../components";
import { FileInput } from "../../../../components/inputs";
import { toast } from "react-toastify";
import { isIOS } from "react-device-detect";

export const getAcceptedTypes = (tipo) => {
  if (tipo === "P") {
    return ".pdf,.jpg,.jpeg,.gif,.png,.bmp";
  }
  return undefined;
};

const onFilesRejectedDefault = (rejected, accepted, acceptedTypes) => {
  if (accepted.length === 0) {
    if (rejected.length > 1) {
      toast.warn(
        `Todos os arquivos selecionados possuem extensões inválidas.\nAs extensões válidas são [${acceptedTypes}]`
      );
    } else {
      toast.warn(
        `O arquivo selecionado possui extensão inválida.\nAs extensões válidas são [${acceptedTypes}]`
      );
    }
  } else {
    if (rejected.length > 1) {
      toast.warn(
        `${rejected.length} arquivos selecionados possuem extensões inválidas.\nAs extensões válidas são [${acceptedTypes}]`
      );
    } else {
      toast.warn(
        `1 arquivo selecionado possui extensão inválida.\nAs extensões válidas são [${acceptedTypes}]`
      );
    }
  }
};

const onFileLengthExceededDefault = () =>
  toast.error("Tamanho do arquivo excedeu o limite de 10 Mb");

const BotaoUploadDocumento = ({
  name,
  onChange,
  onFilesRejected = onFilesRejectedDefault,
  onFileAlreadyExists,
  onFileLengthExceeded = onFileLengthExceededDefault,
  maxFiles,
  uploading,
  className,
  onMaxFilesReached,
  accept,
  fullWidth,
  ...otherProps
}) => {
  const [value, setValue] = useState([]);

  const handleDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0 && onFilesRejected) {
      onFilesRejected(rejectedFiles, acceptedFiles, accept);
    }
    if (acceptedFiles.length === 0) {
      return;
    }
    const files = acceptedFiles
      .filter(
        (item) =>
          !value.some(
            (file) =>
              item.name === file.name &&
              item.type === file.type &&
              item.size === file.size &&
              item.lastModified === file.lastModified
          )
      )
      .map((file) => {
        file.id = `${new Date().getTime()}${Math.floor(Math.random() * 1000)}`;
        return file;
      });

    if (files.length !== acceptedFiles.length && !!onFileAlreadyExists) {
      onFileAlreadyExists();
      return;
    }

    if (!!maxFiles && maxFiles > 0 && value.concat(files).length > maxFiles) {
      if (!!onMaxFilesReached) {
        onMaxFilesReached();
      }
      return;
    }
    const fileLengthExceeded = files.filter(
      (item) => item.size > 10 * 1024 * 1024
    );
    if (fileLengthExceeded.length > 0) {
      onFileLengthExceeded(fileLengthExceeded);
      return;
    }
    setValue(value.concat(files));
    !!onChange && onChange({ name }, files);
  };

  const handleDeleteFile = (file) => {
    setValue(value.filter((item) => item.id !== file.id));
  };

  return (
    <FileInput
      name={name}
      disabled={uploading}
      onDrop={handleDrop}
      files={value}
      fullWidth={fullWidth}
      onDeleteFile={handleDeleteFile}
      accept={accept}
      capture={isIOS ? undefined : true}
      {...otherProps}
    >
      <Button
        size="small"
        loading={uploading}
        variant={"outlined"}
        fullWidth={fullWidth}
        loaderColor={"#005298"}
        classes={{
          root: "transparent-button " + className,
          label: "texto-upload color-secondary ",
        }}
        label={"FAZER UPLOAD"}
      />
    </FileInput>
  );
};

export default BotaoUploadDocumento;
