import React from "react";
import { Dialog, DialogContent, IconButton } from "@material-ui/core";
import { ControlledAutocomplete } from "../../../../components/inputs";
import InputLoader from "../../../../components/loaders/InputLoader";
import { Button, MenuItem } from "../../../../components";
import { CloseSharp } from "@material-ui/icons";

const AdicionarDocumentoDialog = ({
  handleSubmit,
  formProps,
  dominioDocumentos,
  loading,
  submitting,
  handleClose,
  handleOpen,
  open,
}) => {
  return (
    <Dialog
      onClose={handleClose}
      onEnter={handleOpen}
      classes={{ paper: "w100" }}
      maxWidth="sm"
      open={open}
    >
      <DialogContent>
        <IconButton
          onClick={handleClose}
          classes={{ root: "position-absolute clickable close-icon" }}
        >
          <CloseSharp />
        </IconButton>
        <div className={"page-title"}>Adicionar Documento</div>
        <form
          className="d-flex flex-column"
          onSubmit={formProps.handleSubmit(handleSubmit)}
        >
          <div className="row">
            <div className="col-12">
              <ControlledAutocomplete
                name={"documento"}
                margin={"none"}
                formProps={formProps}
                labelField={"nome"}
                domain={dominioDocumentos}
              />
              {loading && <InputLoader color={"red"} />}
            </div>
          </div>
          <div className={"row justify-content-center mb-3 mt-2"}>
            <div className="col-6 mt-3">
              <Button
                loading={submitting}
                label={"CONFIRMAR"}
                type={"submit"}
              />
            </div>
          </div>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AdicionarDocumentoDialog;
