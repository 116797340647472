import {
  createAsyncAction,
  createSyncAction,
} from "../../utils/actionCreators";

export const types = {
  PROCESSAR: "@@carga-processos/PROCESSAR",
  UPDATE_LABEL: "@@carga-processos/UPDATE_LABEL",
};

export const actions = {
  processar: createAsyncAction(types.PROCESSAR),
  updateLabel: (label) => createSyncAction(types.UPDATE_LABEL, label),
};
