import React from "react";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

const MenuProposta = ({
  itens,
  menuSelecionado,
  selecionarOpcaoMenu,
  proposta,
  perfil,
}) => (
  <ToggleButtonGroup
    value={menuSelecionado}
    classes={{
      root: "d-flex",
    }}
    exclusive
    onChange={(e, value) => selecionarOpcaoMenu(e, value, proposta)}
    style={{
      flexWrap: "wrap",
    }}
  >
    {itens.map((item) => {
      if (!!item.perfis) {
        if (item.perfis.filter((p) => p === perfil).length === 0) {
          return <></>;
        }
      }
      return (
        <ToggleButton
          value={item.key}
          key={item.key}
          classes={{
            root: "flex menu-proposta",
            label: "menu-proposta-label d-flex flex-column",
            selected: "menu-proposta-selected",
          }}
        >
          {item.icon}
          {item.label}
        </ToggleButton>
      );
    })}
  </ToggleButtonGroup>
);

export default MenuProposta;
