import { createSyncAction } from "../utils/actionCreators";
import { NOT_FOUND } from "redux-first-router";

export const types = {
  NOT_FOUND: NOT_FOUND,
  OPEN_WINDOW: "@@route/OPEN_WINDOW",
  LOGIN: "@@route/login",
  ESQUECI_SENHA: "@@route/esqueci-minha-senha",
  TROCAR_SENHA: "@@route/trocar-senha",
  TOKEN: "@@route/token",
  PROPOSTAS: "@@route/propostas",
  ARTEFATOS_DOCUMENTO: "@@route/artefatos-documento",
  CARGA_CONSULTAS: "@@route/carga-consultas",
  CADASTRO_DOCUMENTOS: "@@route/cadastro-documentos",
  CADASTRO_PRODUTOS: "@@route/cadastro-produtos",
  PROPOSTA: "@@route/proposta",
  PARTICIPANTE: "@@route/proposta/participante",
  IMOVEL: "@@route/proposta/imovel",
  NOVO_PARTICIPANTE: "@@route/proposta/novo-participante",
  ENVIO_EMAIL: "@@route/proposta/envio-email",
  INTERVENIENTE_QUITANTE: "@@route/proposta/interveniente",
  ADMINISTRACAO_USUARIOS: "@@route/administracao/usuarios",
  ADMINISTRACAO_ORGANIZACAO: "@@route/administracao/organizacao",
  EXPORT_CONSULTAS: "@@route/exportar-consultas",
  ADMINISTRACAO_DOCUMENTOS: "@@route/administracao/documentos",
  ADMINISTRACAO_ATIVIDADES: "@@route/administracao/atividades",
  ADMINISTRACAO_ETAPAS: "@@route/administracao/etapas",
  ADMINISTRACAO_MCHECKLIST: "@@route/administracao/mchecklist",
  ADMINISTRACAO_PRODUTOS: "@@route/administracao/produtos",
  ADMINISTRACAO_PERFIS: "@@route/administracao/perfis",
  EXPORT_SLA: "@@route/exportar-sla",
  DASHBOARD: "@@routes/dashboard",
  ADMINISTRACAO_LOCALIDADES: "@@route/administracao/localidades",
  ADMINISTRACAO_MOTIVO_CANCELAMENTO:
    "@@route/administracao/motivo-cancelamento",
  ADMINISTRACAO_CLAUSULAS: "@@route/administracao/clausulas",
  ADMINISTRACAO_MATRIZ_CLAUSULAS: "@@route/administracao/matriz-clausulas",
  ADMINISTRACAO_TEMPLATES: "@@route/administracao/templates",
  ADMINISTRACAO_INTEGRA_DOC: "@@route/administracao/integra-doc",
  ADMINISTRACAO_RELATORIO_AUDITORIA:
    "@@route/administracao/relatorio-auditoria",
};

export const actions = {
  redirectTo: (route, params = {}) => createSyncAction(route, params),
  openNewWindowWith: (route, params = {}) =>
    createSyncAction(types.OPEN_WINDOW, {
      route,
      params,
    }),
  rejectTo: (route, params = {}) =>
    createSyncAction(route, params, {
      meta: {
        location: {
          kind: "redirect",
        },
      },
    }),
};
