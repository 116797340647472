import React, { useState } from "react";
import { IconButton, Popover, Tooltip } from "@material-ui/core";
import {
  ListAltOutlined,
  DoneSharp,
  InfoOutlined,
  MailOutlined,
} from "@material-ui/icons";
import {
  situacaoAtividade,
  responsavelAtividade,
} from "../../../../utils/parametros";
import { Button, MenuItem } from "../../../../components";
import Menu from "@material-ui/core/Menu";
import DataContratoDialog from "./DataContratoDialog";
import ComentarioDialog from "./ComentarioDialog";

const getSla = (atividade) => {
  const dataSla = !!atividade.dataSla
    ? new Date(atividade.dataSla).toLocaleDateString("pt-BR", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit",
      })
    : null;

  const sla = !!atividade.Atividade.sla ? `(D+${atividade.Atividade.sla})` : "";

  if (!!dataSla) {
    if (!!sla) {
      return `${dataSla} ${sla}`;
    } else {
      return dataSla;
    }
  }
  return sla;
};

const Atividade = ({
  atividade,
  index,
  perfil,
  handleOpenDetalhesDocumento,
  atualizarSituacao,
  loading,
  handleOpenEnvioEmail,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [openDataContratoPopUp, setOpenDataContratoPopUp] = useState(false);
  const [dadosEncerramento, setDadosEncerramento] = useState({});
  const [openComentarioPopUp, setOpenComentarioPopUp] = useState(false);

  const handleCloseComentarioPopUp = () => {
    setOpenComentarioPopUp(false);
  };
  const handleOpenComentarioPopUp = (evento, atividade, situacao) => {
    setDadosEncerramento({
      evento: evento,
      atividade: atividade,
      situacao: situacao,
    });
    setOpenComentarioPopUp(true);
  };

  const handleCloseDataContratoPopUp = () => {
    setOpenDataContratoPopUp(false);
  };
  const handleOpenDataContratoPopUp = (evento, atividade, situacao) => {
    setDadosEncerramento({
      evento: evento,
      atividade: atividade,
      situacao: situacao,
    });
    setOpenDataContratoPopUp(true);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSubmitDataContratoPopUp = (dataContrato) => {
    atualizarSituacao(
      dadosEncerramento.evento,
      dadosEncerramento.atividade,
      dadosEncerramento.situacao,
      dataContrato
    );
  };

  const handleSubmitComentarioPopUp = (dataContrato, comentario) => {
    atualizarSituacao(
      dadosEncerramento.evento,
      dadosEncerramento.atividade,
      dadosEncerramento.situacao,
      dataContrato,
      comentario
    );
  };

  const open = Boolean(anchorEl);

  return (
    <div
      className={
        index % 2 === 0
          ? "row mt-0 d-flex align-items-center medium-row tr-even"
          : "row mt-0 d-flex align-items-center medium-row tr-odd"
      }
    >
      <div className="col-sm-12 col-lg-5 mt-sm-2 mt-lg-0">
        <div className={"d-flex align-items-center"}>
          <div className="resumir-texto">
            <Tooltip title={atividade.Atividade.nome}>
              <span>{atividade.Atividade.nome}</span>
            </Tooltip>
          </div>
          {!!atividade.Atividade.orientacao && (
            <>
              <IconButton className={"p-2"} onClick={handleClick}>
                <InfoOutlined className="info-documento" />
              </IconButton>
              <Popover
                id={atividade.Atividade.id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "center",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "center",
                }}
              >
                <div className="p-2 bg-dark color-white orientacao">
                  {atividade.Atividade.orientacao}
                </div>
              </Popover>
            </>
          )}
        </div>
      </div>
      <div className="col-sm-12 col-lg-1 mt-sm-2 mt-lg-0 resumir-texto">
        <Tooltip
          title={
            responsavelAtividade.filter(
              (s) => s.id === atividade.Atividade.responsavel
            )[0].nome
          }
        >
          <span>
            {
              responsavelAtividade.filter(
                (s) => s.id === atividade.Atividade.responsavel
              )[0].nome
            }
          </span>
        </Tooltip>
      </div>
      <div
        className={
          Date.parse(atividade.dataSla) < Date.now()
            ? "col-sm-12 col-lg-2 mt-sm-2 mt-lg-0 sla-vencido"
            : "col-sm-12 col-lg-2 mt-sm-2 mt-lg-0"
        }
      >
        <span>{getSla(atividade)}</span>
      </div>
      <div className="col my-2 my-lg-0">
        <div className="d-flex">
          <BotaoAtividade
            key={atividade.atividade}
            atividade={atividade}
            atualizarSituacao={atualizarSituacao}
            perfil={perfil}
            loading={loading}
            handleOpenDataContratoPopUp={handleOpenDataContratoPopUp}
            handleOpenComentarioPopUp={handleOpenComentarioPopUp}
          />
        </div>
      </div>
      <div className="col-sm-12 col-lg-auto my-2 my-lg-0 w-92 d-flex justify-content-end">
        {perfil === "BPO" &&
          !!atividade.Atividade.templates &&
          atividade.Atividade.templates.length > 0 && (
            <Tooltip title="Envio de E-mails">
              <IconButton
                className="p-2"
                onClick={() => handleOpenEnvioEmail(atividade.atividade)}
              >
                <MailOutlined className={"color-secondary icon-indexado"} />
              </IconButton>
            </Tooltip>
          )}
        <Tooltip title="Detalhes da Atividade">
          <IconButton
            className="p-2"
            onClick={() => handleOpenDetalhesDocumento(atividade)}
          >
            <ListAltOutlined className={"color-secondary icon-indexado"} />
          </IconButton>
        </Tooltip>
      </div>
      {openDataContratoPopUp && (
        <DataContratoDialog
          open={openDataContratoPopUp}
          handleClose={handleCloseDataContratoPopUp}
          handleSubmit={handleSubmitDataContratoPopUp}
        />
      )}
      {openComentarioPopUp && (
        <ComentarioDialog
          open={openComentarioPopUp}
          handleClose={handleCloseComentarioPopUp}
          handleSubmit={handleSubmitComentarioPopUp}
          hasComentario={atividade.Atividade.isComentarioObrigatorio === "S"}
          hasData={atividade.Atividade.isProducao === "S"}
        />
      )}
    </div>
  );
};

const BotaoAtividade = ({
  atividade,
  atualizarSituacao,
  perfil,
  loading,
  handleOpenDataContratoPopUp,
  handleOpenComentarioPopUp,
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    if (atividade.situacao !== "C" && perfil === "BPO")
      setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Button
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        loading={loading}
        key={atividade.atividade}
        className={
          atividade.situacao === "C"
            ? "btn-atualiza-atividade-concluido"
            : "btn-atualiza-atividade"
        }
        label={
          situacaoAtividade.filter((s) => s.id === atividade.situacao)[0].nome
        }
        endIcon={
          atividade.situacao === "C" ? (
            <DoneSharp className={"color-concluido"} />
          ) : (
            ""
          )
        }
      />
      <Menu
        id="simple-menu"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={handleClose}
        key={"menu" + atividade.atividade}
      >
        {situacaoAtividade.map((s) => (
          <MenuItem
            onClick={(evento) => {
              if (
                atividade.Atividade.isProducao === "S" &&
                atividade.Atividade.isComentarioObrigatorio !== "S" &&
                s.id === "C"
              ) {
                handleOpenDataContratoPopUp(evento, atividade, s.id);
              } else if (
                atividade.Atividade.isComentarioObrigatorio === "S" &&
                s.id === "C"
              ) {
                handleOpenComentarioPopUp(evento, atividade, s.id);
              } else {
                atualizarSituacao(evento, atividade, s.id);
              }
              handleClose();
            }}
            key={atividade.atividade + " " + s.id}
          >
            {s.nome}
          </MenuItem>
        ))}
      </Menu>
    </>
  );
};

export default Atividade;
