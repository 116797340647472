import { getValueFromObject } from "../../utils/basic";

import { Controller } from "react-hook-form";
import OtpTextfield from "./OtpTextfield";

const ControlledOtpTextField = ({
  formProps,
  name,
  margin,
  numInputs,
  separator,
  placeholder,
  isInputNum,
  ...otherProps
}) => {
  const {
    control,
    formState: { errors },
    rules,
    initialValues,
  } = formProps;
  const isError = getValueFromObject(errors, name) !== undefined;

  return (
    <Controller
      name={name}
      control={control}
      rules={rules[name]}
      defaultValue={getValueFromObject(initialValues, name)}
      margin={margin}
      render={({ field: { onChange, onBlur, value } }) => (
        <OtpTextfield
          {...otherProps}
          numInputs={numInputs}
          separator={separator}
          value={value}
          margin={margin}
          error={isError}
          placeholder={placeholder}
          isInputNum={isInputNum}
          helperText={getValueFromObject(errors, name)?.message}
          onChange={(v) => {
            onChange(v);
            !!otherProps.onChange && otherProps.onChange(v);
          }}
          onBlur={() => {
            onBlur();
            !!otherProps.onBlur && otherProps.onBlur(value);
          }}
        />
      )}
    />
  );
};

export default ControlledOtpTextField;
