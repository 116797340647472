import React, { useCallback, useState } from "react";
import formatters from "../../utils/formatters";
import Divider from "@material-ui/core/Divider";
import { Autorenew } from "@material-ui/icons";
import Loader from "../../components/loaders";
import {
  Button,
  VisibleWithoutProfile,
  VisibleWithProfile,
} from "../../components";
import TrocarAnalistaDialog from "./analista/TrocarAnalistaDialog.connected";

const DetalheProposta = ({ data, loading, abrirCockpit }) => {
  const [dadosTrocarAnalista, setDadosTrocarAnalista] = useState({
    open: false,
  });
  const handleCloseTrocarAnalista = useCallback(() => {
    setDadosTrocarAnalista({
      open: false,
    });
  }, []);
  const handleOpenTrocarAnalista = useCallback(() => {
    setDadosTrocarAnalista({
      open: true,
      analista: data.usuarioBackoffice?.id,
      consulta: data.id,
    });
  }, [data]);

  if (!!loading) {
    return (
      <div className="col-12 d-flex justify-content-center">
        <Loader type={"dots"} color={"brand"} size={50} />
      </div>
    );
  }

  return (
    <>
      <div className="col-12 mt-0">
        {data?.parceiro?.nome && (
          <div className="row">
            <div className="col-md-6 col-xs-12">
              <span className="label">Parceiro</span>
              <div className="d-flex flex-row align-items-center">
                <span className="data-label">{data.parceiro.nome}</span>
              </div>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-md-3 col-xs-12">
            <span className="label">Tipo de Operação</span>
            <div>
              <span className="data-label">{data?.Produto?.nome}</span>
            </div>
          </div>
          <div className="col-md-6 col-xs-12">
            <span className="label">Canal de Originação</span>
            <div>
              <span className="data-label">{data?.canalCaptacao}</span>
            </div>
          </div>
          {data?.dataContrato && (
            <div className="col-md-3 col-xs-12">
              <span className="label">Data do Contrato</span>
              <div>
                <span className="data-label">{data?.dataContrato}</span>
              </div>
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-12">
            <Divider />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6 col-xs-12">
            <span className="label">Agência</span>
            <div className="d-flex flex-row align-items-center">
              <span className="data-label">
                {data?.organizacao?.codigo} - {data?.organizacao?.nome}
              </span>
            </div>
          </div>
          <VisibleWithoutProfile profiles={["CLIENTE"]}>
            <div className="col-md-6 col-xs-12">
              <span className="label">Analista Backoffice</span>
              <div className="d-flex flex-row align-items-center">
                <span className="data-label text-transform-unset">
                  {data.usuarioBackoffice?.nome ?? "(Nenhum)"}
                </span>
                <VisibleWithProfile profiles={["ADMINISTRADOR"]}>
                  <Autorenew
                    className={"change-analyst-icon"}
                    onClick={handleOpenTrocarAnalista}
                  />
                </VisibleWithProfile>
              </div>
            </div>
          </VisibleWithoutProfile>
        </div>
        <div className="row">
          <div className="col-md-6 col-xs-12">
            {data.cliente.length > 0 && (
              <span className="label">Comprador</span>
            )}
            {data.cliente.map((comprador, index) => (
              <div key={index}>
                <span className="data-label">{comprador.nome}</span>
              </div>
            ))}
          </div>
          <div className="col-md-6 col-xs-12">
            {data.vendedor.length > 0 && (
              <span className="label">Vendedor</span>
            )}
            {data.vendedor.map((vendedor, index) => (
              <div key={index}>
                <span className="data-label">{vendedor.nome}</span>
              </div>
            ))}
          </div>
        </div>
        <div className="row">
          {data?.interveniente?.nomeInterveniente?.length > 0 && (
            <div className="col-md-6 col-xs-12">
              <span className="label">Interveniente Quitante</span>
              <div>
                <span className="data-label">
                  {data?.interveniente?.nomeInterveniente}
                </span>
              </div>
            </div>
          )}
          <div className="col-md-6 col-xs-12">
            <span className="label">Usa FGTS?</span>
            <div>
              <span className="data-label">
                {data?.cliente?.some(
                  (cliente) =>
                    cliente?.PessoaDadoPessoal?.fgts === "S" ||
                    cliente?.PessoaDadoPessoal?.fgtsConjuge === "S"
                )
                  ? "SIM"
                  : "NÃO"}
              </span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Divider />
          </div>
        </div>
        <div className="row">
          <div className="col-md-3 col-6">
            <span className="label">Avaliação</span>
            <div>
              <span className="data-label">
                R${" "}
                {!!data.imovel[0].valorAvaliacao
                  ? formatters.numbers.currency(data.imovel[0].valorAvaliacao)
                  : ""}
                &nbsp;
              </span>
            </div>
          </div>
          <div className="col-md-3 col-6">
            <span className="label">Imóvel</span>
            <div>
              <span className="data-label">
                R${" "}
                {!!data.simulacao.valorImovel
                  ? formatters.numbers.currency(data.simulacao.valorImovel)
                  : ""}
                &nbsp;
              </span>
            </div>
          </div>
          <div className="col-md-3 col-6">
            <span className="label">Financiamento</span>
            <div>
              <span className="data-label">
                R${" "}
                {formatters.numbers.currency(data.simulacao.valorFinanciamento)}
              </span>
            </div>
          </div>
          <div className="col-md-3 col-6">
            <span className="label">Compr. Renda</span>
            <div>
              <span className="data-label">
                {formatters.numbers.currency(
                  data.simulacao.comprometimentoRenda
                ) + " %"}
              </span>
            </div>
          </div>
          <div className="col-md-3 col-6">
            {false && (
              <>
                <span className="label">Prestação</span>
                <div>
                  <span className="data-label">
                    R${" "}
                    {formatters.numbers.currency(data.simulacao.valorPrestacao)}
                  </span>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="row">
          <div className="col-6 col-md-3">
            <span className="label">Taxa Efetiva</span>
            <div>
              <span className="data-label">
                {formatters.numbers.currency(
                  data.simulacao.taxaJurosEfetivaAnual
                )}{" "}
                % a.a
              </span>
            </div>
          </div>
          <div className="col-6 col-md-3">
            <span className="label">CET</span>
            <div>
              <span className="data-label">
                {formatters.numbers.currency(data.simulacao.cetAnual)} %
              </span>
            </div>
          </div>
          <div className="col-6 col-md-3">
            <span className="label">Sist. Amortização</span>
            <div>
              <span className="data-label">
                {data.simulacao.tipoAmortizacao}
              </span>
            </div>
          </div>
          <div className="col-md-3 col-6">
            <span className="label">Enquadramento</span>
            <div>
              <span className="data-label">{data.simulacao.enquadramento}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12">
            <Divider />
          </div>
        </div>
        <div className="row">
          <div className="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4 mt-3">
            <Button
              label={"COCKPIT DA PROPOSTA"}
              onClick={() => abrirCockpit(data.id)}
            />
          </div>
        </div>
      </div>

      <TrocarAnalistaDialog
        dados={dadosTrocarAnalista}
        handleClose={handleCloseTrocarAnalista}
      />
    </>
  );
};

export default DetalheProposta;
