import React, { useState } from "react";
import {
  ControlledSwitch,
  ControlledTextfield,
  DecimalTextField,
  ControlledSelect,
  CurrencyTextField,
} from "../../../../../components/inputs";
import { MenuItem } from "../../../../../components";

const CardOutrasInformacoes = ({ props, disabled, dominioCartorios }) => {
  return (
    <div className="card spacer">
      <div className="card-body">
        <span className="card-title color-brand">Dados Complementares</span>
        <div className="row">
          <div className="col-12 col-md-4 mt-0">
            <ControlledTextfield
              name={"valorAvaliacao"}
              disabled={disabled}
              margin={"none"}
              formProps={props}
              label={"Valor de Avaliação"}
              InputProps={{
                inputComponent: CurrencyTextField,
              }}
            />
          </div>
          <div className="col-6 col-md-2 mt-md-0">
            <ControlledSwitch
              formProps={props}
              disabled={disabled}
              margin={"none"}
              name={"condominio"}
              label={"Condomínio?"}
            />
          </div>
          <div className="col-6 col-md-2 mt-md-0">
            <ControlledSwitch
              formProps={props}
              disabled={disabled}
              margin={"none"}
              name={"foreiro"}
              label={"Imóvel foreiro?"}
            />
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-md-12">
            <ControlledSelect
              name={"cartorio"}
              formProps={props}
              label={"Cartório"}
              margin={"none"}
              onChange={() => true}
            >
              <MenuItem className={"select-submenu-item"} value={""}>
                <em>(Nenhum)</em>
              </MenuItem>
              {dominioCartorios.map((item) => (
                <MenuItem
                  className={"select-submenu-item"}
                  key={item.id}
                  value={item.id}
                >
                  {item.nome}
                </MenuItem>
              ))}
            </ControlledSelect>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardOutrasInformacoes;
