import React, { useCallback } from "react";
import {
  ControlledSelect,
  ControlledTextfield,
  IntegerTextField,
  ControlledSwitch,
  ControlledFileInput,
} from "../../../components/inputs";
import { Description, Delete } from "@material-ui/icons";
import { Button, MenuItem } from "../../../components";
import { origemDocumento } from "../../../utils/parametros";
import formatters from "utils/formatters";

const fileSize = (size) => {
  if (size > 1024 * 1024) {
    // Mb
    return (
      formatters.numbers.nFixed(Math.round(size / (1024 * 1024), 1), 1) + " Mb"
    );
  }
  if (size > 1024) {
    // Mb
    return formatters.numbers.nFixed(size / 1024, 1) + " Kb";
  }
  return size + " bytes";
};

const FormDocumento = ({
  props,
  disabled,
  handleSubmit,
  documento,
  voltarParaLista,
}) => {
  const handleClickNext = useCallback(
    (values) => {
      handleSubmit(values, documento.id);
    },
    [handleSubmit, documento]
  );

  return (
    <div className="card spacer">
      <div className="card-body">
        <span className="card-title color-brand">Documento</span>
        <form onSubmit={props.handleSubmit(handleClickNext)}>
          <div className="row">
            <div className="col-12 col-md-3">
              <ControlledTextfield
                name={"codigo"}
                margin={"none"}
                disabled={disabled}
                formProps={props}
                label={"Código"}
                InputProps={{
                  inputComponent: IntegerTextField,
                }}
              />
            </div>
            <div className="col-12 col-md-9">
              <ControlledTextfield
                name={"nome"}
                formProps={props}
                uppercase={true}
                disabled={disabled}
                label={"Documento"}
                margin={"none"}
                inputProps={{ maxLength: 200 }}
              />
            </div>
            <div className="col-12 col-md-3">
              <ControlledSelect
                name={"origem"}
                formProps={props}
                label={"Origem"}
                margin={"none"}
              >
                {origemDocumento.map((item) => (
                  <MenuItem
                    className={"select-submenu-item"}
                    key={item.id}
                    value={item.id}
                  >
                    {item.descricao}
                  </MenuItem>
                ))}
              </ControlledSelect>
            </div>
            <div className="col-12 col-md-3">
              <ControlledTextfield
                name={"codigoIntegracao"}
                margin={"none"}
                disabled={disabled}
                formProps={props}
                label={"Códigos de Integração"}
              />
            </div>
            <div className="col-12 col-md-2">
              <ControlledSwitch
                name={"interno"}
                formProps={props}
                label={"Documento Interno?"}
                margin={"none"}
                disabled={disabled}
              />
            </div>
            <div className="col-12 col-md-2">
              <ControlledSwitch
                name={"permiteDispensa"}
                formProps={props}
                label={"Permite Dispensa?"}
                margin={"none"}
                disabled={disabled}
              />
            </div>
            <div className="col-12 col-md-2">
              <ControlledSwitch
                name={"alteracaoCadastral"}
                formProps={props}
                label={"Alteração Cadastral?"}
                margin={"none"}
                disabled={disabled}
              />
            </div>
            <div className="col-12 col-md-12">
              <ControlledTextfield
                name={"orientacao"}
                formProps={props}
                uppercase={false}
                disabled={disabled}
                label={"Orientação"}
                margin={"none"}
                inputProps={{ maxLength: 4000 }}
                maxRows={16}
                minRows={12}
                multiline={true}
              />
            </div>
            <div className="col-12 col-md-12">
              <span className="participantes-label participantes-label-name clickable ">
                Formulário Banco
              </span>
              <div className="row">
                <div className="col-12">
                  <ControlledFileInput
                    name={"files"}
                    formProps={props}
                    maxFiles={1}
                    onChange={(prop) => console.log(prop)}
                    accept={".pdf"}
                    multiple={false}
                    renderFile={(file, index, handleClickDeleteIcon) => {
                      return (
                        <div
                          key={file.id}
                          className="col-12 mt-3 file-thumb d-flex flex-row align-items-center justify-content-between"
                        >
                          <div className="ml-3 d-flex flex-row align-items-center">
                            <Description />
                            <div className="ml-3">
                              <span className="d-block file-thumb-name">
                                {file.name}
                              </span>
                            </div>
                          </div>
                          {!documento.loading && (
                            <Delete
                              onClick={() => handleClickDeleteIcon(file)}
                              className="mr-3 file-thumb-delete clickable"
                            />
                          )}
                        </div>
                      );
                    }}
                    classes={{
                      root:
                        "dropzone d-flex flex-column justify-content-center align-items-center",
                      disabled: "dropzone-disabled",
                    }}
                  >
                    <span className="dropzone-title mt-4">
                      Arraste o arquivo para cá ou clique para selecioná-lo
                    </span>
                    <span className="dropzone-subtitle mb-4">
                      (somente arquivos com extensão .pdf)
                    </span>
                  </ControlledFileInput>
                </div>
              </div>
            </div>
          </div>
          <div className="row spacer">
            <div className="offset-md-2 col-md-4 col-12">
              <Button
                label="Voltar"
                onClick={voltarParaLista}
                loading={documento.loading}
              />
            </div>
            <div className="col-md-4 col-12">
              <Button
                label="CONFIRMAR"
                type={"submit"}
                loading={documento.loading}
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default FormDocumento;
