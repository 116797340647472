import { authenticatedRequest } from "../utils/api";
import basicFlow from "./asyncHandler";
import { actions } from "../reducers/propostas.actions";
import { actions as artefatoActions } from "../reducers/artefatos.actions";
import { toast } from "react-toastify";
import { routeWatcher } from "./rotas.saga";
import { types as routes } from "../reducers/rotas.actions";
import { put, select } from "redux-saga/effects";
import { getArtefatos } from "../selectors/artefatos.selectors";
import { saveAs } from "file-saver";

const listarDocumentosApi = (values) => {
  return authenticatedRequest({
    url: "/lista-documentos",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const listarDocumentos = basicFlow({
  actionGenerator: actions.consultarChecklist,
  api: listarDocumentosApi,
});

const uploadApi = (form) => {
  return authenticatedRequest({
    url: "/upload-documentos",
    isResourceService: true,
    method: "post",
    body: form,
  });
};
const upload = basicFlow({
  actionGenerator: actions.uploadArtefato,
  transform: (payload) => {
    const form = new FormData();

    const { id, files } = payload;

    form.append("id", id);

    files.forEach((file) => {
      form.append("images", file, file.name);
    });

    return form;
  },
  api: uploadApi,
  postSuccess: () => {
    toast.success("Upload realizado com sucesso.");
  },
});

const atuarAnalisarDocumentoApi = (values) => {
  return authenticatedRequest({
    url: "/analise-documento",
    method: "post",
    body: values,
  });
};
const atuarAnalisarDocumento = basicFlow({
  actionGenerator: actions.atuarAnalisarDocumento,
  api: atuarAnalisarDocumentoApi,
  postSuccess: function* ({ original }) {
    toast.success("Documento analisado com sucesso.");
    original.handleClose();
    yield put(
      actions.consultarChecklist.request({ consultaId: original.proposta })
    );
  },
});

const atualizarMotivoAnalise = basicFlow({
  actionGenerator: actions.atualizarMotivoAnalise,
  transform: (payload) => {
    return {
      id: payload.id,
      motivo: payload.motivo,
    };
  },
  api: (values) => {
    return authenticatedRequest({
      url: "/atualizar-motivo",
      method: "post",
      body: values,
    });
  },
  postSuccess: ({ original }) => {
    toast.success("Informação atualizada com sucesso.");
    original.handleClose();
  },
});

function* artefatosRouteWatcher() {
  yield routeWatcher(routes.ARTEFATOS_DOCUMENTO, function* () {
    yield put(artefatoActions.listarArtefatos.request());
  });
}

const artefatosApi = ({ id }) => {
  return authenticatedRequest({
    url: `/artefatos-documento/${id}`,
    isResourceService: true,
    method: "get",
  });
};
const artefatos = basicFlow({
  actionGenerator: artefatoActions.listarArtefatos,
  transform: function* () {
    const { id } = yield select(getArtefatos);
    return { id };
  },
  api: artefatosApi,
  postSuccess: function* ({ response }) {
    if (response.data.list.length === 0 && !response.data.tipo)
      yield toast.error("Documento não encontrado");
  },
});

const downloadApi = ({ artefato }) => {
  return authenticatedRequest({
    url: `/artefato/${artefato}?attachment=true`,
    isResourceService: true,
    method: "get",
    responseType: "blob",
  });
};
const download = basicFlow({
  actionGenerator: artefatoActions.download,
  api: downloadApi,
  postSuccess: ({ response, original }) => {
    downloadFile(response, original);
  },
});
const downloadFile = (response, original) => {
  const blob = new Blob([response.data], {
    type: original.tipo + ";charset=utf-8",
  });
  const fileName = original.nomeImagem;
  saveAs(blob, fileName);
};

const removerArtefatoApi = ({ artefato }) => {
  return authenticatedRequest({
    url: `/artefato/${artefato}`,
    isResourceService: true,
    method: "delete",
  });
};
const removerArtefato = basicFlow({
  actionGenerator: artefatoActions.removerArtefato,
  api: removerArtefatoApi,
});

const uploadArtefato = basicFlow({
  actionGenerator: artefatoActions.upload,
  transform: (payload) => {
    const form = new FormData();

    const { id, files } = payload;

    form.append("id", id);

    files.forEach((file) => {
      form.append("images", file, file.name);
    });

    return form;
  },
  api: uploadApi,
  postSuccess: function* () {
    toast.success("Upload realizado com sucesso.");
    yield put(artefatoActions.listarArtefatos.request());
  },
});

const adicionarDocumentoApi = (values) => {
  return authenticatedRequest({
    url: "/adicionar-documento",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const adicionarDocumento = basicFlow({
  actionGenerator: actions.adicionarDocumento,
  transform: (payload) => {
    return {
      consulta: payload.proposta,
      documento: payload.documento,
      tipo: payload.tipo,
      referencia: payload.id,
    };
  },
  api: adicionarDocumentoApi,
  postSuccess: function* ({ original }) {
    toast.success("Documento adicionado com sucesso.");
    original.handleClose();
    yield put(
      actions.consultarChecklist.request({ consultaId: original.proposta })
    );
  },
});

export const sagas = [
  listarDocumentos.watcher(),
  upload.watcher(),
  atuarAnalisarDocumento.watcher(),
  artefatos.watcher(),
  artefatosRouteWatcher(),
  download.watcher(),
  removerArtefato.watcher(),
  uploadArtefato.watcher(),
  adicionarDocumento.watcher(),
  atualizarMotivoAnalise.watcher(),
];
