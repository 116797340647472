import { useDispatch, useSelector } from "react-redux";
import {
  getAtividades,
  getAtividade,
} from "../../../selectors/administracao.selectors";
import { useCallback } from "react";
import { actions } from "../../../reducers/administracao/atividades.actions";
import validators from "../../../utils/validators";
import { useForm } from "react-hook-form";
import FormAtividade from "./FormAtividade";
import { getEtapas, getTemplates } from "../../../selectors/dominios.selectors";

const AtividadesPageConnected = () => {
  const dispatch = useDispatch();
  const atividades = useSelector(getAtividades);
  const atividade = useSelector(getAtividade);
  const { data: etapas } = useSelector(getEtapas);
  const { data: templates } = useSelector(getTemplates);

  const initialValues = {
    templates: [],
    ...atividade,
    isProducao: atividade.isProducao === "S",
    isComentarioObrigatorio: atividade.isComentarioObrigatorio === "S",
  };

  const rules = {
    codigo: validators.string({ required: true }),
    nome: validators.string({ required: true }),
    "Etapa.id": validators.string({ required: true }),
    condicaoCriacao: validators.string({ required: true }),
    responsavel: validators.string({ required: true }),
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const handleSubmit = useCallback(
    (values, i) => {
      const request = {
        ...values,
        id: i,
        "id-etapa": values.Etapa.id,
        etapa: etapas.filter((u) => u.id === values.Etapa.id)[0],
        isProducao: values.isProducao ? "S" : "N",
        isComentarioObrigatorio: values.isComentarioObrigatorio ? "S" : "N",
        orientacao:
          values?.orientacao?.trim()?.length > 0
            ? values.orientacao.trim()
            : null,
      };
      if (!!i) {
        dispatch(actions.atualizarAtividade.request({ atividade: request }));
      } else {
        dispatch(actions.incluirAtividade.request({ atividade: request }));
      }
    },
    [dispatch, atividades]
  );

  const voltarParaLista = useCallback(() => {
    dispatch(actions.voltarListaAtividade());
  }, [dispatch]);

  const tipoCondicaoCriacao = [
    { id: "C", descricao: "CADASTRO/CARGA" },
    { id: "F", descricao: "SE TEM FGTS" },
    { id: "I", descricao: "SE TEM IQ" },
    { id: "M", descricao: "MANUAL" },
  ];

  const tipoResponsavel = [
    { id: "A", descricao: "AGÊNCIA" },
    { id: "B", descricao: "BPO" },
    { id: "L", descricao: "CLIENTE" },
    { id: "C", descricao: "CRÉDITO" },
    { id: "D", descricao: "DEF" },
    { id: "O", descricao: "DOC" },
    { id: "P", descricao: "PATRIMÔNIO" },
    { id: "S", descricao: "SEGURADORA" },
  ];

  return (
    <FormAtividade
      etapa={etapas}
      atividade={atividade}
      props={formProps}
      handleSubmit={handleSubmit}
      tipoCondicaoCriacao={tipoCondicaoCriacao}
      tipoResponsavel={tipoResponsavel}
      voltarParaLista={voltarParaLista}
      templates={templates}
    />
  );
};

export default AtividadesPageConnected;
