import React, { useCallback, useState } from "react";
import Loader from "../../../../components/loaders";
import { Button } from "../../../../components";
import MenuProposta from "../MenuProposta";
import AdicionarClausulaDialog from "./AdicionarClausula.connected";

const Minuta = ({
  loading,
  minuta,
  gerarVersaoMinuta,
  itens,
  selecionarOpcaoMenu,
  menuSelecionado,
  proposta,
  clausulasSelecionadas,
  clausulas,
}) => {
  const [dadosAdicionarClausula, setDadosAdicionarClausula] = useState({
    open: false,
  });

  const handleCloseAdicionarClausula = useCallback(() => {
    setDadosAdicionarClausula({
      open: false,
    });
  }, []);
  const handleOpenAdicionarClausula = useCallback(() => {
    setDadosAdicionarClausula({
      open: true,
      proposta: proposta,
    });
  }, [proposta]);

  if (!!loading || !!clausulasSelecionadas.loading) {
    return (
      <div className="row">
        <div className="col-12 d-flex justify-content-center">
          <Loader type={"dots"} color={"brand"} size={50} />
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className="row spacer">
          <div className="col-12">
            <MenuProposta
              itens={itens}
              menuSelecionado={menuSelecionado}
              selecionarOpcaoMenu={selecionarOpcaoMenu}
            />
          </div>
        </div>
        {menuSelecionado === 0 && (
          <>
            <div className="row">
              <div className="col-12 col-md-3 offset-md-9">
                <Button onClick={gerarVersaoMinuta} label="GERAR MINUTA" />
              </div>
            </div>
            {minuta?.minuta
              ?.filter((c) => {
                return c.participante.tipo === "C";
              })
              .sort((a, b) => {
                if (a.participante.nome < b.participante.nome) {
                  return -1;
                } else {
                  if (a.participante.nome > b.participante.nome) return 1;
                }
                return 0;
              })
              .concat(
                minuta?.minuta
                  .filter((c) => {
                    return c.participante.tipo === "V";
                  })
                  .sort((a, b) => {
                    if (a.participante.nome < b.participante.nome) {
                      return -1;
                    } else {
                      if (a.participante.nome > b.participante.nome) return 1;
                    }
                    return 0;
                  })
              )
              .concat(
                minuta?.minuta.filter((c) => {
                  return c.participante.tipo === "IM";
                })
              )
              .map((p) => (
                <React.Fragment key={p.participante.id ?? p.clausula}>
                  <div className="row">
                    <div className="col-12">
                      <div className="card" style={{ minHeight: 100 }}>
                        <div className="card-body d-flex flex-column">
                          <div className={"row d-flex align-items-center"}>
                            <div className={"col mt-0"}>
                              <div className="card-title-checklist">
                                {!!p.participante.nome
                                  ? p.participante.nome
                                  : "IMÓVEL"}
                              </div>
                              <div className="card-subtitle-checklist">
                                {p.participante.tipo === "C"
                                  ? "Comprador"
                                  : p.participante.tipo === "V"
                                  ? "Vendedor"
                                  : "Imóvel"}
                              </div>
                            </div>
                          </div>
                          <div className={"row"}>
                            <div className="col-12">{p.texto}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </React.Fragment>
              ))}
          </>
        )}
        {menuSelecionado === 1 && (
          <>
            <div className="row">
              <div className="col-12 col-md-4 offset-md-8">
                <Button
                  onClick={() => handleOpenAdicionarClausula()}
                  label="ADICIONAR CLÁUSULAS"
                />
              </div>
            </div>
            {!!clausulasSelecionadas.list && (
              <React.Fragment key={"clausula"}>
                <div className="row">
                  <div className="col-12">
                    <div className="card" style={{ minHeight: 100 }}>
                      <div className="card-body d-flex flex-column">
                        {clausulasSelecionadas.list.map((p, index) => (
                          <div className={"row d-flex align-items-center"}>
                            <div className={"col-12"}>
                              {index +
                                1 +
                                " - E.T.: " +
                                clausulas.filter((c) => {
                                  return c.id === p || c.id === p.clausula;
                                })[0]?.texto}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            )}
          </>
        )}
        <AdicionarClausulaDialog
          dados={dadosAdicionarClausula}
          handleClose={handleCloseAdicionarClausula}
        />
      </>
    );
  }
};

export default Minuta;
