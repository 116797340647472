import { actions, types } from "./etapas.actions";

const initialState = {
  loading: false,
  error: null,
  list: [],
  page: 1,
  etapa: null,
  search: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.listarEtapas.REQUEST:
    case actions.listarEtapas.SUCCESS:
    case actions.listarEtapas.FAILURE:
      return {
        ...state,
        loading: action.type === actions.listarEtapas.REQUEST,
        error:
          action.type === actions.listarEtapas.FAILURE
            ? action.payload.error
            : null,
        list:
          action.type === actions.listarEtapas.SUCCESS
            ? action.payload.response.data.etapas
            : [],
        total:
          action.type === actions.listarEtapas.SUCCESS
            ? action.payload.response.data.total
            : null,
        page:
          action.type === actions.listarEtapas.REQUEST
            ? action.payload.pagina
            : action.payload.response.data.paginaAtual,
        search: "",
      };
    case actions.atualizarEtapa.REQUEST:
    case actions.atualizarEtapa.SUCCESS:
    case actions.atualizarEtapa.FAILURE:
      return {
        ...state,
        loading: action.type === actions.atualizarEtapa.REQUEST,
        etapa:
          action.type === actions.atualizarEtapa.FAILURE
            ? action.payload.original.etapa
            : null,
        list:
          action.type === actions.atualizarEtapa.SUCCESS
            ? state.list.map((u) => {
                if (u.id === action.payload.original.etapa.id) {
                  return {
                    ...u,
                    codigo: action.payload.original.etapa.codigo ?? u.codigo,
                    nome: action.payload.original.etapa.nome ?? u.nome,
                    ordem: action.payload.original.etapa.ordem ?? u.ordem,
                    apresentaTimeline:
                      action.payload.original.etapa.apresentaTimeline ??
                      u.apresentaTimeline,
                  };
                }
                return u;
              })
            : state.list,
      };
    case types.NOVA_ETAPA:
      return {
        ...state,
        etapa: {
          codigo: "",
          nome: "",
          ordem: "",
          apresentaTimeline: "N",
        },
      };
    case types.EDITAR_ETAPA:
      return {
        ...state,
        etapa: {
          ...action.payload.etapa,
        },
      };
    case types.VOLTAR_LISTA:
      return {
        ...state,
        etapa: null,
      };
    case actions.incluirEtapa.REQUEST:
    case actions.incluirEtapa.SUCCESS:
    case actions.incluirEtapa.FAILURE:
      return {
        ...state,
        loading: action.type === actions.incluirEtapa.REQUEST,
        etapa:
          action.type === actions.incluirEtapa.FAILURE
            ? action.payload.original.etapa
            : null,
        list:
          action.type === actions.incluirEtapa.SUCCESS
            ? action.payload.response.data.etapas
            : state.list,
        total:
          action.type === actions.incluirEtapa.SUCCESS
            ? action.payload.response.data.total
            : state.total,
        page:
          action.type === actions.incluirEtapa.REQUEST
            ? action.payload.pagina
            : state.page,
      };
    case actions.buscarEtapa.REQUEST:
    case actions.buscarEtapa.SUCCESS:
    case actions.buscarEtapa.FAILURE:
      return {
        ...state,
        loading: action.type === actions.buscarEtapa.REQUEST,
        error:
          action.type === actions.buscarEtapa.FAILURE
            ? action.payload.error
            : null,
        list:
          action.type === actions.buscarEtapa.SUCCESS
            ? action.payload.response.data.etapas
            : [],
        total:
          action.type === actions.buscarEtapa.SUCCESS
            ? action.payload.response.data.total
            : null,
        page:
          action.type === actions.buscarEtapa.REQUEST
            ? action.payload.pagina
            : action.payload.response.data.paginaAtual,
        search:
          action.type === actions.buscarEtapa.SUCCESS
            ? action.payload.original.search
            : state.search,
      };
    case actions.excluirEtapa.REQUEST:
    case actions.excluirEtapa.SUCCESS:
    case actions.excluirEtapa.FAILURE:
      return {
        ...state,
        loading: action.type === actions.excluirEtapa.REQUEST,
        etapa: null,
        list:
          action.type === actions.excluirEtapa.SUCCESS
            ? state.list.filter((u) => u.id !== action.payload.original.idEtapa)
            : state.list,
      };
    default:
      return state;
  }
};

export default reducer;
