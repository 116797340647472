import UsuariosPage from "./UsuariosPage";
import { useDispatch, useSelector } from "react-redux";
import { getUsuarios } from "../../../selectors/administracao.selectors";
import { useCallback } from "react";
import { actions } from "../../../reducers/administracao/usuarios.actions";
import validators from "../../../utils/validators";
import { useForm } from "react-hook-form";
import { getPerfil } from "../../../selectors/usuario.selectors";

const UsuariosPageConnected = () => {
  const dispatch = useDispatch();
  const usuarios = useSelector(getUsuarios);
  const loading = usuarios?.loading;
  const total = usuarios?.total;
  const pagina = usuarios?.page;
  const perfil = useSelector(getPerfil);
  const pesquisa = usuarios?.search;

  const handlePagination = useCallback(
    (e, page) => {
      if (!!pesquisa) {
        dispatch(
          actions.buscarUsuario.request({
            search: pesquisa,
            pagina: page,
          })
        );
      } else {
        dispatch(actions.listarUsuarios.request({ pagina: page }));
      }
    },
    [dispatch, pesquisa]
  );

  const handleEdit = useCallback(
    (values) => {
      dispatch(actions.editarUsuario({ usuario: values }));
    },
    [dispatch]
  );

  const handleNew = useCallback(() => {
    dispatch(actions.novoUsuario());
  }, [dispatch]);

  const inativarUsuario = useCallback(
    (values) => {
      const request = {
        id: values,
        situacao: "I",
      };
      dispatch(
        actions.atualizarUsuario.request({
          usuario: request,
        })
      );
    },
    [dispatch]
  );

  const initialValues = {
    search: "",
  };

  const rules = {
    search: validators.string({ required: false }),
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const handleSearch = useCallback(
    (values) => {
      const request = {
        by: "codigo",
        value: values.search,
      };
      dispatch(actions.buscarUsuario.request({ search: request, pagina: 1 }));
    },
    [dispatch]
  );

  return (
    <UsuariosPage
      usuarios={usuarios.list}
      usuario={usuarios.usuario}
      perfis={usuarios.perfis}
      loading={loading}
      total={total}
      handlePagination={handlePagination}
      pagina={pagina}
      inativarUsuario={inativarUsuario}
      handleNew={handleNew}
      handleEdit={handleEdit}
      formProps={formProps}
      handleSearch={handleSearch}
      perfil={perfil}
    />
  );
};

export default UsuariosPageConnected;
