import { useDispatch, useSelector } from "react-redux";
import { getClausulas } from "../../../selectors/administracao.selectors";
import { useCallback } from "react";
import { actions } from "../../../reducers/administracao/clausulas.actions";
import validators from "../../../utils/validators";
import { useForm } from "react-hook-form";
import FormClausula from "./FormClausula";

const FormLocalidadeConnected = () => {
  const dispatch = useDispatch();
  const clausulas = useSelector(getClausulas);

  const initialValues = {
    ...clausulas.clausula,
  };

  const rules = {
    codigo: validators.string({ required: true }),
    nome: validators.string({ required: true }),
    tipo: validators.string({ required: true }),
    texto: validators.string({ required: true }),
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const handleSubmit = useCallback(
    (values, i) => {
      const request = {
        id: i,
        nome: values.nome.toUpperCase(),
        codigo: values.codigo,
        texto: values.texto,
        tipo: values.tipo,
        utilizacao: values.utilizacao,
      };
      if (!!i) {
        dispatch(actions.atualizarClausula.request({ clausula: request }));
      } else {
        dispatch(actions.incluirClausula.request({ clausula: request }));
      }
    },
    [dispatch]
  );

  const voltarParaLista = useCallback(() => {
    dispatch(actions.voltarListaClausula());
  }, [dispatch]);

  return (
    <FormClausula
      clausula={clausulas.clausula}
      props={formProps}
      handleSubmit={handleSubmit}
      voltarParaLista={voltarParaLista}
    />
  );
};

export default FormLocalidadeConnected;
