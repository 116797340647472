import { actions, types } from "./templatesEmail.actions";

const initialState = {
  loading: false,
  error: null,
  list: [],
  page: 1,
  template: null,
  search: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.listarTemplates.REQUEST:
    case actions.listarTemplates.SUCCESS:
    case actions.listarTemplates.FAILURE:
      return {
        ...state,
        loading: action.type === actions.listarTemplates.REQUEST,
        error:
          action.type === actions.listarTemplates.FAILURE
            ? action.payload.error
            : null,
        list:
          action.type === actions.listarTemplates.SUCCESS
            ? action.payload.response.data.templates
            : [],
        total:
          action.type === actions.listarTemplates.SUCCESS
            ? action.payload.response.data.total
            : null,
        page:
          action.type === actions.listarTemplates.REQUEST
            ? action.payload.pagina
            : action.payload.response.data.paginaAtual,
        search: "",
      };
    case actions.atualizarTemplate.REQUEST:
    case actions.atualizarTemplate.SUCCESS:
    case actions.atualizarTemplate.FAILURE:
      return {
        ...state,
        loading: action.type === actions.atualizarTemplate.REQUEST,
        template:
          action.type === actions.atualizarTemplate.FAILURE
            ? action.payload.original
            : null,
        list:
          action.type === actions.atualizarTemplate.SUCCESS
            ? state.list.map((u) => {
                if (u.id === action.payload.original.id) {
                  return {
                    ...u,
                    ...action.payload.original,
                  };
                }
                return u;
              })
            : state.list,
      };
    case types.NOVO_TEMPLATE:
      return {
        ...state,
        template: {
          codigo: "",
          nome: "",
        },
      };
    case types.EDITAR_TEMPLATE:
      return {
        ...state,
        template: {
          ...action.payload.template,
        },
      };
    case types.VOLTAR_TEMPLATE:
      return {
        ...state,
        template: null,
      };
    case actions.incluirTemplate.REQUEST:
    case actions.incluirTemplate.SUCCESS:
    case actions.incluirTemplate.FAILURE:
      return {
        ...state,
        loading: action.type === actions.incluirTemplate.REQUEST,
        template:
          action.type === actions.incluirTemplate.FAILURE
            ? action.payload.original.template
            : null,
        list:
          action.type === actions.incluirTemplate.SUCCESS
            ? action.payload.response.data.templates
            : state.list,
        total:
          action.type === actions.incluirTemplate.SUCCESS
            ? action.payload.response.data.total
            : state.total,
        page:
          action.type === actions.incluirTemplate.REQUEST
            ? action.payload.pagina
            : state.page,
      };
    case actions.buscarTemplate.REQUEST:
    case actions.buscarTemplate.SUCCESS:
    case actions.buscarTemplate.FAILURE:
      return {
        ...state,
        loading: action.type === actions.buscarTemplate.REQUEST,
        error:
          action.type === actions.buscarTemplate.FAILURE
            ? action.payload.error
            : null,
        list:
          action.type === actions.buscarTemplate.SUCCESS
            ? action.payload.response.data.templates
            : [],
        total:
          action.type === actions.buscarTemplate.SUCCESS
            ? action.payload.response.data.total
            : null,
        page:
          action.type === actions.buscarTemplate.SUCCESS
            ? action.payload.response.data.paginaAtual
            : action.payload.pagina,
        search:
          action.type === actions.buscarTemplate.SUCCESS
            ? action.payload.original.search
            : state.search,
      };
    case actions.excluirTemplate.REQUEST:
    case actions.excluirTemplate.SUCCESS:
    case actions.excluirTemplate.FAILURE:
      return {
        ...state,
        loading: action.type === actions.excluirTemplate.REQUEST,
        produto: null,
        list:
          action.type === actions.excluirTemplate.SUCCESS
            ? state.list.filter((u) => u.id !== action.payload.original.id)
            : state.list,
      };
    default:
      return state;
  }
};

export default reducer;
