import React from "react";
import { IconButton, Tooltip } from "@material-ui/core";
import { EditOutlined, DeleteOutlined } from "@material-ui/icons";
import { tipoPessoa, qualificacao } from "../../../../utils/parametros";

const Clausula = ({
  item,
  index,
  handleEdit,
  handleOpenExclusao,
  clausulas,
}) => {
  return (
    <div
      className={
        index % 2 === 0
          ? "row mt-0 d-flex align-items-center medium-row tr-even-adm"
          : "row mt-0 d-flex align-items-center medium-row tr-odd"
      }
    >
      <div className="col-8 mt-lg-0 user-table ">
        <span>
          {
            clausulas.list.filter((t) => {
              return t.id === item.clausula;
            })[0]?.nome
          }
        </span>
      </div>
      <div className="col-2 mt-lg-0 user-table ">
        <span>
          {
            qualificacao.filter((t) => {
              return t.id === item.tipoQualificacao;
            })[0]?.nome
          }
        </span>
      </div>
      <div className="col-1 mt-lg-0 user-table ">
        <span>
          {
            tipoPessoa.filter((t) => {
              return t.id === item.tipoParticipante;
            })[0]?.nome
          }
        </span>
      </div>
      <div className="col-3 mt-lg-0 user-table ">
        <span>{item.estadoCivil}</span>
      </div>
      <div className="col-2 mt-lg-0 user-table ">
        <span>{item.uniaoEstavel === "S" ? "SIM" : "NÃO"}</span>
      </div>
      <div className="col-3 mt-lg-0 user-table ">
        <span>{item.regimeCasamento}</span>
      </div>
      <div className="col-2 mt-lg-0 user-table ">
        <span>
          {item.tipoDataCasamento === "A"
            ? "ANTES DE 1977"
            : item.tipoDataCasamento === "P"
            ? "DEPOIS DE 1977"
            : ""}
        </span>
      </div>
      <div className="col-1 mt-lg-0 user-table ">
        <span>{item.conjugeParticipante === "S" ? "SIM" : "NÃO"}</span>
      </div>
      <Tooltip title="Editar">
        <IconButton className="p-2" onClick={() => handleEdit(item)}>
          <EditOutlined className={"color-black"} />
        </IconButton>
      </Tooltip>
      <Tooltip title="Excluir">
        <IconButton className="p-2" onClick={() => handleOpenExclusao(item)}>
          <DeleteOutlined className={"color-black"} />
        </IconButton>
      </Tooltip>
    </div>
  );
};

export default Clausula;
