import { actions, types } from "./documentos.actions";

const initialState = {
  loading: false,
  error: null,
  list: [],
  page: 1,
  documento: null,
  search: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.listarDocumentos.REQUEST:
    case actions.listarDocumentos.SUCCESS:
    case actions.listarDocumentos.FAILURE:
      return {
        ...state,
        loading: action.type === actions.listarDocumentos.REQUEST,
        error:
          action.type === actions.listarDocumentos.FAILURE
            ? action.payload.error
            : null,
        list:
          action.type === actions.listarDocumentos.SUCCESS
            ? action.payload.response.data.documentos
            : [],
        total:
          action.type === actions.listarDocumentos.SUCCESS
            ? action.payload.response.data.total
            : null,
        page:
          action.type === actions.listarDocumentos.REQUEST
            ? action.payload.pagina
            : action.payload.response.data.paginaAtual,
        search: "",
      };
    case actions.atualizarDocumento.REQUEST:
    case actions.atualizarDocumento.SUCCESS:
    case actions.atualizarDocumento.FAILURE:
      return {
        ...state,
        loading: action.type === actions.atualizarDocumento.REQUEST,
        documento:
          action.type === actions.atualizarDocumento.FAILURE
            ? action.payload.original.documento
            : null,
        list:
          action.type === actions.atualizarDocumento.SUCCESS
            ? state.list.map((u) => {
                if (u.id === action.payload.original.documento.id) {
                  return {
                    ...u,
                    codigo:
                      action.payload.original.documento.codigo ?? u.codigo,
                    nome: action.payload.original.documento.nome ?? u.nome,
                    origem:
                      action.payload.original.documento.origem ?? u.origem,
                    orientacao:
                      action.payload.original.documento.orientacao ??
                      u.orientacao,
                    interno:
                      action.payload.original.documento.interno ?? u.interno,
                    permiteDispensa:
                      action.payload.original.documento.permiteDispensa ??
                      u.permiteDispensa,
                    alteracaoCadastral:
                      action.payload.original.documento.alteracaoCadastral ??
                      u.alteracaoCadastral,
                    codigoIntegracao:
                      action.payload.original.documento.codigoIntegracao ??
                      u.codigoIntegracao,
                    arquivoFormularioBanco:
                      action.payload.original.documento.files.length === 0
                        ? null
                        : u.arquivoFormularioBanco,
                    caminhoFormularioBanco:
                      action.payload.original.documento.files.length === 0
                        ? null
                        : u.caminhoFormularioBanco,
                    codigoArtefatoFormularioBanco:
                      action.payload.original.documento.files.length === 0
                        ? null
                        : u.codigoArtefatoFormularioBanco,
                  };
                }
                return u;
              })
            : state.list,
      };
    case types.NOVO_DOCUMENTO:
      return {
        ...state,
        documento: {
          codigo: "",
          nome: "",
          tipo: "P",
          origem: "",
          orientacao: "",
          interno: "N",
          permiteDispensa: "N",
          codigoIntegracao: "",
        },
      };
    case types.EDITAR_DOCUMENTO:
      return {
        ...state,
        documento: {
          ...action.payload.documento,
        },
      };
    case types.VOLTAR_LISTA:
      return {
        ...state,
        documento: null,
      };
    case actions.incluirDocumento.REQUEST:
    case actions.incluirDocumento.SUCCESS:
    case actions.incluirDocumento.FAILURE:
      return {
        ...state,
        loading: action.type === actions.incluirDocumento.REQUEST,
        documento:
          action.type === actions.incluirDocumento.FAILURE
            ? action.payload.original.documento
            : null,
        list:
          action.type === actions.incluirDocumento.SUCCESS
            ? action.payload.response.data.documentos
            : state.list,
        total:
          action.type === actions.incluirDocumento.SUCCESS
            ? action.payload.response.data.total
            : state.total,
        page:
          action.type === actions.incluirDocumento.REQUEST
            ? action.payload.pagina
            : state.page,
      };
    case actions.buscarDocumento.REQUEST:
    case actions.buscarDocumento.SUCCESS:
    case actions.buscarDocumento.FAILURE:
      return {
        ...state,
        loading: action.type === actions.buscarDocumento.REQUEST,
        error:
          action.type === actions.buscarDocumento.FAILURE
            ? action.payload.error
            : null,
        list:
          action.type === actions.buscarDocumento.SUCCESS
            ? action.payload.response.data.documentos
            : [],
        total:
          action.type === actions.buscarDocumento.SUCCESS
            ? action.payload.response.data.total
            : null,
        page:
          action.type === actions.buscarDocumento.REQUEST
            ? action.payload.pagina
            : action.payload.response.data.paginaAtual,
        search:
          action.type === actions.buscarDocumento.SUCCESS
            ? action.payload.original.search
            : state.search,
      };
    case actions.excluirDocumento.REQUEST:
    case actions.excluirDocumento.SUCCESS:
    case actions.excluirDocumento.FAILURE:
      return {
        ...state,
        loading: action.type === actions.excluirDocumento.REQUEST,
        documento: null,
        list:
          action.type === actions.excluirDocumento.SUCCESS
            ? state.list.filter(
                (u) => u.id !== action.payload.original.idDocumento
              )
            : state.list,
      };
    case actions.uploadFormularioBanco.REQUEST:
    case actions.uploadFormularioBanco.SUCCESS:
    case actions.uploadFormularioBanco.FAILURE:
      return {
        ...state,
        documento: null,
        list:
          action.type === actions.uploadFormularioBanco.SUCCESS
            ? state.list.map((documento) => {
                if (documento.id === Number(action.payload.response.data.id)) {
                  return {
                    ...documento,
                    arquivoFormularioBanco:
                      action.payload.response.data.arquivoFormularioBanco,
                    caminhoFormularioBanco:
                      action.payload.response.data.caminhoFormularioBanco,
                    codigoArtefatoFormularioBanco:
                      action.payload.response.data
                        .codigoArtefatoFormularioBanco,
                  };
                } else {
                  return documento;
                }
              })
            : state.list,
      };

    default:
      return state;
  }
};

export default reducer;
