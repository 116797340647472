import React, { useState, useEffect } from "react";
import { Step, StepContent, StepLabel, Stepper } from "@material-ui/core";
import ActivityTimeLineCircle from "../../../components/ActivityTimeLineCircle";

const situacoes = [
  { id: 1, nome: "Não Iniciada", color: "#8b8b8b", colorMobile: "#b6bcd1" },
  { id: 2, nome: "Em Andamento", color: "#6c99bf", colorMobile: "#6c99bf" },
  { id: 3, nome: "Concluída", color: "#77b287", colorMobile: "#77b287" },
];

const classes = {
  circle: "chart-legend-circle",
  text: "chevron-legend-text ml-2",
};

const ChevronList = ({ timeline }) => {
  const [width, setWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let isMobile = width <= 768;

  return (
    <>
      {!isMobile && desk(timeline, situacoes)}
      {isMobile && mobile(timeline, situacoes)}
    </>
  );
};

const legenda = (situacoes, isMobile = false) => {
  return situacoes.map((entry, index) => {
    return (
      <div
        key={`item-${index}`}
        className={"d-flex flex-row align-items-center mt-2"}
        style={{ width: "200px" }}
      >
        <div
          className={"ml-4 " + classes.circle}
          style={{
            backgroundColor: isMobile ? entry.colorMobile : entry.color,
          }}
        />
        <span title={entry.nome} className={classes.text}>
          {entry.nome}
        </span>
      </div>
    );
  });
};

const desk = (timeline, situacoes) => (
  <>
    <ul className="d-flex flex-row chevron align-items-center">
      {timeline
        .filter((e) => {
          return e.exibir === "S";
        })
        .map((etapa) => (
          <li
            key={etapa.etapa}
            className={
              "flex d-flex justify-content-center text-center align-items-center chevron-item chevron-item-" +
              etapa.status.id
            }
          >
            <div>
              <span className="d-block">{etapa.etapa}</span>
            </div>
          </li>
        ))}
    </ul>
    <div
      className={
        "row spacer d-flex align-items-center justify-content-center text-center"
      }
    >
      {legenda(situacoes)}
    </div>
  </>
);

const mobile = (timeline, situacoes) => {
  let hasMore = false;

  return (
    <div className="card">
      <div className="card-body">
        <Stepper orientation="vertical">
          {timeline.map((item, index) => (
            <Step
              active={true}
              completed={false}
              key={item.etapa}
              last={index === timeline.length - 1 && !hasMore}
            >
              <StepLabel
                classes={{
                  iconContainer: "mb-auto",
                  labelContainer:
                    index === timeline.length - 1 && !hasMore
                      ? ""
                      : "activity-title-container",
                }}
                icon={<ActivityTimeLineCircle type={item.status.id} />}
              >
                <span
                  className="comment-title"
                  style={{ color: colorFormatMobile(item.status.id) }}
                >
                  {item.etapa}
                </span>
              </StepLabel>
              {/*<StepContent className="comment-content-container">*/}
              {/*  <span className="d-block comment-date">*/}
              {/*    {formatters.dates.long(item.date)}*/}
              {/*  </span>*/}
              {/*</StepContent>*/}
            </Step>
          ))}
        </Stepper>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          {legenda(situacoes, true)}
        </div>
      </div>
    </div>
  );
};

const colorFormatMobile = (id) => {
  if (id === "S") {
    return situacoes.find((item) => item.id === 1).colorMobile;
  } else if (id === "A") {
    return situacoes.find((item) => item.id === 2).colorMobile;
  } else {
    return situacoes.find((item) => item.id === 3).colorMobile;
  }
};

export default ChevronList;
