import { actions, types } from "./atividades.actions";

const initialState = {
  loading: false,
  error: null,
  list: [],
  page: 1,
  atividade: null,
  search: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.listarAtividades.REQUEST:
    case actions.listarAtividades.SUCCESS:
    case actions.listarAtividades.FAILURE:
      return {
        ...state,
        loading: action.type === actions.listarAtividades.REQUEST,
        error:
          action.type === actions.listarAtividades.FAILURE
            ? action.payload.error
            : null,
        list:
          action.type === actions.listarAtividades.SUCCESS
            ? action.payload.response.data.atividades
            : [],
        total:
          action.type === actions.listarAtividades.SUCCESS
            ? action.payload.response.data.total
            : null,
        page:
          action.type === actions.listarAtividades.REQUEST
            ? action.payload.pagina
            : action.payload.response.data.paginaAtual,
        search: "",
      };
    case actions.atualizarAtividade.REQUEST:
    case actions.atualizarAtividade.SUCCESS:
    case actions.atualizarAtividade.FAILURE:
      return {
        ...state,
        loading: action.type === actions.atualizarAtividade.REQUEST,
        atividade:
          action.type === actions.atualizarAtividade.FAILURE
            ? action.payload.original.atividade
            : null,
        list:
          action.type === actions.atualizarAtividade.SUCCESS
            ? state.list.map((u) => {
                if (u.id === action.payload.original.atividade.id) {
                  return {
                    ...u,
                    codigo:
                      action.payload.original.atividade.codigo ?? u.codigo,
                    nome: action.payload.original.atividade.nome ?? u.nome,
                    Etapa: { ...action.payload.original.atividade.etapa },
                    condicaoCriacao:
                      action.payload.original.atividade.condicaoCriacao ??
                      u.condicaoCriacao,
                    responsavel:
                      action.payload.original.atividade.responsavel ??
                      u.responsavel,
                    ordem: action.payload.original.atividade.ordem ?? null,
                    sla: action.payload.original.atividade.sla ?? null,
                    isProducao:
                      action.payload.original.atividade.isProducao ??
                      u.isProducao,
                    isComentarioObrigatorio:
                      action.payload.original.atividade
                        .isComentarioObrigatorio ?? u.isComentarioObrigatorio,
                    orientacao:
                      action.payload.original.atividade.orientacao ??
                      u.orientacao,
                    templates: action.payload.original.atividade.templates,
                  };
                } else {
                  return { ...u };
                }
              })
            : state.list,
      };
    case types.NOVA_ATIVIDADE:
      return {
        ...state,
        atividade: {
          codigo: "",
          nome: "",
          etapa: "",
          condicaoCriacao: "",
          responsavel: "",
          ordem: "",
          sla: "",
          isProducao: false,
          isComentarioObrigatorio: false,
        },
      };
    case types.EDITAR_ATIVIDADE:
      return {
        ...state,
        atividade: {
          ...action.payload.atividade,
        },
      };
    case types.VOLTAR_LISTA:
      return {
        ...state,
        atividade: null,
      };
    case actions.incluirAtividade.REQUEST:
    case actions.incluirAtividade.SUCCESS:
    case actions.incluirAtividade.FAILURE:
      return {
        ...state,
        loading: action.type === actions.incluirAtividade.REQUEST,
        atividade:
          action.type === actions.incluirAtividade.FAILURE
            ? action.payload.original.atividade
            : null,
        list:
          action.type === actions.incluirAtividade.SUCCESS
            ? action.payload.response.data.atividades
            : state.list,
        total:
          action.type === actions.incluirAtividade.SUCCESS
            ? action.payload.response.data.total
            : state.total,
        page:
          action.type === actions.incluirAtividade.REQUEST
            ? action.payload.pagina
            : state.page,
      };
    case actions.buscarAtividade.REQUEST:
    case actions.buscarAtividade.SUCCESS:
    case actions.buscarAtividade.FAILURE:
      return {
        ...state,
        loading: action.type === actions.buscarAtividade.REQUEST,
        error:
          action.type === actions.buscarAtividade.FAILURE
            ? action.payload.error
            : null,
        list:
          action.type === actions.buscarAtividade.SUCCESS
            ? action.payload.response.data.atividades
            : [],
        total:
          action.type === actions.buscarAtividade.SUCCESS
            ? action.payload.response.data.total
            : null,
        page:
          action.type === actions.buscarAtividade.REQUEST
            ? action.payload.pagina
            : action.payload.response.data.paginaAtual,
        search:
          action.type === actions.buscarAtividade.SUCCESS
            ? action.payload.original.search
            : state.search,
      };
    case actions.excluirAtividade.REQUEST:
    case actions.excluirAtividade.SUCCESS:
    case actions.excluirAtividade.FAILURE:
      return {
        ...state,
        loading: action.type === actions.excluirAtividade.REQUEST,
        atividade: null,
        list:
          action.type === actions.excluirAtividade.SUCCESS
            ? state.list.filter(
                (u) => u.id !== action.payload.original.idAtividade
              )
            : state.list,
      };
    default:
      return state;
  }
};

export default reducer;
