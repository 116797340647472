import basicFlow from "../asyncHandler";
import { actions } from "../../reducers/administracao/clausulas.actions";
import { authenticatedRequest } from "../../utils/api";
import { routeWatcher } from "../rotas.saga";
import { types as routes } from "../../reducers/rotas.actions";
import { put } from "redux-saga/effects";
import { toast } from "react-toastify";

const listarClausulasApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/clausulas",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const atualizarClausulaApi = ({ clausula }) => {
  return authenticatedRequest({
    url: "/administracao/clausula",
    isResourceService: true,
    method: "post",
    body: { clausula: clausula },
  });
};

const excluirClausulaApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/excluir-clausula",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const buscarClausulaApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/clausula/search",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const listarClausulas = basicFlow({
  actionGenerator: actions.listarClausulas,
  api: listarClausulasApi,
});

const atualizarClausula = basicFlow({
  actionGenerator: actions.atualizarClausula,
  api: atualizarClausulaApi,
  postSuccess: function () {
    toast.success("Atualização realizada com sucesso");
  },
});

const incluirClausula = basicFlow({
  actionGenerator: actions.incluirClausula,
  api: atualizarClausulaApi,
  postSuccess: function () {
    toast.success("Inclusão realizada com sucesso");
  },
});

const excluirClausula = basicFlow({
  actionGenerator: actions.excluirClausula,
  api: excluirClausulaApi,
  postSuccess: function () {
    toast.success("Exclusão realizada com sucesso");
  },
});

const buscarClausula = basicFlow({
  actionGenerator: actions.buscarClausula,
  api: buscarClausulaApi,
});

function* listaClausulasRouteWatcher() {
  const pagina = 1;
  yield routeWatcher(routes.ADMINISTRACAO_CLAUSULAS, function* () {
    yield put(
      actions.listarClausulas.request({
        pagina,
      })
    );
  });
}

export const sagas = [
  listarClausulas.watcher(),
  listaClausulasRouteWatcher(),
  atualizarClausula.watcher(),
  incluirClausula.watcher(),
  buscarClausula.watcher(),
  excluirClausula.watcher(),
];
