import basicFlow from "./asyncHandler";
import { put, select } from "redux-saga/effects";
import { actions } from "../reducers/propostas.actions";
import { actions as dominiosActions } from "../reducers/dominios.actions";
import { authenticatedRequest, unauthenticatedRequest } from "../utils/api";
import { routeWatcher } from "./rotas.saga";
import {
  actions as routeActions,
  types as routes,
} from "../reducers/rotas.actions";
import {
  getDetalheProposta,
  getFiltroValores,
  getPaginaAtual,
} from "../selectors/propostas.selectors";
import { getPayload } from "../selectors/rotas.selectors";
import { removeNonDigitsFromString } from "../utils/basic";
import { toast } from "react-toastify";
import { getPerfil } from "../selectors/usuario.selectors";

const listaPropostasApi = (values) => {
  return authenticatedRequest({
    url: "/listaConsultas",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const consultarPropostaApi = (values) => {
  return authenticatedRequest({
    url: "/detalhesConsulta/" + values,
    isResourceService: true,
    method: "get",
  });
};

const consultarParticipanteApi = (values) => {
  return authenticatedRequest({
    url: "/cadastro/pessoa/" + values,
    isResourceService: true,
    method: "get",
  });
};

const consultaCepCorreiosApi = ({ value }) => {
  return unauthenticatedRequest({
    url: `/ws/${removeNonDigitsFromString(value)}/json/`,
    method: "get",
    base: "https://viacep.com.br",
  });
};

const incluirAtualizarPessoaApi = (values) => {
  return authenticatedRequest({
    url: "/cadastro/pessoa",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const excluirPessoaApi = (values) => {
  return authenticatedRequest({
    url: "/cadastro/pessoa",
    isResourceService: true,
    method: "delete",
    body: values,
  });
};

const consultaIntervenienteApi = (values) => {
  return authenticatedRequest({
    url: "/cadastro/iq/" + values,
    isResourceService: true,
    method: "get",
  });
};

const atualizaIntervenienteApi = (values) => {
  return authenticatedRequest({
    url: "/cadastro/iq",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const atualizaObservacaoApi = (values) => {
  return authenticatedRequest({
    url: "/detalhesConsulta/observacao",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const getWorkflowPropostasApi = (values) => {
  return authenticatedRequest({
    url: "/workflow-consulta/" + values,
    isResourceService: true,
    method: "get",
  });
};

const abrirAtividadeApi = (values) => {
  return authenticatedRequest({
    url: "/abrir-atividade",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const atualizarSituacaoAtividadeApi = (values) => {
  return authenticatedRequest({
    url: "/atualizar-atividade",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const atualizarSituacaoConsultaApi = (values) => {
  return authenticatedRequest({
    url: "/consulta/atualizar-consulta",
    isResourceService: true,
    method: "post",
    body: {
      situacao: values.situacao,
      consultaID: values.consultaID,
      motivoCancelamento: values.motivoCancelamento,
      dataContrato: values.dataContrato,
    },
  });
};

const atualizarPrioridadeConsultaApi = (values) => {
  return authenticatedRequest({
    url: "/consulta/atualizar-prioridade-consulta",
    isResourceService: true,
    method: "post",
    body: { prioridade: values.prioridade, consultaID: values.consultaID },
  });
};

const historicoPropostaApi = (values) => {
  return authenticatedRequest({
    url: "/historico-atividades/" + values,
    isResourceService: true,
    method: "get",
  });
};

const exportarHistoricoAtividadesApi = (values) => {
  return authenticatedRequest({
    url: `/exportar-historico-atividades/${values}`,
    isResourceService: true,
    method: "get",
  });
};

const gerarMinutaApi = (values) => {
  return authenticatedRequest({
    url: `/minuta/gerar-versao`,
    isResourceService: true,
    method: "post",
    body: { consultaId: values },
  });
};

const gerarMinuta = basicFlow({
  actionGenerator: actions.gerarMinuta,
  api: gerarMinutaApi,
  postSuccess: function () {
    toast.success("Minuta Gerada com Sucesso");
  },
});

const listarMinutaApi = (values) => {
  return authenticatedRequest({
    url: `/minuta/listar-clausulas`,
    isResourceService: true,
    method: "post",
    body: { consultaId: values },
  });
};

const gravarClausulasSelecionadasApi = (values) => {
  return authenticatedRequest({
    url: "/minuta/gravar-clausulas-selecionadas",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const gravarClausulasSelecionadas = basicFlow({
  actionGenerator: actions.gravarClausulasSelecionadas,
  api: gravarClausulasSelecionadasApi,
  postSuccess: function ({ original }) {
    toast.success("Cláusulas selecionadas com sucesso.");
    original.handleClose();
  },
});

const listarMinuta = basicFlow({
  actionGenerator: actions.listarClausulasProposta,
  api: listarMinutaApi,
});

const listarClausulasParametroApi = (values) => {
  return authenticatedRequest({
    url: `/minuta/listar-clausulas-parametro`,
    isResourceService: true,
    method: "get",
  });
};

const uploadDocumentosBancoApi = (value) => {
  return authenticatedRequest({
    url: `/integracao/documentos`,
    isResourceService: true,
    method: "post",
    body: { consulta: value },
  });
};

const uploadDocumentosBanco = basicFlow({
  actionGenerator: actions.uploadDocumentosBanco,
  api: uploadDocumentosBancoApi,
  postSuccess: function* ({ original, response }) {
    yield put(actions.consultarChecklist.request({ consultaId: original }));
    if (response.data.qtd_erros > 0) {
      toast.error(
        `Houve ${response.data.qtd_erros} erro${
          response.data.qtd_erros > 1 ? "s" : ""
        } no envio dos documentos`
      );
    } else {
      toast.success(`Integração realizada com sucesso`);
    }
  },
});

const listarClausulasParametro = basicFlow({
  actionGenerator: actions.listarClausulasParametro,
  api: listarClausulasParametroApi,
});

const abrirAtividade = basicFlow({
  actionGenerator: actions.abrirAtividade,
  api: abrirAtividadeApi,
  postSuccess: function ({ original }) {
    toast.success("Atividade adicionada com sucesso.");
    original.handleClose();
  },
});

const atualizarStatusAtividade = basicFlow({
  actionGenerator: actions.atualizarSituacaoAtividade,
  api: atualizarSituacaoAtividadeApi,
  postSuccess: function () {
    toast.success("Situação da fase atualizada com sucesso");
  },
});

const consultaInterveniente = basicFlow({
  actionGenerator: actions.consultarIq,
  transform: function* (payload) {
    let consulta = payload;
    if (!consulta) {
      const detalhe = yield select(getDetalheProposta);
      if (!detalhe) {
        const routePayload = yield select(getPayload);
        consulta = routePayload?.id;
      } else {
        consulta = detalhe.id;
      }
    }
    return consulta;
  },
  api: consultaIntervenienteApi,
});

const atualizarInterveniente = basicFlow({
  actionGenerator: actions.atualizarIq,
  api: atualizaIntervenienteApi,
  postSuccess: function* ({ original }) {
    toast.success("Informações salvas com sucesso.");
    yield put(
      routeActions.redirectTo(routes.PROPOSTA, { id: original.idProposta })
    );
  },
});

const listaPropostas = basicFlow({
  actionGenerator: actions.listarPropostas,
  api: listaPropostasApi,
});

const consultarPropostas = basicFlow({
  actionGenerator: actions.consultarProposta,
  api: consultarPropostaApi,
  preSuccess: function ({ response }) {
    response.data[0].interveniente = {
      nomeInterveniente: response.data[0].interveniente,
      idConsulta: response.data[0].id,
    };
  },
});

const consultarParticipante = basicFlow({
  actionGenerator: actions.consultarParticipante,
  api: consultarParticipanteApi,
});

const historicoProposta = basicFlow({
  actionGenerator: actions.historicoProposta,
  api: historicoPropostaApi,
});

const consultarCep = basicFlow({
  actionGenerator: actions.consultarCepCorreios,
  api: consultaCepCorreiosApi,
  postSuccess: function* ({ response, original }) {
    if (!!original.successCallback) {
      yield original.successCallback(response, original.value);
    }
  },
});

const atualizarSituacaoConsulta = basicFlow({
  actionGenerator: actions.atualizarSituacaoConsulta,
  api: atualizarSituacaoConsultaApi,
  postSuccess: function* ({
    original: { handleCloseAlterarSituacao, consultaID, situacao },
  }) {
    if (situacao === "E") {
      yield put(actions.getWorkflow.request(consultaID));
    }
    toast.success("Situação atualizada com sucesso");
    handleCloseAlterarSituacao();
  },
});

const atualizarPrioridadeConsulta = basicFlow({
  actionGenerator: actions.atualizarPrioridadeConsulta,
  api: atualizarPrioridadeConsultaApi,
  postSuccess: ({ original: { handleCloseAlterarPrioridade } }) => {
    toast.success("Prioridade atualizada com sucesso");
    handleCloseAlterarPrioridade();
  },
});

function* listaPropostasRouteWatcher(values) {
  yield routeWatcher(routes.PROPOSTAS, function* (values) {
    const pagina = yield select(getPaginaAtual);
    const filtroValores = yield select(getFiltroValores);
    const perfil = yield select(getPerfil);
    if (perfil !== "CLIENTE" && perfil !== "AGENCIA") {
      yield put(dominiosActions.analistas.request());
    }
    yield put(dominiosActions.motivos_cancelamento.request());
    yield put(
      actions.listarPropostas.request({
        pagina: values?.payload?.pagina ?? pagina,
        pesquisa: values?.payload?.values,
        filtro: { ...filtroValores },
      })
    );
  });
}

function* intervenienteRouteWatcher() {
  yield routeWatcher(routes.INTERVENIENTE_QUITANTE, function* () {
    const proposta = yield select(getPayload);
    const detalhe = yield select(getDetalheProposta);
    if (!detalhe) yield put(actions.consultarIq.request(proposta.id));
  });
}

function* propostaRouteWatcher() {
  yield routeWatcher(routes.PROPOSTA, function* () {
    const proposta = yield select(getPayload);

    yield put(actions.consultarProposta.request(proposta.id));

    yield put(actions.getWorkflow.request(proposta.id));
  });
}

function* participanteRouteWatcher() {
  yield routeWatcher(routes.PARTICIPANTE, function* () {
    const proposta = yield select(getPayload);
    if (!!proposta) {
      yield put(actions.consultarParticipante.request(proposta.idParticipante));
      yield put(dominiosActions.cartorio.request());
    }
  });
}

function* novoParticipanteRouteWatcher() {
  yield routeWatcher(routes.NOVO_PARTICIPANTE, function* () {
    const proposta = yield select(getPayload);
    const dadosProposta = yield select(getDetalheProposta);
    if (!dadosProposta) {
      yield put(actions.consultarProposta.request(proposta.id));
      yield put(routeActions.redirectTo(routes.PROPOSTA, { id: proposta.id }));
      yield put(dominiosActions.cartorio.request());
    }
  });
}

const incluirAtualizarPessoa = basicFlow({
  actionGenerator: actions.atualizarParticipante,
  api: incluirAtualizarPessoaApi,
  postSuccess: function* ({ original }) {
    toast.success("Informações salvas com sucesso");
    yield put(
      routeActions.redirectTo(routes.PROPOSTA, { id: original.id_consulta })
    );
    yield put(actions.consultarProposta.request(original.id_consulta));
  },
});

const excluirPessoa = basicFlow({
  actionGenerator: actions.excluirParticipante,
  api: excluirPessoaApi,
  postSuccess: function* ({ original }) {
    toast.success("Participante excluído com sucesso");
    yield put(actions.consultarProposta.request(original.idProposta));
  },
});

const atualizarObservacao = basicFlow({
  actionGenerator: actions.atualizarObservacao,
  api: atualizaObservacaoApi,
  postSuccess: function () {
    toast.success("Observação armazenada com sucesso");
  },
});

const getWorkflow = basicFlow({
  actionGenerator: actions.getWorkflow,
  api: getWorkflowPropostasApi,
  postSuccess: function* () {
    yield put(dominiosActions.etapas.request());
  },
});

const atualizarAnalista = basicFlow({
  actionGenerator: actions.atualizarAnalista,
  api: (values) => {
    return authenticatedRequest({
      url: "/analista",
      isResourceService: true,
      method: "post",
      body: values,
    });
  },
  postSuccess: ({ original: { handleClose } }) => {
    toast.success("Analista atualizado com sucesso");
    handleClose();
  },
});

const historicoDocumentosApi = (values) => {
  return authenticatedRequest({
    url: `/consulta/historico-documentos/${values}`,
    isResourceService: true,
    method: "get",
  });
};

const historicoDocumentos = basicFlow({
  actionGenerator: actions.historicoDocumentos,
  api: historicoDocumentosApi,
});

const exportarHistoricoAtividade = basicFlow({
  actionGenerator: actions.exportarHistoricoConsultas,
  api: exportarHistoricoAtividadesApi,
});

const exportHistoricoDocumentosApi = (values) => {
  return authenticatedRequest({
    url: `/consulta/exportar-historico-documentos/${values}`,
    isResourceService: true,
    method: "get",
  });
};

const exportHistoricoDocumentos = basicFlow({
  actionGenerator: actions.exportHistoricoDocumentos,
  api: exportHistoricoDocumentosApi,
});

const historicoEmailsApi = (values) => {
  return authenticatedRequest({
    url: `/consulta/historico-emails/${values}`,
    isResourceService: true,
    method: "get",
  });
};

const historicoEmails = basicFlow({
  actionGenerator: actions.historicoEmails,
  api: historicoEmailsApi,
});

const exportHistoricoEmailsApi = (values) => {
  return authenticatedRequest({
    url: `/consulta/exportar-historico-emails/${values}`,
    isResourceService: true,
    method: "get",
  });
};

const exportarHistoricoEmails = basicFlow({
  actionGenerator: actions.exportHistoricoEmails,
  api: exportHistoricoEmailsApi,
});

const historicoEmailsDetalhesApi = (values) => {
  return authenticatedRequest({
    url: `/consulta/email-historico-conteudo/${values.id}`,
    isResourceService: true,
    method: "get",
  });
};

const historicoEmailsDetalhes = basicFlow({
  actionGenerator: actions.historicoEmailsDetalhes,
  api: historicoEmailsDetalhesApi,
  postSuccess: function* ({ original, response }) {
    original.callback(response.data);
  },
});

export const sagas = [
  listaPropostas.watcher(),
  listaPropostasRouteWatcher(),
  consultarPropostas.watcher(),
  propostaRouteWatcher(),
  participanteRouteWatcher(),
  consultarParticipante.watcher(),
  consultarCep.watcher(),
  incluirAtualizarPessoa.watcher(),
  excluirPessoa.watcher(),
  novoParticipanteRouteWatcher(),
  consultaInterveniente.watcher(),
  atualizarInterveniente.watcher(),
  intervenienteRouteWatcher(),
  atualizarObservacao.watcher(),
  getWorkflow.watcher(),
  abrirAtividade.watcher(),
  atualizarStatusAtividade.watcher(),
  atualizarAnalista.watcher(),
  atualizarSituacaoConsulta.watcher(),
  atualizarPrioridadeConsulta.watcher(),
  historicoProposta.watcher(),
  exportarHistoricoAtividade.watcher(),
  gerarMinuta.watcher(),
  listarMinuta.watcher(),
  listarClausulasParametro.watcher(),
  gravarClausulasSelecionadas.watcher(),
  uploadDocumentosBanco.watcher(),
  historicoDocumentos.watcher(),
  exportHistoricoDocumentos.watcher(),
  historicoEmails.watcher(),
  exportarHistoricoEmails.watcher(),
  historicoEmailsDetalhes.watcher(),
];
