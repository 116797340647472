import BasePage from "../../../BasePage";
import React from "react";
import Loader from "../../../../components/loaders";
import { CardWithDeleteButton } from "../../../../components";
import formatters from "../../../../utils/formatters";
import {
  CloseSharp,
  DeleteForeverSharp,
  CloudDownload,
} from "@material-ui/icons";
import { Button, IconButton } from "@material-ui/core";
import PdfViewer from "./PdfViewer";
import {
  default as BotaoUploadDocumento,
  getAcceptedTypes,
} from "./BotaoUploadDocumento";

const getFileIcon = (mimeType) => {
  if (!mimeType) return "fiv-icon-blank";
  if (mimeType.toLowerCase().indexOf("pdf") > -1) return "fiv-icon-pdf";
  if (mimeType.toLowerCase().indexOf("png") > -1) return "fiv-icon-png";
  if (mimeType.toLowerCase().indexOf("bmp") > -1) return "fiv-icon-bmp";
  if (mimeType.toLowerCase().indexOf("gif") > -1) return "fiv-icon-gif";
  if (
    mimeType.toLowerCase().indexOf("jpg") > -1 ||
    mimeType.toLowerCase().indexOf("jpeg") > -1
  )
    return "fiv-icon-jpeg";
  return "fiv-icon-blank";
};

const ArtefatosDocumentoPage = ({
  list,
  loading,
  selected,
  uploading,
  id,
  tipo,
  situacaoDocumento,
  handleFileSelected,
  handleDelete,
  handleDownload,
  handleUpload,
  downloading,
}) => {
  return (
    <BasePage isLoggedIn={true}>
      <div className="row">
        {!!loading && (
          <div className="col-12 d-flex justify-content-center">
            <Loader type={"dots"} color={"brand"} size={50} />
          </div>
        )}
        {!loading && list.length === 0 && !tipo && (
          <>
            <div className="col-12 col-sm-6 col-lg-3 mt-n2"></div>
          </>
        )}
        {!loading && list.length > 0 && !!tipo && (
          <>
            <div className="col-12 col-sm-6 col-lg-3 mt-n2">
              {list.map((item) => {
                return (
                  <CardWithDeleteButton
                    className={"mt-2"}
                    key={item.artefato}
                    canDelete={situacaoDocumento === "I"}
                    renderDefault={() => {
                      return (
                        <div
                          className="card-body"
                          onClick={() => handleFileSelected(item)}
                        >
                          <div className="d-flex">
                            <span
                              className={
                                "fiv-cla file-icon " + getFileIcon(item.tipo)
                              }
                            />
                            <div className="d-flex flex-column">
                              <span className="file-title">Arquivo</span>
                              <span className="file-value">
                                {item.nomeImagem}
                              </span>
                              <span className="file-title mt-1">
                                Data do upload
                              </span>
                              <span className="file-value">
                                {formatters.dates.simple(
                                  new Date(item.dataHoraCriacao),
                                  {
                                    year: "numeric",
                                    month: "2-digit",
                                    day: "2-digit",
                                    hour: "numeric",
                                    minute: "numeric",
                                    second: "numeric",
                                  }
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    }}
                    renderSecondary={({ handleClick }) => {
                      return (
                        <div className="card-body bg-red visible-1s pb-0">
                          <CloseSharp
                            className="position-absolute clickable close-icon"
                            onClick={handleClick}
                          />
                          <div className="row align-items-center justify-content-center">
                            <DeleteForeverSharp className="large-delete-icon" />
                            <span className="color-white text-center text-confirm-delete">
                              Confirma a exclusão do arquivo selecionado?
                            </span>
                            <Button
                              className="clickable confirm-delete-button"
                              onClick={() => handleDelete(item)}
                            >
                              <span>SIM, PODE EXCLUIR</span>
                            </Button>
                          </div>
                        </div>
                      );
                    }}
                  />
                );
              })}

              {(situacaoDocumento === "F" || situacaoDocumento === "I") && (
                <BotaoUploadDocumento
                  className={"mt-3"}
                  name={id}
                  uploading={uploading}
                  accept={getAcceptedTypes(tipo)}
                  onChange={handleUpload}
                />
              )}
            </div>

            <div className="col-12 col-sm-6 col-lg-9 mt-0 d-none d-md-block">
              {selected !== null && selected.tipo === "application/pdf" && (
                <PdfViewer
                  payload={selected.pdfPayload}
                  docName={selected.nomeImagem}
                  handleDownload={handleDownload}
                />
              )}
              {selected !== null && selected.tipo !== "application/pdf" && (
                <div className={"card"}>
                  <div className={"card-body d-flex flex-column text-center"}>
                    {!!downloading && (
                      <div className="col-12 d-flex justify-content-center">
                        <Loader type={"dots"} color={"brand"} size={50} />
                      </div>
                    )}
                    {!downloading && (
                      <>
                        Clique aqui para realizar o download
                        <IconButton
                          onClick={handleDownload}
                          className={"align-self-center"}
                        >
                          <CloudDownload />
                        </IconButton>
                      </>
                    )}
                  </div>
                </div>
              )}
            </div>
          </>
        )}
      </div>
    </BasePage>
  );
};

export default ArtefatosDocumentoPage;
