import React from "react";
import { ControlledSelect, ControlledSwitch } from "../../components/inputs";
import { MenuItem } from "../../components";
import { Button } from "components";
import { Dialog, DialogContent, IconButton } from "@material-ui/core";
import { CloseSharp } from "@material-ui/icons";

const Filtro = ({
  filtroSelecionado,
  titulo,
  subtitulo,
  code,
  handleFilterClick,
}) => {
  const buttonStyle = `h-100 base-filter`;
  const buttonStyleSelected = `${buttonStyle} filter-selected`;

  const textStyle = `color-dark base-filter-text`;
  const textStyleSelected = `${textStyle} filter-text-selected`;

  return (
    <div className="col-12" style={{ height: "52px", marginTop: "0px" }}>
      <div
        onClick={() => handleFilterClick(code)}
        className={
          filtroSelecionado === code ? buttonStyleSelected : buttonStyle
        }
        style={{ display: "flex", flexDirection: "column" }}
      >
        <span
          className={filtroSelecionado === code ? textStyleSelected : textStyle}
        >
          {titulo}
        </span>
        <span
          className={filtroSelecionado === code ? textStyleSelected : textStyle}
        >
          {subtitulo}
        </span>
      </div>
    </div>
  );
};

const Filtros = ({
  open,
  handleClose,
  filtroSimples,
  handleFilterClick,
  selecionarFiltroSimples,
  formProps,
  dominioAnalistas,
  selecionarFiltroExcludente,
  filtroExcludente,
  responsavel,
  prioridades,
  headerFiltros,
}) => {
  const handleSubmit = (data) => {
    handleFilterClick(
      data,
      headerFiltros(filtroSimples, filtroExcludente, data)
    );
    handleClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      classes={{ paper: "w100" }}
      maxWidth="sm"
      open={open}
    >
      <form className="col-12" onSubmit={formProps.handleSubmit(handleSubmit)}>
        {!!open && (
          <DialogContent>
            <IconButton
              onClick={handleClose}
              classes={{ root: "position-absolute clickable close-icon" }}
            >
              <CloseSharp />
            </IconButton>
            <div className={"page-title"}>Filtros</div>
            <div className={"mb-3"}>
              <div className="row d-flex flex-row">
                <div className="col-12">
                  <Filtro
                    filtroSelecionado={filtroExcludente}
                    handleFilterClick={selecionarFiltroExcludente}
                    titulo={"DOCUMENTAÇÃO ENVIADA FASE I"}
                    code={"faseI"}
                  />
                </div>
                <div className="col-12">
                  <Filtro
                    filtroSelecionado={filtroExcludente}
                    handleFilterClick={selecionarFiltroExcludente}
                    titulo={`DOCUMENTAÇÃO ENVIADA FASE II`}
                    code={"faseII"}
                  />
                </div>
                <div className="col-12">
                  <Filtro
                    filtroSelecionado={filtroExcludente}
                    handleFilterClick={selecionarFiltroExcludente}
                    titulo={"DOCUMENTAÇÃO INCOMPLETA"}
                    code={"docIncompleta"}
                  />
                </div>
                <div className="col-12">
                  <Filtro
                    filtroSelecionado={filtroExcludente}
                    handleFilterClick={selecionarFiltroExcludente}
                    titulo={"DOCUMENTAÇÃO PENDENTE DE ANÁLISE"}
                    code={"pendenteAnalise"}
                  />
                </div>
                <div className="col-12">
                  <Filtro
                    filtroSelecionado={filtroSimples?.find(
                      (item) => item === "analistaBack"
                    )}
                    handleFilterClick={selecionarFiltroSimples}
                    titulo={"ANALISTA BACKOFFICE"}
                    code={"analistaBack"}
                  />
                </div>
                {filtroSimples?.find((item) => item === "analistaBack") && (
                  <div className="col-12 mt-0">
                    <ControlledSelect
                      name={"analistaBack"}
                      formProps={formProps}
                      multiple={true}
                      label={"Analista Backoffice"}
                      margin={"none"}
                      style={{ margin: "10px" }}
                    >
                      {dominioAnalistas?.data
                        ?.sort((a, b) => (a.nome > a.nome ? a : b))
                        .map((item) => (
                          <MenuItem
                            className={"select-submenu-item"}
                            key={item.id}
                            value={item.id}
                          >
                            {item.nome}
                          </MenuItem>
                        ))}
                    </ControlledSelect>
                  </div>
                )}
                <div className="col-12">
                  <Filtro
                    filtroSelecionado={filtroSimples?.find(
                      (item) => item === "resp"
                    )}
                    handleFilterClick={selecionarFiltroSimples}
                    titulo={"RESPONSÁVEL"}
                    code={"resp"}
                  />
                </div>
                {filtroSimples?.find((item) => item === "resp") && (
                  <div className="col-12 mt-0">
                    <ControlledSelect
                      name={"resp"}
                      formProps={formProps}
                      multiple={true}
                      label={"Responsável"}
                      margin={"none"}
                      style={{ margin: "10px" }}
                    >
                      {responsavel.map((item) => (
                        <MenuItem
                          className={"select-submenu-item"}
                          key={item.key}
                          value={item.key}
                        >
                          {item.desc}
                        </MenuItem>
                      ))}
                    </ControlledSelect>
                  </div>
                )}

                <div className="col-12">
                  <Filtro
                    filtroSelecionado={filtroSimples?.find(
                      (item) => item === "priorizadas"
                    )}
                    handleFilterClick={selecionarFiltroSimples}
                    titulo={"PRIORIZADAS"}
                    code={"priorizadas"}
                  />
                </div>
                {filtroSimples?.find((item) => item === "priorizadas") && (
                  <div className="col-12 mt-0">
                    <ControlledSelect
                      name={"priorizadas"}
                      formProps={formProps}
                      multiple={true}
                      label={"Priorizadas"}
                      margin={"none"}
                      style={{ margin: "10px" }}
                    >
                      {prioridades
                        ?.sort((a, b) => (a.nome > a.nome ? a : b))
                        .map((item) => (
                          <MenuItem
                            className={"select-submenu-item"}
                            key={item.key}
                            value={item.key}
                          >
                            {item.desc}
                          </MenuItem>
                        ))}
                    </ControlledSelect>
                  </div>
                )}
                <div className="col-12">
                  <Filtro
                    filtroSelecionado={filtroSimples?.find(
                      (item) => item === "fgts"
                    )}
                    handleFilterClick={selecionarFiltroSimples}
                    titulo={"FGTS"}
                    code={"fgts"}
                  />
                </div>
                {filtroSimples?.find((item) => item === "fgts") && (
                  <div className="col-12 mt-0">
                    <ControlledSwitch
                      name={"fgts"}
                      formProps={formProps}
                      label={"FGTS"}
                      margin={"none"}
                    />
                  </div>
                )}
                <div className="col-12">
                  <Filtro
                    filtroSelecionado={filtroSimples?.find(
                      (item) => item === "iq"
                    )}
                    handleFilterClick={selecionarFiltroSimples}
                    titulo={"IQ"}
                    code={"iq"}
                  />
                </div>
                {filtroSimples?.find((item) => item === "iq") && (
                  <div className="col-12 mt-0">
                    <ControlledSwitch
                      name={"iq"}
                      formProps={formProps}
                      label={"IQ"}
                      margin={"none"}
                    />
                  </div>
                )}
                <div className="col-12">
                  <Filtro
                    filtroSelecionado={filtroSimples?.find(
                      (item) => item === "email"
                    )}
                    handleFilterClick={selecionarFiltroSimples}
                    titulo={"E-mail"}
                    code={"email"}
                  />
                </div>
                {filtroSimples?.find((item) => item === "email") && (
                  <div className="col-12 mt-0">
                    <ControlledSwitch
                      name="email"
                      formProps={formProps}
                      label="Não lidos"
                      margin="none"
                    />
                  </div>
                )}
                <div className="col-12 mt-3">
                  <Button label={"APLICAR"} type={"submit"} />
                </div>
              </div>
            </div>
          </DialogContent>
        )}
      </form>
    </Dialog>
  );
};

export default Filtros;
