import BasePage from "../../../../BasePage";
import ImovelForm from "./ImovelForm";
import React, { useState } from "react";
import Loader from "components/loaders";
import { SecondaryButton, VisibleWithProfile } from "../../../../../components";
import { Dialog, DialogContent, IconButton, Tooltip } from "@material-ui/core";
import { CloseSharp, InsertPhoto } from "@material-ui/icons";

const ImovelPage = ({ loading, error, handleOpenImagens, ...otherProps }) => {
  const [open, setOpen] = useState(false);
  const handleClosePopup = () => {
    setOpen(false);
  };
  return (
    <BasePage isLoggedIn={true}>
      {!!loading && (
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <Loader type={"dots"} color={"brand"} size={50} />
          </div>
        </div>
      )}
      {!loading && !!error && (
        <div className="flex d-flex flex-column justify-content-center align-items-center">
          <span className="d-block colored-card-text mt-4 color-red">
            Não foi possível obter as informações
          </span>
        </div>
      )}
      {!loading && !error && (
        <>
          <div className="row">
            <div className={"col-7"}>
              <span className="page-title">Detalhes do Imóvel</span>
            </div>
            <div className="col-5">
              <VisibleWithProfile profiles={["BPO"]}>
                <SecondaryButton
                  label="Documentos"
                  onClick={() => setOpen(true)}
                  className={"align-middle"}
                />
              </VisibleWithProfile>
            </div>
          </div>
          <ImovelForm {...otherProps} />
          {open &&
            popupChecklistAlteracaoCadastral(
              otherProps.idImovel,
              otherProps.checklistAlteracaoCadastral,
              handleClosePopup,
              open,
              handleOpenImagens
            )}
        </>
      )}
    </BasePage>
  );
};

const popupChecklistAlteracaoCadastral = (
  participante,
  data,
  handleClose,
  open,
  handleOpenImagens
) => {
  return (
    <Dialog
      onClose={handleClose}
      classes={{ paper: "w100" }}
      maxWidth="sm"
      open={open}
    >
      <div className="col-12">
        <DialogContent>
          <IconButton
            onClick={handleClose}
            classes={{ root: "position-absolute clickable close-icon" }}
          >
            <CloseSharp />
          </IconButton>
          <div className="page-title">DOCUMENTOS</div>
          <div className="mb-3">
            {gerarTabela(
              participante,
              "Imóvel",
              "IM",
              data,
              handleOpenImagens,
              handleClose
            )}
          </div>
        </DialogContent>
      </div>
    </Dialog>
  );
};

const gerarTabela = (id, title, tipo, data, handleOpenImagens, handleClose) => {
  return (
    <>
      <div className="row">
        <div className="col mt-0">
          <div className="card-title-checklist">{title}</div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          {data
            ?.filter(
              (checklist) =>
                checklist.tipoParticipante === tipo &&
                checklist.participante === id
            )
            ?.map((checklist, index) => (
              <div
                className={
                  index % 2 === 0
                    ? "row mt-0 d-flex align-items-center medium-row tr-even"
                    : "row mt-0 d-flex align-items-center medium-row tr-odd"
                }
              >
                <div className="col-sm-12 col-lg-11 mt-sm-2 mt-lg-0 ">
                  <span>{checklist.Documento.nome}</span>
                </div>
                <div className="col-sm-12 col-lg-1 my-2 my-lg-0">
                  <Tooltip title="Arquivos">
                    <IconButton
                      className="p-2"
                      onClick={() => {
                        handleClose();
                        handleOpenImagens(checklist.id);
                      }}
                    >
                      <InsertPhoto
                        className={"color-secondary icon-indexado"}
                      />
                    </IconButton>
                  </Tooltip>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default ImovelPage;
