import basicFlow from "../asyncHandler";
import { actions } from "../../reducers/administracao/painelIntegracao.actions";
import { authenticatedRequest } from "../../utils/api";
import { routeWatcher } from "../rotas.saga";
import { types as routes } from "../../reducers/rotas.actions";
import { put } from "redux-saga/effects";

const listarDocumentosErroApi = (values) => {
  return authenticatedRequest({
    url: "/administracao/integracao-documento/listar-erros",
    isResourceService: true,
    method: "post",
    body: values,
  });
};

const listarDocumentosErro = basicFlow({
  actionGenerator: actions.listarDocumentosErro,
  api: listarDocumentosErroApi,
});

function* listaDocumentosErroRouteWatcher() {
  const pagina = 1;
  yield routeWatcher(routes.ADMINISTRACAO_INTEGRA_DOC, function* () {
    yield put(
      actions.listarDocumentosErro.request({ page: pagina, filter: {} })
    );
  });
}

export const sagas = [
  listarDocumentosErro.watcher(),
  listaDocumentosErroRouteWatcher(),
];
