import { IconButton, InputAdornment } from "@material-ui/core";
import { SearchOutlined } from "@material-ui/icons";
import React, { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { Button } from "../../../components";
import { ControlledTextfield, DatePicker } from "../../../components/inputs";
import { ControlledAutocomplete } from "../../../components/inputs";
import Loader from "../../../components/loaders";
import { actions } from "../../../reducers/administracao/relatorios.actions";

const Filtros = ({ loading, loadingFile, formProps, usuarios }) => {
  const [selectedDateInicial, setSelectedDateInicial] = useState(null);
  const [selectedDateFinal, setSelectedDateFinal] = useState(null);

  const dispatch = useDispatch();

  const handleDateInicialChange = useCallback((value) => {
    setSelectedDateInicial(value);
    if (!selectedDateFinal) {
      setSelectedDateFinal(new Date());
    }
    if (selectedDateFinal < value) {
      setSelectedDateFinal(value);
    }
  });

  const handleDateFinalChange = useCallback((value) => {
    setSelectedDateFinal(value);
    if (!selectedDateInicial) {
      setSelectedDateInicial(value);
    }
    if (selectedDateInicial > value) {
      setSelectedDateInicial(value);
    }
  });

  const handleResetForm = () => {
    setSelectedDateInicial(null);
    setSelectedDateFinal(null);
    formProps.setValue("propostas", "");
    formProps.setValue("usuarios", null);
  };

  const handleSubmit = () => {
    let propostas = formProps.getValues("propostas");
    let usuarios = formProps.getValues("usuarios");

    if (
      propostas === "" &&
      usuarios === null &&
      !selectedDateInicial &&
      !selectedDateFinal
    ) {
      toast.error("É obrigatória a escolha de ao menos um dos filtros.");
    } else if (
      selectedDateInicial &&
      selectedDateFinal &&
      Number(selectedDateFinal?.getTime() ?? 0) -
        Number(selectedDateInicial?.getTime() ?? 0) >
        2678400000
    ) {
      toast.error("O período máximo para consulta é de um mês.");
    } else {
      dispatch(
        actions.listarRelatorioAuditoria.request({
          dataFinal: selectedDateFinal ?? null,
          dataInicial: selectedDateInicial ?? null,
          proposta: formProps.getValues("propostas") ?? null,
          usuario: formProps.getValues("usuarios")?.id ?? null,
        })
      );
    }
  };

  return (
    <div className="card">
      {!!loading && (
        <div className="row spacer">
          <div className="col-12 d-flex justify-content-center">
            <Loader type={"dots"} color={"brand"} size={80} />
          </div>
        </div>
      )}

      {!loading && (
        <div className={"card-body"}>
          <div className={"row"}>
            <div className="col-12 card-subtitle-checklist mt-0">
              <span className="">Filtros:</span>
            </div>
          </div>
          <>
            <div className="row">
              <div className="col-3 mt-0">
                <ControlledTextfield
                  name={"propostas"}
                  formProps={formProps}
                  label={"Proposta"}
                />
              </div>
              <div className="col-3 mt-0">
                <ControlledAutocomplete
                  name={"usuarios"}
                  formProps={formProps}
                  label={"Usuário"}
                  labelField={"nome"}
                  domain={usuarios}
                />
              </div>
              <div className="col-3 mt-0 text-center">
                <div className="card-title-dashboard color-brand mt-2">
                  Data Inicial
                </div>

                <DatePicker
                  disableFuture
                  variant={"outline"}
                  margin="none"
                  fullWidth={false}
                  format="dd/MM/yyyy"
                  value={selectedDateInicial}
                  className={"card-date-picker"}
                  onChange={handleDateInicialChange}
                  InputProps={{
                    disabled: true,
                  }}
                />
              </div>
              <div className="col-3 mt-0 text-center">
                <div className="card-title-dashboard color-brand mt-2">
                  Data Final
                </div>

                <DatePicker
                  disableFuture
                  variant={"outline"}
                  margin="none"
                  fullWidth={false}
                  format="dd/MM/yyyy"
                  value={selectedDateFinal}
                  className={"card-date-picker"}
                  onChange={handleDateFinalChange}
                  InputProps={{
                    disabled: true,
                  }}
                />
              </div>
            </div>
            <div className="row spacer">
              <div className="offset-md-6 col-md-3 col-12">
                <Button label="LIMPAR" onClick={handleResetForm} />
              </div>
              <div className="col-md-3 col-12">
                <Button
                  label="Download"
                  onClick={handleSubmit}
                  loading={loadingFile}
                />
              </div>
            </div>
          </>
        </div>
      )}
    </div>
  );
};

export default Filtros;
