import { actions, types as cargaTypes } from "./cargaConsultas.actions";
import { types as routes } from "../rotas.actions";

const initialState = {
  submitting: false,
  error: null,
  "show-results": false,
  resultado: {
    total: null,
    novos: null,
    atualizados: null,
    concluidasAntes: null,
  },
  updateLabel: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case routes.CARGA_CONSULTAS:
      return {
        ...initialState,
      };
    case actions.processar.REQUEST:
    case actions.processar.SUCCESS:
    case actions.processar.FAILURE:
      return {
        ...state,
        submitting: action.type === actions.processar.REQUEST,
        error:
          action.type === actions.processar.FAILURE
            ? action.payload.error
            : null,
        "show-results": action.type === actions.processar.SUCCESS,
        resultado:
          action.type === actions.processar.SUCCESS
            ? action.payload.response.data.length > 0
              ? JSON.parse(
                  action.payload.response.data.slice(
                    action.payload.response.data.indexOf("{"),
                    action.payload.response.data.indexOf("}") + 1
                  )
                )
              : { ...initialState.resultado }
            : state.resultado,
        updateLabel:
          action.type === actions.processar.REQUEST
            ? ""
            : action.type === actions.processar.SUCCESS
            ? ""
            : state.updateLabel,
      };
    case cargaTypes.UPDATE_LABEL:
      return {
        ...state,
        updateLabel: action.payload,
      };

    default:
      return state;
  }
};

export default reducer;
