import EnvioEmailPage from "./EnvioEmailPage";
import { useCallback, useState } from "react";
import useCompleteForm from "../../../../hooks/useCompleteForm";
import validators, { validEmail } from "../../../../utils/validators";
import { useDispatch, useSelector } from "react-redux";
import { actions } from "../../../../reducers/emails/emails.actions";
import { draftToHtml, htmlToDraft } from "../../../../utils/draft";
import {
  actions as rotasActions,
  types as routes,
} from "../../../../reducers/rotas.actions";
import {
  getEmailParams,
  getEmailTemplates,
  getEnvioEmails,
} from "../../../../selectors/emails.selectors";

const EnvioEmailPageConnected = () => {
  const dispatch = useDispatch();
  const { sending } = useSelector(getEnvioEmails);
  const { proposta } = useSelector(getEmailParams);
  const { loading } = useSelector(getEmailTemplates);
  const [hasValue, setHasValue] = useState(false);

  const [formProps, handleSubmit] = useCompleteForm({
    rules: useCallback(
      () => ({
        template: validators.string({ required: true }),
        assunto: validators.string({ required: true }),
        destinatarios: validators.array({
          required: true,
          custom: {
            isEmail: (array) => {
              for (const { value } of array) {
                const validation = validEmail(value);
                if (typeof validation === "string") {
                  return `Email inválido (${value})`;
                }
              }
              return true;
            },
          },
        }),
        conteudo: validators.object({
          custom: {
            exists: (value) => {
              const html = draftToHtml(value);
              if (
                html !== undefined &&
                html !== null &&
                html !== "" &&
                html !== "<p></p>\n"
              ) {
                return true;
              }
              return "Campo obrigatório";
            },
          },
        }),
      }),
      []
    ),
    initialValues: useCallback(
      () => ({
        template: "",
        assunto: "",
        destinatarios: [],
        conteudo: htmlToDraft(""),
      }),
      []
    ),
    handleSubmit: useCallback(
      (values) => {
        dispatch(
          actions.enviarEmail.request({
            ...values,
            conteudo: draftToHtml(values.conteudo),
          })
        );
      },
      [dispatch]
    ),
  });

  const { setValue } = formProps;

  const changeTemplateCallback = useCallback(
    (values) => {
      setValue("assunto", values.assunto, { shouldValidate: true });
      setValue("destinatarios", values.destinatarios, { shouldValidate: true });
      setValue(
        "conteudo",
        htmlToDraft(values.conteudo, { shouldValidate: true })
      );
    },
    [setValue]
  );

  const handleChangeTemplate = useCallback(
    (evt) => {
      dispatch(
        actions.processarTemplate.request({
          template: evt.target.value,
          callback: changeTemplateCallback,
        })
      );
      setHasValue(!!evt.target.value);
    },
    [dispatch, changeTemplateCallback, setHasValue]
  );

  const handleGoBack = useCallback(() => {
    dispatch(
      rotasActions.redirectTo(routes.PROPOSTA, {
        id: proposta,
      })
    );
  }, [dispatch, proposta]);

  const disableSubmitOnEnter = useCallback(
    (e) => e.key === "Enter" && e.preventDefault(),
    []
  );

  return (
    <EnvioEmailPage
      loading={loading}
      formProps={formProps}
      handleChangeTemplate={handleChangeTemplate}
      disableSubmitOnEnter={disableSubmitOnEnter}
      handleSubmit={handleSubmit}
      handleGoBack={handleGoBack}
      hasValue={hasValue}
      submitting={sending}
    />
  );
};

export default EnvioEmailPageConnected;
