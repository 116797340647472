import ProdutosPage from "./ProdutosPage";
import { useDispatch, useSelector } from "react-redux";
import { getProdutos } from "../../../selectors/administracao.selectors";
import { useCallback, useState } from "react";
import { actions } from "../../../reducers/administracao/produtos.actions";
import validators from "../../../utils/validators";
import { useForm } from "react-hook-form";

const ProdutosPageConnected = () => {
  const dispatch = useDispatch();
  const [pesquisa, setPesquisa] = useState();
  const produtos = useSelector(getProdutos);
  const loading = produtos?.loading;
  const total = produtos?.total;
  const pagina = produtos?.page;

  const handlePagination = useCallback(
    (e, page) => {
      if (!!pesquisa) {
        dispatch(
          actions.buscarProduto.request({ search: pesquisa, pagina: page })
        );
      } else {
        dispatch(actions.listarProdutos.request({ pagina: page }));
      }
    },
    [dispatch, pesquisa]
  );

  const handleEdit = useCallback(
    (values) => {
      dispatch(actions.editarProduto({ produto: values }));
    },
    [dispatch]
  );

  const handleNew = useCallback(() => {
    dispatch(actions.novaProduto());
  }, [dispatch]);

  const handleDelete = useCallback(
    (i) => {
      dispatch(actions.excluirProduto.request({ idProduto: i }));
    },
    [dispatch]
  );

  const initialValues = {
    search: "",
  };

  const rules = {
    search: validators.string({ required: false }),
  };

  const formProps = {
    ...useForm(),
    rules,
    initialValues,
  };

  const handleSearch = useCallback(
    (values) => {
      const request = {
        by: "codigo",
        value: values.search,
      };
      setPesquisa(request);
      dispatch(actions.buscarProduto.request({ search: request, pagina: 1 }));
    },
    [dispatch, setPesquisa]
  );

  return (
    <ProdutosPage
      produtos={produtos.list}
      produto={produtos.produto}
      loading={loading}
      total={total}
      handlePagination={handlePagination}
      pagina={pagina}
      handleNew={handleNew}
      handleEdit={handleEdit}
      formProps={formProps}
      handleSearch={handleSearch}
      handleDelete={handleDelete}
    />
  );
};

export default ProdutosPageConnected;
