import TokenForm from "./TokenForm";
import { ReactComponent as Logo } from "assets/logo-preto.svg";

import BasePage from "../BasePage";

const TokenPage = ({
  formProps,
  handleSubmit,
  loading,
  voltar,
  reenviarTokenSenha,
  captchaRef,
}) => {
  return (
    <BasePage>
      <div className="row justify-content-center">
        <div className="col-12 d-flex justify-content-center">
          <Logo className={"logo-login col-auto"} />
        </div>
        <div className="col-12 mw-480">
          <div className="card">
            <div className="card-body">
              <div className="card-title text-uppercase text-center">
                INFORME O TOKEN DE ACESSO
              </div>
              <div className="mini-separator mb-2" />
              <TokenForm
                formProps={formProps}
                handleSubmit={handleSubmit}
                loading={loading}
                voltar={voltar}
                reenviarTokenSenha={reenviarTokenSenha}
                captchaRef={captchaRef}
              />
            </div>
          </div>
        </div>
      </div>
    </BasePage>
  );
};

export default TokenPage;
