import { DialogContent, IconButton } from "@material-ui/core";
import formatters from "../../../utils/formatters";
import { CloseSharp } from "@material-ui/icons";
import React, { useState } from "react";

export default function InformacaoDaProposta({ handleClose, detalheProposta }) {
  return (
    <DialogContent>
      <IconButton
        onClick={handleClose}
        classes={{
          root: "position-absolute clickable close-icon",
        }}
      >
        <CloseSharp />
      </IconButton>
      <div className={"page-title"}>Informação da Situação</div>
      <div className={"mb-3"}>
        {detalheProposta?.dhUsuarioEmAndamento && (
          <div className="row">
            <div className="col-md-6 col-xs-12">
              <span className="label">Data/hora Em andamento</span>
              <div>
                <span className="data-label">
                  {formatters.dates.withHours(
                    new Date(detalheProposta?.dhUsuarioEmAndamento)
                  )}
                </span>
              </div>
            </div>
            <div className="col-md-6 col-xs-12">
              <span className="label">Ativado por</span>
              <div>
                <span className="data-label">
                  {detalheProposta?.usuarioEmAndamento?.nome}
                </span>
              </div>
            </div>
          </div>
        )}
        {detalheProposta?.dhUsuarioParalizada && (
          <div className="row">
            <div className="col-md-6 col-xs-12">
              <span className="label">Data/hora Paralização</span>
              <div>
                <span className="data-label">
                  {formatters.dates.withHours(
                    new Date(detalheProposta?.dhUsuarioParalizada)
                  )}
                </span>
              </div>
            </div>
            <div className="col-md-6 col-xs-12">
              <span className="label">Paralizado por:</span>
              <div>
                <span className="data-label">
                  {detalheProposta?.usuarioParalizada?.nome}
                </span>
              </div>
            </div>
          </div>
        )}
        {detalheProposta?.dhUsuarioContratoEmitido && (
          <div className="row">
            <div className="col-md-6 col-xs-12">
              <span className="label">Data/hora Contrato Emitido:</span>
              <div>
                <span className="data-label">
                  {formatters.dates.withHours(
                    new Date(detalheProposta?.dhUsuarioContratoEmitido)
                  )}
                </span>
              </div>
            </div>
            <div className="col-md-6 col-xs-12">
              <span className="label">Contrato Emitido por:</span>
              <div>
                <span className="data-label">
                  {detalheProposta?.usuarioContratoEmitido?.nome}
                </span>
              </div>
            </div>
          </div>
        )}
        {detalheProposta?.usuarioCancelado?.nome && (
          <div className="row">
            <div className="col-md-6 col-xs-12">
              <span className="label">Data/hora Cancelamento:</span>
              <div>
                <span className="data-label">
                  {formatters.dates.withHours(
                    new Date(detalheProposta?.dhUsuarioCancelamento)
                  )}
                </span>
              </div>
            </div>
            <div className="col-md-6 col-xs-12">
              <span className="label">Cancelado por:</span>
              <div>
                <span className="data-label">
                  {detalheProposta.usuarioCancelado?.nome}
                </span>
              </div>
            </div>
          </div>
        )}
        {detalheProposta?.MotivoCancelamento?.descricao && (
          <>
            <span className="label">Motivo de cancelamento:</span>
            <div>
              <span className="data-label">
                {detalheProposta?.MotivoCancelamento?.descricao}
              </span>
            </div>
          </>
        )}
      </div>
    </DialogContent>
  );
}
