import React, { useCallback, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { IconButton } from "@material-ui/core";
import {
  NavigateBefore,
  NavigateNext,
  RotateLeft,
  RotateRight,
  ZoomIn,
  CloudDownload,
  ZoomOut,
} from "@material-ui/icons";
import { useContainerDimensions } from "../../../../utils/hooks";
import Loader from "../../../../components/loaders";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const scales = [0.5, 0.6, 0.7, 0.8, 0.9, 1, 1.2, 1.5, 2];

const PdfViewer = ({ payload, docName, handleDownload }) => {
  const [totalPages, setTotalPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);

  const componentRef = useRef();
  const { width } = useContainerDimensions(componentRef);

  const onDocumentLoadSuccess = useCallback(
    (pdf) => {
      setTotalPages(pdf.numPages);
    },
    [setTotalPages]
  );
  const onRotateLeftClick = useCallback(() => {
    setRotation((rotation + 270) % 360);
  }, [setRotation, rotation]);
  const onRotateRightClick = useCallback(() => {
    setRotation((rotation + 450) % 360);
  }, [setRotation, rotation]);

  const onNextPageClick = useCallback(() => {
    setPageNumber(pageNumber + 1);
  }, [pageNumber, setPageNumber]);
  const onPreviousPageClick = useCallback(() => {
    setPageNumber(pageNumber - 1);
  }, [pageNumber, setPageNumber]);

  const onZoomInClick = useCallback(() => {
    setZoom(scales[scales.indexOf(zoom) + 1]);
  }, [zoom, setZoom]);
  const onZoomOutClick = useCallback(() => {
    setZoom(scales[scales.indexOf(zoom) - 1]);
  }, [zoom, setZoom]);

  return (
    <div ref={componentRef}>
      <div
        className={
          "header-pdf d-flex align-items-center pl-4 pr-4 justify-content-between"
        }
      >
        {docName}
        <IconButton onClick={handleDownload}>
          <CloudDownload className={"pdf-buttons"} />
        </IconButton>
      </div>
      <Document
        className="mt-0 card"
        file={payload}
        rotate={rotation}
        loading={
          <div className="d-flex justify-content-center">
            <Loader type={"dots"} color={"brand"} size={50} />
          </div>
        }
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page
          pageNumber={pageNumber}
          className={
            "d-flex pdf-page" + (zoom <= 1 ? " justify-content-center" : "")
          }
          width={width - 30}
          scale={zoom}
        />
        <div className="d-flex justify-content-around align-items-center nav-pdf">
          <div>
            <IconButton disabled={zoom === scales[0]} onClick={onZoomOutClick}>
              <ZoomOut className={"pdf-buttons"} />
            </IconButton>
            <IconButton
              disabled={zoom === scales[scales.length - 1]}
              onClick={onZoomInClick}
            >
              <ZoomIn className={"pdf-buttons"} />
            </IconButton>
          </div>

          <div>
            <IconButton
              disabled={pageNumber === 1}
              onClick={onPreviousPageClick}
            >
              <NavigateBefore className={"pdf-buttons"} />
            </IconButton>
            <span className={"pdf-buttons"}>
              {pageNumber}&nbsp;/&nbsp;{totalPages}
            </span>
            <IconButton
              disabled={pageNumber === totalPages}
              onClick={onNextPageClick}
            >
              <NavigateNext className={"pdf-buttons"} />
            </IconButton>
          </div>

          <div>
            <IconButton onClick={onRotateLeftClick}>
              <RotateLeft className={"pdf-buttons"} />
            </IconButton>
            <IconButton onClick={onRotateRightClick}>
              <RotateRight className={"pdf-buttons"} />
            </IconButton>
          </div>
        </div>
      </Document>
    </div>
  );
};

export default PdfViewer;
